import { useAppSelector } from '../..'
import { type BreadcrumbT } from '../../../components/CustomBreadcrumbs'
import { type Division, type DivisionResponse, type SurveyCompletionRate } from '../../../models/Division'
import { type Report } from '../../../models/Report'
import { type ITerm } from '../../../models/Term'

export const getDivisonReportData = (): DivisionResponse | null => useAppSelector((state) => state.report.divisions)
export const isReportDataLoading = (): boolean => useAppSelector((state) => Boolean(state.report.isReportLoading) || Boolean(state.report.isBreadCrumbLoading) || state.report.isCompletionRateLoading)
export const getCompletionRateData = (): SurveyCompletionRate | null => useAppSelector((state) => state.report.completionRate)
export const getReportsData = (): Report[] | null => useAppSelector((state) => state.report.report)
export const isReportDataHavingError = (): string | null => useAppSelector((state) => state.report.error)
export const getBreadCrumData = (): BreadcrumbT[] | null => useAppSelector((state) => state.report.breadCrumb)
export const getDivisionData = (): Division | null => useAppSelector((state) => state.report.divisions)
export const getTermsList = (): ITerm[] => useAppSelector((state) => state.report.termsList ?? [])
export const getCompletionRateError = (): string | null => useAppSelector((state) => state.report.completionRateError)
export const getReportError = (): Record<string, any> | null => useAppSelector((state) => state.report.reportError)
