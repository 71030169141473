import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../store'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../../components/CustomTable/types'
import { type GridSortItem } from '@mui/x-data-grid'
import PAGE_TITLES from '../../../consts/titles'
import LoadingPageV2 from '../../../pages/LoadingPageV2'
import { useParams } from 'react-router-dom'
import { getEnrollmentList, enrollmentsLoading } from '../../../store/slices/termsSectionsSlice/selectors'
import { DeleteEnrollment } from './DeleteEnrollment'
import { Box } from '@mui/material'
import { TopbarActions } from './TopbarActions'
import { CreateEnrollment } from './CreateEnrollment'
import { getEnrollmentsApi } from '../../../store/slices/termsSectionsSlice/api'
import { exportCsv } from '../../../utils/exportCsv'
import { notification } from '../../../components/Notifications'
import api from '../../../utils/api'
import { getTerm } from '../../../store/slices/termsSlice/selectors'

const AppTable = lazy(async () => await import('../../../components/CustomTable'))

const Enrollments: React.FC = () => {
  const [params, setParams] = useState<ApplyFiltersAndDispatchParams>()

  const { id } = useParams()

  const loading = enrollmentsLoading()
  const enrollmentsList = getEnrollmentList()
  const dispatch = useAppDispatch()
  const term = getTerm()

  useEffect(() => {
    void dispatch(getEnrollmentsApi({ urlId: id, params }))
  }, [])

  const columns = enrollmentsList?.keys ?? []
  const rows: Row[] = enrollmentsList?.data ?? []

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get(`/api/terms/${term?.termId}/enrollments/export/`)
      const data = await response?.data

      if (data) {
        exportCsv(data, 'student-enrollments_export.csv')
      }
    } catch (error: any) {
      notification(error?.response?.data?.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  return (
    <Box mt={5}>
      <Suspense fallback={<LoadingPageV2 title={PAGE_TITLES.TERMS} />}>
        <AppTable
          topbarTitle={`Student Enrollments (${enrollmentsList?.pagination?.totalResults ?? 0})`}
          createAction={<CreateEnrollment params={params} />}
          exportCsv={() => { void handleExport() }}
          customTopbarAction={<TopbarActions params={params} />}
          editAction={<CreateEnrollment params={params} />}
          list={enrollmentsList}
          rows={rows}
          loading={loading}
          deleteAction={<DeleteEnrollment params={params} />}
          columns={columns}
          featureName="Student Enrollment"
          params={params}
          setParams={setParams}
        />
      </Suspense>
    </Box>
  )
}

export default Enrollments
