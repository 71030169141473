import { createAsyncThunk, type AsyncThunk } from '@reduxjs/toolkit'
import axios, { type AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios'

import api from './utils/api'
import ROUTES from './consts/paths'

interface ApiError extends AxiosError {
  response?: AxiosResponse
}

interface ThunkApiConfig {
  rejectValue: ApiError
};

interface Payload extends AxiosRequestConfig {
  urlId?: string
  urlIds?: string[]
  data?: Record<string, any>
  isDivisionEditable?: boolean
}

/**
 * This function is used to make all the API calls on the frontend, and allows for great flexibility since user can pass in options and dispatchOptions of type AxiosRequestConfig during calling this function ans also while dispatching.
 * @param typePrefix A string that will be used to generate additional Redux action type constants
 * @param options An object of type AxiosRequestConfig, that should contain the method, url etc properties of the API call
 * @returns Returns an AsyncThunk Object
 *
 * examples of multiple urlIds:
 * - url: '/api/resource/<resourceId>/details/<detailId>'
 * - url: url: '/api/student/<studentId>/grades/<gradeId>'
 */

const urlReplacement = (url: string | undefined, urlIds: string[], urlId: string): string | undefined => {
  return urlIds.length > 0
    ? url?.replace(/<[^>]+>/g, () => urlIds.shift() ?? '')
    : url?.replace(/<[^>]+>/g, () => urlId ?? '')
}

const createApiThunk = <Returned,>(
  typePrefix: string,
  options: AxiosRequestConfig
): AsyncThunk<Returned, Payload, ThunkApiConfig> => {
  return createAsyncThunk<Returned, Payload, { rejectValue: ApiError }>(typePrefix, async (payload: Payload, thunkApi) => {
    try {
      const { urlId = '', urlIds = [], data, ...dispatchOptions } = payload
      let { url } = options
      url = urlReplacement(url, urlIds, urlId)

      const config: AxiosRequestConfig = {
        ...options,
        data,
        url,
        withCredentials: true,
        ...dispatchOptions
      }
      const response = await api.request<Returned>(config)
      if (response.headers.contentType === 'text/csv' || response.headers['content-type'] === 'text/csv') {
        return response as Returned
      }
      return response.data
    } catch (error: any) {
      let rejectedValue: ApiError

      if (axios.isAxiosError(error)) {
        rejectedValue = error
      } else {
        rejectedValue = {
          name: 'ApiError',
          message: error.message ?? 'An error occurred during the API request.',
          stack: error.stack ?? '',
          config: error.config ?? {},
          code: error.code ?? '',
          response: error.response ?? undefined,
          isAxiosError: false,
          toJSON () {
            return {
              name: this.name,
              message: this.message,
              stack: this.stack,
              config: this.config,
              code: this.code,
              response: this.response,
              isAxiosError: this.isAxiosError
            }
          }
        }
      }

      return thunkApi.rejectWithValue(rejectedValue)
    }
  }
  )
}

export default createApiThunk
