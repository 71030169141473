import {
  configureStore,
  combineReducers,
  type AnyAction,
  type Reducer,
  createAction
} from '@reduxjs/toolkit'

import { useDispatch, type TypedUseSelectorHook, useSelector } from 'react-redux'

import reportSlice from './slices/reportSlice'
import reportHierarchySlice from './slices/reportHierarchySlice'
import surveySlice from './slices/SurveySlice'
import academicUnitSlice from './slices/academicUnitSlice'
import instructorSlice from './slices/instructorSlice'
import studentDistributionSlice from './slices/studentSurveyListSlice'
import instructorDistributionSlice from './slices/InstructorSurveyListSlice'
import configSlice from './slices/configSlice'
import facultyAndStaffSlice from './slices/facultyAndStaffSlice'
import studentsSliceSlice from './slices/studentsSlice'
import rolesSlice from './slices/roleSlice'
import termsSlice from './slices/termsSlice'
import surveyTemplateSlice from './slices/SurveyTemplateSlice'
import termHeaderSlice from './slices/TermHeaderSlice'
import distributionSeriesSlice from './slices/distributionSeriesSlice'
import distributionPlanSlice from './slices/distributionPlanSlice'
import courseCatalogSlice from './slices/courseCatalogSlice'
import termsSectionsSlice from './slices/termsSectionsSlice'

const combinedReducer = combineReducers({
  report: reportSlice,
  reportHierarchy: reportHierarchySlice,
  survey: surveySlice,
  academicUnit: academicUnitSlice,
  instructor: instructorSlice,
  studentDistributions: studentDistributionSlice,
  instructorDistributions: instructorDistributionSlice,
  config: configSlice,
  distributionPlan: distributionPlanSlice,
  distributionSeries: distributionSeriesSlice,
  facultyAndStaff: facultyAndStaffSlice,
  students: studentsSliceSlice,
  roles: rolesSlice,
  terms: termsSlice,
  termHeader: termHeaderSlice,
  surveyTemplate: surveyTemplateSlice,
  courseCatalog: courseCatalogSlice,
  termsSections: termsSectionsSlice
})

export const resetAction = createAction('RESET')

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === resetAction.type) {
    state = undefined
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer
})

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
