import React, { useEffect, useState } from 'react'
import useAsyncEffect from 'use-async-effect'
import LoadingPageV2 from '../LoadingPageV2'
import { useParams, useSearchParams } from 'react-router-dom'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'
import { useAppDispatch } from '../../store'
import { getBreadCrumbList, getCompletionRate, getTermsListApi, getDivisionCSVExport, getDivisionPDFExport, getReports } from '../../store/slices/reportSlice/apis'
import { getBreadCrumData, isReportDataHavingError, getReportsData, getCompletionRateData, isReportDataLoading, getCompletionRateError } from '../../store/slices/reportSlice/selectors'
import { resetState } from '../../store/slices/reportSlice'
import ReportsFeature from '../../features/ReportsFeatureV2'
import { type CustomBreadcrumbT, prepareBreadCrumbs } from '../../utils/BreadcrumbDataPreparation'
import ROUTES from '../../consts/paths'
import SkipNavigation from '../../components/SkipNavigation'
import { notification } from '../../components/Notifications'
import { getIsTermHeaderReady, getSelectedSeries, getSelectedTerm } from '../../store/slices/TermHeaderSlice/selectors'
import { Box } from '@mui/material'
import { getAllDistributionSeriesList } from '../../store/slices/distributionSeriesSlice/apis'
import EmptyListCard from '../../features/InstructorDashboardFeature/components/EmptyListCard'
import ReportsControls from '../../features/ReportsFeatureV2/components/ReportsControls'

const ReportsPage: React.FC = (): JSX.Element => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const breadCrumbData = getBreadCrumData()
  const isError = isReportDataHavingError()
  const completionRateError = getCompletionRateError()
  const isLoading = isReportDataLoading()
  const reports = getReportsData()
  const completionRate = getCompletionRateData()
  const selectedTerm = getSelectedTerm()
  const selectedSeries = getSelectedSeries()
  const isTermHeaderReady = getIsTermHeaderReady()
  const [searchParams] = useSearchParams()

  const params = { termId: searchParams.get('termId') ?? selectedTerm?.termId, distributionSeriesId: selectedSeries?.distributionSeriesId }

  useAsyncEffect(async () => {
    dispatch(resetState())
    await dispatch(getBreadCrumbList({ urlId: id ?? '' }))
  }, [id])

  useEffect(() => {
    void dispatch(getAllDistributionSeriesList({}))
  }, [])

  useEffect(() => {
    if (selectedSeries) {
      void dispatch(getTermsListApi({ params: { distributionSeriesId: selectedSeries?.distributionSeriesId } }))
    }
  }, [selectedSeries])

  useAsyncEffect(async () => {
    if (isTermHeaderReady && breadCrumbData !== null && breadCrumbData.length > 0) {
      const divisionId = breadCrumbData[breadCrumbData.length - 1].id
      const opts = {
        urlId: divisionId,
        params
      }
      await dispatch(getCompletionRate(opts))
      await dispatch(getReports(opts))
    }
  }, [breadCrumbData, selectedTerm, selectedSeries])

  const breadcrumbPrefixList: CustomBreadcrumbT[] = []

  const preparedBreadCrumbList = breadcrumbPrefixList.concat(prepareBreadCrumbs(breadCrumbData ?? [], ROUTES.REPORTS))
  const isReportsReady = reports && completionRate

  const downloadCSVFile = async (): Promise<any> => {
    if (breadCrumbData === null || breadCrumbData === undefined || breadCrumbData.length <= 0) {
      return
    }

    const divisionId = breadCrumbData[breadCrumbData.length - 1].id
    if (divisionId === null || divisionId === undefined) {
      return
    }

    try {
      const result = await dispatch(getDivisionCSVExport({ urlId: divisionId, params })).unwrap()
      const href = URL.createObjectURL(result?.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `report-${divisionId}.csv`)
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (err) {
      console.log(err)
      notification('CSV export error', { variant: 'error', subTitle: 'An error occurred while trying to export the CSV' })
    }
  }

  const downloadPDFReport = async (): Promise<any> => {
    if (!breadCrumbData || breadCrumbData.length <= 0) {
      return
    }

    const divisionId = breadCrumbData[breadCrumbData.length - 1].id
    if (!divisionId) {
      return
    }

    try {
      const result = await dispatch(getDivisionPDFExport({ urlId: divisionId, params })).unwrap()
      const blob = new Blob([result], { type: 'application/pdf' })
      const href = URL.createObjectURL(blob)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `report-${divisionId}.pdf`)
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (err) {
      console.log(err)
      notification('PDF export error', { variant: 'error', subTitle: 'An error occurred while trying to export the PDF' })
    }
  }

  if (isLoading) {
    return (<LoadingPageV2 title={PAGE_TITLES.REPORTS} />)
  }

  if (!isLoading && !completionRate && completionRateError) {
    return <>
      <ReportsControls reportHasError={true} downloadPDFReport={downloadPDFReport} downloadCSVFile={downloadCSVFile} />
      <EmptyListCard message={completionRateError ?? ''} />
    </>
  }

  return (
    <>
      <CustomHeader info={preparedBreadCrumbList?.length ? preparedBreadCrumbList[preparedBreadCrumbList.length - 1].title : PAGE_TITLES.REPORTS} />
      <SkipNavigation />

      {
        completionRate &&
        <ReportsFeature
          breadcrumb={preparedBreadCrumbList}
          reports={reports}
          completionRate={completionRate}
          controls={<ReportsControls reportHasError={isError !== null} downloadPDFReport={downloadPDFReport} downloadCSVFile={downloadCSVFile} />}
        />
      }

      {
        !isLoading && !isReportsReady && !isError && !reports?.length && !completionRate &&
        <Box mt={6}>
          {!completionRate && <ReportsControls reportHasError={true} downloadPDFReport={downloadPDFReport} downloadCSVFile={downloadCSVFile} />}
          <EmptyListCard />
        </Box>
      }

    </>
  )
}

export default ReportsPage
