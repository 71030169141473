import React from 'react'
import { Helmet } from 'react-helmet'
import type PAGE_TITLES from '../../consts/titles'

export interface CustomHeaderProps {
  title?: PAGE_TITLES
  info?: string
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  title,
  info
}): JSX.Element => {
  return (
    <Helmet>
      <title>
        {title ?? info ?? 'ClassRanked'}
      </title>
    </Helmet>
  )
}

export default CustomHeader
