import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'
import { grey } from '../../colors'
import { TextField } from '@mui/material'

interface StyledBoxProps {
  theme?: Theme
}

export const StyledBox = styled(Box)<StyledBoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(10),
  marginTop: theme.spacing(15)
}))

export const StyledQuestionBox = styled(Box)<StyledBoxProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor as string}`,
  borderRadius: theme.spacing(3),
  overflow: 'hidden',
  width: '100%',
  transition: '0.5s'
}))

export const StyledCreateQuestionBox = styled(Box)<StyledBoxProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor as string}`,
  borderRadius: theme.spacing(3),
  overflow: 'hidden',
  width: '100%',
  transition: '0.5s'
}))

export const StyledQuestionBoxHeader = styled(Box)<StyledBoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: grey[100],
  borderBottom: `1px solid ${theme.palette.borderColor as string}`,
  padding: `${theme.spacing(2.25)} ${theme.spacing(0)} ${theme.spacing(2.25)} ${theme.spacing(4.5)}`
}))

export const SectionWrapper = styled(Box)<StyledBoxProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor as string}`,
  borderRadius: theme.spacing(3),
  width: '73%',
  transition: '0.5s',
  padding: theme.spacing(6),
  position: 'relative'
}))

export const StyledTextField = styled(TextField)<StyledBoxProps>(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  width: '73%',
  border: `1px solid ${theme.palette.borderColor as string}`,
  '& input': {
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center'
  },
  '& input::placeholder': {
    opacity: 1,
    color: grey[800],
    fontWeight: '500'
  },
  '& fieldset': {
    border: 'none'
  }
}))

export const PreparedEmptyField = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.5),
  height: theme.spacing(11),
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: theme.spacing(1.5),
  color: '#D3D8DB'
}))
