import { type RootState } from './../../index'
import { useAppSelector } from '../..'
import { type BreadcrumbT } from '../../../components/CustomBreadcrumbs'
import { type Division } from '../../../models/Division'
import { type DataObjectList } from '../../../models/DataObject'

export const getReportDetailsLoading = (): boolean => useAppSelector((state) => state.reportHierarchy.isReportDetailsLoading)
export const getLoading = (): boolean => useAppSelector((state: RootState) =>
  Boolean(state.reportHierarchy.isDivisionDetailsLoading) || Boolean(state.reportHierarchy.isDivisionListLoading))
export const divisionDetails = (): Division | undefined => useAppSelector((state: RootState) => state.reportHierarchy.division?.data ?? null)
export const divisionList = (): DataObjectList<Division> | undefined => useAppSelector((state: RootState) => state.reportHierarchy?.divisionList)
export const getReportDetailsError = (): string | null => useAppSelector((state) => state.reportHierarchy.reportDetailsError)
export const getReportSubmitError = (): string | null => useAppSelector((state) => state.reportHierarchy.reportSubmitError)
export const isReportSubmitLoading = (): boolean => useAppSelector((state) => state.reportHierarchy.isReportSubmitLoading)
export const isReportHierarchyHavingError = (): boolean => useAppSelector((state) => state.reportHierarchy.error)
export const getReportModalOpen = (): boolean => useAppSelector((state) => state.reportHierarchy.editModalOpen)
export const divisionDetailsForForm = (): { selectedRow: Division } | undefined => useAppSelector((state: RootState) => ({ selectedRow: state.reportHierarchy.division?.data ?? null }))
export const isCreateEditDivisionLoading = (): boolean => useAppSelector((state: RootState) => state.reportHierarchy.isCreateDivisionLoading || state.reportHierarchy.isEditDivisionLoading)
export const getBreadcrumbListData = (): BreadcrumbT[] | null => useAppSelector((state) => state.reportHierarchy.breadcrumbs)
export const deleteLoading = (): boolean => useAppSelector((state: RootState) => state.reportHierarchy.deleteLoading)
export const archiveLoading = (): boolean => useAppSelector((state: RootState) => state.reportHierarchy.archiveLoading)
export const getReportUpdateState = (): boolean => useAppSelector((state) => state.reportHierarchy.update)
