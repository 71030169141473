import React, { useState } from 'react'
import { Box } from '@mui/material'
import GenericButton from '../../../components/GenericButton'
import { type Role } from '../../../models/Role'
import GenericAutocomplete from '../../../components/FormElements/FormAutoComplete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ButtonType } from '../../../components/GenericButton/styles'
import { shade } from '../../../colors'
import { type Division } from '../../../models/Division'
import { red } from '@mui/material/colors'
import { useQuery } from 'react-query'
import api from '../../../utils/api'
import { isEmpty } from 'lodash'

interface RoleCardProps {
  loading?: boolean
  deleteLoading?: boolean
  selectRoles: Role[]
  deleteRole: () => void
  readOnly?: boolean
  selectedRole?: string
  selectedDivision?: string
  roleFieldName: string
  divisionFieldName: string
  error?: boolean
}

const RoleCard: React.FC<RoleCardProps> = ({
  loading,
  selectRoles,
  deleteRole,
  readOnly = false,
  selectedRole,
  selectedDivision,
  roleFieldName,
  divisionFieldName,
  deleteLoading = false,
  error = false
}) => {
  const [divisionInput, setDivisionInput] = useState<string | undefined>()
  const { data: divisionsList, isLoading: isDivisionsLoading } = useQuery<Division[]>({
    queryKey: ['division', divisionInput, selectedDivision],
    keepPreviousData: true,
    staleTime: 100000,
    queryFn: async (): Promise<Division[]> => {
      let filters

      if (!isEmpty(selectedDivision)) {
        filters = {
          field: 'division_id',
          op: '==',
          value: selectedDivision
        }
      }

      if (divisionInput) {
        filters = {
          field: 'title',
          op: 'like',
          value: `${divisionInput}%`
        }
      }

      const result = await api.get('/api/divisions/', {
        params: {
          pageSize: 10,
          filters: filters ? JSON.stringify(filters) : undefined,
          sort: JSON.stringify({
            field: 'title',
            direction: 'asc'
          })
        }
      })
      return result.data
    }
  })

  return (
    <Box display="flex" flexDirection="column" gap="24px" padding="24px" my="24px" borderRadius="8px" sx={{ border: error ? `2px solid ${red[600]}` : undefined, background: shade[750] }}>
      <GenericAutocomplete selectedValue={selectedRole} readOnly={readOnly} name={roleFieldName} label="Role" options={selectRoles} getOptionLabel={(role) => role?.roleName} getOptionValue={(value) => value?.roleId} loading={loading}/>
      <GenericAutocomplete onInputChange={(value) => { setDivisionInput(value) }} selectedValue={selectedDivision} readOnly={readOnly} name={divisionFieldName} label="Academic Unit" options={divisionsList ?? []} getOptionLabel={(r) => r.title} getOptionValue={(value) => value.divisionId} loading={isDivisionsLoading} />
      <GenericButton onClick={deleteRole} style={{ alignSelf: 'flex-end' }} startIcon={<DeleteOutlineIcon />} label="Delete" buttonType={ButtonType.Destructive} loading={deleteLoading} />
    </Box>
  )
}

export default RoleCard
