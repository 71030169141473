import React from 'react'
import { StyledCreateQuestionBox, StyledQuestionBoxHeader } from '../../../styles'
import { useDispatch } from 'react-redux'
import CreateQuestionCardDropDownMenu from './CreateQuestionCardDropDownMenu/index'
import { StyledBoxWrapper } from './styles'
import { grey } from '../../../../../colors'
import { getEditableQuestion } from '../../../../../store/slices/SurveyTemplateSlice/selectors'
import { deleteQuestionFromSection, setSelectedQuestion } from '../../../../../store/slices/SurveyTemplateSlice'

interface CreateQuestionCardWrapperProps {
  children: JSX.Element
  questionNumber?: number
}

const CreateQuestionCardWrapper: React.FC<CreateQuestionCardWrapperProps> = ({ children, questionNumber }): JSX.Element => {
  const dispatch = useDispatch()
  const editableQuestion = getEditableQuestion()
  const { sectionIndex, questionIndex } = editableQuestion ?? {}

  const handleDelete = (): void => {
    if (editableQuestion?.question) {
      dispatch(deleteQuestionFromSection({ sectionIndex, questionIndex }))
      return
    }
    dispatch(setSelectedQuestion(null))
  }

  return (
    <StyledCreateQuestionBox>
      <StyledQuestionBoxHeader sx={{ color: grey[700], fontWeight: 500 }} >
        Question {questionNumber}
        <CreateQuestionCardDropDownMenu handleDelete={handleDelete} />
      </StyledQuestionBoxHeader>
      <StyledBoxWrapper >
        {children}
      </StyledBoxWrapper>
    </StyledCreateQuestionBox>
  )
}

export default CreateQuestionCardWrapper
