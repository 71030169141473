import React, { useState } from 'react'
import { getTerm } from '../../../store/slices/termsSlice/selectors'
import { Box, Typography } from '@mui/material'
import { Text, Title } from './styles'
import GenericButton from '../../../components/GenericButton'
import GenericModal from '../../../components/GenericModal'
import { CreateAndEditTerms } from '../../Terms/CreateAndEditTerms'
import { formatDateByUserTimeZone } from '../../../helpers/formatDateByUserTimeZone'
import { grey } from '../../../colors'

const TermsDetails: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const termsDetails = getTerm()

  const handleCloseModal = (): void => {
    setOpenModal(false)
  }

  return (
        <>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt={8} mb={6}>
                <Typography color={grey[250]} fontSize='18px' fontWeight='600'>Terms</Typography>
                <GenericButton label='Edit' onClick={() => { setOpenModal(true) }} style={{ color: grey[250] }} fontWeight='600' />
            </Box>

            <Box display='flex' flexDirection='column' gap={6}>
                <Box>
                    <Title>Title:</Title>
                    <Text>{termsDetails?.termName ?? ''}</Text>
                </Box>
                <Box>
                    <Title>Start Date:</Title>
                    <Text>{formatDateByUserTimeZone(termsDetails?.startTimestamp ?? new Date(), 'MM/d/yyyy hh:mm a')}</Text>
                </Box>
                <Box>
                    <Title>End Date:</Title>
                    <Text>{formatDateByUserTimeZone(termsDetails?.endTimestamp ?? new Date(), 'MM/d/yyyy hh:mm a')}</Text>
                </Box>
            </Box>
            <GenericModal
                isOpen={openModal}
                maxWidth='sm'
                title={'Edit Term'}
                onClose={() => {
                  setOpenModal(false)
                }}
                action={false}
            >
                <CreateAndEditTerms selectedRow={termsDetails} handleCloseModal={handleCloseModal}/>
            </GenericModal>
        </>
  )
}

export default TermsDetails
