import React from 'react'
import { Div } from './styles'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AdminMenuItems from '../AdminMenuItems'
import ReportMenuItems from '../ReportMenuItems'
import AccountDropdown from '../AccountDropdown'

const TabMenu: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Div isMobile={isMobile}>
      <Box display={'flex'} gap={1}>
        <ReportMenuItems />
        <AdminMenuItems />
      </Box>
      <AccountDropdown />
    </Div>
  )
}

export default TabMenu
