import React, { useState } from 'react'
import { Text, Title } from './styles'
import { Box, Typography } from '@mui/material'
import GenericButton from '../../../components/GenericButton'
import GenericModal from '../../../components/GenericModal'
import { CreateAndEditDistributionPlan } from '../../DistributionSeriesAndPlan/DistributionPlan/CreateAndEditDistributionPlan'
import { type ISlot } from '../../../models/DistributionPlan'
import { formatDateByUserTimeZone } from '../../../helpers/formatDateByUserTimeZone'

interface DistributionPlanDetailsProps {
  slot: ISlot
}

const DistributionPlanDetails: React.FC<DistributionPlanDetailsProps> = ({ slot }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const releaseTimestamp = slot.releaseTimestamp

  const handleCloseModal = (): void => {
    setOpenModal(false)
  }
  const formattedResultsDate = formatDateByUserTimeZone(releaseTimestamp)

  return (
    <Box>
      <Box display='flex' justifyContent='space-between'>
        <Typography color='black' marginBottom={0} fontSize='20px'>Distribution Plan</Typography>
      <GenericButton label='Edit' onClick={() => { setOpenModal(true) }} />
      </Box>
      <Box display='flex' flexDirection='column' gap={6}>
        <Box>
          <Title>Term</Title>
          <Text>{slot?.term?.termName}</Text>
        </Box>
        <Box>
          <Title>Survey:</Title>
          <Text>{slot?.surveyTemplate?.title}</Text>
        </Box>
        <Box>
          <Title>Default Report Release Date:</Title>
          <Text>{formattedResultsDate}</Text>
        </Box>
      </Box>
      <GenericModal
        isOpen={openModal}
        maxWidth='sm'
        title={'Edit Distribution Plan'}
        onClose={() => {
          setOpenModal(false)
        }}
        action={false}
      >
        <CreateAndEditDistributionPlan selectedRow={slot} handleCloseModal={handleCloseModal} />
      </GenericModal>
    </Box>
  )
}

export default DistributionPlanDetails
