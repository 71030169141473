import React from 'react'
import GenericTabs from '../../components/Tabs'
import { studentsAndFacultyTabs } from '../../consts/studentsAndFacultyTabs'
import FacultyAndStaff from '../../features/FacultyAndStaff'
import PageHeader from '../../components/PageHeader'
import PAGE_TITLES from '../../consts/titles'
import CustomHeader from '../../components/CustomHeader'

const Page = (): JSX.Element => {
  return (
    <>
      <CustomHeader title={PAGE_TITLES.USERS} />
      <PageHeader title={PAGE_TITLES.USERS} />
      <GenericTabs tabs={studentsAndFacultyTabs} />
      <FacultyAndStaff />
    </>
  )
}

export default Page
