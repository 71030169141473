import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { grey, shade } from '../../../colors'

interface SectionNameProps {
  theme?: Theme
}

export const SectionName = styled(Typography)<SectionNameProps>(({ theme }) => ({
  color: grey[250],
  fontWeight: 500,
  cursor: 'pointer',
  maxWidth: 'fit-content',
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  borderRadius: theme.spacing(2),
  '&:hover': {
    background: shade[150]
  }
}))
