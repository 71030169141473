import React from 'react'

import { Container } from '@mui/system'
import useAppBarHeight from '../../hooks/useAppBarHeight'

export interface CenteredContainerProps {
  children: React.ReactNode
}

const CenteredContainer: React.FC<CenteredContainerProps> = ({
  children
}): JSX.Element => {
  const appbarHeight = useAppBarHeight()
  return (
  // @TODO sx or style, I think sx or a separatefile for style
        <Container style={{
          display: 'flex',
          height: `calc(100vh - ${2.5 * appbarHeight}px)`,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {children}
        </Container>
  )
}

export default CenteredContainer
