import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useAppDispatch } from '../../store'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../components/CustomTable/types'
import LoadingPage from '../../pages/LoadingPage'
import StudentsTopbarActions from './StudentsTopbarActions'
import { getStudentsApi } from '../../store/slices/studentsSlice/apis'
import { getLoading, getStudentsList } from '../../store/slices/studentsSlice/selectors'
import DeleteFacultyAndStudent from '../FacultyAndStaff/DeleteFaculty'
import CreateAndEditStudents from './CreateAndEditStudents'
import { exportCsv } from '../../utils/exportCsv'
import { notification } from '../../components/Notifications'
import api from '../../utils/api'

const AppTable = lazy(async () => await import('../../components/CustomTable'))

const Students = (): JSX.Element => {
  const isLoading = getLoading()
  const studentList = getStudentsList()
  const dispatch = useAppDispatch()

  const [params, setParams] = useState<ApplyFiltersAndDispatchParams>()

  const columns = studentList?.keys ?? []
  const rows: Row[] = studentList?.data ?? []

  useEffect(() => {
    void dispatch(getStudentsApi({ params }))
  }, [params])

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get('/api/users/export/?is_student=True')
      const data = response?.data

      if (data) {
        exportCsv(data, 'students_export.csv')
      }
    } catch (error: any) {
      notification(error?.response?.data?.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <AppTable
        topbarTitle={'Students'}
        customTopbarAction={<StudentsTopbarActions/>}
        exportCsv={() => { void handleExport() }}
        list={studentList}
        rows={rows}
        loading={isLoading}
        columns={columns}
        featureName="User"
        editAction={<CreateAndEditStudents/>}
        deleteAction={<DeleteFacultyAndStudent params={params} isStudent={true}/>}
        actionModalEntityDisplayField='firstName'
        params={params}
        setParams={setParams}
      />
    </Suspense>
  )
}

export default Students
