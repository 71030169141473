import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/system'
import CopyrightFooter from '../CopyrightFooter'
import { useMediaQuery, useTheme } from '@mui/material'

const WithFooterOutlet: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))

  const calculateAppbarOffset = (): string => {
    switch (true) {
      case isMobile:
        return `${theme.spacing(12)}`
      case isTablet:
        return `${theme.spacing(14)}`
      default:
        return `${theme.spacing(16)}`
    }
  }

  return (
    <Box marginTop={calculateAppbarOffset()}
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column'
      }}>
      <Box
        component='main'
        sx={{
          flexGrow: '1'
        }}>
        <Outlet />
      </Box>

      <CopyrightFooter />
    </Box>
  )
}

const MemoizedFooterOutlet = memo(WithFooterOutlet)

export default MemoizedFooterOutlet
