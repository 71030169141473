import React from 'react'
import styled from '@mui/system/styled'
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded'
import Typography from '@mui/material/Typography'
import { blue } from '../../../../colors'

const StyledBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '50vh'
}))

const StyledIcon = styled('div')(({ theme }) => ({
  backgroundColor: blue[100],
  padding: `${theme.spacing(6)}`,
  borderRadius: '100%',
  margin: `${theme.spacing(6)}`
}))

const EmptyListCard = (): JSX.Element => {
  return (
    <StyledBox>
      <StyledIcon>
        <DoneOutlineRoundedIcon fontSize="large" color="primary"/>
        </StyledIcon>
      <Typography variant="h3">No surveys available.</Typography>
    </StyledBox>
  )
}

export default EmptyListCard
