import { styled } from '@mui/system'
import { Button } from '@mui/material'
import type { Theme } from '@mui/material/styles'

interface StyledButtonProps {
  theme?: Theme
  isLastButton: boolean
}

export const StyledButton = styled(Button)<StyledButtonProps>(({ theme, isLastButton }) => ({
  textTransform: 'none',
  justifyContent: 'flex-start',
  padding: 0,
  color: isLastButton ? theme.palette.primary.main : theme.palette.text.disabled,
  minWidth: 0,
  '&:disabled': {
    color: theme.palette.primary.main
  }
}))
