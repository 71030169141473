/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { FormProvider, useForm, type SubmitHandler, type FieldValues } from 'react-hook-form'
import FormInput from '../../../components/FormElements/FormInput'
import GenericDateTimePicker from '../../../components/GenericDateTimePicker'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '../../../store'
import * as yup from 'yup'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'
import ValidationAlert from '../../../components/ValidationAlert'
import { createTermsApi, updateTermsApi, getAllTermsApi } from '../../../store/slices/termsSlice/api'
import { getCreateAndUpdateLoading } from '../../../store/slices/termsSlice/selectors'
import _ from 'lodash'
import { formatDateWithTime } from '../../../helpers/formatDateWithTime'

interface FormType {
  termName: string
  startTimestamp: Date
  endTimestamp: Date
}

interface CreateAndEditTermsProps {
  selectedRow?: Record<string, any> | null
  handleCloseModal?: () => void
}

export const CreateAndEditTerms: React.FC<CreateAndEditTermsProps> = ({ selectedRow, handleCloseModal = () => { } }: CreateAndEditTermsProps): JSX.Element => {
  const [errors, setErrors] = useState<unknown>({})

  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isEditAction = selectedRow !== undefined
  const createAndUpdateLoading = getCreateAndUpdateLoading()

  const { termName, startTimestamp, endTimestamp } = selectedRow ?? {}

  const termFormSchema = yup.object().shape({
    termName: yup.string().required('Term name is required'),
    startTimestamp: yup
      .date()
      .required('Start Date is required')
      .max(yup.ref('endTimestamp'), 'Start Date must be before End Date'),
    endTimestamp: yup
      .date()
      .required('End Date is required')
      .min(yup.ref('startTimestamp'), 'End Date must be after Start Date')
  })

  const methods = useForm<FormType>({
    resolver: yupResolver(termFormSchema),
    defaultValues: {
      termName, startTimestamp, endTimestamp
    }
  })
  const { handleSubmit, control, formState: { isDirty, dirtyFields } } = methods

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const { termName, startTimestamp, endTimestamp } = data
    const isEditing = isEditAction
    try {
      const payload = {
        termName,
        startTimestamp: formatDateWithTime(startTimestamp),
        endTimestamp: formatDateWithTime(endTimestamp)
      }
      // TODO Need to fix this sswap of date time string
      const changedFields = _.pickBy(data, (_, key) => (dirtyFields as FieldValues)[key])
      const valuesParsed: { termName?: string, startTimestamp?: string, endTimestamp?: string } = {}
      if (changedFields?.startTimestamp) {
        valuesParsed.startTimestamp = formatDateWithTime(changedFields.startTimestamp)
      }
      if (changedFields?.endTimestamp) {
        valuesParsed.endTimestamp = formatDateWithTime(changedFields.endTimestamp)
      }
      const apiCall = isEditing
        ? dispatch(updateTermsApi({ urlId: selectedRow?.termId, data: { ...changedFields, ...valuesParsed } })).unwrap()
        : dispatch(createTermsApi({ data: payload })).unwrap()

      await apiCall
      !isEditing && await dispatch(getAllTermsApi({}))
      handleCloseModal()
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <ValidationAlert errors={errors} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' flexDirection='column' gap={theme.spacing(6)}>
          <FormInput
            name="termName"
            label="Title"
            placeholder='Title'
            control={control}
            required
          />

          <GenericDateTimePicker
            name="startTimestamp"
            label="Start Date"
            required
          />
          <GenericDateTimePicker
            name="endTimestamp"
            label="End Date"
            required
          />
        </Box>

        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditAction ? 'Save' : 'Create',
            loading: createAndUpdateLoading,
            type: 'submit',
            disabled: isEditAction && !isDirty
          }}
        />
      </form>
    </FormProvider>
  )
}
