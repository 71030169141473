enum PAGE_TITLES {
  REPORTS = 'Reports',
  REPORTING_HIERARCHY = 'Reporting Hierarchy',
  COURSE_CATALOG = 'Course Catalog',
  ACADEMIC_UNITS = 'Academic Units',
  SURVEYS = 'Surveys',
  MY_SURVEYS = 'My Surveys',
  SURVEY_TEMPLATE = 'Survey Template',
  USERS = 'Users',
  TERMS = 'Terms',
  INTEGRATIONS = 'Integrations',
  ACCOUNTS = 'Accounts',
  LOGIN = 'Login',
  LOADING = 'Loading...',
  NOT_FOUND = '404 Not Found',
  SURVEY_COMPLETED = 'Survey Completed',
  SURVEY_CLOSED = 'Survey Closed',
  SETTINGS = 'Settings',
  FACULTY_AND_STAFF = 'Faculty and Staff',
  STUDENTS = 'Students',
  STUDENT_SURVEYS = 'Student Surveys',
  INSTRUCTOR_DASHBOARD = 'Instructor Dashboard',
  ROLES = 'Roles',
  REPORTING = 'Reporting',
  DISTRIBUTION_SERIES = 'Distribution Series',
  COURSE_ENROLLMENT = 'Course Evaluation',
  NO_PERMISSIONS = 'No permissions',
  UNTITLED_SURVEY_TEMPLATE = 'Untitled Survey Template',
  SURVEY_DISTRIBUTION = 'Survey Distribution',
  TASKS = 'Activity Monitor'
}

export default PAGE_TITLES
