import React, { useMemo, useState } from 'react'
import { Autocomplete, Box, Checkbox, Paper, TextField, Typography, FormLabel } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
import { grey, shade } from '../../colors'
import { type IUser } from '../../models/User'
import { useTheme } from '@mui/material/styles'
import { Controller, type FieldValues, useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import api from '../../utils/api'
import { debounce } from 'lodash'

const icon = <Box border={`1px solid ${grey[350]}`} borderRadius='4px' width='20px' height='20px' />

interface MultipleInstructorsSelectProps {
  name: string
  disabled?: boolean
  loading?: boolean
}

const MultipleInstructorsSelect: React.FC<MultipleInstructorsSelectProps> = ({ name, disabled, loading = false }) => {
  const theme = useTheme()
  const [input, setInput] = useState<string>()
  const { control } = useFormContext<FieldValues>()

  const onInputChange = (value: string): void => {
    setInput(value)
  }

  const getDebouncedInput = useMemo(
    () => debounce(onInputChange, 500),
    [onInputChange]
  )

  const { data: instructorList, isLoading: isInstructorListLoading } = useQuery<IUser[]>({
    queryKey: ['instructorList', input],
    keepPreviousData: true,
    staleTime: 100000,
    queryFn: async (): Promise<IUser[]> => {
      const result = await api.get('/api/users/', {
        params: {
          pageSize: 10,
          filters: input !== undefined
            ? JSON.stringify({
              field: 'name',
              op: 'like',
              value: `${input}%`
            })
            : undefined,
          sort: JSON.stringify({
            field: 'name',
            direction: 'asc'
          })
        }
      })
      return result.data
    }
  })

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
        <Box>
          <FormLabel sx={{ color: grey[950], fontSize: '14px' }}>Instructor(s)</FormLabel>
          <Autocomplete
            loading={loading || isInstructorListLoading}
            multiple
            size='small'
            value={field.value || []}
            onChange={(event, newValue) => { field.onChange(newValue) }}
            options={instructorList ?? []}
            disableCloseOnSelect
            clearIcon={<CancelIcon sx={{ color: grey[950] }} />}
            getOptionLabel={(option) => option.email}
            isOptionEqualToValue={(option, value) => option.userId === value.userId}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                getDebouncedInput(value)
              }
            }}
            popupIcon={<ExpandMoreIcon sx={{ color: grey[150] }} />}
            placeholder='Instructor Name'
            sx={{ mt: theme.spacing(2) }}
            PaperComponent={({ children }) => (
              <Paper
                elevation={4}
                sx={{
                  marginTop: theme.spacing(2),
                  '& ::-webkit-scrollbar': { width: theme.spacing(2.5) },
                  '& ::-webkit-scrollbar-thumb': { backgroundColor: shade[650], borderRadius: theme.spacing(2) },
                  '& ::-webkit-scrollbar-track': { backgroundColor: 'transparent' }
                }}
              >
                {children}
              </Paper>
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ width: '98%' }}>
                <Box display='flex'>
                  <Checkbox
                    icon={icon}
                    checked={selected}
                    sx={{ mt: theme.spacing(-3.5) }}
                    disableRipple
                  />
                  <Box display='flex' flexDirection='column'>
                    <Typography fontSize='14px' color={grey[250]} fontWeight={500}>{option.name}</Typography>
                    <Typography fontSize='14px' color={grey[850]}>{option.email}</Typography>
                  </Box>
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={false}
                helperText=''
              />
            )}
            renderTags={(value, getTagProps) => (
              <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '4px' }}>
                {value.map(option => option.name).join(', ')}
              </Typography>
            )}
            disabled={disabled}
          />
        </Box>
        )
      }
    }
    />
  )
}

export default MultipleInstructorsSelect
