import React, { useEffect, useState } from 'react'
import CustomHeader from '../../components/CustomHeader'
import { useAppDispatch } from '../../store'
import PAGE_TITLES from '../../consts/titles'
import { getStudentDistributions } from '../../store/slices/studentSurveyListSlice/apis'
import { getStudentDistributionsList, isStudentDistributionsLoading } from '../../store/slices/studentSurveyListSlice/selectors'
import SurveyListFeature from '../../features/StudentSurveyListFeature'
import EmptyListCard from '../../features/StudentSurveyListFeature/components/EmptyListCard'
import useAsyncEffect from 'use-async-effect'
import { resetState } from '../../store/slices/studentSurveyListSlice/index'
import _ from 'lodash'
import { Grid } from '@mui/material'
import SkipNavigation from '../../components/SkipNavigation'
import LoadingPageV2 from '../LoadingPageV2'
import useProfile from '../../hooks/useProfile'
import PageHeader from '../../components/PageHeader'

const getStudentName = (userAttributes: any): string => {
  if (userAttributes.given_name !== null && userAttributes.family_name !== null) {
    return `${userAttributes.given_name as string} ${userAttributes.family_name as string}`
  } else if (userAttributes.given_name !== null) {
    return userAttributes.given_name
  } else {
    return 'Surveys'
  }
}

const StudentDashboardPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const studentDistributions = getStudentDistributionsList()
  const isLoading = isStudentDistributionsLoading()
  const [studentName, setStudentName] = useState('')
  const [dataLoaded, setDataLoaded] = useState(false)
  const { user } = useProfile()

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [dispatch])

  useAsyncEffect(async () => {
    await dispatch(getStudentDistributions({}))
    setStudentName(user?.name ?? 'student')
    setDataLoaded(true)
  }, [])

  const sortedStudentDistributions = _.orderBy(
    studentDistributions,
    ['end_timestamp', 'title'],
    ['asc', 'desc']
  )

  const renderConditionalContent = (): JSX.Element => {
    if (dataLoaded) {
      if (sortedStudentDistributions != null && sortedStudentDistributions.length > 0) {
        return <SurveyListFeature list={sortedStudentDistributions} studentName={studentName} />
      } else {
        return (
          <React.Fragment>
            <PageHeader
              title={studentName}
              pageTitleAriaLabel={`Page title ${studentName}`}
              isCompactOnly
            />
            <EmptyListCard />
          </React.Fragment>
        )
      }
    } else {
      return <></>
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <SkipNavigation />
      <Grid item>
        <CustomHeader title={PAGE_TITLES.STUDENT_SURVEYS} />
        {isLoading && !dataLoaded && (
          <LoadingPageV2 title={PAGE_TITLES.STUDENT_SURVEYS} />
        )}

        {renderConditionalContent()}
      </Grid>
    </Grid>
  )
}

export default StudentDashboardPage
