import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { Authenticator } from '@aws-amplify/ui-react'
import { store } from '../../store'
import CustomTheme from '../../theme'
import { SnackbarProvider } from 'notistack'
import { Notifications } from '../Notifications'
import { QueryClient, QueryClientProvider } from 'react-query'
import { type AxiosError } from 'axios'

declare module 'react-query' {
  interface Register {
    defaultError: AxiosError
  }
}

interface ProvidersProps {
  children: React.ReactNode
}

const SnackbarComponents = {
  info: Notifications,
  success: Notifications,
  error: Notifications
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000)
    }
  }
})

const Providers: React.FC<ProvidersProps> = ({ children }): JSX.Element => {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={CustomTheme}>
          <SnackbarProvider maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            Components={SnackbarComponents}>
            <Authenticator.Provider>
              <QueryClientProvider client={queryClient}>
                {
                  children
                }
              </QueryClientProvider>
            </Authenticator.Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </>
  )
}

export default Providers
