/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form'
import FormInput from '../../../../components/FormElements/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '../../../../store'
import * as yup from 'yup'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import type { IDistributionSeries } from '../../../../models/DistributionSeries'
import { getDistributionSeriesLoading } from '../../../../store/slices/distributionSeriesSlice/selectors'
import { createDistributionSeriesApi, editDistributionSeriesApi } from '../../../../store/slices/distributionSeriesSlice/apis'
import { Alert, Stack } from '@mui/material'
import { isEmpty } from 'lodash'

interface FormType {
  title: string
}

interface CreateAndEditDistributionSeriesProps {
  distributionSeries?: IDistributionSeries
  handleCloseModal?: () => void
}

export const CreateAndEditDistributionSeries: React.FC<CreateAndEditDistributionSeriesProps> = ({
  distributionSeries,
  handleCloseModal = () => { }
}: CreateAndEditDistributionSeriesProps): JSX.Element => {
  const [errors, setErrors] = useState<string | null>(null)

  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isEditing = distributionSeries
  const loading = getDistributionSeriesLoading()

  const { title } = distributionSeries ?? {}

  const distributionSeriesFormSchema = yup.object().shape({
    title: yup.string().required('Title is required')
  })

  const methods = useForm<FormType>({
    resolver: yupResolver(distributionSeriesFormSchema),
    defaultValues: {
      title: title ?? ''
    }
  })
  const { handleSubmit, control, formState: { isDirty, isSubmitting } } = methods

  const onSubmit: SubmitHandler<FormType> = async ({ title }) => {
    try {
      const seriesData = {
        title
      }

      const apiCall = isEditing
        ? dispatch(editDistributionSeriesApi({ urlId: distributionSeries?.distributionSeriesId, data: seriesData })).unwrap()
        : dispatch(createDistributionSeriesApi({ data: seriesData })).unwrap()

      await apiCall
      handleCloseModal?.()
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Box display='flex' flexDirection='column' gap={theme.spacing(6)}>
            <FormInput
              name="title"
              label="Title"
              control={control}
              required
            />
          </Box>

          { !isEmpty(errors) && <Alert severity="error">{errors}</Alert> }
        </Stack>

        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditing ? 'Save' : 'Create',
            loading,
            type: 'submit',
            disabled: isSubmitting || (isEditing && !isDirty)
          }}
        />
      </form>
    </FormProvider>
  )
}

export default CreateAndEditDistributionSeries
