import createApiThunk from '../../../ApplicationFactory'
import { type IUser, type IUserListResponse } from '../../../models/User'

export const getFacultyAndStaffApi = createApiThunk<IUserListResponse>('getFacultyAndStaffApi', {
  url: '/api/users/?is_faculty=True',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const getInstructorsApi = createApiThunk<IUser[]>('getInstructorsApi', {
  url: '/api/users/?is_instructor=True',
  method: 'GET'
})

export const getFacultyAndStaffByIdApi = createApiThunk<IUser>('getFacultyAndStaffByIdApi', {
  url: '/api/users/<urlId>',
  method: 'GET'
})

export const createFacultyAndStaffApi = createApiThunk<IUser>('createFacultyAndStaffApi', {
  url: '/api/users/?is_faculty=True',
  method: 'POST'
  // headers: {
  //   Accept: 'application/cdg'
  // }
})

export const UpdateFacultyAndStaffApi = createApiThunk<IUser>('UpdateFacultyAndStaffApi', {
  url: '/api/users/<urlId>',
  method: 'PATCH'
  // headers: {
  //   Accept: 'application/cdg'
  // }
})

export const uploadInstructureApi = createApiThunk<IUserListResponse>('createInstructureApi', {
  url: '/api/users/?is_faculty=True',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv',
    Accept: 'application/cdg'
  }
})

export const uploadRolesApiViaCSV = createApiThunk<IUserListResponse>('uploadRolesApiViaCSV', {
  url: '/api/users/roles/?is_faculty=True',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv',
    Accept: 'application/cdg'
  }
})

export const createFacultyRoleApi = createApiThunk<IUserListResponse>('createFacultyRoleApi', {
  url: '/api/users/<urlId>/role/',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})

export const deleteFacultyApi = createApiThunk<IUserListResponse>('deleteFacultyApi', {
  url: '/api/users/<urlId>',
  method: 'DELETE',
  headers: {
    Accept: 'application/cdg'
  }
})
