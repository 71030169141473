import { createSlice } from '@reduxjs/toolkit'
import { type Survey, type EditableQuestion, type Question, type EditableSurveyQuestionItem } from '../../../features/AdminSurveyListFeature/types'
import { archiveSurveyTemplateApi, createSurveyTemplate, deleteSurveyTemplateApi, editSurveyTemplate, getSurveyByIdApi, getSurveyList } from './apis'
import type { ISurveyListResponse, ISurveyRow } from '../../../features/AdminSurveyFeature/types'
import { notification } from '../../../components/Notifications'

export interface SurveySlice {
  survey: Survey
  selectedQuestionType: string | null
  questionSave: boolean
  editableQuestion: EditableQuestion | null
  editableQuestionType: string | null
  inValidQuestionItemId: string | null
  surveyListLoading: boolean
  surveyList: ISurveyListResponse | null
  editableSurvey: ISurveyRow | null
  editableSurveyItemQuestions: Question[] | null
  deleteLoading: boolean
  archiveLoading: boolean
  loading: boolean
}

const initialState: SurveySlice = {
  survey: {
    title: '',
    survey_items: []
  },
  selectedQuestionType: null,
  editableQuestionType: null,
  questionSave: false,
  editableQuestion: null,
  inValidQuestionItemId: null,
  surveyListLoading: false,
  surveyList: null,
  editableSurvey: null,
  editableSurveyItemQuestions: null,
  deleteLoading: false,
  archiveLoading: false,
  loading: false
}

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.survey.title = action.payload
    },
    setQuestion: (state, action) => {
      state.survey.survey_items = [...state.survey.survey_items, action.payload]
    },
    setMoveQuestion: (state, action) => {
      state.survey.survey_items = action.payload
    },
    setSelectedQuestionType: (state, action) => {
      state.selectedQuestionType = action.payload
    },
    setQuestionSaveStatus: (state, action) => {
      state.questionSave = action.payload
    },
    deleteQuestion: (state, action) => {
      const filteredQuestions = state.survey.survey_items.filter((question: Question) => question.item_id !== action.payload)
      state.survey.survey_items = filteredQuestions
    },
    setEditableQuestion: (state, action) => {
      state.editableQuestion = action.payload
    },
    setEditableQuestionType: (state, action) => {
      state.editableQuestionType = action.payload
    },

    setInvalidQuestionItemId: (state, action) => {
      state.inValidQuestionItemId = action.payload
    },
    setEditableSurvey: (state, action) => {
      state.survey.title = action.payload?.title
      const questions = action?.payload?.surveyItems?.map((item: EditableSurveyQuestionItem) => {
        return ({
          item_id: item.itemId,
          item_type: item.itemType,
          prompt: item.prompt,
          object: item.object
        })
      })
      state.survey.survey_items = questions
      state.editableSurveyItemQuestions = questions
      state.editableSurvey = action.payload
    },
    setSurveyItems: (state, action) => {
      state.survey.survey_items = action.payload
    },

    updateQuestion: (state, action) => {
      state.survey.survey_items = state.survey?.survey_items?.map((question: Question) => {
        if (question?.item_id === action.payload?.item_id) {
          return action.payload
        }
        return question
      })
    },

    resetState: (state) => {
      state.survey.title = ''
      state.survey.survey_items = []
      state.selectedQuestionType = null
      state.editableQuestionType = null
      state.questionSave = false
      state.editableQuestion = null
      state.inValidQuestionItemId = null
      state.editableSurvey = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyList.pending, (state) => {
        state.surveyListLoading = true
      })
      .addCase(getSurveyList.fulfilled, (state, action) => {
        state.surveyListLoading = false
        state.surveyList = action.payload
      })
      .addCase(getSurveyList.rejected, (state, action) => {
        state.surveyListLoading = false
      })
      // GET SURVEY BY ID
      .addCase(getSurveyByIdApi.pending, (state) => {
        state.surveyListLoading = true
      })
      .addCase(getSurveyByIdApi.fulfilled, (state, action) => {
        state.surveyListLoading = false
      })
      .addCase(getSurveyByIdApi.rejected, (state, action) => {
        state.surveyListLoading = false
      })

      // Delete SURVEY
      .addCase(deleteSurveyTemplateApi.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteSurveyTemplateApi.fulfilled, (state, action) => {
        state.deleteLoading = false
        if (state.surveyList) {
          state.surveyList.data = state.surveyList?.data.filter((surveyList) => surveyList.data.templateId !== action.meta.arg.urlId)
        }
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteSurveyTemplateApi.rejected, (state, action) => {
        state.deleteLoading = false
      })

      // Archive SURVEY
      .addCase(archiveSurveyTemplateApi.pending, (state) => {
        state.archiveLoading = true
      })
      .addCase(archiveSurveyTemplateApi.fulfilled, (state, action) => {
        state.archiveLoading = false
        if (state.surveyList) {
          state.surveyList.data = state.surveyList?.data.filter((surveyList) => surveyList.data.templateId !== action.meta.arg.urlId)
        }
        notification('Successfully archived', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(archiveSurveyTemplateApi.rejected, (state) => {
        state.archiveLoading = false
      })

      // Create Survey
      .addCase(createSurveyTemplate.pending, (state) => {
        state.loading = true
      })
      .addCase(createSurveyTemplate.fulfilled, (state, action) => {
        state.loading = false
        notification('Survey added successfully', { variant: 'success', subTitle: action.payload?.title })
      })
      .addCase(createSurveyTemplate.rejected, (state) => {
        state.loading = false
      })

      // Update Survey
      .addCase(editSurveyTemplate.pending, (state) => {
        state.loading = true
      })
      .addCase(editSurveyTemplate.fulfilled, (state, action) => {
        state.loading = false
        notification('Survey saved successfully', { variant: 'success', subTitle: action.payload?.title })
      })
      .addCase(editSurveyTemplate.rejected, (state, action) => {
        state.loading = false
        notification(action.payload?.response?.data?.error?.schema[0], { variant: 'error' })
      })
  }
})

export const {
  setTitle,
  setQuestion,
  setSelectedQuestionType,
  deleteQuestion,
  setMoveQuestion,
  setQuestionSaveStatus,
  setEditableQuestion,
  setEditableQuestionType,
  setInvalidQuestionItemId,
  updateQuestion,
  resetState,
  setEditableSurvey,
  setSurveyItems
} = surveySlice.actions
export default surveySlice.reducer
