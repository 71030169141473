import React from 'react'
import { Drawer, List, useTheme } from '@mui/material'
import ROUTES from '../../../consts/paths'
import { useNavigate } from 'react-router-dom'
import GenericButton from '../../GenericButton'
import { ReactComponent as Menuicon } from '../../../assets/menu-icon.svg'
import classrankedLogo from '../../../assets/classRankedLogo.svg'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ReportMenuItems from '../ReportMenuItems'
import AdminMenuItems from '../AdminMenuItems'
import AccountDropdown from '../AccountDropdown'

export const MobileTabMenu: React.FC = (): JSX.Element => {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const navigate = useNavigate()
  const theme = useTheme()

  const handleDrawerToggle = (): void => {
    setMobileOpen((prevState) => !prevState)
  }

  const handelLogoClick = (): void => {
    navigate(ROUTES.HOME)
  }

  return (
    <>
      <GenericButton icon={<Menuicon />} onClick={() => { setMobileOpen(!mobileOpen) }} />

      <Drawer
        variant="temporary"
        open={mobileOpen}
        disableScrollLock
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: false
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(16, 24, 40, 0.9)'
          },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '90%', padding: theme.spacing(4) },
          '& .MuiPaper-root': { overflowY: 'unset' }
        }}
      >

        <GenericButton
          icon={<CloseRoundedIcon />}
          style={{ position: 'absolute', top: theme.spacing(1), right: theme.spacing(-14), zIndex: 2, color: 'white' }}
          onClick={handleDrawerToggle}
        />

        <img aria-label='ClassRanked logo' tabIndex={0} alt='ClassRanked logo' width={'120px'} height={'36px'}
          onClick={handelLogoClick}
          src={classrankedLogo} />

        <List
          sx={{ width: '100%', bgcolor: 'background.paper', mt: theme.spacing(5) }}
          component="nav"
        >
          <ReportMenuItems />
          <AdminMenuItems />
          <AccountDropdown />
        </List>
      </Drawer>
    </>
  )
}
