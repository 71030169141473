import React from 'react'
import { Chip } from '@mui/material'
import GenericButton from '../../../components/GenericButton'
import { ButtonType } from '../../../components/GenericButton/styles'
import { SlotStatus, type ISlot } from '../../../models/DistributionPlan'
import { useTheme } from '@mui/material/styles'
import { updateSlotStatusApi } from '../../../store/slices/distributionPlanSlice/apis'
import { useAppDispatch } from '../../../store'
import { getStatusLoading } from '../../../store/slices/distributionPlanSlice/selectors'
import PageHeader from '../../../components/PageHeader'
import { type CustomBreadcrumbT } from '../../../utils/BreadcrumbDataPreparation'

interface CourseEvaluationHeaderProps {
  slot: ISlot | undefined
  breadcrumb: CustomBreadcrumbT[]
}

const CourseEvaluationHeader: React.FC<CourseEvaluationHeaderProps> = ({ slot, breadcrumb }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const statusLoading = getStatusLoading()
  const status = slot?.slotNodeDetails?.slotStatus ?? 'default'
  const buttonTitle = status === SlotStatus.Draft ? 'Publish' : 'Revert to draft'
  const buttonType = status === SlotStatus.Draft ? ButtonType.Tertiary : ButtonType.Secondary
  const isPublishButtonShow = status === SlotStatus.Draft || status === SlotStatus.Published
  const chipVariant = status === SlotStatus.Draft ? 'outlined' : 'filled'

  const chipColors: Record<string, string> = {
    Draft: 'default',
    Published: 'primary',
    Live: 'success'
  }

  const chipColor: any = chipColors[status]

  const handleUpdateSlotStatus = (): void => {
    const updateStatus = status === SlotStatus.Draft ? SlotStatus.Published : SlotStatus.Draft
    void dispatch(updateSlotStatusApi({ urlId: slot?.slotId, data: { status: updateStatus } }))
  }

  const publishButton = isPublishButtonShow &&
    <GenericButton
      label={buttonTitle}
      buttonType={buttonType}
      onClick={handleUpdateSlotStatus}
      style={{
        flexShrink: 0,
        [theme.breakpoints.down('md')]: {
          width: '100%'
        }
      }} />

  return (
    <React.Fragment>
      <PageHeader
        title={slot?.title ?? ''}
        breadcrumb={breadcrumb}
        controls={
          publishButton
        }
        statusChip={<Chip size='small' label={status} variant={chipVariant} color={chipColor} />
        } />
    </React.Fragment>
  )
}

export default CourseEvaluationHeader
