import React, { useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import useAsyncEffect from 'use-async-effect'
import { useCookies } from 'react-cookie'
import { resetAction, useAppDispatch } from '../../store'
import LoadingPageV2 from '../LoadingPageV2'
import { notification } from '../../components/Notifications'
import ROUTES from '../../consts/paths'

const LogoutPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [cookies, _, __] = useCookies(['edlink_access_token_exists', 'edlink_refresh_token_exists'])
  const { route, signOut } = useAuthenticator((context) => [context.route])
  const navigate = useNavigate()

  useAsyncEffect(async () => {
    localStorage.clear()
    dispatch(resetAction())
    if (cookies.edlink_refresh_token_exists != null || cookies.edlink_access_token_exists != null) {
      try {
        await axios.post('/api/auth/logout')
      } catch {
        notification('Logout Error', { variant: 'error', subTitle: 'An error occurred while trying to log out' })
      }
    }
    signOut()
  }, [])

  useEffect(() => {
    if (route !== 'authenticated' && cookies.edlink_refresh_token_exists == null) {
      navigate(ROUTES.LOGIN)
    }
  }, [route, cookies])

  return (
    <LoadingPageV2 />
  )
}

export default LogoutPage
