import React, { type ChangeEvent } from 'react'
import { type Control, Controller, useFormState } from 'react-hook-form'
import InputLabel from '@mui/material/InputLabel'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { grey } from '../../../colors'
import ErrorIconSvg from '../../../assets/alert-circle.svg'
import HelpiconSvg from '../../../assets/question-icon.svg'

interface FormInputProps {
  name: string
  label?: string
  placeholder?: string
  control?: Control<any>
  textFieldProps?: TextFieldProps
  type?: string
  fullWidth?: boolean
  disabled?: boolean
  required?: boolean
  size?: 'small' | 'medium'
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  showAdornment?: boolean
  width?: string
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  control,
  textFieldProps,
  placeholder,
  type = 'text',
  disabled = false,
  required,
  size = 'medium',
  fullWidth = true,
  onChange,
  showAdornment = false,
  width,
  ...rest
}) => {
  const { errors } = useFormState({ control, name })
  const errorMessage = errors?.[name]?.message ?? null
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          {label !== null && (
            <InputLabel required={required} sx={{ marginBottom: 2, color: grey[950], fontSize: '14px' }}>
              {label}
            </InputLabel>
          )}
          <TextField
            sx={{ width }}
            error={errors[name] != null && true}
            {...rest}
            type={type}
            placeholder={placeholder}
            {...field}
            {...textFieldProps}
            fullWidth={fullWidth}
            disabled={disabled}
            helperText={errorMessage as string}
            size={size}
            InputProps={{
              endAdornment: showAdornment && (
                <>
                  {errors[name] != null && <ErrorIcon />}
                  {errors[name] == null && <InfoIcon />}
                </>
              )
            }}
            onChange={(e) => {
              field.onChange(e)
              if (onChange != null) {
                onChange(e as ChangeEvent<HTMLInputElement>)
              }
            }}
          />
        </Box>
      )}
    />
  )
}

const ErrorIcon = (): JSX.Element => (
  <img src={ErrorIconSvg} onClick={() => {}} />
)

const InfoIcon = (): JSX.Element => (
  <img src={HelpiconSvg} onClick={() => {}} ></img>
)

export default FormInput
