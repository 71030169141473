import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { type SurveyCompletionRate, DivisionCategory } from '../../../../models/Division'
import { BookOutlined, AccessTime, SchoolOutlined } from '@mui/icons-material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { getUserFullName } from '../../../../utils/utils'
import useAsyncEffect from 'use-async-effect'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../consts/paths'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { grey } from '../../../../colors'

export interface ReportsHeaderProps {
  completionRate: SurveyCompletionRate
}

const ReportAttributes: React.FC<ReportsHeaderProps> = ({
  completionRate
}): JSX.Element => {
  function appendS (): string {
    return completionRate.children?.length === 1 ? '' : 's'
  }

  const iconStyle = {
    sx: {
      color: 'iconColor'
    }
  }

  function renderSubtitle (): JSX.Element {
    switch (completionRate.categoryName) {
      case DivisionCategory.University:
        return <><SchoolOutlined {...iconStyle} /> {completionRate.children?.length} School{appendS()}</>

      case DivisionCategory.School:
        return <><BookOutlined {...iconStyle} /> {completionRate.children?.length} Division{appendS()}</>
      case DivisionCategory.College:
        return <><BookOutlined {...iconStyle} /> {completionRate.children?.length} Course{appendS()}</>
      case DivisionCategory.Department:
        return <><BookOutlined {...iconStyle} /> {completionRate.children?.length} Course{appendS()}</>
      case DivisionCategory.Course:
        return <>
          <BookOutlined {...iconStyle} /> {completionRate.abbreviation} &nbsp;&nbsp;
          <AccessTime {...iconStyle} /> {completionRate.children?.length} Section{appendS()} &nbsp;&nbsp;
        </>
      case DivisionCategory.Section:
        return <><AccessTime {...iconStyle} /> {completionRate.abbreviation} &nbsp;&nbsp;
          {completionRate?.instructorDetails?.map((instructor, index) => {
            const isLastInstructor = index === completionRate?.instructorDetails.length - 1
            return (
              <React.Fragment key={index}>
                  <AccountCircleOutlinedIcon {...iconStyle} /> &nbsp;
                <Link target='_blank' style={{ color: grey[700] }} to={`${ROUTES.INSTRUCTOR_SURVEYS}/${instructor.instructorId ?? ''}`}>
                  {instructor.instructorName}&nbsp;
                  <ArrowOutwardIcon sx={{ fontSize: '20px' }} />
                </Link>
                {!isLastInstructor && completionRate?.instructorDetails.length > 1 && ', '}
              </React.Fragment>
            )
          })}
        </>

      default:
        return <></>
    }
  }
  return (
    <Box>
      <Typography tabIndex={0} variant="h3">
        {renderSubtitle()}
      </Typography>
    </Box>
  )
}

export default ReportAttributes
