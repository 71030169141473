import React from 'react'
import { CardHeader, Card, CardContent, Typography, Box } from '@mui/material'
import { TextFieldElement } from 'react-hook-form-mui'
import type { FieldValues, TextFieldElementProps } from 'react-hook-form-mui'
import { InvalidQuestionError, OptionalLabel } from '../../styles'

interface FreeResponseCardProps extends TextFieldElementProps<FieldValues> {
  title: string
  isRequired: boolean
  questionNumber: number
  invalidQuestionItemId: string
}

const FreeResponseCard: React.FC<FreeResponseCardProps> = (
  {
    key,
    name,
    title,
    disabled = false,
    questionNumber,
    isRequired,
    invalidQuestionItemId,
    ...rest
  }

): JSX.Element => {
  return (
    <Card tabIndex={0} aria-label={`Survey Question ${questionNumber} ${title}`} id={name}>
      {
        !isRequired && <OptionalLabel>{!isRequired && 'Optional'}</OptionalLabel>
      }
      {
        invalidQuestionItemId && invalidQuestionItemId === name && <InvalidQuestionError>*Response required. Please answer and submit again.</InvalidQuestionError>
      }
      <CardHeader title={
        <label htmlFor={name}>
          <Typography variant="h5" component="h3" color={ disabled ? 'text.secondary' : 'text.primary' } >
            {questionNumber}. {title}
          </Typography>
        </label>
      }
        sx={{ pb: 0 }}/>
      <CardContent>
        <TextFieldElement
          id={name}
          name={name}
          fullWidth
          placeholder="Type response here..."
          multiline
          {...rest}
        />
      </CardContent>
    </Card>
  )
}

export default FreeResponseCard
