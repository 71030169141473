import { type ListResponse } from './../../../features/ReportHierarchyFeature/types'
import { createSlice } from '@reduxjs/toolkit'
import { getDivisionList, getDivisionDetails, createDivision, editDivision, getBreadcrumbList, deleteDivisionApi, archiveDivisionApi } from './apis'
import { type ReportHierarchyRowData } from '../../../features/ReportHierarchyFeature/types'
import { type BreadcrumbT } from '../../../components/CustomBreadcrumbs'
import { notification } from '../../../components/Notifications'

export interface ReportHierarchySlice {
  division: ReportHierarchyRowData | null
  divisionList: ListResponse | null
  reportSubmitError: string | null
  isReportSubmitLoading: boolean
  editModalOpen: boolean
  isLoading: boolean
  isCreateDivisionLoading: boolean
  isEditDivisionLoading: boolean
  isDivisionDetailsLoading: boolean
  isDivisionListLoading: boolean
  breadcrumbs: BreadcrumbT[]
  isBreadCrumbLoading: boolean
  error: string | null
  deleteLoading: boolean
  archiveLoading: boolean
  update: boolean
}

const initialState: ReportHierarchySlice = {
  division: null,
  divisionList: null,
  isReportSubmitLoading: false,
  reportSubmitError: null,
  editModalOpen: false,
  isLoading: false,
  isCreateDivisionLoading: false,
  isEditDivisionLoading: false,
  isBreadCrumbLoading: false,
  isDivisionDetailsLoading: false,
  isDivisionListLoading: false,
  breadcrumbs: [],
  error: null,
  deleteLoading: false,
  archiveLoading: false,
  update: true // this is a flag that changes when something is updated
}

const reportHierarchySlice = createSlice({
  name: 'reportHierarchy',
  initialState,
  reducers: {
    resetState: state => {
      state.division = initialState.division
      state.divisionList = initialState.divisionList
      state.editModalOpen = initialState.editModalOpen
      state.isLoading = initialState.isLoading
      state.isReportSubmitLoading = initialState.isReportSubmitLoading
      state.reportSubmitError = initialState.reportSubmitError
    },
    openReportModal: state => { state.editModalOpen = true },
    closeReportModal: state => { state.editModalOpen = false },
    startLoader: state => { state.isLoading = true },
    stopLoader: state => { state.isLoading = false }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getDivisionDetails.fulfilled, (state, action) => {
        state.isDivisionDetailsLoading = false
        state.division = action.payload
        state.error = null
      })
      .addCase(getDivisionDetails.pending, (state) => {
        state.isDivisionDetailsLoading = true
      })
      .addCase(getDivisionDetails.rejected, (state, action) => {
        state.isDivisionDetailsLoading = false
        state.error = action.payload?.message ?? 'An error occurred during API request.'
      })

      .addCase(getDivisionList.fulfilled, (state, action) => {
        state.isDivisionListLoading = false
        state.divisionList = action.payload
      })
      .addCase(getDivisionList.pending, (state) => {
        state.isDivisionListLoading = true
      })
      .addCase(getDivisionList.rejected, (state, action) => {
        state.isDivisionListLoading = false
        state.error = action.payload?.message ?? 'An error occurred during API request.'
      })

      .addCase(createDivision.pending, (state) => {
        state.isCreateDivisionLoading = true
      })
      .addCase(createDivision.fulfilled, (state, action) => {
        state.isCreateDivisionLoading = false
        state.update = !state.update
        notification('Unit added successfully', { variant: 'success', subTitle: action.meta.arg.data?.title })
      })
      .addCase(createDivision.rejected, (state, action) => {
        state.isCreateDivisionLoading = false
        state.error = action.payload?.message ?? 'An error occurred during API request.'
      })

      .addCase(editDivision.pending, (state) => {
        state.isEditDivisionLoading = true
      })
      .addCase(editDivision.fulfilled, (state, action) => {
        state.update = !state.update
        state.isEditDivisionLoading = false
        notification('Unit saved successfully', { variant: 'success', subTitle: action.meta.arg.data?.title })
      })
      .addCase(editDivision.rejected, (state, action) => {
        state.isEditDivisionLoading = false
        state.error = action.payload?.message ?? 'An error occurred during API request.'
      })
      .addCase(getBreadcrumbList.pending, (state) => {
        state.isBreadCrumbLoading = true
      })
      .addCase(getBreadcrumbList.fulfilled, (state, action) => {
        state.isBreadCrumbLoading = false
        state.breadcrumbs = action.payload
      })
      .addCase(getBreadcrumbList.rejected, (state, action) => {
        state.isBreadCrumbLoading = false
        state.error = action.payload?.message ?? 'An error occurred during API request.'
      })

      // DELETE DIVISION
      .addCase(deleteDivisionApi.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteDivisionApi.fulfilled, (state, action) => {
        state.deleteLoading = false
        state.update = !state.update
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteDivisionApi.rejected, (state, action) => {
        state.deleteLoading = false
      })

      // ARCHIVE DIVISION
      .addCase(archiveDivisionApi.pending, (state) => {
        state.archiveLoading = true
      })
      .addCase(archiveDivisionApi.fulfilled, (state, action) => {
        state.archiveLoading = false
        state.update = !state.update
        notification('Successfully archived', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(archiveDivisionApi.rejected, (state, action) => {
        state.archiveLoading = false
      })
  }
})
export const { resetState, openReportModal, closeReportModal, startLoader, stopLoader } = reportHierarchySlice.actions
export default reportHierarchySlice.reducer
