import React from 'react'
import { Alert, AlertTitle, Typography } from '@mui/material'
import { ReactComponent as InfoWarning } from '../../../assets/warningalert-circle .svg'
import { ReactComponent as CheckCircle } from '../../../assets/check-circle.svg'
import { blue, green, grey, red, shade, yellow } from '../../../colors'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface UploadModalAlertProps {
  type: string
  title: string
  message?: string
  taskId?: string
  onDownloadErrors?: () => void
}

const UploadModalAlert: React.FC<UploadModalAlertProps> = ({ type, title, message, taskId, onDownloadErrors }) => {
  const getAlertProps = (): any => {
    switch (type) {
      case 'success':
        return {
          severity: 'info',
          border: `1px solid ${blue[300]}`,
          background: shade[1],
          icon: <InfoOutlinedIcon sx={{ color: blue[600] }} />,
          titleColor: blue[600],
          messageColor: blue[600]
        }
      case 'warning':
        return {
          severity: 'warning',
          border: `1px solid ${yellow[400]}`,
          background: shade[50],
          icon: <InfoWarning />,
          titleColor: yellow[500],
          messageColor: yellow[500]
        }
      case 'error':
        return {
          severity: 'error',
          border: `1px solid ${red[0]}`,
          background: grey[25],
          icon: null,
          titleColor: red[700],
          messageColor: red[600]
        }
      default:
        return {
          severity: 'info',
          border: 'none',
          background: 'inherit',
          icon: null,
          titleColor: 'inherit',
          messageColor: 'inherit'
        }
    }
  }

  const { severity, border, background, icon, titleColor, messageColor } = getAlertProps()

  return (
    <Alert severity={severity} sx={{ mb: 4, border, background }} icon={icon}>
      <AlertTitle sx={{ fontSize: '14px', fontWeight: 600, color: titleColor }}>{title}</AlertTitle>
      <Typography sx={{ fontSize: '14px', fontWeight: 400, color: messageColor }}>{message}</Typography>
{taskId && <Typography sx={{ fontSize: '14px', fontWeight: 400, color: messageColor, mt: 3 }}>Task ID: {taskId}</Typography> }
      {onDownloadErrors && (
        <Typography
          onClick={onDownloadErrors}
          sx={{
            mt: 3,
            fontSize: '14px',
            fontWeight: 600,
            color: titleColor,
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          Download Error Summary
        </Typography>
      )}
    </Alert>
  )
}

export default UploadModalAlert
