import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { Box, Button, MenuItem } from '@mui/material'
import { blue, shade, grey } from '../../../colors'

interface ReportsDropdownProps {
  theme?: Theme
  active?: boolean | null
  isTablet: boolean
  isMobile: boolean
}

export const ReportsDropdown = styled(Button)<ReportsDropdownProps>(({ theme, active, isMobile }) => ({
  color: (active ?? false) ? blue[500] : grey[50],
  textTransform: 'capitalize',
  background: active ? shade[950] : 'transparent',
  fontWeight: isMobile ? 'unset' : 'bold',
  cursor: 'pointer',
  letterSpacing: '1%',
  position: 'relative',
  fontSize: isMobile ? theme.spacing(3) : theme.spacing(4),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  transition: '.3s',
  borderRadius: `${theme.spacing(3)}`,
  display: 'flex',
  gap: 2,

  '&:hover': {
    background: blue[100],
    color: theme.palette.primary.main
  }

}))
interface StyledMenuItemProps {
  theme?: Theme
  active?: boolean | null
}

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ theme, active }) => ({
  fontSize: '16px',
  fontWeight: 500,
  padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
  color: (active ?? false) ? blue[500] : grey[250],
  background: (active ?? false) ? shade[1] : 'unset',

  '&:hover': {
    background: shade[150]
  }
}))
interface StyledGroupNameProps {
  theme?: Theme
}
export const StyledGroupName = styled(MenuItem)<StyledGroupNameProps>(({ theme }) => ({
  color: grey[850],
  padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
  fontWeight: '700',
  fontSize: '12px',
  textTransform: 'uppercase'
}))

interface StyledBoxProps {
  theme?: Theme
}

export const MenuWrapper = styled(Box)<StyledBoxProps>(({ theme }) => ({
  minWidth: '240px',
  borderRadius: theme.spacing(2)
}))
