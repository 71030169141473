
import React from 'react'
import Typography from '@mui/material/Typography'
import { grey, shade } from '../../../../colors'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

interface NoResultsFoundProps {
  message?: string
}

const NoResultsFound: React.FC<NoResultsFoundProps> = ({ message = 'You may not have access or there are no survey responses available for this term.' }): JSX.Element => {
  const theme = useTheme()
  return (
        <Box
            padding={theme.spacing(14)}
            border={`1px solid ${shade[650]}`}
            borderRadius={theme.spacing(3)}
            width={'832px'}
            margin={'auto'}
            mt={25}
        >
            <Typography variant="h1" fontSize={'36px'}>No results found</Typography>
            <Typography variant='h4' mt={6} color={grey[850]} fontSize={'20px'}>
                {message ?? ''}
            </Typography>
        </Box>
  )
}

export default NoResultsFound
