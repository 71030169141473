import createApiThunk from '../../../ApplicationFactory'
import { type Division, type IDivision } from '../../../models/Division'

export const getDivisionCoursesApi = createApiThunk<IDivision>('getDivisionCoursesApi', {
  url: '/api/divisions/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const getDivisionDepartmentApi = createApiThunk<Division[]>('getDivisionDepartmentApi', {
  url: '/api/divisions/',
  method: 'GET'
})

export const createDivisionCoursesApi = createApiThunk<IDivision>('createDivisionCoursesApi', {
  url: '/api/divisions/',
  method: 'POST'
})
export const updateDivisionCoursesApi = createApiThunk<IDivision>('updateDivisionCoursesApi', {
  url: '/api/divisions/<urlId>',
  method: 'PATCH'
})
export const deleteDivisionCoursesApi = createApiThunk<{ message: string }>('deleteDivisionCoursesApi', {
  url: '/api/divisions/<urlId>',
  method: 'DELETE'
})
export const uploadCourseCatalogApiViaCSV = createApiThunk<IDivision>('uploadCourseCatalogApiViaCSV', {
  url: '/api/divisions/',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv',
    Accept: 'application/cdg'
  }
})
