import React from 'react'
import { CircularProgress, styled, Backdrop } from '@mui/material'

const StyledBackdrop = styled(Backdrop)`
  && {
    background-color: rgba(256, 256, 256, 0.1);
    z-index: 3;
  }
`

interface LoaderProps {
  open: boolean
}

const Loader: React.FC<LoaderProps> = ({ open }) => {
  return (
    <StyledBackdrop open={open}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  )
}

export default Loader
