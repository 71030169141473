import { Box, Divider, Switch, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { grey } from '../../../../colors'

interface RequiredFieldProps {
  defaultValue: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}
const RequiredField: React.FC<RequiredFieldProps> = ({ defaultValue, handleChange }) => {
  const theme = useTheme()

  return (
        <>
            <Divider sx={{ my: theme.spacing(6) }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: theme.spacing(2), mt: theme.spacing(6) }}>
                <Switch
                    checked={defaultValue}
                    onChange={handleChange}
                />
                <Typography sx={{ fontSize: '16px', fontWeight: 600, color: grey[250] }}>Required</Typography>

            </Box>
        </>
  )
}

export default RequiredField
