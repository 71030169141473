import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useAppDispatch } from '../../store'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LoadingPage from '../../pages/LoadingPage'
import { grey } from '../../colors'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../components/CustomTable/types'
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'
import ROUTES from '../../consts/paths'
import { type CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import { divisionDepartmentList, divisionList, getLoading } from '../../store/slices/courseCatalogSlice/selectors'
import { getDivisionCoursesApi, getDivisionDepartmentApi } from '../../store/slices/courseCatalogSlice/api'
import CreateAndEditCourseCatalog from './CreateNewCourseCatalog'
import CoursesCatalogTopbarActions from './CoursesCatalogTopbarActions'
import DeleteCourseCatalog from './DeleteCourseCatalog'
import PageHeader from '../../components/PageHeader'
import PAGE_TITLES from '../../consts/titles'
import { exportCsv } from '../../utils/exportCsv'
import api from '../../utils/api'
import { notification } from '../../components/Notifications'

const AppTable = lazy(async () => await import('../../components/CustomTable'))

interface ExtendedParams extends ApplyFiltersAndDispatchParams {
  categoryName?: string
}

const CourseCatalog: React.FC = () => {
  const theme = useTheme()
  const [params, setParams] = useState<ExtendedParams>({ categoryName: 'Course' })
  const loading = getLoading()
  const courseCatalogList = divisionList()
  const dispatch = useAppDispatch()
  const departmentList = divisionDepartmentList()

  const breadcrumbPrefixList: CustomBreadcrumbT[] = [{ title: 'Course Catalog', path: ROUTES.COURSE_CATALOG }]

  useEffect(() => {
    void dispatch(getDivisionCoursesApi({ params }))
  }, [params])

  const columns = courseCatalogList?.keys ?? []
  const rows: Row[] = courseCatalogList?.data ?? []

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get('/api/divisions/export/?category_name=Course')
      const data = response?.data

      if (data) {
        exportCsv(data, 'course-sections_export.csv')
      }
    } catch (error: any) {
      console.error('Error exporting course catalog:', error)
      notification(error?.response?.data.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  useEffect(() => {
    if (!departmentList) {
      const params = { categoryName: 'Department', pageSize: 100 }
      void dispatch(getDivisionDepartmentApi({ params }))
    }
  }, [])

  return (
    <Suspense fallback={<LoadingPage />}>
      <PageHeader title={PAGE_TITLES.COURSE_CATALOG} breadcrumb={breadcrumbPrefixList}/>
      <AppTable
        topbarTitle={'Courses'}
        list={courseCatalogList}
        rows={rows}
        deleteAction={<DeleteCourseCatalog/>}
        customTopbarAction={<CoursesCatalogTopbarActions params={params} />}
        editAction={<CreateAndEditCourseCatalog params={params} />}
        exportCsv={() => { void handleExport() }}
        loading={loading}
        columns={columns}
        featureName="Course"
        params={params}
        setParams={setParams}
      />
    </Suspense>
  )
}

export default CourseCatalog
