import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ROUTES from '../consts/paths'
import DashboardLayout from '../layouts/DashboardLayout'
import ReportsPage from './ReportsPageV2'
import { PrivateRoute } from './../components/PrivateRoute'
import MemoizedFooterOutlet from './../components/WithFooterOutlet'
import SurveyPage from './SurveyPage'
import LoginPage from './LoginPage'
import SurveyCompletedPage from './SurveyCompletedPage'
import NotFoundPage from './NotFoundPage'
import AdminSurveyListPage from './AdminSurveyListPage'
import ReportHierarchyPage from './ReportHierarchyPage'
import AcademicUnitsPage from './AcademicUnitsPage'
import AdminSurveyPage from './AdminSurveyPage'
import DistributionSeriesPage from './DistributionSeries'
import StudentDashboardPage from './StudentDashboardPage'
import InstructorDashboardPage from './InstructorDashboardPage'
import MemoizedHeaderFooterLayout from './../layouts/HeaderFooterLayout/index'
import CoursesAndEnrollment from './CoursesAndEnrollment'
import FacultyAndStaff from './FacultyAndStaff'
import Users from './Students'
import { useAppDispatch } from '../store'
import { getLoading, getPagePermissions } from '../store/slices/roleSlice/selectors'
import { getUserPagePermissionsApi } from '../store/slices/roleSlice/api'
import { RoleBaseRoute } from '../components/RoleBaseRoute'
import { preparedRoutes } from '../hooks/useAllowedPath'
import TermsPage from './TermsPage'
import NoPermissions from './NoPermissions'
import CourseCatalog from './CourseCatalog'
import LogoutPage from './LogoutPage'
import useProfile from '../hooks/useProfile'
import LoadingPageV2 from './LoadingPageV2'
import TermsDetailsPage from './TermsDetailsPage'
import TaskPage from './TaskPage'

const AppRoutes: React.FC = (): JSX.Element => {
  const permissionLoading = getLoading()
  const dispatch = useAppDispatch()
  const pagePermissions = getPagePermissions()
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthed } = useProfile()

  const authed = isAuthed()

  useEffect(() => {
    if (isAuthed() && !pagePermissions && location.pathname !== ROUTES.LOGIN && location.pathname !== ROUTES.LOGOUT) {
      void dispatch(getUserPagePermissionsApi({}))
    }
  }, [authed])

  useEffect(() => {
    const path = location.state?.from?.pathname ?? location.pathname + location.search
    if (!Array.isArray(pagePermissions)) {
      return
    }
    const validPaths = pagePermissions?.flatMap(page => preparedRoutes[page]).filter(Boolean)
    const uniqueValidPaths = [...new Set(validPaths)]

    const handleNavigation = (): void => {
      if (path && path === ROUTES.NOT_FOUND) {
        return
      }

      if (pagePermissions && pagePermissions.length === 0) {
        navigate(ROUTES.PERMISSION_DENIED)
        return
      }

      const validRoute = uniqueValidPaths?.find(route => path.startsWith(route)) && path
      const priorityBaseRoute = uniqueValidPaths[0]

      switch (true) {
        case !!validRoute:
          navigate(validRoute, { replace: true })
          return
        case !!priorityBaseRoute:
          navigate(priorityBaseRoute, { replace: true })
          return
        case !permissionLoading && pagePermissions && pagePermissions.length > 0:
          navigate(ROUTES.NOT_FOUND, { replace: true })
      }
    }

    handleNavigation()
  }, [pagePermissions, location.pathname])

  return (
    <>
    {permissionLoading && <LoadingPageV2 />}
    <div style={{ display: permissionLoading ? 'none' : 'block' }}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route index element={<Navigate to={'/'} />} />

          <Route path={ROUTES.ADMIN_PATH} element={<DashboardLayout />}>
            <Route index element={<Navigate to={ROUTES.SETTINGS} />} />
            <Route path={ROUTES.TASKS} element={<TaskPage />} />
            <Route path={ROUTES.FACULTY_AND_STAFF} element={<RoleBaseRoute element={<FacultyAndStaff />} />} />
            <Route path={ROUTES.STUDENTS} element={<RoleBaseRoute element={<Users />} />} />
            <Route path={`${ROUTES.REPORTING_HIERARCHY}/:id?`} element={<RoleBaseRoute element={<ReportHierarchyPage />} />} />
            <Route path={`${ROUTES.ACADEMIC_UNITS}`} element={<RoleBaseRoute element={<AcademicUnitsPage />} />} />
            <Route path={ROUTES.SURVEYS} element={<RoleBaseRoute element={<AdminSurveyPage />} />} />
            <Route path={`${ROUTES.SURVEY_LIST}/:id?`} element={<RoleBaseRoute element={<AdminSurveyListPage />} />} />
            <Route path={ROUTES.DISTRIBUTION_SERIES} element={<RoleBaseRoute element={<DistributionSeriesPage />} />} />
            <Route path={`${ROUTES.COURSE_AND_ENROLLMENT}/:id?`} element={<RoleBaseRoute element={<CoursesAndEnrollment />} />} />
            <Route path={ROUTES.INTEGRATIONS} element={<RoleBaseRoute element={<h1>Integration</h1>} />} />
            <Route path={ROUTES.TERMS} element={<RoleBaseRoute element={<TermsPage />} />} />
            <Route path={ROUTES.COURSE_CATALOG} element={<RoleBaseRoute element={<CourseCatalog />} />} />
            <Route path={`${ROUTES.TERMS_DETAILS}/:id`} element={<RoleBaseRoute element={<TermsDetailsPage />} />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route element={<MemoizedFooterOutlet />}>
            <Route path={ROUTES.SURVEY_COMPLETED} element={<RoleBaseRoute element={<SurveyCompletedPage />} />} />
          </Route>
          <Route element={<MemoizedHeaderFooterLayout isTabMenu={false} />}>
            <Route path={`${ROUTES.SURVEY_PAGE}/:id?`} element={<RoleBaseRoute element={<SurveyPage />} />} />
          </Route>

          <Route element={<MemoizedHeaderFooterLayout />}>
            <Route index element={<Navigate to={ROUTES.INSTRUCTOR_SURVEYS} />} />
            <Route path={`${ROUTES.INSTRUCTOR_SURVEYS}/:id?`} element={<RoleBaseRoute element={<InstructorDashboardPage />} />} />
            <Route path={`${ROUTES.REPORTS}/:id?`} element={<RoleBaseRoute element={<ReportsPage />} />} />
            <Route path={ROUTES.STUDENT_SURVEYS} element={<RoleBaseRoute element={<StudentDashboardPage />} />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path={ROUTES.PERMISSION_DENIED} element={<NoPermissions />} />
          </Route>
        </Route>

        <Route element={<MemoizedFooterOutlet />}>
          <Route path={ROUTES.LOGOUT} element={<LogoutPage/>} />
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        </Route>

      </Routes>
    </div>
    </>
  )
}

export default AppRoutes
