import type { Theme } from '@mui/system'
import type { Interpolation } from '@emotion/react'

const CustomStyles: Interpolation<Theme> = {
  body: {
    fontFamily: '\'Inter\', sans-serif',
    fontStyle: 'normal'
  },
  '.MuiTypography-h2 b': {
    fontWeight: '500 !important'
  },
  '.MuiTypography-h6 b': {
    fontWeight: '600 !important'
  },

  '@media only screen and (min-width: 950px)': {
    '.MuiDataGrid-actionsCell': {
      marginRight: '70px'
    }
  },

  '@media only screen and (min-width: 600px) and (max-width: 949px)': {
    '.MuiDataGrid-actionsCell': {
      marginRight: '20px'
    }
  }
}

export default CustomStyles
