import React from 'react'
import { Container } from '@mui/system'
import { Box, Card, Stack, Typography } from '@mui/material'
import { type SurveyCompletionRate } from '../../models/Division'
import type { Report } from '../../models/Report'
import { SurveyQuestionType } from '../../models/Survey'
import { handleSurveyItems, titleFormatted } from '../../helpers'
import ReportAttributes from './components/ReportAttributes'
import BarChart from './components/BarChart'
import CommentCard from './components/CommentCard'
import CompletionRateCard from './components/CompletionRateCard'
import SurveyItemResponsePlaceHolder from './components/SurveyItemResponsePlaceHolder'
import type { CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import { getConfig } from '../../store/slices/configSlice/selectors'
import { useAppDispatch } from '../../store'
import PageHeader from '../../components/PageHeader'
import { getReportError } from '../../store/slices/reportSlice/selectors'
import { grey } from '../../colors'

export interface ReportsFeatureProps {
  completionRate: SurveyCompletionRate
  reports: Report[] | null
  breadcrumb: CustomBreadcrumbT[]
  controls?: React.ReactNode
}

const ReportsFeature: React.FC<ReportsFeatureProps> = ({
  completionRate,
  breadcrumb,
  reports,
  controls
}): JSX.Element => {
  const { displayAverage, displayPercentages } = getConfig()

  const dispatch = useAppDispatch()
  const reportError = getReportError()
  const isGenericAcademicUnit = completionRate !== null && 'divisionId' in completionRate

  let i = 0

  const handleReportItem = (report: Report): JSX.Element => {
    const title = `${i + 1}. ${report.prompt}`
    switch (report.itemType) {
      case SurveyQuestionType.FreeResponse:
        i += 1
        if (isGenericAcademicUnit) {
          return <SurveyItemResponsePlaceHolder key={i} title={title} />
        }
        if ('releaseDate' in report) {
          return <SurveyItemResponsePlaceHolder key={i} title={title} releaseTimeStamp={report.releaseDate} />
        }

        return (
          <CommentCard key={i} title={title} comments={report.comments} />
        )
      case SurveyQuestionType.LinearScale: {
        i += 1

        const keys = report?.options?.map(option => option.name)
        const values = report?.options?.map(option => option.count)
        if (values === null || values === undefined) return (<></>)
        return (<BarChart key={i} title={title} keys={keys} values={values} showPercents={displayPercentages} average={displayAverage ? report?.averageWeight : null} />)
      }
      case SurveyQuestionType.MultipleChoice: {
        i += 1

        const keys = report?.options?.map(option => option.name)
        const values = report?.options?.map(option => option.count)
        if (values === null || values === undefined) return (<></>)
        return (<BarChart key={i} title={title} keys={keys} values={values} showPercents={displayPercentages} />)
      }
      case SurveyQuestionType.Title: {
        return titleFormatted(report.prompt)
      }
      default:
        return (<></>)
    }
  }

  return (
    <Container>
      <Stack>
        <PageHeader
          breadcrumb={breadcrumb}
          title={completionRate.title}
          attributes={
            <ReportAttributes completionRate={completionRate} />
          }
          controls={controls}
        />
        <CompletionRateCard completionRate={completionRate} />

        {
          reports !== null
            ? handleSurveyItems(reports, handleReportItem)
            : <Card >
              <Box sx={{ p: '32px 24px' }}>
                {
                  reportError?.earlyAccess_1ReleaseDate === null && reportError?.earlyAccess_2ReleaseDate === null
                    ? <Typography fontSize={'18px'} color={grey[850]} >
                      Results for this survey are currently being collected.
                    </Typography>
                    : <Typography fontSize={'18px'} color={grey[850]} >Results for this survey will be available by:
                      <Typography component={'span'} color={grey[250]} pl={'2px'} fontWeight={500}>
                        {reportError?.releaseDate}
                      </Typography>
                    </Typography>
                }
              </Box>
            </Card>
        }
      </Stack>
    </Container>
  )
}

export default ReportsFeature
