import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'
interface StyledAppBarProps {
  theme?: Theme
}

export const StyledAppBar = styled(AppBar)<StyledAppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'fixed'
}))
interface StyledToolbarProps {
  theme?: Theme
  isMobile: boolean
}

export const StyledToolbar = styled(Toolbar)<StyledToolbarProps>(({ theme, isMobile }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: isMobile ? `${theme.spacing(12)} !important` : 'unset'
}))
