import Box from '@mui/material/Box'
import type { Theme } from '@mui/material/styles'
import { styled } from '@mui/system'

interface DrawerBoxProps {
  theme?: Theme
}

export const StyledDrawerBox = styled(Box)<DrawerBoxProps>(() => ({
  display: 'flex',
  width: '100%'
}))

interface OutletBoxProps {
  theme?: Theme
  sideBarWidth: string
  isMobile: boolean
}

export const StyledOutletBox = styled(Box)<OutletBoxProps>(({ theme, sideBarWidth, isMobile }) => ({
  padding: isMobile ? `${theme.spacing(4)} ${theme.spacing(6)}` : `${theme.spacing(8)} ${theme.spacing(13)}`,
  width: '100%'
}))
