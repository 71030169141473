import React, { useEffect, useState } from 'react'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'
import axios from 'axios'
import { useSearchParams, useNavigate, useLocation, useParams } from 'react-router-dom'
import { Alert, Button, Authenticator, useAuthenticator, View, useTheme as useAmplifyTheme } from '@aws-amplify/ui-react'
import { Amplify, Auth } from 'aws-amplify'
import CenteredContainer from '../../components/CenteredContainer'
import './style.css'
import classRankedLogo from '../../assets/classRankedLogo.svg'
import classRankedLogoMob from '../../assets/classrankedLogo_Mob.svg'
import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { env } from '../../env'
import useAsyncEffect from 'use-async-effect'
import { isEmpty } from 'lodash'
import useProfile from '../../hooks/useProfile'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../store'
import { getUserPagePermissionsApi } from '../../store/slices/roleSlice/api'
import { getLoading, getPagePermissionError } from '../../store/slices/roleSlice/selectors'

const tenantSetup = async (username: string): Promise<void> => {
  const urlSearch = username?.split('@')?.[1]

  const res = await axios.get(`/api/tenant_info/${urlSearch}`)

  const auth = {
    region: res.data.AWS_REGION,
    userPoolId: res.data.COGNITO_USERPOOL_ID,
    userPoolWebClientId: res.data.COGNITO_APP_CLIENT_ID
  }

  Amplify.configure({
    Auth: auth
  })

  localStorage.setItem('tenantInfo', JSON.stringify(res.data))
  localStorage.setItem('tenantKey', urlSearch)
  localStorage.setItem('authType', 'cognito')
}

const LOGIN_REDIRECT_URI = `${window.location.origin}/login`

const LoginPage: React.FC = (): JSX.Element => {
  const [searchParams, _] = useSearchParams()
  const [cookies, setCookie, removeCookie] = useCookies(['edlink_access_token_exists', 'edlink_refresh_token_exists'])

  const dispatch = useAppDispatch()
  const state = searchParams.get('state')
  const code = searchParams.get('code')
  const error = searchParams.get('error')

  const pagePermissionsLoading = getLoading()

  const permissionsError = getPagePermissionError()

  const [apiError, setApiError] = useState(false)
  const [tokenLoading, setTokenLoading] = useState(false)

  const { tokens } = useAmplifyTheme()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  function gotoEdlinkSSO (): void {
    setApiError(false)
    const state = window.crypto.randomUUID()
    // localStorage.setItem('authType', 'edlink')
    localStorage.setItem('edlinkState', state)
    const EDLINK_SSO_URL = `https://ed.link/sso/login?client_id=${env.REACT_APP_EDLINK_CLIENT_ID}&redirect_uri=${encodeURI(LOGIN_REDIRECT_URI)}&response_type=code&state=${state}`
    window.location.href = EDLINK_SSO_URL
  }

  const {
    setCognitoProfile,
    setEdlinkProfile
  } = useProfile()

  const { route } = useAuthenticator((context) => [context.route])

  const edlinkLoggingIn = !isEmpty(code) && !isEmpty(state)

  if (edlinkLoggingIn) {
    localStorage.setItem('authType', 'edlink')
  }

  useEffect(() => {
    if (route === 'authenticated' || cookies.edlink_access_token_exists != null) {
      void dispatch(getUserPagePermissionsApi({}))
    }
    if (route === 'authenticated') {
      void setCognitoProfile()
    }
  }, [route, cookies])

  useAsyncEffect(async () => {
    // this effect tries to call /api/token if we have code and state from oauth
    if (!edlinkLoggingIn || cookies.edlink_access_token_exists != null) {
      // dont run token fetch if we already have token, or dont have edlink info
      return
    }

    const edlinkState = localStorage.getItem('edlinkState')
    if (isEmpty(edlinkState) || state !== edlinkState) {
      // check sso edlink state matches previous (for security)
      setApiError(true)
      return
    }

    try {
      setTokenLoading(true)
      const result = await axios.post('/api/auth/token', {
        code,
        redirect_uri: LOGIN_REDIRECT_URI
      })

      if (!isEmpty(result?.data)) {
        setEdlinkProfile(result.data)
      }
    } catch {
      localStorage.clear()
      setApiError(true)
    } finally {
      setTokenLoading(false)
    }
  }, [edlinkLoggingIn])

  const services = {
    async handleForgotPassword (username: string) {
      try {
        await tenantSetup(username)
        const result = await Auth.forgotPassword(username)
        return result
      } catch (err) {
        throw new Error('Incorrect email. Please review and try again.')
      }
    },
    async handleSignIn (formData: { username: string, password: string }) {
      const { username } = formData
      try {
        await tenantSetup(username)
        const result = await Auth.signIn(formData)
        return result
      } catch (err) {
        throw new Error('Incorrect email and/or password. Please review and try again.')
      }
    }
  }

  const formFields = {
    signIn: {
      username: {
        label: 'University Email',
        placeholder: 'Enter your Email'
      }
    },
    resetPassword: {
      username: {
        label: 'Enter your University Email',
        placeholder: 'Enter your Email'
      }
    }
  }

  return (
    <CenteredContainer>
      <View className="auth-wrapper">
        <CustomHeader title={PAGE_TITLES.LOGIN} />
        <Authenticator
          formFields={formFields}
          services={services}
          hideSignUp={true}
          components={{
            Header: () => {
              // timeout ensures render of page before this code is run
              // setTimeout(() => {
              //   const usernameField = (document.getElementsByName('username')?.[0] as HTMLInputElement)
              //   const referralUser = searchParams.get('referraluser') ?? location.state?.referralUser
              //   if (referralUser && usernameField) {
              //     usernameField.defaultValue = referralUser
              //   }
              // }, 0)
              return (
                <View textAlign="center" padding={tokens.space.xl}>
                  <Stack textAlign="center">
                    <Box>
                      <img aria-label='ClassRanked logo' src={isMobile ? classRankedLogoMob : classRankedLogo} width={160} />
                    </Box>
                    <Stack spacing={3}>
                      <Typography variant="h1">Log in to your account</Typography>
                      <Typography variant="label">Welcome! Please enter your details</Typography>
                    </Stack>
                    <Button
                      isFullWidth={true}
                      isLoading={edlinkLoggingIn && (pagePermissionsLoading || tokenLoading)}
                      loadingText="Login with School Account"
                      onClick={gotoEdlinkSSO}>
                      Login with School Account
                    </Button>

                    {!isEmpty(error) && <Alert variation="error">Edlink SSO had an error</Alert>}

                    {apiError && <Alert variation="error">Failed to authenticate using Edlink SSO</Alert>}

                    {permissionsError && <Alert variation="error">Failed to obtain User details</Alert>}

                    <Divider><Typography variant="label" marginBottom={50}>or</Typography></Divider>
                  </Stack>
                </View>)
            }
          }}
          signUpAttributes={[
            'email',
            'name',
            'given_name',
            'middle_name',
            'family_name'
          ]} />
      </View>

    </CenteredContainer>
  )
}

export default LoginPage
