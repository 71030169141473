import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { Box, Button, MenuItem } from '@mui/material'
import { blue, shade, grey, red, yellow } from '../../../colors'

interface AccountDropdownButtonProps {
  theme?: Theme
  active?: boolean | null
  isTablet?: boolean
  isMobile?: boolean
}

export const AccountDropdownButton = styled(Button)<AccountDropdownButtonProps>(({ theme, active, isMobile }) => ({
  color: (active ?? false) ? blue[500] : grey[50],
  textTransform: 'capitalize',
  background: active ? shade[950] : 'transparent',
  fontWeight: isMobile ? 'unset' : 'bold',
  cursor: 'pointer',
  letterSpacing: '1%',
  position: 'relative',
  fontSize: isMobile ? theme.spacing(3) : theme.spacing(4),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  transition: '.3s',
  borderRadius: `${theme.spacing(3)}`,
  display: 'flex',
  gap: 2,

  '&:hover': {
    background: blue[100],
    color: theme.palette.primary.main
  }
}))

interface StyledMenuItemProps {
  theme?: Theme
  active?: boolean | null
}

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ theme, active }) => ({
  fontSize: '16px',
  fontWeight: 500,
  padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
  color: (active ?? false) ? blue[500] : grey[250],
  background: (active ?? false) ? shade[1] : 'unset',

  '&:hover': {
    background: shade[150]
  }
}))

interface StyledChipProps {
  theme?: Theme
}

export const StyledChip = styled(Box)<StyledChipProps>(({ theme }) => ({
  width: theme.spacing(6.5),
  height: theme.spacing(5),
  fontWeight: 500,
  color: red[800],
  backgroundColor: shade[1000],
  borderRadius: theme.spacing(1.5),
  border: ` 1px solid ${yellow[100]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))
