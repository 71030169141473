import React from 'react'
import { CssBaseline, GlobalStyles } from '@mui/material'
import CustomStyles from './style'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

import CacheBuster from 'react-cache-buster'
import version from '../package.json'
import LoadingPage from './pages/LoadingPage'
import AppRoutes from './pages'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import Providers from './components/Providers'
import { BrowserRouter } from 'react-router-dom'

const tenantInfo = JSON.parse(localStorage.getItem('tenantInfo') ?? '""')
if (tenantInfo !== null && tenantInfo !== undefined) {
  Amplify.configure({
    Auth: {
      region: tenantInfo?.AWS_REGION,
      userPoolId: tenantInfo?.COGNITO_USERPOOL_ID,
      userPoolWebClientId: tenantInfo?.COGNITO_APP_CLIENT_ID
    }
  })
}

dayjs.extend(utc)
dayjs.extend(timezone)

const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
dayjs.tz.setDefault(userTimeZone)

const App = (): JSX.Element => {
  const isProduction = process.env.NODE_ENV === 'production'

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<LoadingPage />}
    >
      <BrowserRouter>
        <Providers>
          <GlobalStyles styles={CustomStyles} />
          <CssBaseline />
          <AppRoutes />
        </Providers>
      </BrowserRouter>
    </CacheBuster>
  )
}

export default App
