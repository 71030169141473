import FormLabel from '@mui/material/FormLabel'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}

export const StyledFormLabel = styled(FormLabel)<StyledProps>(({ theme }) => ({
  mb: theme.spacing(5),
  fontWeight: '500',
  letterSpacing: '0.01em',
  whiteSpace: 'unset'
}))
