import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import type { Theme } from '@mui/system'

interface StyledDialogTitleProps {
  theme?: Theme
}

export const StyledDialogTitle = styled(DialogTitle)<StyledDialogTitleProps>(({ theme, title }) => ({
  marginBottom: 0,
  paddingBottom: 0
  // fontVariant: 'h2',
  // fontWeight: 500,
  // padding: `${theme.spacing(12.5)} 0px 0px ${theme.spacing(23.5)}`,
  // fontFamily: 'Inter',
  // fontStyle: 'Regular',
  // fontSize: `${theme.spacing(4.5)}`
}))
