import React, { useEffect } from 'react'
import AcademicUnitsFeature from '../../features/AcademicUnitFeature'
import { getAcademicUnitList } from '../../store/slices/academicUnitSlice/apis'
import { useAppDispatch } from '../../store'
import { academicUnitDetails, isAcademicUnitLoading } from '../../store/slices/academicUnitSlice/selectors'
import PAGE_TITLES from '../../consts/titles'
import { type CustomBreadcrumbProps } from '../../components/CustomBreadcrumbs'
import CustomHeader from '../../components/CustomHeader'
import { resetState } from '../../store/slices/academicUnitSlice'
import ROUTES from '../../consts/paths'
import { type GridSortItem } from '@mui/x-data-grid'
import { isEmpty } from 'lodash'
import LoadingPageV2 from '../LoadingPageV2'

const mockList = [{ title: 'Academic Unit', path: ROUTES.ACADEMIC_UNITS }]

const AcademicUnitsPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [breadcrumbList] = React.useState<CustomBreadcrumbProps>({ breadcrumb: mockList })
  const academicUnitList = academicUnitDetails()
  const isLoading = isAcademicUnitLoading()

  const getAcademicUnitApi = async (): Promise<void> => {
    await dispatch(getAcademicUnitList({})).unwrap()
  }

  useEffect(() => {
    dispatch(resetState())
    void getAcademicUnitApi()
  }, [])

  const applyFiltersAndDispatch = async (sortModel: GridSortItem): Promise<void> => {
    const sort = (sortModel != null) && { field: sortModel.field, direction: sortModel.sort }
    const params = {
      sort: (sortModel != null) ? JSON.stringify(sort) : undefined
    }

    void dispatch(getAcademicUnitList({ params }))
  }

  const onSortModelChange = async (sortModel: GridSortItem): Promise<void> => {
    await applyFiltersAndDispatch(sortModel)
  }

  return (
    <>
      {academicUnitList !== null &&
        <>
          <CustomHeader title={PAGE_TITLES.ACADEMIC_UNITS} />
          <AcademicUnitsFeature breadcrumb={breadcrumbList.breadcrumb} list={academicUnitList} onSortModelChange={(sortModel: GridSortItem) => { void onSortModelChange(sortModel) }} loading={isLoading} />
        </>
      }
      {isLoading && isEmpty(academicUnitList) && (
        <LoadingPageV2 title={PAGE_TITLES.ACADEMIC_UNITS} />
      )}

    </>
  )
}

export default AcademicUnitsPage
