import React from 'react'
import { Box, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme, useMediaQuery } from '@mui/material'
import type { SurveyCompletionRate } from '../../../../models/Division'
import { DivisionCategory } from '../../../../models/Division'
import { getPercent } from '../../../../helpers'
import LinearProgressWithLabel from '../LinearProgressWithLabel'
import CompletionChart from '../CompletionChart'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../consts/paths'
import { blue } from '../../../../colors'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
export interface CompletionRateCardProps {
  completionRate: SurveyCompletionRate
}

const CompletionRateCard: React.FC<CompletionRateCardProps> = ({
  completionRate
}): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  function getId (rate: SurveyCompletionRate): string | null {
    const id = rate?.divisionId ?? rate?.sectionId ?? null
    return id
  }

  function completionChart (): JSX.Element {
    const percent = getPercent(completionRate.completionCount, completionRate.studentCount)
    const progressAriaLabel = `Survey Completion Rate Progress, ${percent}, ${completionRate.completionCount} out of ${completionRate.studentCount} responses complete`
    return (
      <Box sx={{ width: '100%' }} tabIndex={0} aria-label={progressAriaLabel}>
        <Typography variant="h3">
          Surveys Completed
        </Typography>
        {
          isMobile
            ? <LinearProgressWithLabel
              value={percent}
              sx={{
                height: {
                  xs: theme.spacing(3),
                  sm: theme.spacing(3.5)
                }
              }} />
            : <CompletionChart value={percent} />
        }

        <Typography
          component="h4"
          variant="caption"
          sx={{
            textAlign: 'right',
            mt: isMobile ? undefined : -7
          }}>
          {completionRate.completionCount}/{completionRate.studentCount} Responses
        </Typography>
      </Box>
    )
  }

  let headers: string[] = []
  let tableContents: Array<(arg0: SurveyCompletionRate) => string | number | JSX.Element | JSX.Element[]>

  switch (completionRate.categoryName) {
    case DivisionCategory.University:
      headers = ['Academic Unit', 'Abbreviation', 'Subunits', 'Surveys', 'Completed']
      tableContents = [
        rate => <Link style={{ color: blue[500] }} to={`${ROUTES.REPORTS}/${getId(rate) ?? ''}`} >{rate.title}</Link>,
        rate => rate.abbreviation,
        rate => rate.childrenCount,
        rate => rate.studentCount,
        rate => `${getPercent(rate.completionCount, rate.studentCount)}%`
      ]
      break
    case DivisionCategory.College:
      headers = ['Academic Unit', 'Abbreviation', 'Subunits', 'Surveys', 'Completed']
      tableContents = [
        rate => <Link style={{ color: blue[500] }} to={`${ROUTES.REPORTS}/${getId(rate) ?? ''}`} >{rate.title}</Link>,
        rate => rate.abbreviation,
        rate => rate.childrenCount,
        rate => rate.studentCount,
        rate => `${getPercent(rate.completionCount, rate.studentCount)}%`
      ]
      break
    case DivisionCategory.School:
      headers = ['Academic Unit', 'Abbreviation', 'Courses', 'Surveys', 'Completed']
      tableContents = [
        rate => <Link style={{ color: blue[500] }} to={`${ROUTES.REPORTS}/${getId(rate) ?? ''}`}>{rate.title}</Link>,
        rate => rate.abbreviation,
        rate => rate.childrenCount,
        rate => rate.studentCount,
        rate => `${getPercent(rate.completionCount, rate.studentCount)}%`
      ]
      break
    case DivisionCategory.Department:
      headers = ['Course', 'Course ID', 'Sections', 'Surveys', 'Completed']
      tableContents = [
        rate => <Link style={{ color: blue[500] }} to={`${ROUTES.REPORTS}/${getId(rate) ?? ''}`} >{rate.title}</Link>,
        rate => rate.abbreviation,
        rate => rate.childrenCount,
        rate => rate.studentCount,
        rate => `${getPercent(rate.completionCount, rate.studentCount)}%`
      ]
      break
    // case DivisionCategory.Course:
    //   headers = ['Course Section', 'Course ID', 'Sections', 'Surveys', 'Completed']
    //   tableContents = [
    //     rate => <Link style={{ color: blue[500] }} to={`${ROUTES.REPORTS}/${getId(rate) ?? ''}`} >{rate.title}</Link>,
    //     rate => rate.abbreviation,
    //     rate => rate.childrenCount,
    //     rate => rate.studentCount,
    //     rate => `${getPercent(rate.completionCount, rate.studentCount)}%`
    //   ]
    //   break
    default:
      headers = ['Section', 'Section ID', 'Instructor', 'Surveys', 'Completed']
      tableContents = [
        rate => <Link style={{ color: blue[500] }} to={`${ROUTES.REPORTS}/${getId(rate) ?? ''}`} >{rate.title}</Link>,
        rate => rate.abbreviation,
        rate => rate.instructorDetails?.map((instructor, index) => {
          const isLastInstructor = index === rate.instructorDetails.length - 1
          return (
            <React.Fragment key={index}>
              <Link target='_blank' style={{ color: blue[500] }} to={`${ROUTES.INSTRUCTOR_SURVEYS}/${instructor.instructorId ?? ''}`}>
                {instructor.instructorName}
                <ArrowOutwardIcon sx={{ fontSize: '20px' }} />
              </Link>
              {!isLastInstructor && rate.instructorDetails.length > 1 && ', '}
            </React.Fragment>
          )
        }),
        rate => rate.studentCount,
        rate => `${getPercent(rate.completionCount, rate.studentCount)}%`
      ]
      break
  }
  if (completionRate.children.length === 0) {
    return (<Card tabIndex={0} aria-label='Completion Rate Table ' >
      <CardContent >
        {completionChart()}
      </CardContent>
    </Card>)
  } else {
    return (
      <Card tabIndex={0} aria-label='Completion Rate Table ' >
        <CardContent >
          {completionChart()}
        </CardContent>
        {
          completionRate.categoryName !== DivisionCategory.Section
            ? <CardContent style={{ paddingTop: 4 }} >
              <Table tabIndex={0} aria-label='Courses Table ' >
                <TableHead >
                  <TableRow >
                    {
                      headers.map(header =>
                        <TableCell key={header}><Typography variant="p2">{header}</Typography></TableCell>
                      )
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    completionRate.children.map(rate => (
                      <TableRow key={rate.divisionId} tabIndex={0} >
                        {
                          tableContents.map((func, i) =>
                            <TableCell sx={{ maxWidth: '30%' }} key={i}>
                              <Typography variant="p3">
                                {func(rate)}
                              </Typography>
                            </TableCell>)
                        }
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </CardContent>
            : null
        }
      </Card>
    )
  }
}

export default CompletionRateCard
