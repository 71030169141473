import ROUTES from './paths'

export interface LinkProps {
  label: string
  link: string
}

export interface MenuItemProps {
  group?: string
  links: LinkProps[]
}

export const adminMenuList: MenuItemProps[] = [
  {
    group: 'Users',
    links: [
      {
        label: 'Faculty & Staff',
        link: ROUTES.FACULTY_AND_STAFF
      },
      {
        label: 'Students',
        link: ROUTES.STUDENTS
      }
    ]
  },
  {
    group: 'Reporting',
    links: [
      {
        label: 'Reporting Hierarchy',
        link: ROUTES.REPORTING_HIERARCHY
      },
      {
        label: 'Academic Units',
        link: ROUTES.ACADEMIC_UNITS
      },
      {
        label: 'Course Catalog',
        link: ROUTES.COURSE_CATALOG
      },
      {
        label: 'Terms',
        link: ROUTES.TERMS
      }
    ]
  },
  {
    group: 'Surveys',
    links: [
      {
        label: 'My Surveys',
        link: ROUTES.SURVEYS
      },
      {
        label: 'Distribution Series',
        link: ROUTES.DISTRIBUTION_SERIES
      }
    ]
  },
  {
    group: 'Other',
    links: [
      {
        label: 'Integrations',
        link: ROUTES.INTEGRATIONS
      },
      {
        label: 'Academic Units',
        link: ROUTES.INTEGRATIONS
      },

      {
        label: 'Activity Monitor',
        link: ROUTES.TASKS
      }
    ]
  }
]
