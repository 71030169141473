import { useAppSelector } from '../..'
import { type InstructorDistribution } from '../../../models/Distribution'
import { type ISection } from '../../../models/TermsSection'

export const getInstructorDistributionsList = (): InstructorDistribution[] | [] => useAppSelector((state) => state.instructorDistributions.instructorDistributions ?? [])
export const isInstructorDistributionsLoading = (): boolean => useAppSelector((state) => state.instructorDistributions.isinstructorDistributionsLoading)
export const isInstructorDistributionsHavingError = (): string | null => useAppSelector((state) => state.instructorDistributions.instructorDistributionsError)

export const getSectionsList = (): ISection[] | null => useAppSelector((state) => state.instructorDistributions.sections)
export const isSectionsLoading = (): boolean => useAppSelector((state) => state.instructorDistributions.isSectionsLoading)
export const isSectionsHavingError = (): string | null => useAppSelector((state) => state.instructorDistributions.sectionError)
