import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Typography, Box, useTheme, Slide } from '@mui/material'
import {
  StickyCompactHeader,
  FullSizedHeader,
  HeaderDetailsContainer,
  StickyCompactHeaderOffset
} from './styles'
import type { CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import CustomBreadcrumbs from '../CustomBreadcrumbs'
import BackButton from '../BackButton'
import useAppBarHeight from '../../hooks/useAppBarHeight'

interface PageHeaderProps {
  breadcrumb?: CustomBreadcrumbT[]
  title: string
  description?: string
  statusChip?: React.ReactNode
  attributes?: React.ReactNode
  controls?: React.ReactNode
  isCollapsible?: boolean
  isCompactOnly?: boolean
  showBackButton?: boolean
  resetState?: () => any
  pageTitleAriaLabel?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({
  breadcrumb,
  title,
  description,
  statusChip,
  attributes,
  controls,
  isCollapsible = true,
  isCompactOnly = false,
  showBackButton = false,
  resetState,
  pageTitleAriaLabel
}) => {
  const theme = useTheme()
  const [isCollapsed, setIsCollapsed] = useState(isCompactOnly)
  const headerRef = useRef<HTMLDivElement>(null)
  const stickyCompactHeaderRef = useRef<HTMLDivElement>(null)
  const [stickyCompactHeaderHeight, setStickyCompactHeaderHeight] = useState(72)
  const appBarHeight = useAppBarHeight()

  const handleScroll = useCallback((): void => {
    if (isCollapsible && headerRef.current) {
      const threshold = (headerRef.current.offsetHeight + appBarHeight) * 0.8
      setIsCollapsed(window.scrollY > threshold)
    }
  }, [isCollapsible, appBarHeight])

  const updateStickyCompactHeaderHeight = useCallback((): void => {
    if (stickyCompactHeaderRef.current) {
      setStickyCompactHeaderHeight(stickyCompactHeaderRef.current.offsetHeight)
    }
  }, [isCollapsible])

  useEffect(() => {
    if (isCollapsible && !isCompactOnly) {
      window.addEventListener('scroll', handleScroll)
    }
    // For responsiveness, adjusts sticky header height on window resize
    updateStickyCompactHeaderHeight()
    window.addEventListener('resize', updateStickyCompactHeaderHeight)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', updateStickyCompactHeaderHeight)
    }
  }, [isCollapsed])

  const showStickyCompactHeader = (isCompactOnly || (isCollapsible && isCollapsed))

  return (
    <React.Fragment>
      {
        !isCompactOnly &&
        <Box display={'flex'} gap={theme.spacing(4)} flexDirection={'column'} ref={headerRef}>
          {breadcrumb && <CustomBreadcrumbs breadcrumb={breadcrumb} />}
          <FullSizedHeader>
            <HeaderDetailsContainer hasControls={controls != null}>
              <Box display={'flex'} alignItems={'center'} gap={theme.spacing(4)}>
                {showBackButton && <BackButton showLabel={false} resetState={resetState} />}
                <Typography aria-label={pageTitleAriaLabel ?? title} variant='h1' marginBottom={description ? theme.spacing(2) : attributes ? theme.spacing(3) : 0}>{title}</Typography>
                {statusChip && statusChip}
              </Box>
              {description && <Typography variant='body1' marginBottom={theme.spacing(3)}>{description}</Typography>}
              {attributes && attributes}
            </HeaderDetailsContainer>
            {controls && controls}
          </FullSizedHeader>
        </Box>
      }
      {
        isCompactOnly &&
        <StickyCompactHeaderOffset id="Sticky-header-offset" stickyCompactHeaderHeight={stickyCompactHeaderHeight} />
      }
      {
        showStickyCompactHeader &&
        <Slide direction="down" in={isCollapsed} timeout={300} mountOnEnter unmountOnExit>
          <StickyCompactHeader ref={stickyCompactHeaderRef}>
            <HeaderDetailsContainer hasControls={controls != null}>
              <Box display={'flex'} alignItems={'center'} gap={theme.spacing(4)}>
                {showBackButton && <BackButton showLabel={false} resetState={resetState} />}
                <Typography variant='h2' marginBottom={description ? theme.spacing(2) : 0} >{title}</Typography>
                {statusChip && statusChip}
              </Box>
              {description && <Typography variant='body1'>{description}</Typography>}
            </HeaderDetailsContainer>
            {controls && controls}
          </StickyCompactHeader>
        </Slide>
      }
    </React.Fragment>
  )
}

export default PageHeader
