import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}

interface StyledBoxProps {
  theme?: Theme
}

export const StyledMultipleChoiceWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4)
}))

export const StyledOptionWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(4)
}))

export const StyledQuestionWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
  gap: theme.spacing(3.75)
}))

export const StyledTextField = styled(TextField)<StyledProps>(({ theme }) => ({
  flex: 2.5,
  '& .MuiInputBase-input': {
    height: theme.spacing(6.5)
  }
}))

export const StyledTypography = styled(Typography)<StyledProps>(({ theme }) => ({
  fontWeight: 500,
  textTransform: 'capitalize'
}))

export const StyledBox = styled(Box)<StyledBoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignContent: 'center'
}))
