import React from 'react'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'
import { getLoadingTitle } from '../../consts/dynamicTitles'
import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { blue, grey } from '../../colors'

export interface LoadingPageV2Props {
  title?: PAGE_TITLES
}

export const FullScreenBox = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh'
})

const LoadingPageV2 = ({ title }: LoadingPageV2Props): JSX.Element => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <>
      {
        (title != null)
          ? <CustomHeader title={title} info={PAGE_TITLES.LOADING} />
          : <CustomHeader title={PAGE_TITLES.LOADING} />
      }
      <FullScreenBox
        gap={8}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={104} color='primary' />
        </Container>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 3,
            gap: 4
          }}
        >
          <Typography variant="h1">
            {getLoadingTitle(title)}
          </Typography>
          <Typography variant="p3">
            Hold tight
          </Typography>
        </Container>
      </FullScreenBox>
    </>
  )
}

export default LoadingPageV2
