import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useAppDispatch } from '../../store'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../components/CustomTable/types'
import LoadingPage from '../../pages/LoadingPage'
import { getFacultyAndStaffApi } from '../../store/slices/facultyAndStaffSlice/apis'
import { getLoading, getUserList } from '../../store/slices/facultyAndStaffSlice/selectors'
import CreateAndEditFacultyAndStaff from './CreateAndEditFacultyAndStaff'
import FacultyAndStaffTopbarActions from './FacultyAndStaffTopbarActions'
import DeleteFaculty from './DeleteFaculty'
import { exportCsv } from '../../utils/exportCsv'
import api from '../../utils/api'
import { notification } from '../../components/Notifications'

const AppTable = lazy(async () => await import('../../components/CustomTable'))

const FacultyAndStaff = (): JSX.Element => {
  const isLoading = getLoading()
  const userList = getUserList()
  const dispatch = useAppDispatch()

  const [params, setParams] = useState<ApplyFiltersAndDispatchParams>({
    pageSize: 15
  })

  useEffect(() => {
    void dispatch(getFacultyAndStaffApi({ params }))
  }, [params])

  const columns = userList?.keys ?? []
  const rows: Row[] = userList?.data ?? []

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get('/api/users/export/?is_faculty=True')
      const data = response?.data

      if (data) {
        exportCsv(data, 'faculty-and-staff_export.csv')
      }
    } catch (error: any) {
      notification(error?.response?.data?.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <AppTable
        topbarTitle={'Faculty & Staff'}
        customTopbarAction={<FacultyAndStaffTopbarActions/>}
        exportCsv={() => { void handleExport() }}
        list={userList}
        rows={rows}
        loading={isLoading}
        columns={columns}
        featureName="User"
        params={params}
        setParams={setParams}
        editAction={<CreateAndEditFacultyAndStaff/>}
        deleteAction={<DeleteFaculty params={params}/>}
        actionModalEntityDisplayField="firstName"
      />
    </Suspense>
  )
}

export default FacultyAndStaff
