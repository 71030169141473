import React from 'react'
import MultipleChoiceCard from '../components/QuestionsPreparation/MultpleChoiceCard'
import DescriptiveTextCard from '../components/QuestionsPreparation/DescriptiveTextCard'
import FreeResponseCard from '../components/QuestionsPreparation/FreeResponseCard'
import { type Question, SurveyItemQuestionType, type MultiChoiceQuestion, type FreeResponseQuestion, type DescriptiveQuestion, type LinearQuestion } from '../types'
import CreateMultipleChoiceQuestionCard from '../components/QuestionsCreation/CreateMultipleChoiceQuestionCard'
import CreateDescriptiveQuestionCard from '../components/QuestionsCreation/CreateDescriptiveQuestionCard'
import CreateFreeResponseQuestionCard from '../components/QuestionsCreation/CreateFreeResponseQuestionCard'
import { StyledQuestionsWrapper } from './styles'
import CreateLinearScaleCard from '../components/QuestionsCreation/CreateLinearScaleCard'
import LinearQuestionCard from '../components/QuestionsPreparation/LinearQuestionCard'
import { getEditableQuestion } from '../../../store/slices/SurveyTemplateSlice/selectors'

interface SurveyPreparedQuestionsProps {
  surveyItems: any
  sectionIndex: number
}

const SurveyPreparedQuestions: React.FC<SurveyPreparedQuestionsProps> = ({ surveyItems, sectionIndex }): JSX.Element => {
  const questions = surveyItems
  const editableQuestion = getEditableQuestion()
  const totalQuestionInSection = surveyItems?.length
  let questionNumber = 0

  const showPreparedQuestions = (question: Question, index: number): JSX.Element => {
    const commonProps = { questionNumber, index, sectionIndex, totalQuestionInSection }

    switch (question?.itemType) {
      case SurveyItemQuestionType.MULTIPLE_CHOICE:
        return <MultipleChoiceCard question={question as MultiChoiceQuestion} {...commonProps}/>
      case SurveyItemQuestionType.FREE_RESPONSE:
        return <FreeResponseCard question={question as FreeResponseQuestion} {...commonProps} />
      case SurveyItemQuestionType.DESCRIPTIVE_TEXT:
        return <DescriptiveTextCard question={question as DescriptiveQuestion} index={index} sectionIndex={sectionIndex} totalQuestionInSection={totalQuestionInSection} />
      case SurveyItemQuestionType.LINEAR_SCALE:
        return <LinearQuestionCard question={question as LinearQuestion} {...commonProps} />
      default:
        return <></>
    }
  }

  const showEditableQuestion = (index: number): JSX.Element => {
    switch (editableQuestion?.question.itemType) {
      case SurveyItemQuestionType.MULTIPLE_CHOICE:
        return <CreateMultipleChoiceQuestionCard sectionIndex={sectionIndex} questionIndex={index} questionNumber={questionNumber}/>
      case SurveyItemQuestionType.DESCRIPTIVE_TEXT:
        return <CreateDescriptiveQuestionCard sectionIndex={sectionIndex} questionIndex={index}/>
      case SurveyItemQuestionType.FREE_RESPONSE:
        return <CreateFreeResponseQuestionCard sectionIndex={sectionIndex} questionIndex={index} questionNumber={questionNumber} />
      case SurveyItemQuestionType.LINEAR_SCALE:
        return <CreateLinearScaleCard sectionIndex={sectionIndex} questionIndex={index} questionNumber={questionNumber}/>
      default:
        return <></>
    }
  }

  const renderQuestion = (question: Question, index: number): JSX.Element => {
    if (editableQuestion != null && sectionIndex === editableQuestion.sectionIndex && index === editableQuestion?.questionIndex) {
      return showEditableQuestion(index)
    }

    return showPreparedQuestions(question, index)
  }

  const getQuestionNumber = (question: Question): void => {
    if (question?.itemType !== SurveyItemQuestionType.DESCRIPTIVE_TEXT) {
      questionNumber++
    }
  }

  return (
    <StyledQuestionsWrapper>
      {
        questions?.map((question: Question, index: number) => {
          getQuestionNumber(question)
          return renderQuestion(question, index)
        })
      }
    </StyledQuestionsWrapper>
  )
}

export default SurveyPreparedQuestions
