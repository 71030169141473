import React from 'react'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Box, InputLabel } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

interface DateTimePickerProps {
  name: string
  label?: string
  fullWidth?: boolean
  required?: boolean
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  onChange?: (value: Date | null, keyboardInputValue?: string | undefined) => void
}

const GenericDateTimePicker: React.FC<DateTimePickerProps> = ({
  name,
  label,
  fullWidth = true,
  required,
  minDate = null,
  maxDate,
  disabled,
  onChange = () => { }
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const errorMessage = error?.message ?? false
        const { value, ...rest } = field
        const utc = dayjs.utc(value).tz()
        return (
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel sx={{ mb: 2 }} required={required}>{label}</InputLabel>
              <DateTimePicker
                {...rest}
                disabled={disabled}
                value={utc}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(value) => {
                  const toUtc = dayjs.tz(value).utc().toDate()
                  field.onChange(toUtc)
                  onChange(toUtc)
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth={fullWidth} helperText={errorMessage} error={Boolean(errorMessage)} />
                )}
              />
            </LocalizationProvider>
          </Box>
        )
      }}
    />
  )
}

export default GenericDateTimePicker
