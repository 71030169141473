import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useAppDispatch } from '../../store'
import { useTheme } from '@mui/material/styles'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../components/CustomTable/types'
import { type GridSortItem } from '@mui/x-data-grid'
import { getAllTerms, getTermsLoading } from '../../store/slices/termsSlice/selectors'
import { getAllTermsApi } from '../../store/slices/termsSlice/api'
import { CreateAndEditTerms } from './CreateAndEditTerms'
import DeleteTerms from './DeleteTerms'
import ROUTES from '../../consts/paths'
import { type CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import PAGE_TITLES from '../../consts/titles'
import LoadingPageV2 from '../../pages/LoadingPageV2'
import PageHeader from '../../components/PageHeader'
import { useNavigate } from 'react-router-dom'
import { exportCsv } from '../../utils/exportCsv'
import api from '../../utils/api'
import { notification } from '../../components/Notifications'

const AppTable = lazy(async () => await import('../../components/CustomTable'))

const Terms: React.FC = () => {
  const [perPageSize, setPerPageSize] = useState<number>(15)
  const [search, setSearch] = useState<string>('')
  const [sortModel, setSortModel] = useState<GridSortItem>()
  const theme = useTheme()

  const isLoading = getTermsLoading()
  const terms = getAllTerms()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const breadcrumbPrefixList: CustomBreadcrumbT[] = [{ title: 'Terms', path: ROUTES.TERMS }]

  useEffect(() => {
    const params = { pageSize: perPageSize }
    void dispatch(getAllTermsApi({ params }))
  }, [])

  const columns = terms?.keys ?? []
  const rows: Row[] = terms?.data ?? []

  const applyFiltersAndDispatch = async ({
    pageSize,
    pageNumber,
    search,
    sort: sortModel
  }: ApplyFiltersAndDispatchParams): Promise<void> => {
    const sort = sortModel && { field: sortModel.field, direction: sortModel.sort }
    const params = {
      search,
      sort: sortModel ? JSON.stringify(sort) : undefined,
      pageSize,
      pageNumber
    }

    void dispatch(getAllTermsApi({ params }))
  }

  const handleRowsPerPageChange = async (pageSize: number): Promise<void> => {
    setPerPageSize(pageSize)
    await applyFiltersAndDispatch({ pageSize, search, sort: sortModel })
  }

  const handleSearch = async (value: string): Promise<void> => {
    setSearch(value)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search: value, sort: sortModel })
  }

  const handlePageChange = async (page: number): Promise<void> => {
    await applyFiltersAndDispatch({ pageSize: perPageSize, pageNumber: page, search, sort: sortModel })
  }

  const onSortModelChange = async (sortModel: GridSortItem): Promise<void> => {
    setSortModel(sortModel)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search, sort: sortModel })
  }

  const handleEditClick = (selectedRow: any): void => {
    const termId = selectedRow?.data?.termId
    navigate(`${ROUTES.TERMS_DETAILS}/${termId}`)
  }

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get('/api/terms/export/')
      const data = response?.data

      if (data) {
        exportCsv(data, 'terms_export.csv')
      }
    } catch (error: any) {
      notification(error?.response?.data?.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  return (
    <Suspense fallback={<LoadingPageV2 title={PAGE_TITLES.TERMS} />}>
      <PageHeader title={PAGE_TITLES.TERMS} breadcrumb={breadcrumbPrefixList} />
      <AppTable
        topbarTitle={'Terms'}
        createAction={<CreateAndEditTerms />}
        list={terms}
        rows={rows}
        exportCsv={() => { void handleExport() }}
        loading={isLoading}
        deleteAction={<DeleteTerms />}
        onEditClick={handleEditClick}
        columns={columns}
        createActionTitle='Create Term'
        featureName="Term"
        onSearch={(value: string) => { void handleSearch(value) }}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        onSortModelChange={onSortModelChange}
        actionModalEntityDisplayField='termName'
      />
    </Suspense>
  )
}

export default Terms
