import createApiThunk from '../../../ApplicationFactory'
import type { ISlot, SlotStatus } from '../../../models/DistributionPlan'
import { type ITermListResponse } from '../../../models/Term'
import { type IDistributionDetails, type ISectionsList } from '../../../models/TermsSection'

export interface ISlotStatus {
  status: SlotStatus
}

export const editSlotApi = createApiThunk<ISlot>('editSlot', {
  url: '/api/slots/<urlId>',
  method: 'PATCH'
})

export const deleteSlotApi = createApiThunk<{ message: string }>('deleteSlotApi', {
  url: '/api/slots/<urlId>',
  method: 'DELETE'
})

export const getSlotApi = createApiThunk<ISlot>('getSlot', {
  url: '/api/slots/<urlId>',
  method: 'GET'
})

export const getSlotSectionApi = createApiThunk<ISectionsList>('getSlotSection', {
  url: '/api/slots/<urlId>/sections/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const createSectionApi = createApiThunk<ISectionsList>('createSection', {
  url: '/api/slots/<urlId>/sections/',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})

export const updateSectionApi = createApiThunk<IDistributionDetails>('updateSection', {
  url: '/api/slots/<urlId>/sections/<extraUrlId>',
  method: 'PATCH',
  headers: {
    Accept: 'application/cdg'
  }
})

export const deleteSectionApi = createApiThunk<{ message: string }>('deleteSectionApi', {
  url: '/api/slots/<slot_id>/sections/<section_id>',
  method: 'DELETE'
})

export const uploadSectionCsvApi = createApiThunk<ISectionsList>('uploadSectionCsv', {
  url: '/api/slots/<urlId>/sections/',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv',
    Accept: 'application/cdg'
  }
})
interface ISlotStatusWithMessage extends ISlotStatus {
  message: string
}

export const updateSlotStatusApi = createApiThunk<ISlotStatusWithMessage>('updateSlotStatus', {
  url: '/api/slots/<urlId>/status/',
  method: 'POST'
})
