import React from 'react'
import { SurveyQuestionType } from '../models/Survey'
import type { Division } from '../models/Division'
import type { SurveyItem } from '../models/Survey'
import { Typography, Stack } from '@mui/material'

// @TODO All helpers are in same file. we should have separate different files for each other

// return percent from 0 to 100
export function getPercent (part: number, total: number): number {
  if (total === 0) return 0
  const result = (100 * part) / total
  if (result < 1 && result > 0) {
    return 1
  }
  return Math.round(result)
}

// return average score, with each item in the list weighted (index 0 is 1, index 1 is 2, etc).
// Total is the total number of entries
export function getAverageScore (values: number[]): number {
  const total = values.reduce((acc, val) => acc + val, 0)
  if (total === 0) return 0
  return values.reduce((acc, cur, i) => acc + (i + 1) * cur, 0) / total
}

export function roundedPercentages (percentages: number[]): number[] {
  const totalPercentage = percentages.reduce((sum, percentage) => sum + percentage, 0)
  const roundedPercentages = percentages.map(percentage => Math.floor((percentage / totalPercentage) * 100))

  const remainder = 100 - roundedPercentages.reduce((sum, percentage) => sum + percentage, 0)
  const sortedIndexes = Array.from({ length: percentages.length }, (_, index) => index).sort((a, b) => percentages[b] - percentages[a])

  for (let i = 0; i < remainder; i++) {
    roundedPercentages[sortedIndexes[i % percentages.length]]++
  }

  return roundedPercentages
}

export function breadcrumbToList (division: Division): Division[] {
  let cur: Division | null = division
  const divisions: Division[] = []

  while (cur != null) {
    divisions.push(cur)
    cur = cur?.children != null && cur.children.length > 0 ? cur?.children[0] : null
  }
  return divisions
}

export function getDivisionFromBreadcrumb (breadcrumb: Division | null): Division | null {
  if (breadcrumb === null) {
    return null
  }

  const divisions = breadcrumbToList(breadcrumb)

  if (divisions.length === 0) {
    return null
  }

  return divisions[divisions.length - 1]
}

export function handleSurveyItems<T extends SurveyItem> (surveySections: T[], handleSurveyItem: (arg0: T, index: number) => JSX.Element): JSX.Element {
  let titles: T[] = []
  let questionNumber = 0
  return (
    <Stack role="region">
      {
        surveySections?.map((section: Record<string, any>) => {
          return section?.surveyItems?.map((child: T, i: React.Key | null | undefined, arr: string | any[]) => {
            if (child.itemType !== SurveyQuestionType.Title) {
              questionNumber = questionNumber + 1
            }

            if (child.itemType === SurveyQuestionType.Title) {
              // if last item is a title, add that title and print all titles
              if (i === arr.length - 1) {
                titles.push(child)
                return (
                  <div key={i}>
                    {titles?.map((child: T) => handleSurveyItem(child, questionNumber))}
                  </div>
                )
              }

              // otherwise, if the item is a title, do not print it yet but store it
              titles.push(child)
              return <></>
            }

            // if the child is not a title
            // if there are stored titles previously, print them
            if (titles.length > 0) {
              const titleElement = (
                <>
                  <div>
                    {titles.map((child) => handleSurveyItem(child, questionNumber))}
                  </div>
                  {handleSurveyItem(child, questionNumber)}
                </>
              )
              titles = []
              return titleElement
            }

            // otherwise, handle question normally
            return handleSurveyItem(child, questionNumber)
          })
        })
      }
    </Stack>
  )
}

export function titleFormatted (title: string = ''): JSX.Element {
  if (title === '') {
    return (<></>)
  }

  const colonPos = title.indexOf(':')

  if (colonPos > 0) {
    const bolded = title.substring(0, colonPos + 1)
    const after = title.substring(colonPos + 1)
    return (<Typography tabIndex={0} component="h2" variant="h6"><b>{bolded}</b>{after}</Typography>)
  }
  return (<Typography tabIndex={0} component="h2" variant="h5">{title}</Typography>)
}
