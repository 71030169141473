import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}

export const StyledDescriptiveWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(5),
  gap: theme.spacing(3.75)
}))

export const StyledTextField = styled(TextField)<StyledProps>(({ theme }) => ({
  flex: 2.5,
  '& .MuiInputBase-input': {
    height: theme.spacing(6.5)
  }
}))

interface StyledBoxProps {
  theme?: Theme
}

export const StyledBox = styled(Box)<StyledBoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignContent: 'center'
}))
