import { useMediaQuery, Card, CardContent, Typography, Grid, Box } from '@mui/material'
import React from 'react'
import { StyledButton, SurveyCompletionState as surveyCompletionState, type SurveyCompletionState as StyleSurveyCompletionState } from './styles'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../../consts/paths'
import { grey } from '../../../../colors'

type SurveyCompletionState = StyleSurveyCompletionState

export interface SurveyListCardProps {
  title: string
  surveyId: string
  surveyState: SurveyCompletionState
  owner: string
  endDate: string
  surveyURL?: string
  onClick?: () => void
  index: number
  total: number
}

const SurveyListCard: React.FC<SurveyListCardProps> = ({
  title,
  surveyId,
  surveyState,
  owner,
  endDate,
  surveyURL,
  onClick = () => { },
  index,
  total
}): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const endDateStr = new Date(Date.parse(endDate))?.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'short' })
  const handleClick = (): void => {
    if (surveyURL !== undefined && surveyURL !== null && surveyURL.length > 0 && surveyURL.trim() !== '') {
      navigate(surveyURL)
    } else if (surveyId.length > 0) {
      navigate(`${ROUTES.SURVEY_PAGE}/${surveyId}`)
    }

    onClick()
  }

  return (
    <Card sx={{ marginBottom: '2rem', width: { xs: '100%', sm: '500px' }, maxWidth: '500px' }} aria-label={`Survey ${index + 1} of ${total} ${title}`} tabIndex={0} >
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={12} md={8} >
            <Box mb={4}>
              <Typography variant="h5" gutterBottom tabIndex={0} sx={{ color: grey[250], fontSize: '20px' }}>
                {title}
              </Typography>
              {
                owner && <Typography variant="p4" tabIndex={0} aria-label={`Instructor ${owner}`} sx={{ color: grey[850], fontWeight: 400, fontSize: '16px' }}>
                  {owner}
                </Typography>
              }
            </Box>

            <Typography sx={{ color: grey[250], fontWeight: 600, fontSize: '14px' }}>Survey Ends</Typography>
            <Typography variant="caption" tabIndex={0} sx={{ fontSize: '14px' }} >
              {endDateStr}
            </Typography>

          </Grid>

          <Grid item xs={12} sm={12} md={4} textAlign={'end'}>
            <StyledButton aria-label={'Press Enter to Start survey'} sx={{ mt: isMobile ? 4 : 0 }} fullWidth={isMobile} tabIndex={0} variant="contained" onClick={handleClick} surveyState={surveyState} disableElevation disabled={surveyState === surveyCompletionState.Submitted || surveyState === surveyCompletionState.Closed}>{surveyState}</StyledButton>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  )
}

export default SurveyListCard
