/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { useForm, FormProvider, type SubmitHandler } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useAppDispatch } from '../../../store'
import { createAcademicUnit, editAcademicUnit } from '../../../store/slices/academicUnitSlice/apis'
import { isCreateAcademicUnitLoading, academicUnitDetailsForChildren } from '../../../store/slices/academicUnitSlice/selectors'
import type { AcademicUnitRowData, AcademicUnitData } from '../types'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormInput from '../../../components/FormElements/FormInput'
import ValidationAlert from '../../../components/ValidationAlert'
import CustomSelectMenuItem from '../../../components/CustomSelectMenuItem'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'

interface CreateNewAcademicUnitProps {
  selectedRow?: AcademicUnitRowData
  handleCloseModal?: () => void
}
interface FormType { children?: any[] | undefined, categoryName: string }

const academicSchema = yup.object().shape({
  categoryName: yup.string().required('Title is required'),
  children: yup.array().min(1, 'At least one valid child is required')
})

const CreateNewAcademicUnit: React.FC<CreateNewAcademicUnitProps> = ({ handleCloseModal = () => { }, selectedRow }): JSX.Element => {
  const [errors, setErrors] = useState<unknown>({})

  const academicUnitData = academicUnitDetailsForChildren()
  const isEditAction = selectedRow !== undefined

  const validChildren = academicUnitData?.map((data: AcademicUnitData) => ({ categoryName: data?.data?.categoryName }))

  const getChildrensOnEdit = selectedRow?.children?.map((children) => ({ categoryName: children }))
  const methods = useForm({
    resolver: yupResolver(academicSchema),
    defaultValues: {
      categoryName: selectedRow?.categoryName,
      children: selectedRow ? getChildrensOnEdit?.map(child => child.categoryName) : undefined
    }
  })

  const dispatch = useAppDispatch()
  const loading = isCreateAcademicUnitLoading()
  const { handleSubmit, formState: { isDirty, isSubmitting } } = methods
  const isEditing = selectedRow

  const onSubmit: SubmitHandler<FormType> = async ({ categoryName, children }): Promise<void> => {
    const isEditing = isEditAction
    try {
      const payload = {
        categoryName,
        children
      }

      const apiCall = isEditing
        ? dispatch(editAcademicUnit({ urlId: selectedRow?.categoryId, data: payload })).unwrap()
        : dispatch(createAcademicUnit({ data: payload })).unwrap()

      await apiCall
      handleCloseModal()
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }
  return (
    <FormProvider {...methods}>
      <ValidationAlert errors={errors} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <FormInput name='categoryName' type='text' label='Title' size='medium' required />
          </Grid>
          <Grid item xs={12}>
            <CustomSelectMenuItem
              name='children'
              label='Valid Children'
              defaultValue={getChildrensOnEdit ?? []}
              options={validChildren}
            />

            <DefaultModalActions
              destructiveButtonProps={{
                onClick: handleCloseModal
              }}
              confirmButtonProps={{
                text: isEditing ? 'Save' : 'Create',
                loading,
                type: 'submit',
                disabled: isSubmitting || (isEditing && !isDirty)
              }}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider >
  )
}

export default CreateNewAcademicUnit
