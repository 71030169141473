import { Typography } from '@mui/material'
import type { Theme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import { blue, grey } from '../../../colors'

interface TitleProps {
  theme?: Theme
}

export const Title = styled(Typography)<TitleProps>(({ theme }) => ({
  fontWeight: 500,
  color: grey[950]
}))

interface TextProps {
  theme?: Theme
}

export const Text = styled(Typography)<TextProps>(({ theme }) => ({
  color: blue[1100],
  marginTop: theme.spacing(4)
}))
