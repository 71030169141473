import { createSlice } from '@reduxjs/toolkit'
import { createFacultyAndStaffApi, getFacultyAndStaffApi, uploadInstructureApi, uploadRolesApiViaCSV, UpdateFacultyAndStaffApi, deleteFacultyApi, getFacultyAndStaffByIdApi } from './apis'
import { type IUserListResponse } from '../../../models/User'

export interface IfacultyAndStaffSlice {
  loading: boolean
  userList: IUserListResponse | null
  userRoles: any | null
  actionLoading: boolean
  userRoleLoading: boolean
}

const initialState: IfacultyAndStaffSlice = {
  loading: false,
  userList: null,
  userRoles: null,
  actionLoading: false,
  userRoleLoading: false
}

const facultyAndStaffSlice = createSlice({
  name: 'facultyAndStaff',
  initialState,
  reducers: {
    resetState: state => {
      state.loading = false
    }

  },
  extraReducers: (builder) => {
    builder
      // GET ALL STAFF AND FACULTY
      .addCase(getFacultyAndStaffApi.pending, (state, action) => {
        state.loading = true
      })

      .addCase(getFacultyAndStaffApi.fulfilled, (state, action) => {
        state.loading = false
        state.userList = action.payload
      })

      .addCase(getFacultyAndStaffApi.rejected, (state, action) => {
        state.loading = false
      })

      // GET ALL STAFF AND FACULTY By ID
      .addCase(getFacultyAndStaffByIdApi.pending, (state, action) => {
        state.loading = true
      })

      .addCase(getFacultyAndStaffByIdApi.fulfilled, (state, action) => {
        state.loading = false
      })

      .addCase(getFacultyAndStaffByIdApi.rejected, (state, action) => {
        state.loading = false
      })

      // Create Faculty and staff
      .addCase(createFacultyAndStaffApi.pending, (state, action) => {
        state.actionLoading = true
      })

      .addCase(createFacultyAndStaffApi.fulfilled, (state, action) => {
        state.actionLoading = false
        state.userRoles = action.payload
      })

      .addCase(createFacultyAndStaffApi.rejected, (state, action) => {
        state.actionLoading = false
      })

      // upload  Faculty and staff
      .addCase(uploadInstructureApi.pending, (state, action) => {
        state.actionLoading = true
      })

      .addCase(uploadInstructureApi.fulfilled, (state, action) => {
        state.actionLoading = false
      })

      .addCase(uploadInstructureApi.rejected, (state, action) => {
        state.actionLoading = false
      })

      // Delete User
      .addCase(deleteFacultyApi.pending, (state, action) => {
        state.actionLoading = true
      })

      .addCase(deleteFacultyApi.fulfilled, (state, action) => {
        state.actionLoading = false
      })

      .addCase(deleteFacultyApi.rejected, (state, action) => {
        state.actionLoading = false
      })

      // upload  Roles
      .addCase(uploadRolesApiViaCSV.pending, (state, action) => {
        state.actionLoading = true
      })

      .addCase(uploadRolesApiViaCSV.fulfilled, (state, action) => {
        state.actionLoading = false
      })

      .addCase(uploadRolesApiViaCSV.rejected, (state, action) => {
        state.actionLoading = false
      })

      // update user
      .addCase(UpdateFacultyAndStaffApi.fulfilled, (state, action) => {
        if (state.userList) {
          const updatedIndex = state.userList.data.findIndex(dat => dat.data.userId === action.payload?.userId)
          if (updatedIndex !== -1) {
            state.userList.data[updatedIndex] = {
              ...state.userList.data[updatedIndex],
              data: action?.payload
            }
          }
        }
      })
  }
})
export const { resetState } = facultyAndStaffSlice.actions
export default facultyAndStaffSlice.reducer
