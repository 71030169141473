import React, { lazy, Suspense, useEffect, useState } from 'react'
import DeleteModalActions from './DeleteSurveyTemplate'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../consts/paths'
import type { ISurveyRowDataType } from '../AdminSurveyFeature/types'
import GenericButton from '../../components/GenericButton'
import { ButtonType } from '../../components/GenericButton/styles'
import { type DataKey } from '../../models/DataObject'
import { useAppDispatch } from '../../store'
import { getSurveyList } from '../../store/slices/SurveySlice/apis'
import { isSurveyListLoading, surveyListDetails } from '../../store/slices/SurveySlice/selectors'
import { isEmpty } from 'lodash'
import Loader from '../../components/Loader'
import { type Row, type ApplyFiltersAndDispatchParams } from '../../components/CustomTable/types'
import { type GridSortItem } from '@mui/x-data-grid'
import ArchiveSurveyTemplate from './ArchiveSurveyTemplate'
import { setEditableSurvey } from '../../store/slices/SurveyTemplateSlice'
import { type CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import PageHeader from '../../components/PageHeader'
import PAGE_TITLES from '../../consts/titles'
import { useTheme } from '@mui/material/styles'
import LoadingPageV2 from '../../pages/LoadingPageV2'

export interface AdminSurveyFeatureProps {
  breadcrumb: CustomBreadcrumbT[]
}

const AppTable = lazy(async () => await import('../../components/CustomTable'))
const AdminSurveyFeature: React.FC<AdminSurveyFeatureProps> = ({ breadcrumb }): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const isLoading = isSurveyListLoading()

  const list = surveyListDetails()

  const [perPageSize, setPerPageSize] = useState<number>(15)
  const [search, setSearch] = useState<string>('')
  const [sortModel, setSortModel] = useState<GridSortItem>()

  useEffect(() => {
    const params = { pageSize: perPageSize }
    void dispatch(getSurveyList({ params }))
  }, [dispatch])

  const rows: ISurveyRowDataType = list?.data

  const columns: DataKey[] = list?.keys

  const handleCreateSurvey = (): void => {
    navigate(ROUTES.SURVEY_LIST)
  }
  const extraColumnProps = {
    width: 260
  }
  const extraModalProps = {
    maxWidth: 'md'
  }

  const applyFiltersAndDispatch = async ({
    pageSize,
    pageNumber,
    search,
    sort: sortModel
  }: ApplyFiltersAndDispatchParams): Promise<void> => {
    const sort = sortModel && { field: sortModel.field, direction: sortModel.sort }
    const params = {
      search,
      sort: sortModel ? JSON.stringify(sort) : undefined,
      pageSize,
      pageNumber
    }
    void dispatch(getSurveyList({ params }))
  }

  const handleRowsPerPageChange = async (pageSize: number): Promise<void> => {
    setPerPageSize(pageSize)
    await applyFiltersAndDispatch({ pageSize, search, sort: sortModel })
  }

  const handleSearch = async (value: string): Promise<void> => {
    setSearch(value)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search: value, sort: sortModel })
  }

  const handlePageChange = async (page: number): Promise<void> => {
    await applyFiltersAndDispatch({ pageSize: perPageSize, pageNumber: page, search, sort: sortModel })
  }

  const onSortModelChange = async (sortModel: GridSortItem): Promise<void> => {
    setSortModel(sortModel)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search, sort: sortModel })
  }

  const onEditClick = (selectedRow: Row): void => {
    const templateId = selectedRow?.data?.templateId
    dispatch(setEditableSurvey(selectedRow?.data))
    navigate(`${ROUTES.SURVEY_LIST}/${templateId as string}`)
  }

  return (
    <React.Fragment>
      <Loader open={isLoading && isEmpty(list)} />
      <PageHeader
        title={PAGE_TITLES.MY_SURVEYS}
        description='All your survey templates are listed below'
        breadcrumb={breadcrumb}
        controls=
        {<GenericButton
          label=' Create Survey'
          buttonType={ButtonType.Primary}
          onClick={() => { handleCreateSurvey() }}
          style={{
            flexShrink: 0,
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          }}
        />}
      />
      <Suspense fallback={<LoadingPageV2 title={PAGE_TITLES.MY_SURVEYS} />}>
        {columns !== undefined && rows !== undefined &&
          <AppTable
            loading={isLoading}
            topbarTitle={'Surveys'}
            rows={rows}
            list={list}
            columns={columns}
            featureName="Survey"
            deleteAction={<DeleteModalActions />}
            archiveAction={<ArchiveSurveyTemplate />}
            extraColumnProps={extraColumnProps}
            extraModalProps={extraModalProps}
            onSearch={(text: string) => { void handleSearch(text) }}
            onSortModelChange={onSortModelChange}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            onEditClick={onEditClick}
          />
        }
      </Suspense>
    </ React.Fragment>
  )
}

export default AdminSurveyFeature
