/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form'
import { getSlotLoading } from '../../../../store/slices/distributionPlanSlice/selectors'
import FormAutoComplete from '../../../../components/FormElements/FormAutoComplete'
import { editSlotApi } from '../../../../store/slices/distributionPlanSlice/apis'
import GenericDateTimePicker from '../../../../components/GenericDateTimePicker'
import { yupResolver } from '@hookform/resolvers/yup'
import { SlotStatus, type ISlot, type ISurveyTemplate } from '../../../../models/DistributionPlan'
import { useAppDispatch } from '../../../../store'
import { type ITermList } from '../../../../models/Term'
import * as yup from 'yup'
import { isEmpty } from 'lodash'
import { getInstructorDistributions } from '../../../../store/slices/InstructorSurveyListSlice/apis'
import Loader from '../../../../components/Loader'
import { useTheme } from '@mui/material/styles'
import { isSurveyListLoading, surveyListDetails } from '../../../../store/slices/SurveySlice/selectors'
import { getSurveyList } from '../../../../store/slices/SurveySlice/apis'
import { type ISurveyRow } from '../../../AdminSurveyFeature/types'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import ValidationAlert from '../../../../components/ValidationAlert'
import type { IDistributionSeries } from '../../../../models/DistributionSeries'
import { createSlotApi, getSeriesSlotApi } from '../../../../store/slices/distributionSeriesSlice/apis'
import { addHours, isAfter } from 'date-fns'
import { getAllTermsApi } from '../../../../store/slices/termsSlice/api'
import { getAllTerms } from '../../../../store/slices/termsSlice/selectors'

interface FormType {
  releaseTimestamp: Date
  templateId: string
  termId: string
  startTimestamp: Date
  endTimestamp: Date
}

interface CreateDistributionProps {
  selectedRow?: ISlot & Pick<ISurveyTemplate, 'templateRevision'> | Record<string, any>
  distributionSeries?: IDistributionSeries
  handleCloseModal?: () => void
}

export const CreateAndEditDistributionPlan: React.FC<CreateDistributionProps> = ({ selectedRow, distributionSeries, handleCloseModal = () => { } }: CreateDistributionProps): JSX.Element => {
  const [errors, setErrors] = useState<unknown>({})

  const theme = useTheme()
  const dispatch = useAppDispatch()
  const termsList = getAllTerms()
  const surveyList = surveyListDetails()
  const surveyListLoading = isSurveyListLoading()
  const isEditAction = selectedRow !== undefined
  const loading = getSlotLoading()

  const { surveyTemplate, term, slotStartTimestamp, slotEndTimestamp, releaseTimestamp, slotNodeDetails } = selectedRow ?? {}
  const { templateId, templateRevision } = surveyTemplate ?? {}
  const { termId } = term ?? {}

  const isSurveyLive = slotNodeDetails?.slotStatus === SlotStatus.Live

  const distributionFormSchema = yup.object().shape({
    termId: yup.string().required('Term is required'),
    templateId: yup.string().required('Survey is required'),
    startTimestamp: yup
      .date()
      .required('Default Start Date is required')
      .max(yup.ref('endTimestamp'), 'Start Date must be before End Date'),

    endTimestamp: yup
      .date()
      .required('Default End Date is required')
      .min(yup.ref('startTimestamp'), 'End Date must be after Start Date')
      .test('is-24-hours-later', 'End Date must be at least 24 hours after Start Date', function (endTimestamp) {
        const startTimestamp = this.parent.startTimestamp
        if (!startTimestamp || !endTimestamp) return true
        return isAfter(endTimestamp, addHours(new Date(startTimestamp), 24))
      }),
    releaseTimestamp: yup
      .date()
      .required('Instructor Release Date is required')
      .min(yup.ref('endTimestamp'), 'Release Date must be on or after End Date')
  })

  const methods = useForm<FormType>({
    resolver: yupResolver(distributionFormSchema),
    defaultValues: {
      templateId, termId: termId ?? null, startTimestamp: slotStartTimestamp, endTimestamp: slotEndTimestamp, releaseTimestamp
    }
  })
  const { handleSubmit, control, formState: { isDirty } } = methods

  const DEFAULT_TEMPLATE_REVISION = 1

  const onSubmit: SubmitHandler<FormType> = async ({ templateId, termId = '', startTimestamp, endTimestamp, releaseTimestamp }) => {
    const isEditing = isEditAction
    const templateRevisionToUpdate = isEditing ? Number(templateRevision) + 1 : DEFAULT_TEMPLATE_REVISION
    try {
      const slotData = {
        termId,
        templateId,
        templateRevision: templateRevisionToUpdate,
        startTimestamp,
        endTimestamp,
        releaseTimestamp
      }

      const apiCall = isEditing
        ? dispatch(editSlotApi({ urlId: selectedRow.slotId, data: slotData }))
        : dispatch(createSlotApi({ urlId: distributionSeries?.distributionSeriesId, data: slotData }))

      await apiCall
      // @TODO: Remove this once we receive a proper response from the backend for editing and creating actions.
      !isEditing && await dispatch(getSeriesSlotApi({ urlId: distributionSeries?.distributionSeriesId }))
      handleCloseModal()
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (isEmpty(termsList) || isEmpty(surveyList)) {
        await Promise.all([
          dispatch(getAllTermsApi({})),
          dispatch(getInstructorDistributions({})),
          dispatch(getSurveyList({}))
        ])
      }
    }
    void fetchData()
  }, [dispatch])

  if (surveyListLoading) {
    return <Box height={theme.spacing(107)}>
      <Loader open={true} />
    </Box>
  }

  const surveyDropdownList = surveyList?.data?.map((survey) => survey.data)

  return (
    <FormProvider {...methods}>
      <ValidationAlert errors={errors} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' flexDirection='column' gap={theme.spacing(6)}>
          <FormAutoComplete
            label="Term"
            name="termId"
            defaultValue={termsList?.data?.find((term: ITermList) => term?.data?.termId === termId)}
            options={termsList?.data ?? []}
            required
            getOptionLabel={(option: ITermList) => option?.data.termName}
            getOptionValue={(option: ITermList) => option?.data.termId}
            readOnly={isSurveyLive}
          />
          <FormAutoComplete
            label="Survey"
            name="templateId"
            required
            loading={surveyListLoading}
            options={surveyDropdownList ?? []}
            getOptionLabel={(option: ISurveyRow) => option?.title}
            getOptionValue={(option: ISurveyRow) => option?.templateId}
            readOnly={isSurveyLive}
          />
          <GenericDateTimePicker
            name="startTimestamp"
            label="Default Survey Start Date"
            required
            minDate={new Date()}
          />

          <GenericDateTimePicker
            name="endTimestamp"
            label="Default Survey End Date"
            required
            minDate={new Date()}
          />
          <GenericDateTimePicker
            name="releaseTimestamp"
            label="Default Report Release Date"
            required
            minDate={new Date()}
          />
        </Box>

        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditAction ? 'Save draft' : 'Create',
            loading,
            type: 'submit',
            disabled: isEditAction && !isDirty
          }}
        />
      </form>
    </FormProvider>
  )
}
