import { createSlice } from '@reduxjs/toolkit'
import { configLoadState, configResetState, configSaveState } from './api'

export interface ConfigSliceType {
  displayPercentages: boolean
  displayAverage: boolean
}

export const initialState: ConfigSliceType = {
  displayPercentages: false,
  displayAverage: false
}

const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(configLoadState.fulfilled, (_, action) => {
        console.log(action)
        if (action.payload == null) {
          return initialState
        }
        return action.payload
      })
      .addCase(configSaveState.fulfilled, (state, action) => {
        return { ...state, ...action.payload }
      })
      .addCase(configResetState.fulfilled, (_) => {
        return initialState
      })
  }
})

export default ConfigSlice.reducer
