import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../store'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../../components/CustomTable/types'
import { type GridSortItem } from '@mui/x-data-grid'
import PAGE_TITLES from '../../../consts/titles'
import LoadingPageV2 from '../../../pages/LoadingPageV2'
import { useParams } from 'react-router-dom'
import { getTermsSectionsApi } from '../../../store/slices/termsSectionsSlice/api'
import { getTermsSectionsList, loading } from '../../../store/slices/termsSectionsSlice/selectors'
import { CreateAndEditTermsSection } from './CreateAndEditTermsSection'
import DeleteSection from './DeleteSection'
import { Box } from '@mui/material'
import TermsTopbarActions from './TermsTopbarActions'
import { exportCsv } from '../../../utils/exportCsv'
import api from '../../../utils/api'
import { notification } from '../../../components/Notifications'

const AppTable = lazy(async () => await import('../../../components/CustomTable'))

const TermsSectionTable: React.FC = () => {
  const [perPageSize, setPerPageSize] = useState<number>(15)
  const [search, setSearch] = useState<string>('')
  const [sortModel, setSortModel] = useState<GridSortItem>()

  const { id } = useParams()

  const isLoading = loading()
  const termsSectionList = getTermsSectionsList()
  const dispatch = useAppDispatch()

  const params = { pageSize: perPageSize, termId: id }
  useEffect(() => {
    void dispatch(getTermsSectionsApi({ params }))
  }, [])

  const columns = termsSectionList?.keys ?? []
  const rows: Row[] = termsSectionList?.data ?? []

  const applyFiltersAndDispatch = async ({
    pageSize,
    pageNumber,
    search,
    sort: sortModel
  }: ApplyFiltersAndDispatchParams): Promise<void> => {
    const sort = sortModel && { field: sortModel.field, direction: sortModel.sort }
    const params = {
      search,
      sort: sortModel ? JSON.stringify(sort) : undefined,
      pageSize,
      pageNumber
    }

    void dispatch(getTermsSectionsApi({ params }))
  }

  const handleRowsPerPageChange = async (pageSize: number): Promise<void> => {
    setPerPageSize(pageSize)
    await applyFiltersAndDispatch({ pageSize, search, sort: sortModel })
  }

  const handleSearch = async (value: string): Promise<void> => {
    setSearch(value)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search: value, sort: sortModel })
  }

  const handlePageChange = async (page: number): Promise<void> => {
    await applyFiltersAndDispatch({ pageSize: perPageSize, pageNumber: page, search, sort: sortModel })
  }

  const onSortModelChange = async (sortModel: GridSortItem): Promise<void> => {
    setSortModel(sortModel)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search, sort: sortModel })
  }

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get(`/api/sections/export/?term_id=${params.termId}`)
      const data = response?.data

      if (data) {
        exportCsv(data, 'course-sections_export.csv')
      }
    } catch (error: any) {
      notification(error?.response?.data?.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  return (
    <Box mt={5}>
      <Suspense fallback={<LoadingPageV2 title={PAGE_TITLES.TERMS} />}>
        <AppTable
          topbarTitle={`Course Sections (${termsSectionList?.pagination?.totalResults ?? 0})`}
          createAction={<CreateAndEditTermsSection params={params} />}
          customTopbarAction={<TermsTopbarActions params={params} />}
          editAction={<CreateAndEditTermsSection params={params} />}
          exportCsv={() => { void handleExport() }}
          list={termsSectionList}
          rows={rows}
          loading={isLoading}
          deleteAction={<DeleteSection />}
          columns={columns}
          featureName="Courses"
          onSearch={(value: string) => { void handleSearch(value) }}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          onSortModelChange={onSortModelChange}
        />
      </Suspense>
    </Box>
  )
}

export default TermsSectionTable
