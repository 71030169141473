import React from 'react'
import { Stack } from '@mui/system'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, Typography, Container } from '@mui/material'
import GenericButton from '../GenericButton'
import { ButtonType } from '../GenericButton/styles'

const NotFoundCard: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  const spacing = {
    xs: 4,
    sm: 6,
    md: 9
  }

  return (
    <Container>
      <Card
        variant="elevation"
        elevation={12}
        sx={{
          width: '100%',
          borderRadius: 2
        }}
      >
        <CardContent>
          <Stack
            spacing={spacing}
            sx={{
              py: spacing,
              alignItems: 'center'
            }}>
            {/* @TODO This needs style file, we should avoid inline completly, unless its a rare easy scenerio */}
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                maxWidth: 477
              }}
            >
              <Typography variant='poster'>
                404
              </Typography>
              <Typography variant='h3' sx={{ fontWeight: 'bold' }}>
                Page Not Found
              </Typography>
              <Typography variant='h3'>
                The link you clicked may be broken or the page may have been removed or renamed
              </Typography>
            </Stack>
            <GenericButton
              buttonType={ButtonType.Primary}
              label='Return'
              onClick={() => { navigate(-1) }}
              startIcon={<ArrowBack />}
            />
          </Stack>
        </CardContent>
      </Card>
    </Container>
  )
}

export default NotFoundCard
