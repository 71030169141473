import React from 'react'
import SurveyTemplateFeature from '../../features/SurveyTempleteFeature'

const AdminSurveyListPage: React.FC = (): JSX.Element => {
  return (
    <SurveyTemplateFeature/>
  )
}

export default AdminSurveyListPage
