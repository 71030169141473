import { createSlice } from '@reduxjs/toolkit'
import { createTermsSectionsApi, deleteEnrollmentApi, deleteTermsSectionApi, getEnrollmentsApi, getTermsSectionsApi, updateTermsSectionsApi, uploadTermsSectionApiViaCSV } from './api'
import { notification } from '../../../components/Notifications'
import { type ISectionRow, type ISectionsList } from '../../../models/TermsSection'
import { type IEnrollmentsList } from '../../../models/Enrollment'

export interface TermsSectionsSliceType {
  loading: boolean
  actionLoading: boolean
  termsSectionsList: ISectionsList | null
  enrollmentList: IEnrollmentsList | null
  enrollmentsLoading: boolean
}

export const initialState: TermsSectionsSliceType = {
  loading: false,
  actionLoading: false,
  termsSectionsList: null,
  enrollmentList: null,
  enrollmentsLoading: false
}

const TermsSectionsSlice = createSlice({
  name: 'termsSections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get terms section
      .addCase(getTermsSectionsApi.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getTermsSectionsApi.fulfilled, (state, action) => {
        state.loading = false
        state.termsSectionsList = action.payload
      })
      .addCase(getTermsSectionsApi.rejected, (state, action) => {
        state.loading = false
      })
      // Create terms section
      .addCase(createTermsSectionsApi.pending, (state, action) => {
        state.actionLoading = true
      })
      .addCase(createTermsSectionsApi.fulfilled, (state, action) => {
        state.actionLoading = false
        notification('Successfully created', { variant: 'success', subTitle: action?.meta?.arg?.data?.title })
      })
      .addCase(createTermsSectionsApi.rejected, (state, action) => {
        state.actionLoading = false
      })
      // Update terms section
      .addCase(updateTermsSectionsApi.pending, (state, action) => {
        state.actionLoading = true
      })
      .addCase(updateTermsSectionsApi.fulfilled, (state, action) => {
        state.actionLoading = false
        notification('Successfully updated', { variant: 'success' })
      })
      .addCase(updateTermsSectionsApi.rejected, (state, action) => {
        state.actionLoading = false
      })// Delete terms section
      .addCase(deleteTermsSectionApi.pending, (state, action) => {
        state.actionLoading = true
      })
      .addCase(deleteTermsSectionApi.fulfilled, (state, action) => {
        state.actionLoading = false
        if (state.termsSectionsList) {
          state.termsSectionsList.data = state.termsSectionsList?.data.filter((terms: ISectionRow) => terms?.data?.sectionId !== action.meta?.arg?.urlId)
          state.termsSectionsList.pagination.totalResults = state.termsSectionsList?.pagination?.totalResults - 1
        }
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteTermsSectionApi.rejected, (state, action) => {
        state.actionLoading = false
      })

      // Upload Terms section via CSV
      .addCase(uploadTermsSectionApiViaCSV.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(uploadTermsSectionApiViaCSV.fulfilled, (state, action) => {
        state.actionLoading = false
      })
      .addCase(uploadTermsSectionApiViaCSV.rejected, (state, action) => {
        state.actionLoading = false
      })

      // ENROLLMENTS
      //  Get enrollment api
      .addCase(getEnrollmentsApi.pending, (state, action) => {
        state.enrollmentsLoading = true
      })
      .addCase(getEnrollmentsApi.fulfilled, (state, action) => {
        state.enrollmentsLoading = false
        state.enrollmentList = action.payload
      })
      .addCase(getEnrollmentsApi.rejected, (state, action) => {
        state.enrollmentsLoading = false
      })

      // Delete enrollment
      .addCase(deleteEnrollmentApi.pending, (state, action) => {
        state.actionLoading = true
      })
      .addCase(deleteEnrollmentApi.fulfilled, (state, action) => {
        state.actionLoading = false
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteEnrollmentApi.rejected, (state, action) => {
        state.actionLoading = false
      })
  }
})

export default TermsSectionsSlice.reducer
