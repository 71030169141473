import { Box } from '@mui/material'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import LoadingPage from '../../../pages/LoadingPage'
import { getCourseLoading, getSlotSections } from '../../../store/slices/distributionPlanSlice/selectors'
import { CreateAndEditCourses } from './CreateAndEditCourses'
import { type ISlot } from '../../../models/DistributionPlan'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../../components/CustomTable/types'
import { useAppDispatch } from '../../../store'
import { getSlotSectionApi } from '../../../store/slices/distributionPlanSlice/apis'
import { useParams } from 'react-router-dom'
import CoursesTopbarActions from './CoursesTopbarActions'
import CourseDelete from './CourseDelete'
import { getTermsSectionsApi } from '../../../store/slices/termsSectionsSlice/api'
import api from '../../../utils/api'
import { exportCsv } from '../../../utils/exportCsv'
import { notification } from '../../../components/Notifications'

const AppTable = lazy(async () => await import('../../../components/CustomTable'))
interface CoursesProps {
  slot: ISlot
}

const Courses: React.FC<CoursesProps> = ({ slot }) => {
  const [params, setParams] = useState<ApplyFiltersAndDispatchParams>()

  const { id } = useParams()
  const dispatch = useAppDispatch()

  const isCourseLoading = getCourseLoading()
  const slotSections = getSlotSections()

  const totalSections = slotSections?.pagination?.totalResults ?? 0

  const columns = slotSections?.keys ?? []

  const rows: Row[] = slotSections?.data ?? []

  useEffect(() => {
    void dispatch(getSlotSectionApi({ urlId: id, params }))
  }, [id, params])

  useEffect(() => {
    const params = {
      termId: slot?.term?.termId
    }
    void dispatch(getTermsSectionsApi({ params }))
  }, [])

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get(`/api/slots/${slot?.slotId}/sections/export/`)
      const data = response?.data

      if (data) {
        exportCsv(data, 'course-section-survey-dates_export.csv')
      }
    } catch (error: any) {
      notification(error?.response?.data?.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  return (
    <Box>
      <Suspense fallback={<LoadingPage />}>
        <AppTable
          topbarTitle={`Course Sections (${totalSections})`}
          exportCsv={() => { void handleExport() }}
          loading={isCourseLoading}
          list={slotSections}
          rows={rows}
          columns={columns}
          noDataComponentProps={{
            title: ' 1. Start by importing your courses',
            subTitle: 'Distribution Plans cannot be published until you have uploaded your courses.'
          }}
          customTopbarAction={<CoursesTopbarActions slot={slot} params={params}/>}
          featureName='Course Section Assignment'
          editAction={<CreateAndEditCourses slot={slot} />}
          deleteAction={<CourseDelete params={params}/> }
          params={params}
          setParams={setParams}
        />
      </Suspense>
    </Box>
  )
}

export default Courses
