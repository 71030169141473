import { createSlice } from '@reduxjs/toolkit'
import { createAcademicUnit, getAcademicUnitList, editAcademicUnit, deleteAcademicUnitApi } from './apis'
import { type ListResponse } from '../../../features/AcademicUnitFeature/types'
import { notification } from '../../../components/Notifications'

export interface AcademicUnitSlice {
  academicUnit: ListResponse | null
  isAcademicUnitLoading: boolean
  isCreateAcademicUnitLoading: boolean
  isEditAcademicUnitLoading: boolean
  deleteLoading: boolean
}

const initialState: AcademicUnitSlice = {
  academicUnit: null,
  isAcademicUnitLoading: true,
  isCreateAcademicUnitLoading: false,
  isEditAcademicUnitLoading: false,
  deleteLoading: false
}

const academicUnitSlice = createSlice({
  name: 'academicUnit',
  initialState,
  reducers: {
    resetState: _ => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAcademicUnitList.pending, (state) => {
        state.isAcademicUnitLoading = true
      })
      .addCase(getAcademicUnitList.fulfilled, (state, action) => {
        state.isAcademicUnitLoading = false
        state.academicUnit = action.payload
      })
      .addCase(getAcademicUnitList.rejected, (state, action) => {
        state.isAcademicUnitLoading = false
      })
      .addCase(createAcademicUnit.pending, (state) => {
        state.isCreateAcademicUnitLoading = true
      })
      .addCase(createAcademicUnit.fulfilled, (state, action) => {
        state.isCreateAcademicUnitLoading = false
        if (state.academicUnit !== null && state.academicUnit !== undefined) {
          state.academicUnit = {
            ...state.academicUnit,
            data: [...state.academicUnit.data, action.payload?.data[0]]
          }
        }
        notification('Unit added successfully', { variant: 'success', subTitle: action.meta.arg.data?.categoryName })
      })
      .addCase(createAcademicUnit.rejected, (state, action) => {
        state.isCreateAcademicUnitLoading = false
      })

      .addCase(editAcademicUnit.pending, (state) => {
        state.isEditAcademicUnitLoading = true
      })
      .addCase(editAcademicUnit.fulfilled, (state, action) => {
        state.isEditAcademicUnitLoading = false
        if (state.academicUnit !== null && state.academicUnit !== undefined) {
          const updatedIndex = state.academicUnit.data.findIndex(dat => dat.data.categoryId === action.payload?.data?.categoryId)
          if (updatedIndex !== -1) {
            state.academicUnit.data[updatedIndex] = {
              ...state.academicUnit.data[updatedIndex],
              data: action?.payload?.data
            }
          }
        }
        notification('Unit saved successfully', { variant: 'success', subTitle: action.meta.arg.data?.categoryName })
      })
      .addCase(editAcademicUnit.rejected, (state) => {
        state.isEditAcademicUnitLoading = false
      })

      // DELETE ACADEMIC UNIT
      .addCase(deleteAcademicUnitApi.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteAcademicUnitApi.fulfilled, (state, action) => {
        if (state.academicUnit) {
          state.academicUnit.data = state.academicUnit?.data.filter((academicUnit) => academicUnit.data.categoryId !== action.meta.arg.urlId)
        }
        state.deleteLoading = false
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteAcademicUnitApi.rejected, (state, action) => {
        state.deleteLoading = false
      })
  }
})
export const { resetState } = academicUnitSlice.actions
export default academicUnitSlice.reducer
