import { createSlice } from '@reduxjs/toolkit'
import { type IDistributionPlan, type ISlot, type IDistributionPlanList, SlotStatus } from '../../../models/DistributionPlan'
import { editSlotApi, getSlotApi, getSlotSectionApi, updateSlotStatusApi, uploadSectionCsvApi, deleteSlotApi, deleteSectionApi } from './apis'

import { notification } from '../../../components/Notifications'
import { type ISectionRow, type ISectionsList } from '../../../models/TermsSection'

export interface IDistributionPlanSlice {
  distributionPlan: IDistributionPlan | Record<string, any>
  slotSections: ISectionsList | null
  isLoading: boolean
  slot: ISlot | null
  statusLoading: boolean
  courseLoading: boolean
  deleteLoading: boolean
  uploadLoading: boolean
}

const initialState: IDistributionPlanSlice = {
  distributionPlan: {},
  slotSections: null,
  isLoading: false,
  slot: null,
  statusLoading: false,
  courseLoading: false,
  deleteLoading: false,
  uploadLoading: false
}

const distributionPlanSlice = createSlice({
  name: 'distributionPlan',
  initialState,
  reducers: {
    resetState: (state) => {
      state.slotSections = null
      state.slot = null
    }
  },
  extraReducers: (builder) => {
    builder
      //  DELETE SLOT
      .addCase(deleteSlotApi.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteSlotApi.fulfilled, (state, action) => {
        state.deleteLoading = false
        if (state.distributionPlan) {
          state.distributionPlan.data = state.distributionPlan?.data.filter((distributionPlan: IDistributionPlanList) => distributionPlan.data.slotId !== action.meta.arg.urlId)
        }
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteSlotApi.rejected, (state) => {
        state.deleteLoading = false
      })
      // UPDATE SLOT`
      .addCase(editSlotApi.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editSlotApi.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(editSlotApi.fulfilled, (state, action) => {
        const slotList = state.distributionPlan?.data
        state.isLoading = false
        if (slotList !== undefined) {
          const updateSlotList = slotList.map((slot: any) => {
            if (slot?.data?.slotId === action.payload?.slotId) {
              return { ...slot, data: action.payload }
            }
            return slot
          })
          state.distributionPlan.data = updateSlotList
        }
        notification('Distribution Plan saved successfully', { variant: 'success', subTitle: action.meta.arg?.data?.title })
      })

      // Get Single Slot
      .addCase(getSlotApi.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSlotApi.fulfilled, (state, action) => {
        state.slot = action.payload
        state.isLoading = false
      })
      .addCase(getSlotApi.rejected, (state) => {
        state.isLoading = false
      })
      // Uplod Slot Course
      .addCase(uploadSectionCsvApi.pending, (state) => {
        state.uploadLoading = true
      })
      .addCase(uploadSectionCsvApi.fulfilled, (state, action) => {
        state.uploadLoading = false
      })
      .addCase(uploadSectionCsvApi.rejected, (state) => {
        state.uploadLoading = false
      })
      // Courses
      .addCase(getSlotSectionApi.pending, (state, action) => {
        state.courseLoading = true
      })
      .addCase(getSlotSectionApi.fulfilled, (state, action) => {
        state.slotSections = action.payload
        state.courseLoading = false
      })
      .addCase(getSlotSectionApi.rejected, (state, action) => {
        state.courseLoading = false
      })
      // DELETE COURSE SECTION
      .addCase(deleteSectionApi.pending, (state, action) => {
        state.deleteLoading = true
      })
      .addCase(deleteSectionApi.fulfilled, (state, action) => {
        state.deleteLoading = false
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteSectionApi.rejected, (state, action) => {
        state.deleteLoading = false
      })
      // Publish slot
      .addCase(updateSlotStatusApi.pending, (state) => {
        state.statusLoading = true
      })
      .addCase(updateSlotStatusApi.fulfilled, (state, action) => {
        const updatedSlotStatus = action.payload?.status
        if (state.slot != null) {
          state.slot.slotNodeDetails.slotStatus = updatedSlotStatus
          state.statusLoading = false
        }

        const publishedMessage = {
          title: 'Distribution Plan Published',
          subTitle: 'Your Distribution Plan is ready to go live.'
        }
        const drafMessage = {
          title: 'Reverted to Draft',
          subTitle: 'You can now edit your Distribution Plan.'
        }

        const message = updatedSlotStatus === SlotStatus.Published ? publishedMessage : drafMessage

        notification(message.title, { variant: 'success', subTitle: message.subTitle })
      })
      .addCase(updateSlotStatusApi.rejected, (state, action) => {
        state.statusLoading = false
        const subTitle = action.payload?.response?.data?.error?.slotStatus?.[0]
        notification('Cannot publish your Distribution Plan ', { variant: 'error', subTitle })
      })
  }
})
export const { resetState } = distributionPlanSlice.actions
export default distributionPlanSlice.reducer
