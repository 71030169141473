import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { Theme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

interface TitleProps {
  theme?: Theme
}

export const Title = styled(Typography)<TitleProps>(({ theme }) => ({
  fontWeight: 500,
  color: grey[600]
}))
interface TextProps {
  theme?: Theme
}

export const Text = styled(Typography)<TextProps>(({ theme }) => ({
  fontWeight: 500,
  marginTop: theme.spacing(3)
}))
