import React, { useEffect, useState } from 'react'
import { StyledOptionsWrapper, StyledStyledTextField } from './styles'
import { Typography } from '@mui/material'
import { grey } from '../../../../../../colors'

interface MultipleChoiceOptionsProps {
  option: string
  index: number
  handleUpdate: (index: number, value: string) => void
}

const MultipleChoiceOptions: React.FC<MultipleChoiceOptionsProps> = ({ option, index, handleUpdate }) => {
  const [value, setValue] = useState('')

  const placeholders = ['e.g. Strongly disagree', 'e.g. Disagree', 'e.g. Neutral', 'e.g. Agree', 'e.g. Strongly agree']

  const handleChange = (value: string, index: number): void => {
    setValue(value)
    handleUpdate(index, value)
  }

  useEffect(() => {
    setValue(option)
  }, [option, index])

  const weightForInput = `(${index + 1} Points)`
  const weight = value === 'Not Applicable' ? '(N/A)' : weightForInput

  return (
    <>
      <StyledOptionsWrapper key={index} >
        <Typography color={grey[150]} fontWeight={500}>{weight}</Typography>
        <StyledStyledTextField
         placeholder={placeholders?.[index] ?? 'Type Option'}
          value={value}
          disabled={value === 'Not Applicable'}
          onChange={(e) => { handleChange(e.target.value, index) }}
        />
      </StyledOptionsWrapper>
    </>
  )
}

export default MultipleChoiceOptions
