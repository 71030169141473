import React, { useRef, useState } from 'react'
import { Divider, Typography } from '@mui/material'
import MultipleChoiceCard from './components/MultipleChoiceCard'
import FreeResponseCard from './components/FreeResponseCard'
import { createSurveySubmit, scrollIntoInvalidQuestion } from '../../models/Survey'
import { FormContainer, useFormContext } from 'react-hook-form-mui'
import { submitStudentSurvey } from '../../store/slices/studentSurveyListSlice/apis'
import { useNavigate, useParams } from 'react-router-dom'
import ROUTES from '../../consts/paths'
import CustomHeader from '../../components/CustomHeader'
import { Box } from '@mui/system'
import { titleFormatted } from '../../helpers'
import { useAppDispatch } from '../../store'
import LinearScaleCard from './components/LinearScaleCard'
import type { ILinearObject } from '../AdminSurveyListFeature/types'
import { getIsLoading, getStudentSelectedSurvey } from '../../store/slices/studentSurveyListSlice/selectors'
import { type ISurveyItem, type ISurveySection } from '../../models/SurveyTemplate'
import { SectionWrapper } from './styles'
import { SurveyItemQuestionType } from '../SurveyTempleteFeature/types'
import GenericButton from '../../components/GenericButton'
import { ButtonType } from '../../components/GenericButton/styles'
import { notification } from '../../components/Notifications'
import { useTheme } from '@mui/material/styles'
import { grey } from '../../colors'
import PageHeader from '../../components/PageHeader'

const SurveyFeature: React.FC = (): JSX.Element => {
  const [invalidQuestionItemId, setInvalidQuestionItemId] = useState<string[]>([])
  const theme = useTheme()

  const dispatch = useAppDispatch()

  const { id } = useParams()
  const survey = getStudentSelectedSurvey()
  const isLoading = getIsLoading()
  const navigate = useNavigate()

  const surveyResponseMap: Record<string, string | undefined> = {}

  const onSubmit = async (surveyResponseMap: Record<string, string | undefined>): Promise<any> => {
    try {
      if (survey) {
        const invalidItemId = scrollIntoInvalidQuestion(survey, surveyResponseMap)
        if (invalidItemId.length > 0) {
          setInvalidQuestionItemId(invalidItemId)
          return
        }
        const surveySumbitData = createSurveySubmit(survey, surveyResponseMap)
        const res = await dispatch(submitStudentSurvey({ urlId: id, data: surveySumbitData })).unwrap()
        if (res) {
          navigate(ROUTES.SURVEY_COMPLETED)
        }
      }
    } catch (error: any) {
      notification(error?.error ?? 'An error occoured while submitting this survey', { variant: 'error' })
    }
  }

  const showQuestion = ({ prompt, itemId, itemType, object, isRequired }: any, questionNumber: number): JSX.Element => {
    const commonProps = {
      questionNumber,
      disabled: false,
      title: prompt,
      name: itemId,
      isRequired,
      invalidQuestionItemId: invalidQuestionItemId.includes(itemId) ? itemId : ''
    }

    switch (itemType) {
      case SurveyItemQuestionType.FREE_RESPONSE:
        return <FreeResponseCard {...commonProps} />
      case SurveyItemQuestionType.MULTIPLE_CHOICE:
        return <MultipleChoiceCard {...commonProps} options={object as string[]} />
      case SurveyItemQuestionType.LINEAR_SCALE:
        return <LinearScaleCard {...commonProps} options={object as ILinearObject[]} />
      case SurveyItemQuestionType.DESCRIPTIVE_TEXT:
        return titleFormatted(prompt)
      default:
        return <></>
    }
  }

  const handleSurveyQuestion = (section: ISurveySection): JSX.Element[] => {
    let questionNumber = 0
    return section.surveyItems.map((question: ISurveyItem, index: number): JSX.Element => {
      if (question.itemType !== SurveyItemQuestionType.DESCRIPTIVE_TEXT as string) {
        questionNumber++
      }

      return (
        <Box key={question.itemId} marginTop={theme.spacing(6)}>
          {
            showQuestion(question, questionNumber)
          }
        </Box>
      )
    })
  }

  return (
    <React.Fragment>
      <CustomHeader info={survey?.title} />
      <FormContainer
        defaultValues={surveyResponseMap}
        onSuccess={onSubmit}
      >
        {/* We are keeping Page Header inside the Form Container so we can trigger Submit from the Sticky Compact Page Header */}
        <PageHeader
          title={survey ? survey.title : ''}
          controls={
            <GenericButton
              loading={isLoading}
              type='submit'
              label='Submit'
              buttonType={ButtonType.Primary}
              style={{
                flexShrink: 0,
                [theme.breakpoints.down('md')]: {
                  width: '100%'
                }
              }} />}
          isCompactOnly
          showBackButton
        />
        {survey?.surveySections.map((section: ISurveySection, index: number) => (
          <React.Fragment key={section.surveySectionId}>
            <SectionWrapper>
              <Typography variant='h5' fontWeight={600} fontSize={'20px'} color={grey[250]}>
                {section.title}
              </Typography>
              <Typography color={grey[850]}>{section.descr}</Typography>

              {handleSurveyQuestion(section)}

            </SectionWrapper>

            {survey?.surveySections.length !== index + 1 && <Divider sx={{ m: `${theme.spacing(8)} 0` }} />}
          </React.Fragment>
        ))}

        <Box display="flex" justifyContent="center" mt={theme.spacing(6)}>
          <GenericButton loading={isLoading} type='submit' label='Submit' buttonType={ButtonType.Primary} />
        </Box>
      </FormContainer>
    </React.Fragment>
  )
}

export default SurveyFeature
