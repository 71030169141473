enum ROUTES {
  HOME = '/',
  REPORTS = '/reports',
  ACCOUNTS = 'accounts',
  FEEDBACK = 'feedback',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SURVEY_PAGE = '/surveys',
  SURVEY_COMPLETED = '/survey_completed',
  ADMIN_PATH = '/admin/',
  ADMIN_REPORTS = '/admin/reports',
  SETTINGS = '/admin/settings',
  USERS = '/admin/users',
  FACULTY_AND_STAFF = '/admin/faculty_and_staff',
  TASKS = '/admin/tasks',
  KITCHEN = '/admin/kitchen',
  STUDENTS = '/admin/students',
  ROLES = '/admin/roles',
  REPORTING_HIERARCHY = '/admin/reporting_hierarchy',
  ACADEMIC_UNITS = '/admin/academic_units',
  SURVEYS = '/admin/surveys',
  SURVEY_LIST = '/admin/survey_list',
  DISTRIBUTION_SERIES = '/admin/distribution_series',
  COURSE_AND_ENROLLMENT = '/admin/distribution_series/courses_and_enrollment',
  INTEGRATIONS = '/admin/integrations',
  STUDENT_SURVEYS = '/student/surveys',
  INSTRUCTOR_SURVEYS = '/instructor/surveys',
  TERMS = '/admin/terms',
  COURSE_CATALOG = '/admin/course_catalog',
  PERMISSION_DENIED = '/permission_denied',
  NOT_FOUND = '/not_found',
  TERMS_DETAILS = '/admin/terms/terms_details'
}

export default ROUTES
