import React from 'react'
import { ButtonType } from '../../../../components/GenericButton/styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../../store'
import { grey } from '../../../../colors'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import { deleteEnrollmentApi, getEnrollmentsApi } from '../../../../store/slices/termsSectionsSlice/api'
import { actionLoading } from '../../../../store/slices/termsSectionsSlice/selectors'
import { type IEnrollment } from '../../../../models/Enrollment'
import { useParams } from 'react-router-dom'
import { type ApplyFiltersAndDispatchParams } from '../../../../components/CustomTable/types'

interface DeleteEnrollmentProps {
  selectedRow?: IEnrollment
  handleCloseModal?: () => void
  showErrorModal?: () => void
  setErrorMessage?: (arg: string) => void
  params?: ApplyFiltersAndDispatchParams
}

export const DeleteEnrollment: React.FC<DeleteEnrollmentProps> = ({ selectedRow, handleCloseModal, showErrorModal, setErrorMessage, params }) => {
  const dispatch = useAppDispatch()
  const loading = actionLoading()
  const theme = useTheme()
  const { id } = useParams()

  const handleDelete = async (): Promise<void> => {
    try {
      if (selectedRow) {
        await dispatch(deleteEnrollmentApi({ urlIds: [selectedRow?.userId, selectedRow?.roleId] })).unwrap()
        handleCloseModal?.()
        await dispatch(getEnrollmentsApi({ urlId: id, params }))
      }
    } catch (error: any) {
      if (error && error.response?.status === 422) {
        const errorMessage = error.response?.data?.error?.error
        setErrorMessage?.(errorMessage)
        showErrorModal?.()
      }
    }
  }

  return (
    <>
      <Typography fontSize='14px' lineHeight={theme.spacing(5)} color={grey[850]}>
        You will not be able to recover this Student Enrollment. All references to it will be removed.
      </Typography>
      <DefaultModalActions
        confirmButtonProps={{
          onClick: handleDelete,
          text: 'Delete',
          loading,
          buttonType: ButtonType.Destructive
        }}
        destructiveButtonProps={{
          onClick: handleCloseModal
        }}
      />
    </>

  )
}
