import { createSlice } from '@reduxjs/toolkit'
import { getInstructorDistributions, getInstructorDistributionsById, getSections } from './apis'
import { type InstructorDistribution } from '../../../models/Distribution'
import { type ISection } from '../../../models/TermsSection'

export interface InstructionDistributionSliceType {
  instructorDistributions: InstructorDistribution[] | null
  sections: ISection[] | null
  isinstructorDistributionsLoading: boolean
  isSectionsLoading: boolean
  instructorDistributionsError: string | null
  sectionError: string | null
}

const initialState: InstructionDistributionSliceType = {
  instructorDistributions: null,
  sections: null,
  isinstructorDistributionsLoading: false,
  isSectionsLoading: false,
  instructorDistributionsError: null,
  sectionError: null
}

const InstructionDistributionSlice = createSlice({
  name: 'instructorDistributions',
  initialState,
  reducers: {
    resetState: (state) => {
      state.instructorDistributions = null
      state.sections = null
      state.isinstructorDistributionsLoading = false
      state.isSectionsLoading = false
      state.instructorDistributionsError = null
      state.sectionError = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstructorDistributions.pending, (state) => {
        state.isinstructorDistributionsLoading = true
      })
      .addCase(getInstructorDistributions.fulfilled, (state, action) => {
        state.isinstructorDistributionsLoading = false
        state.instructorDistributions = action.payload
      })
      .addCase(getInstructorDistributions.rejected, (state, action) => {
        state.isinstructorDistributionsLoading = false
        state.instructorDistributionsError = action.payload?.message ?? 'An error occurred during API request.'
        state.instructorDistributions = null
      })
      .addCase(getSections.pending, (state) => {
        state.isSectionsLoading = true
      })
      .addCase(getSections.fulfilled, (state, action) => {
        state.isSectionsLoading = false
        state.sections = action.payload
      })
      .addCase(getSections.rejected, (state, action) => {
        state.isSectionsLoading = false
        state.sectionError = action.payload?.message ?? 'An error occurred during API request.'
      })

      .addCase(getInstructorDistributionsById.pending, (state) => {
        state.isinstructorDistributionsLoading = true
      })
      .addCase(getInstructorDistributionsById.fulfilled, (state, action) => {
        state.isinstructorDistributionsLoading = false
        state.instructorDistributions = action.payload
      })
      .addCase(getInstructorDistributionsById.rejected, (state, action) => {
        state.isinstructorDistributionsLoading = false
        state.sectionError = action.payload?.message ?? 'An error occurred during API request.'
        state.instructorDistributions = null
      })
  }
})
export const { resetState } = InstructionDistributionSlice.actions
export default InstructionDistributionSlice.reducer
