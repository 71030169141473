import Button, { type ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/system'

export enum SurveyCompletionState {
  Submitted = 'Submitted',
  Continue = 'Continue',
  Closed = 'Closed',
  StartSurvey = 'Start Survey'
}

export interface StyledButtonProps extends ButtonProps {
  surveyState: SurveyCompletionState
}

export const StyledButton = styled(Button)<StyledButtonProps>(({ theme, surveyState }) => ({
  textTransform: 'none',
  color: theme.palette.secondary.main,
  backgroundColor: (surveyState === SurveyCompletionState.Submitted || surveyState === SurveyCompletionState.Closed) ? theme.palette.backgroundColor : null,
  '&:disabled': {
    color: null,
    backgroundColor: theme.palette.backgroundColor
  }
}))
