/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form'
import FormInput from '../../../../components/FormElements/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '../../../../store'
import * as yup from 'yup'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import ValidationAlert from '../../../../components/ValidationAlert'
import _ from 'lodash'
import { getEnrollmentsApi } from '../../../../store/slices/termsSectionsSlice/api'
import { type ISectionRow, type ISection } from '../../../../models/TermsSection'
import { useParams } from 'react-router-dom'
import FormAutoComplete from '../../../../components/FormElements/FormAutoComplete'
import { getTermsSectionsList } from '../../../../store/slices/termsSectionsSlice/selectors'
import { getTerm } from '../../../../store/slices/termsSlice/selectors'
import { createUserRolesApi } from '../../../../store/slices/roleSlice/api'
import { getActionLoading } from '../../../../store/slices/roleSlice/selectors'
import { type ApplyFiltersAndDispatchParams } from '../../../../components/CustomTable/types'

interface FormType {
  email: string
  sectionId: string
}

interface CreateEnrollmentProps {
  selectedRow?: ISection | Record<string, any>
  handleCloseModal?: () => void
  params?: ApplyFiltersAndDispatchParams
}

export const CreateEnrollment: React.FC<CreateEnrollmentProps> = ({ selectedRow, handleCloseModal = () => { }, params }: CreateEnrollmentProps): JSX.Element => {
  const [errors, setErrors] = useState<unknown>({})

  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const term = getTerm()

  const loading = getActionLoading()
  const sectionList = getTermsSectionsList()

  const termFormSchema = yup.object().shape({
    email: yup.string().required('Student email is required'),
    sectionId: yup.string().required('Section Id is required')
  })

  const methods = useForm<FormType>({
    resolver: yupResolver(termFormSchema),
    defaultValues: {
      email: '', sectionId: ''
    }
  })

  const { handleSubmit, control } = methods

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const payload = {
      objectId: data?.sectionId,
      email: data.email,
      roleId: 'student',
      termId: term?.termId
    }

    try {
      const apiCall = dispatch(createUserRolesApi({ data: payload })).unwrap()
      await apiCall
      handleCloseModal()
      await dispatch(getEnrollmentsApi({ urlId: id, params }))
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <ValidationAlert errors={errors} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' flexDirection='column' gap={theme.spacing(6)}>
          <FormInput
            name="email"
            label="Student Email"
            placeholder='Student Email'
            control={control}
          />
          <FormAutoComplete
            label="Section ID"
            name="sectionId"
            options={sectionList?.data ?? []}
            required
            placeholder='Select Section ID'
            getOptionLabel={(option: ISectionRow) => option?.data?.abbreviation}
            getOptionValue={(option: ISectionRow) => option?.data?.sectionId}
          />
        </Box>

        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: 'Create',
            loading,
            type: 'submit'
          }}
        />
      </form>
    </FormProvider>
  )
}
