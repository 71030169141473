import React from 'react'
import { CardHeader, Card, CardContent, FormControl, Typography } from '@mui/material'
import type { RadioButtonGroupProps, FieldValues } from 'react-hook-form-mui'
import CustomRadioButtonGroup, { getId } from '../../../../components/CustomRadioButtonGroup'
import type { ILinearObject } from '../../../AdminSurveyListFeature/types'
import { InvalidQuestionError, OptionalLabel } from '../../styles'

interface LinearScaleCardProps extends Omit<RadioButtonGroupProps<FieldValues>, 'options'> {
  title: string
  options: ILinearObject[]
  questionNumber: number
  invalidQuestionItemId: string | undefined
  isRequired: boolean
}

const LinearScaleCard: React.FC<LinearScaleCardProps> = (
  {
    title,
    name,
    options,
    disabled = false,
    questionNumber,
    invalidQuestionItemId,
    isRequired,
    ...rest
  }
): JSX.Element => {
  return (
    <Card tabIndex={0} aria-label={`Survey Question ${questionNumber} ${title}`} id={name}>
      {
        !isRequired && <OptionalLabel>{!isRequired && 'Optional'}</OptionalLabel>
      }
      {
        invalidQuestionItemId && invalidQuestionItemId === name && <InvalidQuestionError>*Response required. Please answer and submit again.</InvalidQuestionError>
      }
      <CardHeader title={
        <Typography variant="h5" component="h3" color={ disabled ? 'text.secondary' : 'text.primary' } >
          {questionNumber}. {title}
        </Typography>
      } />
      <CardContent>
        <FormControl aria-label={title} aria-owns={options?.map((value) => getId(name, value.option)).join(' ')} >
          <CustomRadioButtonGroup
            name={name}
            type="string"
            options={options?.map((value) => ({
              id: value.option,
              label: value.option
            }))}
            {...rest}/>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default LinearScaleCard
