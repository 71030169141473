import { useAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import { useState } from 'react'

const PROFILE_KEY = 'classrankedUserProfile'

interface AuthUserProfile {
  email: string
  firstName: string
  middleName?: string
  lastName: string
  name: string
  student_id?: string
  user_id: string
}

interface UseProfile {
  user: AuthUserProfile | null
  isAuthed: () => boolean
  removeProfile: () => void
  setCognitoProfile: () => Promise<void>
  setEdlinkProfile: (data: Record<any, any>) => void
}

const getCognitoName = (userAttributes: any): string => {
  return `${userAttributes.given_name as string} ${userAttributes.family_name as string}`
}

export default function useProfile (): UseProfile {
  const defaultVal = localStorage.getItem(PROFILE_KEY)
  const [user, setUser] = useState<AuthUserProfile | null>(defaultVal !== null && defaultVal !== null ? JSON.parse(defaultVal) : null)

  const { route } = useAuthenticator((context) => [context.route])

  function isAuthed (): boolean {
    return route === 'authenticated' || Cookies.get('edlink_access_token_exists') != null
  }

  function removeProfile (): void {
    localStorage.removeItem(PROFILE_KEY)
    setUser(null)
  }

  function setEdlinkProfile (data: Record<any, any>): void {
    // pass in profile that comes from token call
    const toJson = JSON.stringify(data)
    localStorage.setItem(PROFILE_KEY, toJson)
    setUser(data as AuthUserProfile)
  }

  async function setCognitoProfile (): Promise<void> {
    const info = await Auth.currentUserInfo()
    if (!info) {
      return
    }
    const { attributes: attrs, username } = info

    if (attrs === undefined || attrs === null) {
      return
    }

    const data: AuthUserProfile = {
      user_id: username,
      email: attrs.email,
      firstName: attrs.given_name,
      middleName: attrs?.middle_name,
      lastName: attrs.family_name,
      name: getCognitoName(attrs),
      student_id: attrs?.['custom:student_id']
    }

    localStorage.setItem(PROFILE_KEY, JSON.stringify(data))
    setUser(data)
  }

  return {
    user,
    removeProfile,
    setCognitoProfile,
    setEdlinkProfile,
    isAuthed
  }
}
