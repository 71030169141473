import { Box, type Theme, styled } from '@mui/material'

import { blue, grey, red, shade } from '../../colors'

interface StyledDropzoneProps {
  theme?: Theme
  isError?: boolean
  isFocused?: boolean
  isDragAccept?: boolean
  isDragReject?: boolean
  isDragActive?: boolean
  isFileDrop?: boolean
}

const onDragAcceptStyle = {
  border: `1px solid ${blue[600]}`,
  background: shade[950]
}

const onDragRejectStyle = {
  border: `1px solid ${red[600]}`
}

const errorStyle = {
  border: `1px solid ${red[100]}`,
  background: grey[25]
}

const isFileDropStyle = {
  border: `1px solid ${shade[650]}`
}

export const StyledDropzone = styled(Box)<StyledDropzoneProps>(({ theme, isError, isFocused, isDragAccept, isDragReject, isDragActive, isFileDrop }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme?.spacing(6),
  gap: theme?.spacing(4),
  borderRadius: theme?.spacing(3),
  transition: 'border 0.24s ease-in-out',
  cursor: 'pointer',
  background: shade[750],
  border: `1px dashed ${shade[650]}`,
  ...(isDragAccept ? onDragAcceptStyle : {}),
  ...(isDragReject ? onDragRejectStyle : {}),
  ...(isError ? errorStyle : {}),
  ...(isFileDrop ? isFileDropStyle : {})
}))
