import { FormLabel } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}
export const StyledTextField = styled(TextField)<StyledProps>(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: '0px !important'
  },
  '& fieldset': {
    border: 'none'
  }
}))

export const StyledTypography = styled(Typography)<StyledProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor as string}`,
  flex: 1,
  display: 'flex',
  placeItems: 'center',
  gap: theme.spacing(2.75),
  padding: theme.spacing(0, 4),
  borderRadius: theme.spacing(1.5)
}))

export const StyledBox = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(5)
}))

export const StyledFormLabel = styled(FormLabel)<StyledProps>(({ theme }) => ({
  mb: theme.spacing(5),
  fontWeight: '500',
  letterSpacing: '0.01em',
  whiteSpace: 'unset'
}))
