import { CreateOutlined } from '@mui/icons-material'
import { Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import React, { useState } from 'react'
import CreateEditDivision from '../CreateDivision'
import { divisionDetailsForForm } from '../../../store/slices/reportHierarchySlice/selectors'
import GenericButton from '../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../components/GenericButton/styles'
import GenericModal from '../../../components/GenericModal'
export interface HeaderItem {
  name: string
  value: string | number | JSX.Element | null | undefined
}

export interface EditTableHeaderProps {
  items: HeaderItem[]
  onEdit: () => void
  isDivisionEditable: boolean
}

const EditTableHeader: React.FC<EditTableHeaderProps> = ({
  items
}): JSX.Element => {
  const theme = useTheme()
  const [openModal, setOpenModal] = useState(false)
  const selectedDivision = divisionDetailsForForm()
  const handleEditButtonClick = (): void => {
    setOpenModal(true)
  }

  return (
    <>
      <Stack paddingTop={theme.spacing(5)} spacing={5}>
        {items?.map(item => {
          const { name, value } = item
          return <Box key={name}>
            <Typography
              variant="h6"
              component="span"
              color="text.disabled"
              sx={{
                mr: 6
              }}>
              {name}
            </Typography>
            <Typography
              variant="h6"
              component="span"
              color={value === null ? 'text.disabled' : undefined}>
              {value ?? 'N/A'}
            </Typography>
          </Box>
        })}

        <Box sx={{ pt: 3 }}>
          <GenericButton
            buttonType={ButtonType.Secondary}
            label='Edit Details'
            onClick={handleEditButtonClick}
            startIcon={<CreateOutlined />}
            buttonSize={ButtonSize.Medium}
            textTransform='uppercase'
          />
        </Box>

        <GenericModal
          isOpen={openModal}
          maxWidth='sm'
          title={'Edit Details'}
          onClose={() => {
            setOpenModal(false)
          }}
          action={false}
        >
          <CreateEditDivision handleCloseModal={() => { setOpenModal(false) }} selectedRow={selectedDivision?.selectedRow} />
        </GenericModal>
      </Stack>

    </>
  )
}

export default EditTableHeader
