import React from 'react'
import CreateMultipleChoiceQuestionCard from '../components/QuestionsCreation/CreateMultipleChoiceQuestionCard'
import CreateDescriptiveQuestionCard from '../components/QuestionsCreation/CreateDescriptiveQuestionCard'
import CreateFreeResponseQuestionCard from '../components/QuestionsCreation/CreateFreeResponseQuestionCard'
import { SurveyItemQuestionType } from '../types'
import { StyledSelectQuestionWrapper } from '../SurveyCreateQuestions/styles'
import CreateLinearScaleCard from '../components/QuestionsCreation/CreateLinearScaleCard'
import { getSelectedQuestion, getSurveySections } from '../../../store/slices/SurveyTemplateSlice/selectors'

interface SurveyCreateQuestionsProps {
  sectionNumber: number
}

const SurveyCreateQuestions: React.FC<SurveyCreateQuestionsProps> = ({ sectionNumber }): JSX.Element => {
  const selectedQuestionType = getSelectedQuestion()
  const surveySectionsList = getSurveySections()
  const questionNumber = (surveySectionsList[sectionNumber].surveyItems?.length ?? 0) + 1

  const showCreateQuestionType = (): JSX.Element => {
    switch (selectedQuestionType.questionType) {
      case SurveyItemQuestionType.MULTIPLE_CHOICE:
        return <CreateMultipleChoiceQuestionCard questionNumber={questionNumber} />
      case SurveyItemQuestionType.DESCRIPTIVE_TEXT:
        return <CreateDescriptiveQuestionCard />
      case SurveyItemQuestionType.FREE_RESPONSE:
        return <CreateFreeResponseQuestionCard questionNumber={questionNumber} />
      case SurveyItemQuestionType.LINEAR_SCALE:
        return <CreateLinearScaleCard questionNumber={questionNumber}/>
      default:
        return <></>
    }
  }
  return (
    <StyledSelectQuestionWrapper>
      {
        selectedQuestionType && sectionNumber === selectedQuestionType?.sectionIndex &&
        showCreateQuestionType()
      }
    </StyledSelectQuestionWrapper>
  )
}

export default SurveyCreateQuestions
