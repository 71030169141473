import React from 'react'
import TaskFeature from '../../features/TaskFeature'
import PageHeader from '../../components/PageHeader'
import PAGE_TITLES from '../../consts/titles'
import CustomHeader from '../../components/CustomHeader'

const TaskPage = (): JSX.Element => {
  return (
    <>
      <CustomHeader title={PAGE_TITLES.TASKS} />
      <PageHeader title={PAGE_TITLES.TASKS} />
      <TaskFeature />
    </>
  )
}

export default TaskPage
