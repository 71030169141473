import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import type { Theme } from '@mui/system'

import { grey } from '../../colors'
interface DialogTitleProps {
  theme?: Theme
}

export const StyledDialogTitle = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
  color: grey[250],
  padding: `0 0 ${theme.spacing(1)} 0 `,
  fontSize: `${theme.spacing(4.5)}`
}))
interface StyledDialogActionsProps {
  theme: Theme
}

export const StyledDialogActions = styled(DialogActions)<StyledDialogActionsProps>(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: `${theme.spacing(4)}`
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column-reverse'
  },
  padding: 0
}))
