import { useAppSelector } from '../..'
import { type EditableQuestion, type Question } from '../../../features/AdminSurveyListFeature/types'
import type { ISurveyListResponse, ISurveyRow } from '../../../features/AdminSurveyFeature/types'

export const getSurveyTitle = (): string => useAppSelector((state) => state.survey?.survey?.title)
export const getSurveyQuestions = (): Question[] => useAppSelector((state) => state.survey?.survey?.survey_items)
export const getSelectedQuestionType = (): string => useAppSelector((state) => state.survey?.selectedQuestionType)
export const getQuestionSaveStatus = (): boolean => useAppSelector((state) => state.survey?.questionSave)
export const getEditableQuestion = (): EditableQuestion => useAppSelector((state) => state.survey?.editableQuestion)
export const getEditableQuestionType = (): string => useAppSelector((state) => state.survey?.editableQuestionType)
export const getInvalidQuestionItemId = (): string => useAppSelector((state) => state.survey?.inValidQuestionItemId)
export const surveyListDetails = (): ISurveyListResponse => useAppSelector((state) => state.survey?.surveyList)
export const isSurveyListLoading = (): boolean => useAppSelector((state) => state.survey.surveyListLoading)
export const getEditableSurvey = (): ISurveyRow => useAppSelector((state) => state.survey?.editableSurvey)
export const getEditableSurveyItemQuestions = (): Question[] => useAppSelector((state) => state.survey.editableSurveyItemQuestions)
export const deleteLoading = (): boolean => useAppSelector((state) => state.survey.deleteLoading)
export const archiveLoading = (): boolean => useAppSelector((state) => state.survey.archiveLoading)
export const loading = (): boolean => useAppSelector((state) => state.survey.loading)
