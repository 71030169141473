import { useAppSelector } from '../..'
import { type ISlot, type IDistributionPlan } from '../../../models/DistributionPlan'
import { type ITerm } from '../../../models/Term'
import { type ISectionsList } from '../../../models/TermsSection'

export const getAllSlotData = (): IDistributionPlan | null => useAppSelector((state) => state.distributionPlan?.distributionPlan ?? [])
export const getSlotData = (): ISlot | null => useAppSelector((state) => state.distributionPlan?.slot ?? {})
export const getSlotSections = (): ISectionsList | null => useAppSelector((state) => state.distributionPlan?.slotSections ?? [])
export const getStatusLoading = (): boolean => useAppSelector((state) => state.distributionPlan?.statusLoading)
export const getSlotLoading = (): boolean => useAppSelector((state) => state.distributionPlan?.isLoading)
export const getCourseLoading = (): boolean => useAppSelector((state) => state.distributionPlan?.courseLoading)
export const deleteLoading = (): boolean => useAppSelector((state) => state.distributionPlan?.deleteLoading)
export const uploadLoading = (): boolean => useAppSelector((state) => state.distributionPlan?.uploadLoading)
