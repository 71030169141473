/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react'
import CustomHeader from '../../components/CustomHeader'
import { useAppDispatch } from '../../store'
import { getDivisionList, getDivisionDetails, getBreadcrumbList } from '../../store/slices/reportHierarchySlice/apis'
import { divisionDetails, divisionList, getBreadcrumbListData, getLoading, getReportUpdateState, isReportHierarchyHavingError } from '../../store/slices/reportHierarchySlice/selectors'
import PAGE_TITLES from '../../consts/titles'
import { useParams } from 'react-router-dom'
import DivisionDetails from '../../features/ReportHierarchyFeature/EditTableHeader/index'
import DivisionTable from '../../features/ReportHierarchyFeature/Table/'
import { resetState } from '../../store/slices/reportHierarchySlice'
import ROUTES from '../../consts/paths'
import { getInstructors } from '../../store/slices/instructorSlice/apis'
import NotFoundPage from '../NotFoundPage'
import isEmpty from 'lodash/isEmpty'
import useAsyncEffect from 'use-async-effect'
import { type CustomBreadcrumbT, prepareBreadCrumbs } from '../../utils/BreadcrumbDataPreparation'
import { type ApplyFiltersAndDispatchParams } from '../../components/CustomTable/types'
import { type GridSortItem } from '@mui/x-data-grid'
import PageHeader from '../../components/PageHeader'
import LoadingPageV2 from '../LoadingPageV2'

const breadcrumbPrefixList: CustomBreadcrumbT[] = [{ title: 'Reporting Hierarchy', path: ROUTES.REPORTING_HIERARCHY.toString() }]

const ReportHierarchyPage: React.FC = (): JSX.Element => {
  const [perPageSize, setPerPageSize] = useState<number>(10)
  const [search, setSearch] = useState<string>('')
  const [sortModel, setSortModel] = useState<GridSortItem>()

  const { id } = useParams()

  const dispatch = useAppDispatch()
  const division = divisionDetails()
  const list = divisionList()
  const isLoading = getLoading()
  const isError = isReportHierarchyHavingError()
  const breadcrumbData = getBreadcrumbListData()
  const updateState = getReportUpdateState()
  const preparedBreadcrumbs = prepareBreadCrumbs(breadcrumbData ?? [], ROUTES.REPORTING_HIERARCHY)
  const breadcrumb = (breadcrumbData !== undefined && breadcrumbData !== null && breadcrumbData.length > 0)
    ? breadcrumbData[breadcrumbData.length - 1]
    : null

  useAsyncEffect(async () => {
    dispatch(resetState())
    await dispatch(getBreadcrumbList({ urlId: id ?? '' }))
  }, [id])

  useAsyncEffect(async () => {
    if (breadcrumb != null) {
      void dispatch(getInstructors({}))
      void dispatch(getDivisionDetails({ urlId: breadcrumb.id }))

      if (breadcrumb.model === 'DIVISION') {
        const params = { pageSize: perPageSize }
        void dispatch(getDivisionList({ urlId: breadcrumb.id, params }))
      }
    }
  }, [breadcrumbData, updateState])

  const isReportHierarchyReady = !isEmpty(division) && (breadcrumb?.model === 'DIVISION' ? !isEmpty(list) : true)

  const childrenCount = list?.data?.length ?? 0

  const applyFiltersAndDispatch = async ({
    pageSize,
    pageNumber,
    search,
    sort: sortModel
  }: ApplyFiltersAndDispatchParams): Promise<void> => {
    const sort = sortModel && { field: sortModel.field, direction: sortModel.sort }
    const params = {
      search,
      sort: sortModel ? JSON.stringify(sort) : undefined,
      pageSize,
      pageNumber
    }

    void dispatch(getDivisionList({ urlId: breadcrumb?.id, params }))
  }

  const handleRowsPerPageChange = async (pageSize: number): Promise<void> => {
    setPerPageSize(pageSize)
    await applyFiltersAndDispatch({ pageSize, search, sort: sortModel })
  }

  const handleSearch = async (value: string): Promise<void> => {
    setSearch(value)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search: value, sort: sortModel })
  }

  const handlePageChange = async (page: number): Promise<void> => {
    await applyFiltersAndDispatch({ pageSize: perPageSize, pageNumber: page, search, sort: sortModel })
  }

  const onSortModelChange = async (sortModel: GridSortItem): Promise<void> => {
    setSortModel(sortModel)
    await applyFiltersAndDispatch({ pageSize: perPageSize, search, sort: sortModel })
  }

  return (
    <>
      {isLoading && (
        <LoadingPageV2 title={PAGE_TITLES.REPORTING_HIERARCHY} />
      )}

      <CustomHeader title={PAGE_TITLES.REPORTING_HIERARCHY} />
      {isReportHierarchyReady && (
        <>
          <PageHeader
            title={division.title}
            breadcrumb={breadcrumbPrefixList.concat(preparedBreadcrumbs ?? [])}
          />
          <DivisionDetails division={division} childrenCount={childrenCount} />
          {
            !isEmpty(list) &&
            <DivisionTable
              list={list}
              loading={isLoading}
              handlePageChange={(page: number) => { void handlePageChange(page) }}
              onSearch={(value: string) => { void handleSearch(value) }}
              onSortModelChange={(sortModel: GridSortItem) => { void onSortModelChange(sortModel) }}
              handleRowsPerPageChange={(pageSize: number) => { void handleRowsPerPageChange(pageSize) }}
            />
          }
        </>
      )}

      {isError != null && !isLoading && (<NotFoundPage />)}
    </>
  )
}

export default ReportHierarchyPage
