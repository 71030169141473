import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'
import { grey } from '../../../../../colors'
import { TextField } from '@mui/material'
interface StyledBoxWrapperProps {
  theme?: Theme
}

interface StyledProps {
  theme?: Theme
}

export const StyledTextField = styled(TextField)<StyledProps>(({ theme }) => ({
  '& fieldset': {
    border: 'none'
  }
}))

export const StyledBoxWrapper = styled(Box)<StyledBoxWrapperProps>(({ theme }) => ({
  border: `1px solid ${grey[200]}`,
  borderRadius: theme.spacing(3),
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}))
