import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/system'
import CustomAppBar from '../../components/CustomAppbar'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { StyledDrawerBox, StyledOutletBox } from './styles'

const DashboardLayout: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))

  const calculateSidebarWidth = (): string => {
    switch (true) {
      case isMobile:
        return `${theme.spacing(58)}`
      case isTablet:
        return `${theme.spacing(58)}`
      default:
        return `${theme.spacing(58)}`
    }
  }

  const calculateAppbarOffset = (): string => {
    switch (true) {
      case isMobile:
        return `${theme.spacing(12)}`
      case isTablet:
        return `${theme.spacing(14)}`
      default:
        return `${theme.spacing(16)}`
    }
  }

  return (
    <Box marginTop={calculateAppbarOffset()}>
      <CustomAppBar />
      <StyledDrawerBox>
        <StyledOutletBox sideBarWidth={calculateSidebarWidth()} isMobile={isMobile}>
          <Outlet />
        </StyledOutletBox>
      </StyledDrawerBox>
    </Box>
  )
}

export default DashboardLayout
