import { styled } from '@mui/system'
import { Box, type BoxProps } from '@mui/material'
interface StickyCompactHeaderOffsetProps extends BoxProps {
  stickyCompactHeaderHeight?: number
}

interface HeaderDetailsContainerProps extends BoxProps {
  hasControls?: boolean
}

export const FullSizedHeader = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column', // Once we get to the 'md' screensize, wrap buttons underneath the header details
    flexWrap: 'wrap'
  }
}))

export const HeaderDetailsContainer = styled(Box)<HeaderDetailsContainerProps>(({ theme, hasControls }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    marginBottom: hasControls ? theme.spacing(4) : 0
  }
}))

export const StickyCompactHeaderOffset = styled(Box)<StickyCompactHeaderOffsetProps>(({ stickyCompactHeaderHeight = 72 }) => ({
  minHeight: `${stickyCompactHeaderHeight}px`
}))

export const StickyCompactHeader = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100vw',
  position: 'fixed',
  marginTop: '0px !important',
  padding: `${theme.spacing(4)} ${theme.spacing(12)}`,
  top: `${theme.spacing(16)} !important`, // Default top based on our CustomAppbar component
  [theme.breakpoints.down('md')]: {
    top: `${theme.spacing(14)} !important`,
    flexDirection: 'column', // Once we get to the 'md' screensize, wrap buttons underneath the header details
    flexWrap: 'wrap',
    alignItems: 'flex-start' // Positions things right aligned
  },
  [theme.breakpoints.down('sm')]: {
    top: `${theme.spacing(12)} !important`,
    padding: theme.spacing(4)
  },
  left: 0,
  zIndex: 7,
  backgroundColor: 'white',
  boxShadow: '0px 4px 8px -2px rgba(32, 41, 57, 0.10), 0px 2px 4px -2px rgba(32, 41, 57, 0.06)'
}))
