import { createSlice } from '@reduxjs/toolkit'
import { type ITermListResponse, type ITerm, type ITermList } from '../../../models/Term'
import { createTermsApi, deleteTermsApi, getAllTermsApi, getTermByIdApi, updateTermsApi } from './api'
import { notification } from '../../../components/Notifications'

export interface TermsSliceType {
  loading: boolean
  deleteLoading: boolean
  createAndUpdateLoading: boolean
  terms: ITermListResponse | null
  term: ITerm | null
  termLoading: boolean
}

export const initialState: TermsSliceType = {
  loading: false,
  deleteLoading: false,
  createAndUpdateLoading: false,
  terms: null,
  term: null,
  termLoading: false
}

const TermsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get all terms
      .addCase(getAllTermsApi.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllTermsApi.fulfilled, (state, action) => {
        state.loading = false
        state.terms = action.payload
      })
      .addCase(getAllTermsApi.rejected, (state, action) => {
        state.loading = false
      })
      // Create terms
      .addCase(createTermsApi.pending, (state) => {
        state.createAndUpdateLoading = true
      })
      .addCase(createTermsApi.fulfilled, (state, action) => {
        state.createAndUpdateLoading = false
      })
      .addCase(createTermsApi.rejected, (state) => {
        state.createAndUpdateLoading = false
      })
      // Update terms
      .addCase(updateTermsApi.pending, (state) => {
        state.createAndUpdateLoading = true
      })
      .addCase(updateTermsApi.fulfilled, (state, action) => {
        state.createAndUpdateLoading = false
        state.term = action.payload
      })
      .addCase(updateTermsApi.rejected, (state) => {
        state.createAndUpdateLoading = false
      })
    // Get single terms Details
      .addCase(getTermByIdApi.pending, (state) => {
        state.termLoading = true
      })
      .addCase(getTermByIdApi.fulfilled, (state, action) => {
        state.term = action.payload
        state.termLoading = false
      })
      .addCase(getTermByIdApi.rejected, (state) => {
        state.termLoading = false
      })
      // Delete terms
      .addCase(deleteTermsApi.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteTermsApi.fulfilled, (state, action) => {
        state.deleteLoading = false
        if (state.terms) {
          state.terms.data = state.terms?.data.filter((terms: ITermList) => terms?.data?.termId !== action.meta?.arg?.urlId)
          state.terms.pagination.totalResults = state.terms?.pagination?.totalResults - 1
        }
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteTermsApi.rejected, (state) => {
        state.deleteLoading = false
      })
  }
})

export default TermsSlice.reducer
