import React from 'react'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'
import NotFoundCard from '../../components/NotFoundCard'
import CenteredContainer from '../../components/CenteredContainer'
import NoPermissionCard from './NoPermissionCard'

export interface NoPermissionsFeatureProps {
  title?: PAGE_TITLES
}

const NoPermissionsFeature: React.FC<NoPermissionsFeatureProps> = ({
  title
}): JSX.Element => {
  return (
    <>
     {(title != null)
       ? <CustomHeader title={title} info={PAGE_TITLES.NO_PERMISSIONS} />
       : <CustomHeader title={PAGE_TITLES.NO_PERMISSIONS} />}
        <NoPermissionCard/>
    </>

  )
}

export default NoPermissionsFeature
