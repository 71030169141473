import React from 'react'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import { ItemTypeMappings, type Question, SurveyItemQuestionType } from '../../../types'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { StyledDropdownWrapper, StyledMenuItem } from './styles'
import { setEditableQuestion, setSelectedQuestion, updateQuestionInSection } from '../../../../../store/slices/SurveyTemplateSlice'
import { getEditableQuestion, getSelectedQuestion } from '../../../../../store/slices/SurveyTemplateSlice/selectors'

interface QuestionDropDownProps {
  questionIndex: number
  sectionIndex: number
}

const QuestionDropDown: React.FC<QuestionDropDownProps> = ({ questionIndex, sectionIndex }): JSX.Element => {
  const dispatch = useDispatch()
  const editableQuestion = getEditableQuestion()
  const selectedQuestion = getSelectedQuestion()
  const defaultValue = editableQuestion?.question?.itemType ?? selectedQuestion?.questionType
  const theme = useTheme()

  const handleChange = (event: SelectChangeEvent): void => {
    const questionType = event.target.value

    if (selectedQuestion) {
      dispatch(setSelectedQuestion({ sectionIndex, questionType }))
      return
    }

    let data

    if (questionType === SurveyItemQuestionType.FREE_RESPONSE) {
      data = {
        itemType: SurveyItemQuestionType.FREE_RESPONSE,
        prompt: ''
      }
    }
    if (questionType === SurveyItemQuestionType.LINEAR_SCALE) {
      data = {
        itemType: SurveyItemQuestionType.LINEAR_SCALE,
        prompt: '',
        object: [{ option: '', weight: 1 }, { option: '', weight: 2 }, { option: '', weight: 3 }, { option: '', weight: 4 }, { option: '', weight: 5 }],
        isRequired: false
      }
    }
    if (questionType === SurveyItemQuestionType.MULTIPLE_CHOICE) {
      data = {
        itemType: SurveyItemQuestionType.MULTIPLE_CHOICE,
        prompt: '',
        object: ['', ''],
        isRequired: false
      }
    }
    dispatch(updateQuestionInSection({ sectionIndex, questionIndex, updatedQuestion: data as Question }))
    dispatch(setEditableQuestion({ sectionIndex, questionIndex, question: data as Question }))
  }

  const itemKeys = Object.keys(ItemTypeMappings)

  return (
    <StyledDropdownWrapper>
      <FormControl fullWidth >
        <Select
          value={defaultValue}
          onChange={handleChange}
          MenuProps={{ disablePortal: true }}
          sx={{
            height: theme.spacing(11.5),
            '& .MuiInputBase-input': {
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2.5)
            }
          }}
        >
          {
            itemKeys.map((item: string, index: number) => {
              if (ItemTypeMappings[item] !== SurveyItemQuestionType.DESCRIPTIVE_TEXT) {
                return (
                  <StyledMenuItem key={index} value={ItemTypeMappings[item]}>
                    {item}
                  </StyledMenuItem>
                )
              }
              return null
            })
          }
          {/* <StyledMenuItem value={SurveyItemQuestionType.FREE_RESPONSE}>
          </StyledMenuItem>
          <StyledMenuItem value={SurveyItemQuestionType.LINEAR_SCALE}>
            {ItemTypeMappings[SurveyItemQuestionType.LINEAR_SCALE]}
          </StyledMenuItem>
          <StyledMenuItem value={SurveyItemQuestionType.MULTIPLE_CHOICE}>
            {ItemTypeMappings[SurveyItemQuestionType.MULTIPLE_CHOICE]}
          </StyledMenuItem> */}
        </Select>
      </FormControl>
    </StyledDropdownWrapper >
  )
}

export default QuestionDropDown
