import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}

export const StyledBoxWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.spacing(3),
  border: `1px solid ${theme.palette.borderColor as string}`,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2)
}))

export const StyledBox = styled(Box)<StyledProps>(({ theme }) => ({
  width: '100%'
}))

export const StyledTextField = styled(TextField)<StyledProps>(({ theme }) => ({
  '& fieldset':
    { border: 'none' }

}))
