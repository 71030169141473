import React, { useState, type ChangeEvent } from 'react'
import { SectionWrapper } from '../styles'
import { useAppDispatch } from '../../../store'
import { type ISurveySection, setSectionName, setSectionDescription, setSectionTitle } from '../../../store/slices/SurveyTemplateSlice'
import { Box, InputLabel, TextField, useTheme } from '@mui/material'
import SectionActions from '../components/SectionActions'
import SurveyItemDropdown from '../components/SurveyItemDropdown'
import { getSurveySections } from '../../../store/slices/SurveyTemplateSlice/selectors'
import { grey } from '../../../colors'
import SurveyCreateQuestions from '../SurveyCreateQuestions'
import SurveyPreparedQuestions from '../SurveyPreparedQuestions'
import { SectionName } from './styles'
import GenericButton from '../../../components/GenericButton'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ButtonType } from '../../../components/GenericButton/styles'

interface EditableSectionNameProps {
  sectionNumber: number
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSave: () => void
  onCancel: () => void
}

const EditableSectionName: React.FC<EditableSectionNameProps> = ({ sectionNumber, name, onChange, onSave, onCancel }) => {
  const theme = useTheme()

  return (
    <Box display="flex" gap={2} alignItems="center">
      <TextField
        sx={{ marginTop: theme.spacing(2.5), minWidth: '300px' }}
        placeholder="Untitled section"
        value={name}
        onChange={onChange}
      />
      <Box display="flex" gap={1} alignItems="center" mt={2.5}>
        <GenericButton onClick={onSave} buttonType={ButtonType.Secondary} style={{ padding: '10px' }} icon={<CheckRoundedIcon />} />
        <GenericButton onClick={onCancel} buttonType={ButtonType.Secondary} style={{ padding: '10px' }} icon={<CloseRoundedIcon />} />
      </Box>
    </Box>
  )
}

const SurveySections: React.FC = () => {
  const [editableSectionName, setEditableSectionName] = useState<number | null>(null)
  const [name, setName] = useState<string>('')

  const surveySectionsList = getSurveySections()
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const handleSectionChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    sectionNumber: number,
    setter: typeof setSectionTitle | typeof setSectionDescription
  ): void => {
    const { value } = event.target
    dispatch(setter({ sectionNumber, value }))
  }

  const handleSectionNameChange = (sectionNumber: number): void => {
    dispatch(setSectionName({ sectionNumber, value: name || 'Untitled section' }))
    setEditableSectionName(null)
  }

  const handleEditSectionName = (sectionNumber: number, currentName: string): void => {
    setEditableSectionName(sectionNumber)
    setName(currentName)
  }

  const handleCloseSectionName = (): void => {
    setEditableSectionName(null)
  }

  return (
    <>
      {surveySectionsList?.map((section: ISurveySection, index: number) => (
        <SectionWrapper key={index} id={index.toString()}>
          {editableSectionName === index
            ? (
            <EditableSectionName
              sectionNumber={index}
              name={name}
              onChange={(e) => { setName(e.target.value) }}
              onSave={() => { handleSectionNameChange(index) }}
              onCancel={handleCloseSectionName}
            />
              )
            : (
            <SectionName onClick={() => { handleEditSectionName(index, section.name) }}>{section.name}</SectionName>
              )}

          <InputLabel sx={{ fontSize: '14px', fontWeight: 500, color: grey[950], mt: theme.spacing(6) }}>Display title (Optional)</InputLabel>
          <TextField
            sx={{ marginTop: theme.spacing(2.5) }}
            fullWidth
            placeholder="e.g General questions"
            value={section?.title}
            onChange={(e) => { handleSectionChange(e, index, setSectionTitle) }}
          />

          <InputLabel sx={{ fontSize: '14px', fontWeight: 500, color: grey[950], mt: theme.spacing(6) }}>Section description (Optional)</InputLabel>
          <TextField
            sx={{ marginY: theme.spacing(2.5) }}
            fullWidth
            placeholder="e.g. A simple description that captures the content of this section."
            value={section?.descr}
            onChange={(e) => { handleSectionChange(e, index, setSectionDescription) }}
          />
          <SurveyPreparedQuestions surveyItems={section?.surveyItems} sectionIndex={index} />
          <SurveyCreateQuestions sectionNumber={index} />
          <SurveyItemDropdown sectionNumber={index} />
          <SectionActions sectionNumber={index} totalSections={surveySectionsList?.length} />
        </SectionWrapper>
      ))}
    </>
  )
}

export default SurveySections
