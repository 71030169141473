import React, { useEffect, useState } from 'react'
import QuestionDropDown from '../QuestionDropDown'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MultipleChoiceOptions from './MultipleChoiceOptions'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { type MultiChoiceQuestion, SurveyItemQuestionType } from '../../../types'
import { useDispatch } from 'react-redux'
import CreateQuestionCardWrapper from '../CreateQuestionCardWrapper'
import { StyledBox, StyledMultipleChoiceWrapper, StyledOptionWrapper, StyledQuestionWrapper, StyledTextField } from './styles'
import { ButtonSize, ButtonType } from '../../../../../components/GenericButton/styles'
import GenericButton from '../../../../../components/GenericButton'
import { addSurveyQestionInSection, setEditableQuestion, setSelectedQuestion, updateQuestionInSection } from '../../../../../store/slices/SurveyTemplateSlice'
import { getEditableQuestion } from '../../../../../store/slices/SurveyTemplateSlice/selectors'
import RequiredField from '../../RequiredField'
import { useTheme } from '@mui/material/styles'
import { grey } from '../../../../../colors'

interface CreateMultipleChoiceQuestionCardProps {
  sectionIndex?: number
  questionIndex?: number
  questionNumber?: number
}

const CreateMultipleChoiceQuestionCard: React.FC<CreateMultipleChoiceQuestionCardProps> = ({ sectionIndex = 0, questionIndex = 0, questionNumber }): JSX.Element => {
  const dispatch = useDispatch()
  const editableQuestion = getEditableQuestion()
  const theme = useTheme()

  const [multipleChoiceCardData, setMultipleChoiceCardData] = useState<MultiChoiceQuestion>({
    itemType: SurveyItemQuestionType.MULTIPLE_CHOICE,
    prompt: '',
    object: ['', ''],
    isRequired: false
  })

  useEffect(() => {
    if (editableQuestion != null) {
      setMultipleChoiceCardData(editableQuestion?.question as MultiChoiceQuestion)
    }
  }, [editableQuestion, dispatch])

  const handleQuestionTitle = (questionTitle: string): void => {
    setMultipleChoiceCardData(prevState => {
      return { ...prevState, prompt: questionTitle }
    })
  }

  const AddNewOption = (): void => {
    setMultipleChoiceCardData(prevState => {
      const newOptions = [...prevState?.object]
      newOptions.push('')
      return { ...prevState, object: newOptions }
    })
  }

  const RemoveOption = (index: number): void => {
    setMultipleChoiceCardData(prevState => {
      const newOptions = [...prevState.object]
      newOptions.splice(index, 1)
      return { ...prevState, object: newOptions }
    })
  }

  const handleUpdate = (index: number, option: string): void => {
    setMultipleChoiceCardData(prevState => {
      const newOptions = [...prevState.object]
      if (option !== '') {
        newOptions[index] = option
      } else {
        newOptions[index] = ''
      }
      return { ...prevState, object: newOptions }
    })
  }
  const handleRequired = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setMultipleChoiceCardData(prevState => {
      return { ...prevState, isRequired: checked }
    })
  }

  const handleClickAway = (): void => {
    if (editableQuestion) {
      dispatch(updateQuestionInSection({ sectionIndex, questionIndex, updatedQuestion: multipleChoiceCardData }))
      dispatch(setEditableQuestion(null))
      return
    }

    dispatch(addSurveyQestionInSection(multipleChoiceCardData))
    dispatch(setSelectedQuestion(null))
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}>
      <StyledBox >
        <CreateQuestionCardWrapper questionNumber={questionNumber}>
          <>
            <StyledQuestionWrapper >
              <StyledTextField value={multipleChoiceCardData?.prompt} type='text' placeholder='Type your question...' autoFocus onChange={(e) => { handleQuestionTitle(e.target.value) }} />
              <QuestionDropDown sectionIndex={sectionIndex} questionIndex={questionIndex} />
            </StyledQuestionWrapper>
            <StyledMultipleChoiceWrapper >
              {multipleChoiceCardData?.object?.map((option: string, index: number) => (
                <StyledOptionWrapper key={index}>
                  <MultipleChoiceOptions option={option} index={index} handleUpdate={handleUpdate} />
                  <GenericButton
                    buttonSize={ButtonSize.Small}
                    buttonType={ButtonType.Ghost}
                    icon={<ClearIcon color='action' />}
                    onClick={() => { RemoveOption(index) }}
                    disabled={multipleChoiceCardData?.object?.length <= 2} />
                </StyledOptionWrapper>
              ))}
            </StyledMultipleChoiceWrapper>
            <Box display='flex' gap={theme.spacing(0)} mt={theme.spacing(4)} alignItems='center'>
              <GenericButton
                buttonType={ButtonType.Ghost}
                startIcon={<AddIcon sx={{ fontWeight: 500 }} />}
                onClick={() => { AddNewOption() }}
                label='Add Option'
                buttonSize={ButtonSize.Medium}
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: grey[850],
                  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`
                }}
              />
            </Box>
            <RequiredField defaultValue={multipleChoiceCardData.isRequired ?? false} handleChange={handleRequired} />
          </>
        </CreateQuestionCardWrapper>
      </StyledBox>
    </ClickAwayListener>
  )
}

export default CreateMultipleChoiceQuestionCard
