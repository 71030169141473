import React from 'react'
import { Box, Divider, useMediaQuery } from '@mui/material'
import type { AppBarProps } from '@mui/material'
import { StyledAppBar, StyledToolbar } from './styles'
import classrankedLogo from '../../assets/classRankedLogo.svg'
import { useTheme } from '@mui/material/styles'
import TabMenu from './TabMenu/index'
import { MobileTabMenu } from './MobileTabMenu'
import { useAppDispatch } from '../../store'
import useAsyncEffect from 'use-async-effect'
import { getUserRolesApi } from '../../store/slices/roleSlice/api'
import { getRoles } from '../../store/slices/roleSlice/selectors'

interface CustomAppBarProps extends AppBarProps {
  onClick?: () => void
  isTabMenu?: boolean
}
const CustomAppBar = function ({
  isTabMenu = true,
  onClick = () => { }
}: CustomAppBarProps): JSX.Element {
  const theme = useTheme()
  const divisions = getRoles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useAppDispatch()

  useAsyncEffect(() => {
    if (!divisions) {
      void dispatch(getUserRolesApi({}))
    }
  }, [])

  return (
    <StyledAppBar
      elevation={0} >
      <StyledToolbar isMobile={isMobile}>
        <Box display='flex' alignItems='center' justifyContent={isMobile ? 'space-between' : 'unset'} gap={theme.spacing(5.5)} width='100%'>
          <img aria-label='ClassRanked logo' tabIndex={0} alt='ClassRanked logo' width={'120px'} height={'36px'}
            onClick={onClick}
            src={classrankedLogo} />
          {isTabMenu && !isMobile && <TabMenu />}
          {isTabMenu && isMobile && <MobileTabMenu />}
        </Box>
      </StyledToolbar>
      <Divider />
    </StyledAppBar>
  )
}

export default CustomAppBar
