import React, { useEffect } from 'react'
import { useLocation, useSearchParams, Navigate, Outlet } from 'react-router-dom'

import { useAuthenticator } from '@aws-amplify/ui-react'
import LoadingPageV2 from '../../pages/LoadingPageV2'
import ROUTES from '../../consts/paths'
import { useAppDispatch } from '../../store'
import { isEmpty } from 'lodash'
import { useCookies } from 'react-cookie'

// https://ui.docs.amplify.aws/react/guides/auth-protected
// @TODO We need to use route types for route variable, AWS has predefined types for that.
export const PrivateRoute: React.FC = (): JSX.Element => {
  const [cookies, setCookie, removeCookie] = useCookies(['edlink_refresh_token_exists'])
  const location = useLocation()
  const [searchParams, _] = useSearchParams()

  const { route } = useAuthenticator((context) => [context.route])

  if (cookies.edlink_refresh_token_exists != null) {
    // if edlink_refresh_token_exists exists, even if access token is expired, server should refresh
    return <Outlet />
  }

  if (route === 'idle') { return <LoadingPageV2 /> }
  if (route === 'signOut') {
    return <Navigate to={ROUTES.LOGOUT} replace />
  }
  if (route !== 'authenticated') {
    return <Navigate to={ROUTES.LOGOUT} state={{ from: location, referralUser: searchParams.get('referraluser') }} replace />
  }

  return <Outlet />
}
