
import { useAppSelector } from '../..'
import { type DivisionAndSection } from '../../../models/Role'

export const getLoading = (): boolean => useAppSelector((state) => state.roles.loading)
export const getActionLoading = (): boolean => useAppSelector((state) => state.roles.actionLoading)
export const getPagePermissionError = (): boolean => useAppSelector((state) => state.roles.pagePermissionError)
export const getRoleError = (): boolean => useAppSelector((state) => state.roles.roleError)
export const getRoles = (): DivisionAndSection[] => useAppSelector((state) => state.roles.roles)
export const getPagePermissions = (): string[] => useAppSelector((state) => state.roles.pagePermissions)
export const getPrimaryInstructorRole = (): DivisionAndSection | undefined => {
  const roles: DivisionAndSection[] | undefined = useAppSelector((state) => state.roles.roles)
  const primaryInstructorRole = roles?.find(role => role.role?.roleName === 'Primary Instructor')
  return primaryInstructorRole
}
