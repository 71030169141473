import { isEmpty } from 'lodash'
import { useAppSelector } from '../..'
import { type IDistributionSeries } from '../../../models/DistributionSeries'
import { type ITerm } from '../../../models/Term'

export const getSelectedTerm = (): ITerm | undefined => useAppSelector((state) => state.termHeader.selectedTerm)
export const getTermListFromTermSlice = (): ITerm[] | undefined => useAppSelector((state) => state.termHeader.term)
export const getSelectedSeries = (): IDistributionSeries | undefined => useAppSelector((state) => state.termHeader.series)
export const getIsTermHeaderReady = (): boolean => useAppSelector((state) => !isEmpty(state.termHeader.series) && !isEmpty(state.termHeader.term))

export const getTermAndSliceApiParams = (): Record<string, any> => useAppSelector((state) =>
  ({ termId: state.termHeader.selectedTerm?.termId, distributionSeriesId: state.termHeader.series?.distributionSeriesId }))
