import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import TextField from '@mui/material/TextField'
import QuestionDropDown from '../QuestionDropDown'
import { useDispatch } from 'react-redux'
import { type FreeResponseQuestion, SurveyItemQuestionType } from '../../../types'
import CreateQuestionCardWrapper from '../CreateQuestionCardWrapper'
import { StyledBox, StyledDescriptiveWrapper, StyledTextField } from './styles'
import { addSurveyQestionInSection, setEditableQuestion, setSelectedQuestion, updateQuestionInSection } from '../../../../../store/slices/SurveyTemplateSlice'
import { getEditableQuestion } from '../../../../../store/slices/SurveyTemplateSlice/selectors'
import RequiredField from '../../RequiredField'

interface CreateFreeResponseQuestionCardProps {
  sectionIndex?: number
  questionIndex?: number
  questionNumber?: number
}

const CreateFreeResponseQuestionCard: React.FC<CreateFreeResponseQuestionCardProps> = ({ sectionIndex = 0, questionIndex = 0, questionNumber }): JSX.Element => {
  const dispatch = useDispatch()
  const editableQuestion = getEditableQuestion()

  const [freeResponseData, setFreeResponseData] = useState<FreeResponseQuestion>({
    isRequired: false,
    itemType: SurveyItemQuestionType.FREE_RESPONSE,
    prompt: ''
  })

  useEffect(() => {
    if (editableQuestion != null && editableQuestion?.question?.itemType === SurveyItemQuestionType.FREE_RESPONSE) {
      setFreeResponseData(editableQuestion.question as FreeResponseQuestion)
    }
  }, [editableQuestion])

  const handleQuestionTitle = (prompt: string): void => {
    setFreeResponseData(prevState => {
      return { ...prevState, prompt }
    })
  }

  const handleClickAway = (): void => {
    if (editableQuestion) {
      dispatch(updateQuestionInSection({ sectionIndex, questionIndex, updatedQuestion: freeResponseData }))
      dispatch(setEditableQuestion(null))
      return
    }

    dispatch(addSurveyQestionInSection(freeResponseData))
    dispatch(setSelectedQuestion(null))
  }
  const handleRequired = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setFreeResponseData((prevState: any) => {
      return { ...prevState, isRequired: checked }
    })
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}>
      <StyledBox>
        <CreateQuestionCardWrapper questionNumber={questionNumber}>
          <Box>
            <StyledDescriptiveWrapper>
              <StyledTextField type='text' value={freeResponseData?.prompt} placeholder='Type your question...' autoFocus onChange={(e) => { handleQuestionTitle(e.target.value) }} />
              <QuestionDropDown sectionIndex={sectionIndex} questionIndex={questionIndex} />
            </StyledDescriptiveWrapper>
            <TextField disabled type='text' placeholder='Type your response...' fullWidth />
            <RequiredField defaultValue={freeResponseData.isRequired ?? false} handleChange={handleRequired} />
          </Box>
        </CreateQuestionCardWrapper>
      </StyledBox>
    </ClickAwayListener>
  )
}

export default CreateFreeResponseQuestionCard
