import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import PreparedQuestionCardWrapper from '../PreparedQuestionCardWrapper'
import { StyledFormLabel } from './styles'
import { type LinearQuestion } from '../../../types'
import { type IOption } from '../../../../../models/SurveyTemplate'
import { PreparedEmptyField } from '../../../styles'

interface LinearQuestionCardProps {
  question: LinearQuestion
  questionNumber: number
  index: number
  sectionIndex: number
  totalQuestionInSection: number
}

const LinearQuestionCard: React.FC<LinearQuestionCardProps> = ({ question, questionNumber, index, sectionIndex, totalQuestionInSection }): JSX.Element => {
  const showOptions = (option: string, index: number): JSX.Element | undefined => {
    if (option !== '') {
      return <FormControlLabel key={index} value={option} control={<Radio disabled />} label={option} />
    }
  }

  return (
    <PreparedQuestionCardWrapper questionNumber={questionNumber} question={question} index={index} sectionIndex={sectionIndex} totalQuestionInSection={totalQuestionInSection}>
      <FormControl fullWidth>
        {question?.prompt !== ''
          ? <StyledFormLabel>{question.prompt}</StyledFormLabel>
          : <PreparedEmptyField>Type your question...</PreparedEmptyField>
        }
        <RadioGroup
          name='radio-buttons-group'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            mt: 1
          }}
        >
          {question?.object?.map((option: IOption, index: number) => {
            return showOptions(option.option, index)
          })}
        </RadioGroup>
      </FormControl>
    </PreparedQuestionCardWrapper>
  )
}

export default LinearQuestionCard
