import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { type SelectChangeEvent } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { red } from '../../colors'

interface ChildItem {
  categoryName: string
}

interface CustomSelectMenuItemProps {
  name: string
  label: string
  defaultValue: any
  options: ChildItem[]
}

const CustomSelectMenuItem: React.FC<CustomSelectMenuItemProps> = ({
  name,
  label,
  defaultValue,
  options

}) => {
  const { control, formState: { errors } } = useFormContext()

  return (
    <>
      <InputLabel id="select-label" sx={{ marginBottom: 2 }} required>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange: onFieldChange, onBlur, value }, fieldState: { error } }) => {
          const errorMessage = error?.message ?? false
          return (
          <>
            <Select
            fullWidth
            size='small'
              labelId="checkbox-label"
              id="checkbox"
              multiple
              value={value}
              onChange={(e: SelectChangeEvent<Record<string, any>>) => {
                onFieldChange(e.target.value as string[])
              }}
              renderValue={(selected) =>
                selected
                  .map((categoryId: string) =>
                    options.find((child) => child.categoryName === categoryId)?.categoryName
                  )
                  .join(', ')
              }
            >
              {options?.map((child, ind) => (
                <MenuItem key={ind} value={child.categoryName}>
                  <Checkbox checked={value.includes(child.categoryName)} />
                  <ListItemText primary={child.categoryName} />
                </MenuItem>
              ))}
            </Select>
            {errors[name] && <span style={{ color: red[200], fontSize: '14px' }}>{errorMessage}</span>}
          </>
          )
        }
              }
      />
    </>
  )
}

export default CustomSelectMenuItem
