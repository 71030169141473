import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import GenericButton from '../GenericButton'
import { ButtonType } from '../GenericButton/styles'
import { useTheme } from '@mui/material'
import { useAppDispatch } from '../../store'

interface BackButtonProps {
  showLabel?: boolean
  resetState?: () => any
}

const BackButton: React.FC<BackButtonProps> = ({ showLabel = true, resetState }): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const theme = useTheme()

  const handleBack = useCallback((): void => {
    navigate(-1)
    if (resetState) {
      dispatch(resetState())
    }
  }, [])

  return (
    <GenericButton
      variant='outlined'
      buttonType={ButtonType.Secondary}
      label={showLabel ? 'Back' : undefined}
      icon={<ArrowBackIcon fontSize='small' />}
      onClick={handleBack}
      style={{ padding: theme.spacing(3) }}
    />
  )
}

export default BackButton
