import { styled, alpha } from '@mui/material/styles'
import type { Theme } from '@mui/system'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

interface StyledProps {
  theme?: Theme
}

export const StyledMenu = styled(Menu)<StyledProps>(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
        color: theme.palette.primary.dark

      },
      '&:hover': {
        color: theme.palette.primary.dark
      }
    }
  }
}))

export const StyledMenuItem = styled(MenuItem)<StyledProps>(({ theme }) => ({
  width: theme.spacing(71),
  height: theme.spacing(10)
}))
