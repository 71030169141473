import React from 'react'
import NotFoundPage from '../NotFoundPage'
import SurveyFeature from '../../features/SurveyFeature'
import { useParams } from 'react-router-dom'
import PAGE_TITLES from '../../consts/titles'
import CustomHeader from '../../components/CustomHeader'
import { useAppDispatch } from '../../store'
import useAsyncEffect from 'use-async-effect'
import SkipNavigation from '../../components/SkipNavigation'
import { getStudentSurveyById } from '../../store/slices/studentSurveyListSlice/apis'
import { getIsLoading } from '../../store/slices/studentSurveyListSlice/selectors'
import Loader from '../../components/Loader'

const SurveyPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { id } = useParams()
  const loading = getIsLoading()

  useAsyncEffect(async () => {
    void dispatch(getStudentSurveyById({ urlId: id }))
  }, [id, dispatch])

  if (id == null) {
    return <NotFoundPage />
  }

  if (loading) {
    return <Loader open={loading} />
  }

  return (
      <>
        <SkipNavigation />
        <CustomHeader title={PAGE_TITLES.SURVEYS} />
        <SurveyFeature/>
      </>
  )
}

export default SurveyPage
