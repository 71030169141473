import React from 'react'
import TermsDetailsFeature from '../../features/TermsDetailsFeature'

const TermsDetailsPage: React.FC = (): JSX.Element => {
  return (
    <>
      <TermsDetailsFeature />
    </>
  )
}

export default TermsDetailsPage
