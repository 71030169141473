import React from 'react'
import { type ReportHierarchyRowData } from '../types'
import { ButtonType } from '../../../components/GenericButton/styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { grey } from '../../../colors'
import { archiveDivisionApi } from '../../../store/slices/reportHierarchySlice/apis'
import { archiveLoading } from '../../../store/slices/reportHierarchySlice/selectors'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'

interface ArchiveDivisionDivisionProps {
  selectedRow?: ReportHierarchyRowData
  handleCloseModal?: () => void
  showErrorModal?: () => void
  setErrorMessage?: (arg: string) => void
}

const ArchiveDivision: React.FC<ArchiveDivisionDivisionProps> = ({ selectedRow, handleCloseModal, showErrorModal, setErrorMessage }) => {
  const dispatch = useAppDispatch()
  const isLoading = archiveLoading()
  const theme = useTheme()

  const handleDelete = async (): Promise<void> => {
    try {
      await dispatch(archiveDivisionApi({ urlId: selectedRow?.divisionId })).unwrap().then(() => { handleCloseModal?.() })
    } catch (error: any) {
      if (error && error.response?.status === 422) {
        const errorMessage = error.response?.data?.error
        setErrorMessage?.(errorMessage)
        showErrorModal?.()
      }
    }
  }

  return (
    <>
      <Typography fontSize='14px' lineHeight={theme.spacing(5)} color={grey[850]}>
        You will not be able to recover this Academic Unit. All references to it will be hidden.
      </Typography>
      <DefaultModalActions
        confirmButtonProps={{
          onClick: handleDelete,
          text: 'Archive',
          loading: isLoading,
          buttonType: ButtonType.Destructive
        }}
        destructiveButtonProps={{
          onClick: handleCloseModal
        }}
      />
    </>

  )
}

export default ArchiveDivision
