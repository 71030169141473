import React from 'react'
import { StyledQuestionBox, StyledQuestionBoxHeader } from '../../../styles'
import PreparedQuestionCardDropDownMenu from './PreparedQuestionCardDropDownMenu'
import { useDispatch } from 'react-redux'
import { StyledBox } from './styles'
import { type Question } from '../../../types'
import { getEditableQuestion, getSelectedQuestion } from '../../../../../store/slices/SurveyTemplateSlice/selectors'
import { setEditableQuestion } from '../../../../../store/slices/SurveyTemplateSlice'

interface PreparedQuestionCardWrapperProps {
  children: JSX.Element
  question: Question
  index?: number
  questionNumber?: number
  sectionIndex: number
  totalQuestionInSection: number
}

const PreparedQuestionCardWrapper: React.FC<PreparedQuestionCardWrapperProps> = ({ questionNumber, children, question, index = 0, sectionIndex, totalQuestionInSection }): JSX.Element => {
  const dispatch = useDispatch()

  const editableQuestion = getEditableQuestion()
  const selectedQuestion = getSelectedQuestion()

  const handleEditableQuestion = (): void => {
    if (editableQuestion == null && !selectedQuestion) {
      dispatch(setEditableQuestion({ question, questionIndex: index, sectionIndex }))
    }
  }

  return (
    <StyledQuestionBox id={`${sectionIndex}${index}`}>
      <StyledQuestionBoxHeader>
        Question {questionNumber}
        <PreparedQuestionCardDropDownMenu questionIndex={index} sectionIndex={sectionIndex} totalQuestionInSection={totalQuestionInSection}/>
      </StyledQuestionBoxHeader>
      <StyledBox onClick={handleEditableQuestion}>
        {children}
      </StyledBox>
    </StyledQuestionBox>
  )
}

export default PreparedQuestionCardWrapper
