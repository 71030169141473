import React from 'react'
import { ButtonType } from '../../../components/GenericButton/styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { grey } from '../../../colors'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'
import { deleteFacultyApi, getFacultyAndStaffApi } from '../../../store/slices/facultyAndStaffSlice/apis'
import { type IUser } from '../../../models/User'
import { notification } from '../../../components/Notifications'
import { actionLoading } from '../../../store/slices/facultyAndStaffSlice/selectors'
import { type ApplyFiltersAndDispatchParams } from '../../../components/CustomTable/types'
import { getStudentsApi } from '../../../store/slices/studentsSlice/apis'
interface DeleteFacultyAndStudentProps {
  selectedRow?: IUser
  handleCloseModal?: () => void
  showErrorModal?: () => void
  setErrorMessage?: (arg: string) => void
  params?: ApplyFiltersAndDispatchParams
  isStudent?: boolean
}

const DeleteFacultyAndStudent: React.FC<DeleteFacultyAndStudentProps> = ({ selectedRow, handleCloseModal, setErrorMessage, showErrorModal, params, isStudent = false }) => {
  const dispatch = useAppDispatch()
  const isDeleteLoading = actionLoading()
  const theme = useTheme()
  const handleDelete = async (): Promise<void> => {
    try {
      await dispatch(deleteFacultyApi({ urlId: selectedRow?.userId })).unwrap()
      handleCloseModal?.()
      notification('User Deleted !', { variant: 'success' })

      if (isStudent) {
        void dispatch(getStudentsApi({ params }))
        return
      }
      void dispatch(getFacultyAndStaffApi({ params }))
    } catch (error: any) {
      if (error && error.response?.status === 422) {
        const errorMessage = error.response?.data?.error
        setErrorMessage?.(errorMessage)
        showErrorModal?.()
      }
    }
  }

  return (
    <>
      <Typography fontSize='14px' lineHeight={theme.spacing(5)} color={grey[850]}>
        You will not be able to recover this user. All references to it will be deleted.
      </Typography>
      <DefaultModalActions
        confirmButtonProps={{
          onClick: handleDelete,
          text: 'Delete',
          loading: isDeleteLoading,
          buttonType: ButtonType.Destructive
        }}
        destructiveButtonProps={{
          onClick: handleCloseModal
        }}
      />
    </>

  )
}

export default DeleteFacultyAndStudent
