import React, { useState } from 'react'
import { List, ListItem, ListItemButton, Popover } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TermHeader from '../../../../components/TermHeader'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import GenericButton from '../../../../components/GenericButton'
import { ButtonType } from '../../../../components/GenericButton/styles'
import DisplayCustomizationModal from '../DisplayCustomizationModal'
import { getAllSeriesData } from '../../../../store/slices/distributionSeriesSlice/selectors'
import theme from '../../../../theme'

export interface ReportControlsProps {
  reportHasError?: boolean
  downloadCSVFile: () => Promise<any>
  downloadPDFReport: () => Promise<any>
}

const ReportsControls: React.FC<ReportControlsProps> = ({ reportHasError, downloadCSVFile, downloadPDFReport }): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const showSeries = getAllSeriesData().length > 1

  const [settingsAnchorEl, setSettingsAnchorEl] = React.useState<null | HTMLElement>(null)
  const isSettingsMenuOpen = Boolean(settingsAnchorEl)
  const openSettingsMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setSettingsAnchorEl(event.currentTarget)
  }
  const closeSettingsMenu = (): void => {
    setSettingsAnchorEl(null)
  }

  const [exportAnchorEl, setExportAnchorEl] = React.useState<null | HTMLElement>(null)
  const isExportMenuOpen = Boolean(exportAnchorEl)
  const openExportMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setExportAnchorEl(event.currentTarget)
  }
  const closeExportMenu = (): void => {
    setExportAnchorEl(null)
  }

  return (
    <React.Fragment>
      {!reportHasError
        ? <React.Fragment>
          <DisplayCustomizationModal isModalOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} />
          <TermHeader showSeries={showSeries}>
            <GenericButton
              aria-describedby="export-menu"
              label='Export'
              variant="outlined"
              fontWeight="bold"
              endIcon={isExportMenuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              buttonType={ButtonType.Secondary}
              onClick={openExportMenu}
              aria-controls={isExportMenuOpen ? 'long-menu' : undefined}
              aria-expanded={isExportMenuOpen ? 'true' : undefined}
              aria-haspopup="true"
              style={{
                flexShrink: 0,
                [theme.breakpoints.down('md')]: {
                  width: '100% !important'
                }
              }}
            />
            <GenericButton
              buttonType={ButtonType.Secondary}
              aria-label="Display settings"
              aria-describedby="settings-menu"
              aria-controls={isSettingsMenuOpen ? 'long-menu' : undefined}
              aria-expanded={isSettingsMenuOpen ? 'true' : undefined}
              icon={<MoreVertIcon />}
              onClick={openSettingsMenu}
              style={{
                flexShrink: 0,
                [theme.breakpoints.down('md')]: {
                  width: '100% !important'
                }
              }}
            />
            <Popover
              onClose={closeExportMenu}
              open={isExportMenuOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              id="export-menu"
              anchorEl={exportAnchorEl}
            >
              <List>
                <ListItem disablePadding >
                  <ListItemButton onClick={() => {
                    closeExportMenu()
                    void downloadPDFReport()
                  }}>
                    Export to PDF
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => {
                    closeExportMenu()
                    void downloadCSVFile()
                  }}>
                    Export to CSV
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>

            <Popover
              onClose={closeSettingsMenu}
              open={isSettingsMenuOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              id="settings-menu"
              anchorEl={settingsAnchorEl}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={(event) => {
                      closeSettingsMenu()
                      setIsModalOpen(true)
                    }}>
                    Display Settings
                  </ListItemButton>
                </ListItem>
                <ListItem disabled disablePadding>
                  <ListItemButton>
                    Print
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>
          </TermHeader>
        </React.Fragment>
        : <TermHeader></TermHeader>
      }
    </React.Fragment>
  )
}

export default ReportsControls
