import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import CourseEvaluationAlert from './CourseEvaluationAlert'
import CourseEvaluationHeader from './CourseEvaluationHeader'
import DistributionPlanDetails from './DistributionPlanDetails'
import Courses from './Courses'
import { getSlotData } from '../../store/slices/distributionPlanSlice/selectors'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../store'
import { getSlotApi } from '../../store/slices/distributionPlanSlice/apis'
import { isEmpty } from 'lodash'
import { resetState } from '../../store/slices/distributionPlanSlice'
import ROUTES from '../../consts/paths'
import { type CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import LoadingPageV2 from '../../pages/LoadingPageV2'
import PAGE_TITLES from '../../consts/titles'

const CoursesAndEnrollment: React.FC = () => {
  const slot = getSlotData()

  const breadcrumbPrefixList: CustomBreadcrumbT[] = [{ title: 'Distribution Series', path: ROUTES.DISTRIBUTION_SERIES }, { title: slot?.title ?? '', path: ROUTES.COURSE_AND_ENROLLMENT }]

  const isLoading = isEmpty(slot)
  const { id } = useParams()

  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        await dispatch(getSlotApi({ urlId: id }))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    void fetchData()
    return () => {
      dispatch(resetState())
    }
  }, [dispatch, id])

  if (isLoading) {
    return <LoadingPageV2 title={PAGE_TITLES.SURVEY_DISTRIBUTION} />
  }

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
        <CourseEvaluationHeader breadcrumb={breadcrumbPrefixList} slot={slot} />
        <CourseEvaluationAlert slot={slot} />
        <DistributionPlanDetails slot={slot} />
        <Courses slot={slot} />
      </Box>
    </>
  )
}

export default CoursesAndEnrollment
