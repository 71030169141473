import React, { useState } from 'react'
import { Menu, MenuItem, type MenuProps, useTheme } from '@mui/material'
import { grey } from '../../colors'
import GenericButton from '../GenericButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ButtonSize, ButtonType } from '../GenericButton/styles'

interface MenuItemProps {
  onClick: () => void
  text: string
  menuStyles?: React.CSSProperties
}

const MenuItemContent: React.FC<MenuItemProps> = ({ onClick, text, menuStyles }) => {
  const theme = useTheme()
  const defaultMenuStyle = {
    fontSize: '14px',
    fontWeight: '500',
    color: grey[950],
    marginBottom: theme.spacing(1)
  }

  return <MenuItem sx={{ ...defaultMenuStyle, ...menuStyles }} onClick={onClick}>{text}</MenuItem>
}

interface DropdownProps {
  menuItems: Array<{ text: string, action: () => void }>
  label: string
  buttonType?: ButtonType
  buttonSize?: ButtonSize
  extraButtonProps?: object | undefined
  menuProps?: MenuProps
  menuStyles?: React.CSSProperties
  menuPaperStyles?: React.CSSProperties
}

const Dropdown: React.FC<DropdownProps> = ({
  menuItems,
  label,
  buttonType = ButtonType.Secondary,
  buttonSize = ButtonSize.Medium,
  extraButtonProps,
  menuProps,
  menuStyles,
  menuPaperStyles
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (action: () => void): void => {
    action()
    handleClose()
  }

  const buttonIcon = open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />

  return (
    <>
      <GenericButton
        onClick={handleClick}
        label={label}
        endIcon={buttonIcon}
        fontWeight='bold'
        buttonType={buttonType}
        buttonSize={buttonSize}
        {...extraButtonProps}/>
      <Menu
        elevation={4}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiMenu-paper': {
            borderRadius: theme.spacing(2),
            width: theme.spacing(60),
            marginTop: theme.spacing(2),
            ...menuPaperStyles
          }
        }}
        {...menuProps}
      >
        {menuItems?.map((item, index) => (
          <MenuItemContent
            key={index}
            onClick={() => { handleMenuItemClick(item.action) }}
            text={item.text}
            menuStyles={menuStyles}
          />
        ))}
      </Menu>
    </>
  )
}

export default Dropdown
