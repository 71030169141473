import { createSlice } from '@reduxjs/toolkit'
import { createStudentsApi, getStudentsApi, getUserRolesApi, createStudentsApiViaCSV, uploadRolesApiViaCSV, updateStudentsApi, createUserRolesApi } from './apis'
import { type IUserListResponse } from '../../../models/User'

export interface IstudentsSliceSlice {
  loading: boolean
  userList: IUserListResponse | null
  userRoles: any | null
  actionLoading: boolean
  userRoleLoading: boolean
}

const initialState: IstudentsSliceSlice = {
  loading: false,
  userList: null,
  userRoles: null,
  actionLoading: false,
  userRoleLoading: false
}

const studentsSliceSlice = createSlice({
  name: 'studentsSlice',
  initialState,
  reducers: {
    resetState: state => {
      state.loading = false
    }

  },
  extraReducers: (builder) => {
    builder
      // GET ALL STAFF AND FACULTY
      .addCase(getStudentsApi.pending, (state, action) => {
        state.loading = true
      })

      .addCase(getStudentsApi.fulfilled, (state, action) => {
        state.loading = false
        state.userList = action.payload
      })

      .addCase(getStudentsApi.rejected, (state, action) => {
        state.loading = false
      })

      // GET User Roles
      .addCase(getUserRolesApi.pending, (state, action) => {
        state.userRoleLoading = true
      })

      .addCase(getUserRolesApi.fulfilled, (state, action) => {
        state.userRoleLoading = false
        state.userRoles = action.payload
      })

      .addCase(getUserRolesApi.rejected, (state, action) => {
        state.userRoleLoading = false
      })
    // Create  User Roles
      .addCase(createUserRolesApi.pending, (state, action) => {
        state.actionLoading = true
      })
      .addCase(createUserRolesApi.fulfilled, (state, action) => {
        state.actionLoading = false
      })
      .addCase(createUserRolesApi.rejected, (state, action) => {
        state.actionLoading = false
      })

      // Create Faculty and staff
      .addCase(createStudentsApi.pending, (state, action) => {
        state.actionLoading = true
      })

      .addCase(createStudentsApi.fulfilled, (state, action) => {
        state.actionLoading = false
        state.userRoles = action.payload
      })

      .addCase(createStudentsApi.rejected, (state, action) => {
        state.actionLoading = false
      })

      // upload  Faculty and staff
      .addCase(createStudentsApiViaCSV.pending, (state, action) => {
        state.actionLoading = true
      })

      .addCase(createStudentsApiViaCSV.fulfilled, (state, action) => {
        state.actionLoading = false
      })

      .addCase(createStudentsApiViaCSV.rejected, (state, action) => {
        state.actionLoading = false
      })

      // upload  Roles
      .addCase(uploadRolesApiViaCSV.pending, (state, action) => {
        state.actionLoading = true
      })

      .addCase(uploadRolesApiViaCSV.fulfilled, (state, action) => {
        state.actionLoading = false
      })

      .addCase(uploadRolesApiViaCSV.rejected, (state, action) => {
        state.actionLoading = false
      })

      // update user
      .addCase(updateStudentsApi.fulfilled, (state, action) => {
        if (state.userList) {
          const updatedIndex = state.userList.data.findIndex(dat => dat.data.userId === action.payload?.userId)
          if (updatedIndex !== -1) {
            state.userList.data[updatedIndex] = {
              ...state.userList.data[updatedIndex],
              data: action?.payload
            }
          }
        }
      })
  }
})
export const { resetState } = studentsSliceSlice.actions
export default studentsSliceSlice.reducer
