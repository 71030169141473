import React, { useEffect, useState } from 'react'
import CustomHeader from '../../components/CustomHeader'
import { useAppDispatch } from '../../store'
import PAGE_TITLES from '../../consts/titles'
import LoadingPage from '../LoadingPage'
import { getInstructorDistributions, getInstructorDistributionsById } from '../../store/slices/InstructorSurveyListSlice/apis'
import { getInstructorDistributionsList, isInstructorDistributionsLoading } from '../../store/slices/InstructorSurveyListSlice/selectors'
import InstructorSurveyListFeature from '../../features/InstructorDashboardFeature'
import useAsyncEffect from 'use-async-effect'
import _ from 'lodash'
import Box from '@mui/material/Box'
import SkipNavigation from '../../components/SkipNavigation'
import Loader from '../../components/Loader'
import { useParams, useSearchParams } from 'react-router-dom'
import { type InstructorDistribution } from '../../models/Distribution'
import { getFacultyAndStaffByIdApi } from '../../store/slices/facultyAndStaffSlice/apis'
import { getLoading } from '../../store/slices/facultyAndStaffSlice/selectors'
import TermHeader from '../../components/TermHeader'
import { getSelectedTerm } from '../../store/slices/TermHeaderSlice/selectors'
import { getTermsListApi } from '../../store/slices/reportSlice/apis'
import useProfile from '../../hooks/useProfile'

const InstructorDashboardPage: React.FC = (): JSX.Element => {
  const [instrctorSurveys, setInstrctorSurveys] = useState<InstructorDistribution[]>([])
  const [instructorName, setInstructorName] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const instructorDistributions = getInstructorDistributionsList() ?? []
  const hasInstructorDistributionsLoading = isInstructorDistributionsLoading()

  const isLoading = hasInstructorDistributionsLoading

  const { id } = useParams()
  const instructorLoading = getLoading()

  const { user } = useProfile()

  const selectedTerm = getSelectedTerm()

  useEffect(() => {
    void dispatch(getTermsListApi({}))
  }, [])

  useAsyncEffect(async () => {
    const params = { termId: selectedTerm?.termId }
    setSearchParams({ termId: selectedTerm?.termId ?? '' })

    if (!selectedTerm) {
      return
    }
    if (id) {
      try {
        await dispatch(getInstructorDistributionsById({ urlId: id, params }))
        const res = await dispatch(getFacultyAndStaffByIdApi({ urlId: id, params })).unwrap()
        if (res) {
          const instructorName = `${res?.firstName} ${res?.lastName}`
          setInstructorName(instructorName)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    } else {
      await dispatch(getInstructorDistributions({ params }))
      setInstructorName(user?.name ?? 'Courses')
    }
  }, [selectedTerm?.termId, id])

  const getSortedDistributions = (surveys: InstructorDistribution[]): InstructorDistribution[] => {
    const currentDate = new Date()

    const releasedSurveys = _.filter(surveys, (survey) => {
      const releaseDate = new Date(survey.releaseTimestamp)
      return currentDate >= releaseDate
    })

    const completedSurveys = _.filter(surveys, (survey) => {
      const releaseDate = new Date(survey.releaseTimestamp)
      const endDate = new Date(survey.endTimestamp)
      return currentDate < releaseDate && currentDate > endDate
    })

    const ongoingSurveys = _.filter(surveys, (survey) => {
      const releaseDate = new Date(survey.releaseTimestamp)
      const endDate = new Date(survey.endTimestamp)
      const startDate = new Date(survey.startTimestamp)
      return currentDate < releaseDate && currentDate <= endDate && currentDate >= startDate
    })

    const upcomingSurveys = _.filter(surveys, (survey) => {
      const releaseDate = new Date(survey.releaseTimestamp)
      const endDate = new Date(survey.endTimestamp)
      const startDate = new Date(survey.startTimestamp)
      return currentDate < releaseDate && currentDate < endDate && currentDate <= startDate
    })

    // Define sorting functions for each category
    const sortByCourse = ['courseName', 'sectionAbbr']

    const sortByReleasedResults = (surveys: InstructorDistribution[]): InstructorDistribution[] => {
      return _.orderBy(surveys, sortByCourse, ['asc', 'asc'])
    }

    const sortByCompletedSurveys = (surveys: InstructorDistribution[]): InstructorDistribution[] => {
      return _.orderBy(surveys, ['releaseTimestamp', ...sortByCourse], ['asc', 'asc'])
    }

    const sortByOngoingSurveys = (surveys: InstructorDistribution[]): InstructorDistribution[] => {
      return _.orderBy(surveys, ['endTimestamp', ...sortByCourse], ['asc', 'asc'])
    }

    const sortByUpcomingSurveys = (surveys: InstructorDistribution[]): InstructorDistribution[] => {
      return _.orderBy(surveys, ['startTimestamp', ...sortByCourse], ['asc', 'asc'])
    }

    const sortedReleasedSurveys = sortByReleasedResults(releasedSurveys)
    const sortedCompletedSurveys = sortByCompletedSurveys(completedSurveys)
    const sortedOngoingSurveys = sortByOngoingSurveys(ongoingSurveys)
    const sortedUpcomingSurveys = sortByUpcomingSurveys(upcomingSurveys)

    return [
      ...sortedReleasedSurveys,
      ...sortedCompletedSurveys,
      ...sortedOngoingSurveys,
      ...sortedUpcomingSurveys
    ]
  }

  useAsyncEffect(async () => {
    if (instructorDistributions?.length) {
      const sortedInstructorDistributions = getSortedDistributions(instructorDistributions)
      setInstrctorSurveys(sortedInstructorDistributions)
      return
    }
    setInstrctorSurveys([])
  }, [instructorDistributions])

  if (isLoading || instructorLoading) {
    return <Loader open={isLoading}></Loader>
  }

  return (
    <Box>
      <SkipNavigation />
      <CustomHeader info={isLoading ? PAGE_TITLES.INSTRUCTOR_DASHBOARD : instructorName} />
      {isLoading && (
        <LoadingPage title={PAGE_TITLES.INSTRUCTOR_DASHBOARD} />
      )}
      <InstructorSurveyListFeature
        list={instrctorSurveys}
        instructorFullName={instructorName}
        controls={<TermHeader showSeries={false} />}
      />
    </Box>
  )
}

export default InstructorDashboardPage
