import Box from '@mui/material/Box'
import type { Theme } from '@mui/material/styles'
import { styled } from '@mui/system'

interface OutletBoxProps {
  theme?: Theme
  isMobile: boolean
}

export const StyledOutletBox = styled(Box)<OutletBoxProps>(({ theme, isMobile }) => ({
  padding: isMobile ? `${theme.spacing(4)} ${theme.spacing(6)}` : `${theme.spacing(8)} ${theme.spacing(13)}`

}))
