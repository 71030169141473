import { type BreadcrumbT } from '../components/CustomBreadcrumbs'
import type ROUTES from '../consts/paths'

export interface CustomBreadcrumbT {
  title: string
  path: string
}

export const prepareBreadCrumbs = (breadCrumbData: BreadcrumbT[], route: ROUTES): CustomBreadcrumbT[] => breadCrumbData?.map((data: BreadcrumbT) => ({
  title: data.title,
  path: `${route}/${data.id}`
}))
