import { createSlice } from '@reduxjs/toolkit'
import { createUserRolesApi, getUserPagePermissionsApi, getUserRolesApi } from './api'
import { notification } from '../../../components/Notifications'

export interface RoleSliceType {
  loading: boolean
  actionLoading: boolean
  pagePermissionError: boolean
  roleError: boolean
  roles: any
  pagePermissions: string[] | null
}

export const initialState: RoleSliceType = {
  loading: false,
  actionLoading: false,
  roles: null,
  roleError: false,
  pagePermissionError: false,
  pagePermissions: null
}

const RoleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRolesApi.pending, (state, action) => {
        state.loading = true
        state.roleError = false
      })
      .addCase(getUserRolesApi.fulfilled, (state, action) => {
        state.loading = false
        state.roles = action.payload
        state.roleError = false
      })
      .addCase(getUserRolesApi.rejected, (state, action) => {
        state.loading = false
        state.roleError = true
      })
      // Page Permissions
      .addCase(getUserPagePermissionsApi.pending, (state, action) => {
        state.loading = true
        state.pagePermissionError = false
      })
      .addCase(getUserPagePermissionsApi.fulfilled, (state, action) => {
        state.loading = false
        state.pagePermissionError = false
        state.pagePermissions = action.payload
      })
      .addCase(getUserPagePermissionsApi.rejected, (state, action) => {
        state.loading = false
        state.pagePermissionError = true
      })

      // Create User Role
      .addCase(createUserRolesApi.pending, (state, action) => {
        state.actionLoading = true
      })
      .addCase(createUserRolesApi.fulfilled, (state, action) => {
        state.actionLoading = false
        notification('Successfully created', { variant: 'success' })
      })
      .addCase(createUserRolesApi.rejected, (state, action) => {
        state.actionLoading = false
      })
  }
})

export default RoleSlice.reducer
