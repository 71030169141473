/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { Box, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { type SubmitHandler, useForm, useFieldArray, FormProvider } from 'react-hook-form'
import FormInput from '../../../components/FormElements/FormInput'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'
import { useAppDispatch } from '../../../store'
import { actionLoading } from '../../../store/slices/studentsSlice/selectors'
import { updateStudentsApi, createStudentsApi, getStudentsApi } from '../../../store/slices/studentsSlice/apis'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { notification } from '../../../components/Notifications'
import { isAxiosError } from 'axios'
interface FormData {
  firstName: string
  lastName: string
  studentId?: string
  email: string
  password?: string
}

interface CreateAndEditStudentProps {
  selectedRow?: any
  handleCloseModal?: () => void
}

const StudentFormSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup.string().test('password', 'Password must be at least 6 characters',
    (password) => {
      if (!password) {
        return true
      }
      if (password.length < 6) {
        return false
      }
      return true
    }
  )
})

const CreateAndEditStudent: React.FC<CreateAndEditStudentProps> = ({ selectedRow, handleCloseModal }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const loading = actionLoading()
  const isEditAction = selectedRow !== undefined
  const { firstName, lastName, studentId, email } = selectedRow ?? {}
  const methods = useForm<FormData>({
    resolver: yupResolver(StudentFormSchema),
    defaultValues: {
      firstName,
      lastName,
      studentId,
      email
    }
  })
  const { handleSubmit, control, formState: { isDirty } } = methods

  const onSubmit: SubmitHandler<FormData> = async (values) => {
    try {
      let userPayload: Partial<FormData> = {}
      if (isEditAction) {
        if (values.firstName !== firstName) {
          userPayload.firstName = values.firstName
        }
        if (values.lastName !== lastName) {
          userPayload.lastName = values.lastName
        }
        if (values.email !== email) {
          userPayload.email = values.email
        }
        if (values.studentId && values.studentId !== studentId) {
          userPayload.studentId = values.studentId
        }
      } else {
        userPayload = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          studentId: values.studentId
        }
      }
      const apiCall = isEditAction
        ? dispatch(updateStudentsApi({ urlId: selectedRow.userId, data: userPayload }))
        : dispatch(createStudentsApi({ data: userPayload }))
      const res = await apiCall.unwrap()

      if (res?.userId) {
        void dispatch(getStudentsApi({ }))
      }
      handleCloseModal?.()
      notification(`Student ${isEditAction ? 'Updated' : 'Added'} Successfully!`, { variant: 'success' })
    } catch (error) {
      console.error('API call failed:', error)
      if (isAxiosError(error)) {
        if (error.response?.data) {
          const errorMessage = (error.response.data as { error: string }).error || 'Unknown error'
          notification(`Operation Failed: ${JSON.stringify(errorMessage)}`, { variant: 'error' })
        } else {
          notification('Operation Failed: An unknown error occurred', { variant: 'error' })
        }
      } else {
        notification('Operation Failed: An unknown error occurred', { variant: 'error' })
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" mt={2} gap={theme.spacing(6)}>
          <FormInput name="firstName" label="First name" placeholder="Enter first name" control={control} required />
          <FormInput name="lastName" label="Last name" placeholder="Enter last name" control={control} required />
          <FormInput name="studentId" label="School ID" placeholder="Enter a school ID" control={control} />
          <FormInput name="email" label="Email" placeholder="Enter an email" control={control} required />
          {
            !isEditAction &&
            <FormInput name="password" type="password" label="Password" placeholder="Enter a password" control={control} />
          }
        </Box>
        <Divider sx={{ my: '24px' }} />
        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditAction ? 'Save' : 'Create',
            type: 'submit',
            loading,
            disabled: isEditAction && !isDirty
          }}
        />
      </form>
    </FormProvider>
  )
}

export default CreateAndEditStudent
