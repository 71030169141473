import { Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { Theme } from '@mui/system'
import { grey, shade } from '../../colors'

interface SkipNavigationProps {
  theme?: Theme
  focused: boolean
}

export const SkipNavigationWrapper = styled(Paper)<SkipNavigationProps>(({ theme, focused }) => ({
  border: `1px solid ${shade[650]}`,
  width: theme.spacing(60),
  position: 'absolute',
  top: theme.spacing(5),
  left: focused ? theme.spacing(5) : `-${theme.spacing(125)}`,
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  zIndex: 23333,
  background: grey[0],
  lineHeight: theme.spacing(4.5),
  borderRadius: theme.spacing(2),
  transition: '.4s'

}))
export const StyledTypography = styled(Typography)({
  width: '100%',
  fontSize: '12px',
  fontWeight: 700,
  color: grey[850],
  marginBottom: 6
})
