/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { format } from 'date-fns'
import CheckIcon from '../../../assets/Featured-icon-outline.svg'
import InfoIcon from '../../../assets/info-icon.svg'
import { Heading, Wrapper, SubHeading } from './styles'
import { SlotStatus, type ISlot } from '../../../models/DistributionPlan'
import Box from '@mui/material/Box'
import { grey, yellow } from '../../../colors'

interface CourseEvaluationAlertProps {
  slot: ISlot
}

interface AlertContent {
  icon: string
  title: string
  subHeading?: string
}

const SlotStatusComponent: React.FC<CourseEvaluationAlertProps> = ({ slot }) => {
  const status = slot?.slotNodeDetails?.slotStatus

  const getFormattedDate = (date: string | null): string | any => {
    return date != null ? format(new Date(date), "MMMM d, yyyy 'at' h:mmaa") : null
  }

  const renderAlert = ({ icon, title, subHeading }: AlertContent): JSX.Element => (
    <Wrapper status={status}>
      <img src={icon} alt={`${icon} Icon`} />
      <Box padding={1}>
        <Heading color={status === SlotStatus.Draft ? yellow[500] : grey[950]}>{title}</Heading>
        {(subHeading != null) && <SubHeading>{subHeading}</SubHeading>}
      </Box>
    </Wrapper>
  )

  switch (status) {
    case SlotStatus.Draft:
      return renderAlert({
        icon: CheckIcon,
        title: 'In order to publish, please add or import both Course sections and Student enrollments.'
      })
    case SlotStatus.Published:
      return renderAlert({
        icon: InfoIcon,
        title: `Survey will go live on ${getFormattedDate(slot?.slotLiveTimestamp)}`,
        subHeading: 'After this date, you will not be able to revert to draft.'
      })
    case SlotStatus.Live:
      return renderAlert({
        icon: InfoIcon,
        title: `Survey will close: ${getFormattedDate(slot?.slotCloseTimestamp)}`
      })
    case SlotStatus.Closed:
      return renderAlert({
        icon: InfoIcon,
        title: `Survey Closed: ${getFormattedDate(slot?.slotCloseTimestamp)}`
      })
    default:
      return <></>
  }
}

const CourseEvaluationAlert: React.FC<CourseEvaluationAlertProps> = ({ slot }) => (
  <SlotStatusComponent slot={slot} />
)

export default CourseEvaluationAlert
