import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch } from 'react-redux'
import GenericButton from '../../../../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../../../../components/GenericButton/styles'
import { deleteQuestionFromSection, moveQuestionDown, moveQuestionUp } from '../../../../../../store/slices/SurveyTemplateSlice'
interface PreparedQuestionCardDropDownMenuProps {
  questionIndex: number
  sectionIndex: number
  totalQuestionInSection: number
}

const PreparedQuestionCardDropDownMenu: React.FC<PreparedQuestionCardDropDownMenuProps> = ({ questionIndex, sectionIndex, totalQuestionInSection }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const dispatch = useDispatch()

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleDeleteClick = (): void => {
    dispatch(deleteQuestionFromSection({ questionIndex, sectionIndex }))
    setAnchorEl(null)
  }

  const moveUp = (): void => {
    dispatch(moveQuestionUp({ sectionIndex, questionIndex }))
  }
  const moveDown = (): void => {
    dispatch(moveQuestionDown({ sectionIndex, questionIndex }))
  }

  const disabledDeleteButton = totalQuestionInSection === 0

  return (
    <>
      <GenericButton
        buttonType={ButtonType.Ghost}
        buttonSize={ButtonSize.Small}
        icon={<MoreVertIcon />}
        onClick={handleClick}
        style={{
          background: 'transparent'
        }} />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        <MenuItem disabled={questionIndex === 0} onClick={moveUp}>Move Up</MenuItem>
        <MenuItem disabled={totalQuestionInSection - 1 === questionIndex} onClick={moveDown}>Move Down</MenuItem>
        <MenuItem disabled={totalQuestionInSection === 1} onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </>
  )
}

export default PreparedQuestionCardDropDownMenu
