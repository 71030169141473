import React, { useState } from 'react'
import { useMediaQuery, useTheme, Divider, Menu, Collapse, List } from '@mui/material'
import { ReportsDropdown, StyledMenuItem, StyledGroupName } from './styles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { getPagePermissions, getRoles } from '../../../store/slices/roleSlice/selectors'
import { PreparedRouteKeys } from '../../../hooks/useAllowedPath'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../consts/paths'
import { StyledListItemButton, StyledListItemText } from '../MobileTabMenu/style'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

interface LinkProps {
  label: string
  link: string
}

interface MenuItemProps {
  group?: string
  links: LinkProps[]
}

const renderMenuList = (
  filteredMenuItems: MenuItemProps[] | undefined,
  currentPath: string,
  handleMenuItemClick: (route: string) => void
): JSX.Element => {
  return (
    <>
      {filteredMenuItems?.map((item: MenuItemProps, index: number) => (
        <>
          {(item?.group != null) &&
            <StyledGroupName tabIndex={1}>
              {item.group}
            </StyledGroupName>}

          {item?.links?.map((link: LinkProps, linkIndex: number) => {
            return (
              <StyledMenuItem
                tabIndex={1}
                active={link?.link === currentPath}
                key={linkIndex}
                onClick={() => { handleMenuItemClick(link.link) }}
              >
                {link.label}
              </StyledMenuItem>
            )
          })}
          {(item?.group != null && filteredMenuItems?.length - 1 !== index) && <Divider />}
        </>
      ))}
    </>
  )
}

const ReportMenuItems: React.FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const currentPath = window.location.pathname
  const isReportsActive = currentPath.includes('/reports')
  const pagePermissions = getPagePermissions()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isOpenForMobile, setIsOpenForMobile] = useState<boolean>(false)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }

  const divisionAndSections = getRoles()

  const handleMenuItemClick = (route: string): void => {
    navigate(route)
    handleCloseMenu()
  }

  const preparedAdminRoutes = divisionAndSections?.filter(division => division?.division)?.map(division => ({
    label: division?.division?.title ?? 'Division',
    link: `${ROUTES.REPORTS}/${division?.division?.divisionId}`
  }))

  const checkMyCoursePath = pagePermissions?.includes(PreparedRouteKeys.InstructorCourseListPage)
  const menuItems: MenuItemProps[] = []

  if (checkMyCoursePath) {
    menuItems.push({
      group: 'Instructor',
      links: [
        {
          label: 'My Courses',
          link: ROUTES.INSTRUCTOR_SURVEYS
        }
      ]
    })
  }

  if (preparedAdminRoutes?.length) {
    menuItems.push({
      group: 'Admin',
      links: preparedAdminRoutes
    })
  }

  return (
    <>
      {
        !isMobile && menuItems?.length > 0 &&
        <>
          <ReportsDropdown
            disabled={!pagePermissions || !menuItems?.length} isMobile={isMobile} isTablet={isTablet} active={isReportsActive}
            onClick={(e) => { handleOpenMenu(e) }}>
            Reports <ExpandMoreRoundedIcon />
          </ReportsDropdown>

          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            disableScrollLock
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            slotProps={{
              paper: {
                style: {
                  padding: `${theme.spacing(2)} 0`
                }
              }
            }}
            MenuListProps={{
              style: {
                padding: 0,
                minWidth: theme.spacing(60),
                borderRadius: theme.spacing(2)
              }
            }}
            anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} elevation={3}>
            {renderMenuList(menuItems, currentPath, handleMenuItemClick)}
          </Menu >
        </>
      }

      {
        isMobile && menuItems?.length > 0 &&
        <>
          <StyledListItemButton onClick={() => { setIsOpenForMobile(!isOpenForMobile) }} active={isOpenForMobile} >
            <StyledListItemText primary="Reports" />
            {isOpenForMobile ? <ExpandLess /> : <ExpandMore />}
          </StyledListItemButton>
          <Collapse in={isOpenForMobile} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderMenuList(menuItems, currentPath, handleMenuItemClick)}
            </List>
          </Collapse>
        </>
      }
    </>
  )
}

export default ReportMenuItems
