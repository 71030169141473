import React, { useState } from 'react'
import { Menu, Box, useTheme, useMediaQuery, Divider, MenuList, Collapse, List, ListItemButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { StyledGroupName, StyledMenuItem, InstitutionDropdown } from './styles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { getPagePermissions } from '../../../store/slices/roleSlice/selectors'
import { useAllowedPath } from '../../../hooks/useAllowedPath'
import { type MenuItemProps, adminMenuList, type LinkProps } from '../../../consts/TopbarMenuItems'
import { StyledListItemButton, StyledListItemText } from '../MobileTabMenu/style'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const renderMenuList = (
  filteredMenuItems: MenuItemProps[] | undefined,
  currentPath: string,
  handleMenuItemClick: (route: string) => void
): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      {filteredMenuItems?.map((item: MenuItemProps, index: number) => (
        <>
          {(item?.group != null) &&
            <StyledGroupName tabIndex={1}>
              {item.group}
            </StyledGroupName>}
          {item?.links?.map((link: LinkProps, linkIndex: number) => {
            return (
              <StyledMenuItem
                role='menuitem'
                tabIndex={1}
                active={link?.link === currentPath}
                key={linkIndex}
                onClick={() => { handleMenuItemClick(link.link) }}
              >
                {link.label}
              </StyledMenuItem>
            )
          })}
          {(item?.group != null && filteredMenuItems?.length - 1 !== index) && <Divider />}
        </>
      ))}
    </>
  )
}

const AdminMenuItems: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isOpenForMobile, setIsOpenForMobile] = useState<boolean>(false)

  const navigate = useNavigate()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const currentPath = window.location.pathname
  const isAdminActive = currentPath.includes('/admin/')
  const pagePermissions = getPagePermissions()

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (route: string): void => {
    navigate(route)
    handleCloseMenu()
  }

  const filteredMenuItems = adminMenuList?.reduce((acc: MenuItemProps[], item) => {
    const links = item?.links?.filter((link) => {
      return link.link && useAllowedPath(link.link)
    })
    if (links.length > 0) {
      acc.push({ ...item, links })
    }
    return acc
  }, [])

  return (
    <>
      {
        !isMobile && filteredMenuItems?.length > 0 &&
        <>
          <InstitutionDropdown disabled={!pagePermissions || !filteredMenuItems?.length} isMobile={isMobile} isTablet={isTablet} active={isAdminActive || Boolean(anchorEl)}
            onClick={handleOpenMenu}>
            Institution <ExpandMoreRoundedIcon />
          </InstitutionDropdown>

          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            disableScrollLock
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            slotProps={{
              paper: {
                style: {
                  padding: `${theme.spacing(2)} 0`
                }
              }
            }}
            MenuListProps={{
              style: {
                padding: 0,
                minWidth: theme.spacing(60),
                borderRadius: theme.spacing(2)
              }
            }}
            anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} elevation={3}>
            {renderMenuList(filteredMenuItems, currentPath, handleMenuItemClick)}
          </Menu >
        </>
      }

      {
        isMobile && filteredMenuItems?.length > 0 &&
        <>
          <StyledListItemButton onClick={() => { setIsOpenForMobile(!isOpenForMobile) }} active={isOpenForMobile} >
            <StyledListItemText primary="Institution" />
            {isOpenForMobile ? <ExpandLess /> : <ExpandMore />}
          </StyledListItemButton>
          <Collapse in={isOpenForMobile} timeout="auto" unmountOnExit>
            <List component="div" disablePadding role='list'>
              {renderMenuList(filteredMenuItems, currentPath, handleMenuItemClick)}
            </List>
          </Collapse>
        </>
      }

    </>
  )
}

export default AdminMenuItems
