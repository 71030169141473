import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import { shade } from '../../../../colors'
import { styled } from '@mui/system'
import theme from '../../../../theme'

export interface SurveyItemResponsePlaceHolderProps {
  key: number
  title: string
  releaseTimeStamp?: string
}

interface ResultsAvailableProps {
  releaseTimeStamp: string
}

const StyledBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  backgroundColor: shade[100],
  borderRadius: `${theme.spacing(2.5)}`
}))

const ResultsAvailable = ({ releaseTimeStamp }: ResultsAvailableProps): JSX.Element => {
  return (
    <>
      <AccessTimeOutlinedIcon />
      <Typography
        variant="body1"
        sx={{
          paddingLeft: `${theme.spacing(2.5)}`
        }}
      >
        Results available <b>{releaseTimeStamp}</b>.
      </Typography>
    </>
  )
}

const CommentsAvailable = (): JSX.Element => {
  return (
    <>
      <CommentOutlinedIcon />
      <Typography
        variant="body1"
        sx={{
          paddingLeft: `${theme.spacing(2.5)}`
        }}
      >
        Comments available on course report.
      </Typography>
    </>
  )
}

const SurveyItemResponsePlaceHolder = ({ key, title, releaseTimeStamp }: SurveyItemResponsePlaceHolderProps): JSX.Element => {
  return (
    <Card key={key}>
      <CardContent>
        <Typography variant="h6">
          {title}
        </Typography>
        <StyledBox>
          {(releaseTimeStamp != null)
            ? (
          <ResultsAvailable releaseTimeStamp={releaseTimeStamp} />
              )
            : (
          <CommentsAvailable />
              )}
        </StyledBox>
      </CardContent>
    </Card>
  )
}

export default SurveyItemResponsePlaceHolder
