/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { shade } from '../../colors'

interface TabItem {
  label: string
  to: string
  disabled?: boolean
}

interface GenericTabsProps {
  tabs: TabItem[]
}

const GenericTabs: React.FC<GenericTabsProps> = ({ tabs }) => {
  const { pathname } = useLocation()

  return (
    <Box sx={{ borderBottom: 1, borderColor: shade[650] }}>
      <Tabs aria-label="Tab navigation" value={pathname}>
        {tabs.map((tab, index) => (
          <Tab
          sx={{
            textTransform: 'capitalize',
            fontWeight: '600',
            fontSize: '14px',
            paddingLeft: 0
          }}
            key={index}
            label={tab.label}
            component={Link}
            to={tab.to}
            value={tab.to}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default GenericTabs
