import { type MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigateToUrl = (): ((event: MouseEvent<HTMLButtonElement>, url: string, openInNewTab?: boolean) => void) => {
  const navigate = useNavigate()

  const navigateToURL = (event: MouseEvent<HTMLButtonElement>, url: string, openInNewTab: boolean = false): void => {
    const shouldOpenInNewTab = openInNewTab || event.ctrlKey
    shouldOpenInNewTab ? window.open(url, '_blank') : navigate(url)
  }

  return navigateToURL
}
