import React from 'react'
import Container from '@mui/material/Container'
import { CircularProgress } from '@mui/material'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'

export interface LoadingPageProps {
  title?: PAGE_TITLES
}

const LoadingPage: React.FC<LoadingPageProps> = ({ title }): JSX.Element => {
  return (
    <Container sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 8
    }}>
      {
        (title != null)
          ? <CustomHeader title={title} info={PAGE_TITLES.LOADING} />
          : <CustomHeader title={PAGE_TITLES.LOADING} />

      }
      <CircularProgress />
    </Container>
  )
}

export default LoadingPage
