import createApiThunk from '../../../ApplicationFactory'
import { type StudentDistribution } from '../../../models/Distribution'
import { type ISurveyTemplate } from '../../../models/SurveyTemplate'

export const getStudentDistributions = createApiThunk<StudentDistribution[]>('studentDistributions', {
  url: '/api/distributions/students/',
  method: 'GET'
})

export const getStudentSurveyById = createApiThunk<ISurveyTemplate>('getStudentSurveyById', {
  url: '/api/surveys/<urlId>',
  method: 'GET'
})

export const submitStudentSurvey = createApiThunk<ISurveyTemplate>('submitSurvey', {
  url: '/api/surveys/<urlId>',
  method: 'patch'
})
