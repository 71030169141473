import React, { type MouseEvent, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { SurveyStatusState, CenteredCardContent, StyledLinearProgressBar } from './styles'
import { useSnackbar } from 'notistack'
import ROUTES from '../../../../consts/paths'
import { formatDate } from '../../../../../src/utils/utils'
import { getPercent } from '../../../../helpers'
import GenericButton from '../../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../../components/GenericButton/styles'
import { grey } from '../../../../colors'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigateToUrl } from '../../../../hooks/useNavigateToUrl'
import { useSearchParams } from 'react-router-dom'

export interface InstructorSurveyListCardProps {
  distributionId: string
  surveyNumber: number
  totalSurveys: number
  courseName: string
  sectionAbbr: string
  endDate: string
  startDate: string
  releaseDate: string
  surveyURL: string
  reportURL: string
  studentCount: number
  completionCount: number
  onClick?: () => Promise<void>
}

const InstructorSurveyListCard: React.FC<InstructorSurveyListCardProps> = ({
  distributionId,
  surveyNumber,
  totalSurveys,
  courseName,
  sectionAbbr,
  endDate,
  startDate,
  releaseDate,
  completionCount,
  studentCount,
  surveyURL,
  reportURL,
  onClick = () => { }
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()
  const navigateToURL = useNavigateToUrl()

  const currDateObj = new Date()
  const endDateStr = formatDate(endDate)
  const startDateStr = formatDate(startDate)
  const releaseDateStr = formatDate(releaseDate)
  const percentValue = getPercent(completionCount, studentCount)
  const [surveyState, setSurveyState] = useState<SurveyStatusState | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [searchParams] = useSearchParams()

  useEffect(() => {
    /**
     * This helps to calculate if the survey state which could be
     * Not Ended (Ongoing), Not Released (Ended but not yet release), or Released
     * @param endDate survey end date object
     * @param releaseDate survey release date object
     * @param currDate the current date object
     */
    const calculateSurveyState = (endDate: Date, releaseDate: Date, currDate: Date): void => {
      if (currDate < endDate) {
        setSurveyState(SurveyStatusState.NotEnded)
      } else if (currDate >= endDate && currDate < releaseDate) {
        setSurveyState(SurveyStatusState.NotReleased)
      } else {
        setSurveyState(SurveyStatusState.Released)
      }
    }
    calculateSurveyState(new Date(endDate), new Date(releaseDate), currDateObj)
  }, [distributionId])

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>): void => {
    if (surveyState === SurveyStatusState.Released && reportURL.length > 0 && reportURL.trim() !== '') {
      navigateToURL(event, `${ROUTES.REPORTS}/${reportURL}?termId=${searchParams.get('termId')}`, true)
    } else if (surveyState === SurveyStatusState.NotEnded) {
      const baseURL = window.location.origin
      const updatedSurveyURL = `${baseURL}/surveys/${distributionId}`
      void navigator.clipboard.writeText(updatedSurveyURL).then(() => {
        onClick()
        enqueueSnackbar('Survey link copied to clipboard', { variant: 'info' })
      }).catch(error => {
        enqueueSnackbar('Failed to copy to clipboard:', error)
      })
    }
  }

  const renderSurveyStatus = (
    surveyState: SurveyStatusState,
    startDateStr: string,
    endDateStr: string,
    startDate: string,
    currDateObj: Date
  ): JSX.Element => {
    try {
      if (surveyState === SurveyStatusState.NotEnded) {
        return (
          <Typography fontWeight='500' fontSize='14px' color={grey[900]} variant="caption">
            {currDateObj < new Date(startDate) ? `Survey Starts: ${startDateStr}` : `Survey Ends: ${endDateStr}`}
          </Typography>
        )
      } else {
        return (
          <Typography fontWeight='500' fontSize='14px' color={grey[900]} variant="caption">Survey Closed</Typography>
        )
      }
    } catch (error) {
      return <></>
    }
  }

  const displayButtonText = surveyState === SurveyStatusState.NotEnded ? 'Copy Student Link' : 'View Results'
  const isSurveyDisabled = surveyState === SurveyStatusState.NotReleased

  return (

    <Card tabIndex={0} aria-label={`survey ${surveyNumber} of ${totalSurveys} ${courseName}`} style={{ marginBottom: '2rem' }}>
      {
        surveyState === null
          ? (
            // Render a circular loader when surveyState is null
            <CenteredCardContent>
              <CircularProgress />
            </CenteredCardContent>
            )
          : (
            // Render the card content when surveyState is not null
            <>
              <CardContent >

                <Box display="flex" justifyContent="space-between" >
                  <Box>
                    <Typography variant="h5" gutterBottom>
                      {courseName}
                    </Typography>
                    <Typography variant="p4">
                      {sectionAbbr}
                    </Typography>
                  </Box>
                  {
                    !isMobile &&
                    <GenericButton
                      buttonType={ButtonType.Secondary}
                      label={displayButtonText}
                      fontWeight='600'
                      buttonSize={ButtonSize.Medium}
                      onClick={handleOnClick}
                      disabled={isSurveyDisabled}
                    />
                  }
                </Box>
              </CardContent>
              <CardContent aria-label={`Survey closed${percentValue}% completed ${completionCount}/${studentCount} Responses`} tabIndex={0}>
                <Box display='flex' justifyContent='space-between'>
                  {renderSurveyStatus(surveyState, startDateStr, endDateStr, startDate, currDateObj)}
                  {
                    surveyState === SurveyStatusState.NotReleased &&
                    <Typography variant="caption">
                      Results Available: <b>{releaseDateStr}</b>
                    </Typography>
                  }
                </Box>
                <Box padding='4px'>
                  <StyledLinearProgressBar value={percentValue} surveyState={surveyState} />
                </Box>
                <Box display='flex' justifyContent='space-between'>
                  <Typography color={grey[950]} fontWeight='500' component="h4" variant="caption">
                    {percentValue}% Complete
                  </Typography>
                  <Typography color={grey[950]} fontWeight='500' component="h4" variant="caption">
                    {completionCount}/{studentCount} Responses
                  </Typography>
                </Box>

                {
                  isMobile &&
                  <GenericButton
                    buttonType={ButtonType.Secondary}
                    label={displayButtonText}
                    fontWeight='600'
                    buttonSize={ButtonSize.Medium}
                    onClick={handleOnClick}
                    disabled={isSurveyDisabled}
                    fullWidth
                    style={{ marginTop: theme.spacing(5) }}
                  />
                }
              </CardContent>
            </>
            )}
    </Card>
  )
}

export default InstructorSurveyListCard
