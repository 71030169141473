/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useAppDispatch } from '../../../../store'
import { createSectionApi, getSlotSectionApi, updateSectionApi } from '../../../../store/slices/distributionPlanSlice/apis'
import GenericDateTimePicker from '../../../../components/GenericDateTimePicker'
import Loader from '../../../../components/Loader'
import { formatDateWithTime } from '../../../../helpers/formatDateWithTime'
import FormAutoComplete from '../../../../components/FormElements/FormAutoComplete'
import FormInput from '../../../../components/FormElements/FormInput'
import { Alert, AlertTitle } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { isAfter } from 'date-fns'
import { SlotStatus, type ISlot } from '../../../../models/DistributionPlan'
import { type ISection, type ISectionRow } from '../../../../models/TermsSection'
import { getTermsSectionsList } from '../../../../store/slices/termsSectionsSlice/selectors'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import { red } from '../../../../colors'
import MultipleInstructorsSelect from '../../../../components/MultipleInstructorsSelect'

interface FormSubmissionPayload {
  urlId?: string
  urlIds?: string[]
  data: Record<string, any>
}

interface CreateAndEditCoursesProps {
  selectedRow?: ISection
  slot: ISlot
  handleCloseModal?: () => void
}

export const CreateAndEditCourses: React.FC<CreateAndEditCoursesProps> = ({
  selectedRow,
  slot,
  handleCloseModal = () => { }
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const termSections = getTermsSectionsList()
  const isEditAction = !!selectedRow
  const { sectionId, distributionDetails, title, enrollment, instructors } = selectedRow ?? {}
  const { endTimestamp, startTimestamp, earlyAccessReleaseTimestamp1, earlyAccessReleaseTimestamp2 } = distributionDetails ?? {}
  const { id } = useParams()

  const coursesAndEnrollmentFormSchema = yup.object().shape({
    sectionId: yup.string().required('Section is required'),
    startTimestamp: yup.date()
      .required('Default Start Date is required')
      .when('slot', {
        is: () => slot?.slotNodeDetails?.slotStatus !== SlotStatus.Draft && isEditAction,
        then: schema => schema.min(new Date(Date.now() + 24 * 60 * 60 * 1000), 'Start date must be > 24 hrs from now')
      })
      .max(slot.releaseTimestamp, 'Start date must be before Release Date'),
    endTimestamp: yup.date()
      .required('Default End Date is required')
      .min(yup.ref('startTimestamp'), 'End Date must be after Start Date')
      .max(slot.releaseTimestamp, 'End Date must be on or before Release Date')
      .test('is-24-hours-after-start', 'End date must be at least 1 day after the Start Date', function (value) {
        const { startTimestamp } = this.parent
        return value && startTimestamp && new Date(value).getTime() >= new Date(startTimestamp).getTime() + 24 * 60 * 60 * 1000
      }),
    earlyAccessReleaseTimestamp1: yup.date().nullable().min(yup.ref('endTimestamp'), 'Admin  Release Date must be after end Date').max(slot.releaseTimestamp, 'Admin  Release Date must be on or before Release Date'),
    earlyAccessReleaseTimestamp2: yup.date().nullable().min(yup.ref('endTimestamp'), 'Instructor Release Date must be after end Date').max(slot.releaseTimestamp, 'Instructor  Release Date must be on or before Release Date')
  })

  const selectedInstructors = instructors?.map((user: { id: string, title: string }) => ({
    user_id: user.id,
    name: user.title
  }))

  const methods = useForm<any>({
    resolver: yupResolver(coursesAndEnrollmentFormSchema),
    defaultValues: {
      sectionId: sectionId ?? null,
      enrollment,
      title,
      startTimestamp: startTimestamp ?? null,
      endTimestamp: endTimestamp ?? null,
      instructors: selectedInstructors,
      earlyAccessReleaseTimestamp1: earlyAccessReleaseTimestamp1 ?? null,
      earlyAccessReleaseTimestamp2: earlyAccessReleaseTimestamp2 ?? null
    }
  })

  const { handleSubmit, control, watch, formState: { isDirty, isSubmitting } } = methods

  const onSubmit: SubmitHandler<any> = async (values) => {
    const payload: FormSubmissionPayload = {
      urlId: id,
      data: {
        sectionId: values.sectionId,
        startTimestamp: formatDateWithTime(values?.startTimestamp),
        endTimestamp: formatDateWithTime(values.endTimestamp),
        earlyReleaseTimestamp1: formatDateWithTime(values.earlyAccessReleaseTimestamp1),
        earlyReleaseTimestamp2: formatDateWithTime(values.earlyAccessReleaseTimestamp2)
      }
    }

    try {
      if (id) {
        setLoading(true)
        if (isEditAction) {
          payload.urlIds = [id, selectedRow?.sectionId]
          await dispatch(updateSectionApi(payload))
        } else {
          await dispatch(createSectionApi(payload))
        }
        await dispatch(getSlotSectionApi({ urlId: id, params: { pageSize: 15 } }))
      }
    } catch (error) {
      console.error('API call failed:', error)
    } finally {
      handleCloseModal()
      setLoading(false)
    }
  }

  const isStartTimeDisabled = isEditAction && startTimestamp ? !isAfter(new Date(startTimestamp), new Date(Date.now() + 24 * 60 * 60 * 1000)) : undefined
  const isEndTimeDisabled = isEditAction && endTimestamp ? !isAfter(new Date(endTimestamp), new Date(Date.now() + 24 * 60 * 60 * 1000)) : undefined

  return (
    <FormProvider {...methods}>
      <Loader open={loading} />
      <Box mb={6} mt={5}>
        {isEditAction && (
          <Alert icon={<WarningAmberIcon sx={{ color: red[900], fontSize: '24px' }} />} variant='outlined' severity='warning'>
            <AlertTitle color={red[900]} sx={{ fontWeight: 600, fontSize: '14px' }}>
              Course section details are managed in your Terms. To manage them go to the Terms page.
            </AlertTitle>
          </Alert>
        )}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap={theme.spacing(6)}>
          <FormAutoComplete
            label="Section ID"
            name="sectionId"
            options={termSections?.data ?? []}
            required
            placeholder='Select Section ID'
            defaultValue={termSections?.data.find((section: ISectionRow) => section?.data?.sectionId === sectionId)}
            getOptionLabel={(option: ISectionRow) => option?.data?.abbreviation}
            getOptionValue={(option: ISectionRow) => option?.data?.sectionId}
            readOnly={isEditAction}
          />
          {isEditAction && (
            <>
              <FormInput name="title" label="Section Title" control={control} required disabled={isEditAction} />
              <MultipleInstructorsSelect
                name="instructors"
                disabled
              />
              <FormInput name="enrollment" label="Enrollment" control={control} required disabled={isEditAction} />
            </>
          )}
          <GenericDateTimePicker name="startTimestamp" label="Start Date" required disabled={isStartTimeDisabled} minDate={new Date()} />
          <GenericDateTimePicker name="endTimestamp" label="End Date" required disabled={isEndTimeDisabled} minDate={watch('startTimestamp') || new Date()} />
          <GenericDateTimePicker name="earlyAccessReleaseTimestamp2" label="Instructor Release Date" minDate={watch('endTimestamp') || new Date()} />
          <GenericDateTimePicker name="earlyAccessReleaseTimestamp1" label="Admin Release Date" minDate={watch('endTimestamp') || new Date()} />
        </Box>

        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditAction ? 'Save' : 'Create',
            loading,
            type: 'submit',
            disabled: isSubmitting || !isDirty
          }}
        />
      </form>
    </FormProvider>
  )
}
