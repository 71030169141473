import React from 'react'
import { Navigate, type RoutesProps } from 'react-router-dom'
import { useAllowedPath } from '../../hooks/useAllowedPath'
import { getPagePermissions } from '../../store/slices/roleSlice/selectors'
interface PrivateRouteProps extends RoutesProps {
  element: React.ReactNode
}

export const RoleBaseRoute: React.FC<PrivateRouteProps> = ({ element }): any => {
  const pagePermissions = getPagePermissions()

  const isAllowed = useAllowedPath()
  if (!pagePermissions) {
    return
  }

  if (isAllowed) {
    return <>{element}</>
  }
  return <Navigate to="*" />
}
