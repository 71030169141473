
import { format, isValid } from 'date-fns'

export const formatDateWithTime = (date: Date | string): string => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date

  if (!isValid(parsedDate)) {
    return ''
  }

  return parsedDate.toISOString().replace('.000Z', '').replace('Z', '')
}
