import React from 'react'
import type PAGE_TITLES from '../../consts/titles'
import NoPermissionsFeature from '../../features/NoPermissionsFeature'

export interface PageProps {
  title?: PAGE_TITLES
}

const Page: React.FC<PageProps> = ({ title }): JSX.Element => {
  return (
    <NoPermissionsFeature title={title} />
  )
}

export default Page
