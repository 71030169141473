export const blue = {
  100: '#DEE7FD',
  200: '#AEC4F9',
  300: '#7EA3F6',
  400: '#4B7FF6',
  500: '#0F49D2',
  600: '#0A328F',
  700: '#082A77',
  800: '#07225F',
  900: '#041233',
  // THESE COLORS ARE ADDED FOR THE NEW DESIGN LAYOUT DONE BY UI TEAM,
  // THESE COLORS WERE NOT IN OUR PALLETE SO WE HAD TO ADD THESE UP HERE
  // MAY BE FOR COLOR PALLETE WE WILL NEED TO DESIGN AND REDO OUR PALLETE
  1100: '#121926'
}

export const grey = {
  0: '#FFFFFF',
  25: '#FFFBFA',
  50: '#344054',
  100: '#F0F2F5',
  150: '#697586',
  200: '#D5D9DC',
  250: '#121926',
  300: '#BAC0C5',
  350: '#CDD5DF',
  400: '#9EA6AE',
  450: '#9AA4B2',
  500: '#7E8E9A',
  550: '#CDD5DF',
  600: '#657481',
  650: '#475467',
  700: '#4D5B66',
  750: '#FCFCFD',
  800: '#364049',
  850: '#4B5565',
  900: '#21262C',
  // THESE COLORS ARE ADDED FOR THE NEW DESIGN LAYOUT DONE BY UI TEAM,
  // THESE COLORS WERE NOT IN OUR PALLETE SO WE HAD TO ADD THESE UP HERE
  // MAY BE FOR COLOR PALLETE WE WILL NEED TO DESIGN AND REDO OUR PALLETE
  950: '#364152',
  1000: '#7f8f9a'
}

export const red = {
  0: '#FDA29B',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#D32F2F',
  600: '#D92D20',
  700: '#B42318',
  800: '#B93815',
  900: '#93370D'
}

// added missing colors for new UI
export const green = {
  0: '#ECFDF3',
  25: '#75E0A7',
  50: '#ABEFC6',
  100: '#067647',
  150: '#F6FEF9'
}

export const shade = {
  1: '#F8FAFF',
  50: '#FFFCF5',
  100: '#F6FAFD',
  150: '#EEF2F6',
  200: '#EEF4FC',
  300: '#E5EFFA',
  400: '#DCEAF9',
  500: '#D4E4F7',
  550: '#2029391A',
  600: '#CBDFF6',
  650: '#E3E8EF',
  700: '#C2DAF4',
  750: '#F8FAFC',
  800: '#BAD4F3',
  850: '#B9CCF9',
  900: '#B1CFF1',
  // THESE COLORS ARE ADDED FOR THE NEW DESIGN LAYOUT DONE BY UI TEAM,
  // THESE COLORS WERE NOT IN OUR PALLETE SO WE HAD TO ADD THESE UP HERE
  // MAY BE FOR COLOR PALLETE WE WILL NEED TO DESIGN AND REDO OUR PALLETE
  950: '#EEF3FF',
  1000: '#FEF6EE'
}

export const yellow = {
  100: '#FFD6AE',
  400: '#FEC84B',
  500: '#B54708',
  600: '#DC6803'
}
