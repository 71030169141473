import React from 'react'
import { Card, Chip, CardContent, Typography, useTheme } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import type { ApexOptions } from 'apexcharts'
import { roundedPercentages } from '../../../../helpers'

export interface BarChartProps {
  title: string
  keys: string[]
  key: number
  values: number[]
  showPercents?: boolean
  average?: number | null
}

const BarChart: React.FC<BarChartProps> = ({
  title, keys, values, key, showPercents = false, average = null
}): JSX.Element => {
  const theme = useTheme()

  let percentages: number[] = []
  const percentMap: Record<number, number> = {}

  if (showPercents) {
    percentages = roundedPercentages(values)

    values.forEach((value, i) => {
      percentMap[value] = percentages[i]
    })
  }

  const series = [{
    name: 'Answered',
    data: values
  }]

  const seriesLength = values.length
  const optimalColumnWidthPercent = 20 + (60 / (1 + 30 * Math.exp(-seriesLength / 3)))

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      },
      parentHeightOffset: 0,
      offsetY: 10,
      animations: {
        enabled: false
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    tooltip: {
      enabled: false
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    fill: {
      opacity: 1,
      colors: [theme.palette.primary.dark, 'rgba(0,0,0,0)']
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: `${optimalColumnWidthPercent}%`,
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -10,
      style: {
        fontSize: '0.875rem',
        colors: [theme.palette.text.secondary]
      }
    },

    xaxis: {
      categories: keys,
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      offsetY: 25,
      labels: {
        trim: true,
        hideOverlappingLabels: false,
        rotate: 0,
        offsetY: -15,
        style: {
          fontSize: '0.875rem',
          colors: [theme.palette.text.secondary]
        }
      },
      crosshairs: {
        show: false,
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0,
            opacityTo: 0
          }
        }
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 4,
      max: Math.max(...values) * 1.1,
      show: false,
      crosshairs: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    }
  }

  const optionsPercent: ApexOptions = {
    ...options,
    dataLabels: {
      ...options.dataLabels,
      formatter: (value: number) => {
        const percent = isNaN(percentMap[value]) ? 0 : percentMap[value]
        return `${value.toString()} (${percent}%)`
      }
    }
  }

  return (
    <Card key={key}>
      <CardContent>
        <Typography variant="h6">
          {title}
        </Typography>
        { average !== null && average !== undefined && <Chip label={`Average Rating: ${average}`} sx={{ mt: 2 }} /> }
        {showPercents &&
          <ReactApexChart key={key} options={optionsPercent} series={series} type="bar" height={350} />}
        {!showPercents &&
          <ReactApexChart key={key} options={options} series={series} type="bar" height={350} />}
      </CardContent>
    </Card>
  )
}

export default BarChart
