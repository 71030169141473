/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAppDispatch } from '../../../../store'
import FormInput from '../../../../components/FormElements/FormInput'
import GenericDateTimePicker from '../../../../components/GenericDateTimePicker'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import ValidationAlert from '../../../../components/ValidationAlert'
import MultipleInstructorsSelect from '../../../../components/MultipleInstructorsSelect'
import { createTermsSectionsApi, getTermsSectionsApi, updateTermsSectionsApi } from '../../../../store/slices/termsSectionsSlice/api'
import { createUserRolesApi, getAllRolesApi } from '../../../../store/slices/roleSlice/api'
import { getTerm } from '../../../../store/slices/termsSlice/selectors'
import { actionLoading } from '../../../../store/slices/termsSectionsSlice/selectors'
import { blue, grey } from '../../../../colors'
import ROUTES from '../../../../consts/paths'
import { ROLES } from '../../../../consts/roles'
import { type IUser } from '../../../../models/User'
import { type ISection } from '../../../../models/TermsSection'
import useAsyncEffect from 'use-async-effect'
import _ from 'lodash'
import { Link } from 'react-router-dom'

interface FormType {
  courseId: string
  abbreviation: string
  title: string
  startTimestamp: Date
  endTimestamp: Date
  instructors?: IUser[]
}

interface CreateAndEditTermsSectionProps {
  selectedRow?: ISection | Record<string, any>
  handleCloseModal?: () => void
  params: Record<string, any>
}

export const CreateAndEditTermsSection: React.FC<CreateAndEditTermsSectionProps> = ({
  selectedRow,
  handleCloseModal = () => {},
  params
}) => {
  const [errors, setErrors] = useState<unknown>({})
  const [primaryInstructorId, setPrimaryInstructorId] = useState<string | null>(null)

  const dispatch = useAppDispatch()
  const termsDetails = getTerm()
  const enrollmentActionLoading = actionLoading()

  const isEditAction = Boolean(selectedRow)
  const { abbreviation, title, course, startTimestamp, endTimestamp, instructors } = selectedRow ?? {}
  const { abbreviation: courseAbbreviation } = course ?? {}

  const termFormSchema = yup.object().shape({
    courseId: yup.string().required('Course Id is required'),
    abbreviation: yup.string().required('Section Id is required'),
    title: yup.string().required('Title is required'),
    startTimestamp: yup.date()
      .required('Start Date is required')
      .max(yup.ref('endTimestamp'), 'Start Date must be before End Date'),
    endTimestamp: yup.date()
      .required('End Date is required')
      .min(yup.ref('startTimestamp'), 'End Date must be after Start Date')
      .test('is-before-term-end', 'End Date must be before term end date', function (value) {
        const { endTimestamp } = termsDetails ?? {}
        return !endTimestamp || value <= new Date(endTimestamp)
      })
  })

  const selectedInstructors = instructors.map((user: { id: string, title: string }) => ({
    user_id: user.id,
    name: user.title
  }))

  const methods = useForm<FormType>({
    resolver: yupResolver(termFormSchema),
    defaultValues: {
      courseId: courseAbbreviation,
      abbreviation,
      title,
      startTimestamp,
      endTimestamp,
      instructors: selectedInstructors
    }
  })

  const { handleSubmit, control, formState: { dirtyFields } } = methods

  const assignInstructors = async (sectionId: string, instructors: IUser[]): Promise<void> => {
    if (sectionId && instructors.length) {
      await Promise.all(instructors.map(async (instructor) => {
        const payload = {
          objectId: sectionId,
          email: instructor.email,
          roleId: primaryInstructorId,
          termId: termsDetails?.termId
        }
        return await dispatch(createUserRolesApi({ data: payload })).unwrap()
      }))
    }
  }

  const onSubmit = async (data: FormType): Promise<void> => {
    try {
      const changedFields = _.pickBy(data, (_, key) => dirtyFields[key as keyof typeof dirtyFields])
      const apiCall = isEditAction
        ? dispatch(updateTermsSectionsApi({ urlId: selectedRow?.sectionId, data: changedFields })).unwrap()
        : dispatch(createTermsSectionsApi({ data: { ...data, termId: params?.termId }, params: { termId: params?.termId } })).unwrap()

      const res = await apiCall

      if (!selectedRow?.instructors.length) {
        await assignInstructors(res?.sectionId, data?.instructors ?? [])
      }

      handleCloseModal()
      await dispatch(getTermsSectionsApi({ params }))
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }

  useAsyncEffect(async () => {
    const roles = await dispatch(getAllRolesApi({ params: { isInstructor: true } })).unwrap()
    const primaryRole = roles.find((role: Record<string, any>) => role?.roleName === ROLES.PRIMARY_INSTRUCTOR)
    setPrimaryInstructorId(primaryRole?.roleId ?? null)
  }, [])

  return (
    <FormProvider {...methods}>
      <ValidationAlert errors={errors} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap={2}>
          <FormInput name="courseId" label="Course ID" disabled={isEditAction} control={control} required />
          <FormInput name="abbreviation" label="Section ID" control={control} required />

          {!isEditAction && (
            <Typography fontSize="14px" mt={1} color={grey[850]}>
              Best practice: Spell out the whole Course Section ID
            </Typography>
          )}

          <FormInput name="title" label="Title" control={control} required />
          <MultipleInstructorsSelect
            name="instructors"
            disabled={Boolean(selectedRow?.instructors.length)}
          />
          <Typography fontSize="14px" color={grey[850]}>
            If the instructor does not exist, create a user profile &nbsp;
            <Link to={ROUTES.FACULTY_AND_STAFF} target="_blank" style={{ color: blue[500] }}>
              here.
            </Link>
          </Typography>
          <GenericDateTimePicker name="startTimestamp" label="Start Date" required />
          <GenericDateTimePicker name="endTimestamp" label="End Date" required />
        </Box>
        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditAction ? 'Save' : 'Create',
            loading: enrollmentActionLoading,
            type: 'submit'
          }}
        />
      </form>
    </FormProvider>
  )
}
