import React, { useEffect, useState } from 'react'
import { StyledBoxWrapper, StyledOptionsWrapper, StyledStyledTextField } from './styles'

interface MultipleChoiceOptionsProps {
  option: string
  index: number
  handleUpdate: (index: number, value: string) => void
}

const MultipleChoiceOptions: React.FC<MultipleChoiceOptionsProps> = ({ option, index, handleUpdate }) => {
  const [value, setValue] = useState('')

  const handleChange = (value: string, index: number): void => {
    setValue(value)
    handleUpdate(index, value)
  }

  useEffect(() => {
    setValue(option)
  }, [option, index])

  return (
    <>
      <StyledOptionsWrapper key={index} >
        <StyledBoxWrapper />
        <StyledStyledTextField
          placeholder='Type Option'
          value={value}
          onChange={(e) => { handleChange(e.target.value, index) }}
        />
      </StyledOptionsWrapper>
    </>
  )
}

export default MultipleChoiceOptions
