import React, { lazy, Suspense } from 'react'
import Box from '@mui/material/Box'
import LoadingPage from '../../../pages/LoadingPage'
import CreateEditDivision from '../CreateDivision'
import type { DataObjectList } from '../../../models/DataObject'
import { type Division } from '../../../models/Division'
import { type GridSortItem } from '@mui/x-data-grid'
import DeleteDivisionUnit from '../DeleteDivision'
import ArchiveDivision from '../ArchiveDivision'

interface ReportHierarchyFeatureProps {
  list: DataObjectList<Division>
  handleRowsPerPageChange: (arg: number) => void
  handlePageChange: (arg: number) => void
  onSearch: (arg: string) => void
  onSortModelChange: (sortModel: GridSortItem) => void
  loading: boolean
}

const AppTable = lazy(async () => await import('../../../components/CustomTable'))
const DivisionTable: React.FC<ReportHierarchyFeatureProps> = ({ list, handleRowsPerPageChange, handlePageChange, onSearch, onSortModelChange, loading }): JSX.Element => {
  const rows = list?.data
  const columns = Array.isArray(list?.keys)
    ? list.keys.filter((key) => key.field !== 'children_count')
    : []

  const headerName: string = list?.header?.headerName
  const extraColumnProps = {
    flex: 1
  }
  const extraModalProps = {
    width: 'sm'
  }

  return (
    <Box width={'100%'}>
      <Suspense fallback={<LoadingPage />}>

        {columns !== undefined && rows !== undefined &&
          <AppTable
          loading={loading}
            topbarTitle={headerName}
            createAction={<CreateEditDivision />}
            rows={rows}
            list={list}
            columns={columns}
            editAction={<CreateEditDivision />}
            deleteAction={<DeleteDivisionUnit/>}
            archiveAction={<ArchiveDivision/>}
            featureName="Unit"
            extraColumnProps={extraColumnProps}
            extraModalProps={extraModalProps}
            createActionTitle='Create New Division'
            onSearch={onSearch}
            onSortModelChange={onSortModelChange}
            handlePageChange={(page: number) => { handlePageChange(page) }}
            handleRowsPerPageChange={(pageSize: number) => { handleRowsPerPageChange(pageSize) }}
          />
        }
      </Suspense>
    </Box>
  )
}

export default DivisionTable
