/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type SurveyItemQuestionType } from '../../../models/SurveyTemplate'

import { type Question } from '../../../features/SurveyTempleteFeature/types'

import { createSurveyTemplate, editSurveyTemplate, getSurveyByIdApi } from './apis'

export interface IEditableQuestion {
  questionIndex: number
  sectionIndex: number
  question: Question
}

export interface ISurveySection {
  name: string
  title?: string | null
  descr?: string | null
  surveySectionId?: string
  surveyItems?: Question[]
}

export interface ISelectedQuestion {
  sectionIndex: number
  questionType: string | null
}

const initialSection: ISurveySection = {
  name: 'Untitled section'
}

interface ISurveyTemplate {
  selectedQuestion: ISelectedQuestion | null
  title: string
  surveySections: ISurveySection[]
  loading: boolean
  actionLoading: boolean
  editableQuestion: IEditableQuestion | null | undefined
  editableSurvey: ISurveySection | null
}

const initialState: ISurveyTemplate = {
  selectedQuestion: null,
  title: '',
  surveySections: [initialSection],
  loading: false,
  actionLoading: false,
  editableQuestion: null,
  editableSurvey: null
}

const surveyTemplateSlice = createSlice({
  name: 'surveyTemplate',
  initialState,
  reducers: {
    resetState (state) {
      Object.assign(state, initialState)
    },
    setTitle (state, action) {
      state.title = action.payload
    },
    addSection (state) {
      state.surveySections.push({ name: 'Untitled section' })
    },
    removeSection (state, action) {
      state.surveySections.splice(action.payload.sectionNumber, 1)
    },
    setSectionName (state, action) {
      const sectionNumber = action.payload.sectionNumber
      const name = action.payload.value
      state.surveySections[sectionNumber].name = name
    },
    setSectionTitle (state, action) {
      const sectionNumber = action.payload.sectionNumber
      const title = action.payload.value
      state.surveySections[sectionNumber].title = title
    },
    setSectionDescription (state, action) {
      const sectionNumber = action.payload.sectionNumber
      const description = action.payload.value
      state.surveySections[sectionNumber].descr = description
    },
    moveSectionUp: (state, action) => {
      const { sectionNumber } = action.payload
      if (sectionNumber > 0) {
        const temp = state.surveySections[sectionNumber]
        state.surveySections[sectionNumber] = state.surveySections[sectionNumber - 1]
        state.surveySections[sectionNumber - 1] = temp
      }
    },
    moveSectionDown: (state, action) => {
      const { sectionNumber } = action.payload
      if (sectionNumber < state.surveySections.length - 1) {
        const temp = state.surveySections[sectionNumber]
        state.surveySections[sectionNumber] = state.surveySections[sectionNumber + 1]
        state.surveySections[sectionNumber + 1] = temp
      }
    },

    moveQuestionUp (state, action: PayloadAction<{ sectionIndex: number, questionIndex: number }>) {
      const { sectionIndex, questionIndex } = action.payload
      const surveySections = state.surveySections ?? []

      if (sectionIndex < surveySections.length) {
        const surveyItems = surveySections[sectionIndex].surveyItems ?? []
        if (questionIndex > 0 && questionIndex < surveyItems.length) {
          const temp = surveyItems[questionIndex]
          surveyItems[questionIndex] = surveyItems[questionIndex - 1]
          surveyItems[questionIndex - 1] = temp
        }
      }
    },

    moveQuestionDown (state, action: PayloadAction<{ sectionIndex: number, questionIndex: number }>) {
      const { sectionIndex, questionIndex } = action.payload
      const surveySections = state.surveySections ?? []

      if (sectionIndex < surveySections.length) {
        const surveyItems = surveySections[sectionIndex].surveyItems ?? []
        if (questionIndex < surveyItems.length - 1) {
          const temp = surveyItems[questionIndex]
          surveyItems[questionIndex] = surveyItems[questionIndex + 1]
          surveyItems[questionIndex + 1] = temp
        }
      }
    },
    deleteQuestionFromSection (state, action: PayloadAction<{ sectionIndex: number, questionIndex: number }>) {
      const { sectionIndex, questionIndex } = action.payload
      const surveySections = state.surveySections ?? []

      if (sectionIndex < surveySections.length) {
        const surveyItems = surveySections[sectionIndex].surveyItems ?? []
        if (questionIndex < surveyItems.length) {
          surveyItems.splice(questionIndex, 1)
        }
      }
    },

    addSurveyQestionInSection (state, action) {
      const question = action.payload
      const sectionIndex = state.selectedQuestion?.sectionIndex
      if (sectionIndex !== null && sectionIndex !== undefined) {
        state.surveySections[sectionIndex].surveyItems = [
          ...(state.surveySections[sectionIndex].surveyItems ?? []),
          question
        ]
      }
    },

    setSelectedQuestion (state, action) {
      state.selectedQuestion = action.payload
    },

    setEditableQuestion (state, action: PayloadAction<IEditableQuestion | null>) {
      if (action.payload?.question) {
        state.editableQuestion = {
          sectionIndex: action.payload?.sectionIndex,
          questionIndex: action.payload?.questionIndex,
          question: action.payload.question
        }
        return
      }
      state.editableQuestion = null
    },
    updateQuestionInSection (state, action: PayloadAction<{ sectionIndex: number, questionIndex: number, updatedQuestion: Question }>) {
      const { sectionIndex, questionIndex, updatedQuestion } = action.payload
      const surveySections = state.surveySections ?? []
      const surveyItems = surveySections[sectionIndex].surveyItems ?? []
      surveyItems[questionIndex] = updatedQuestion
    },

    setEditableSurvey (state, action) {
      state.editableSurvey = action.payload
      state.title = action.payload?.title
      state.surveySections = action.payload?.surveySections
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getSurveyByIdApi.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getSurveyByIdApi.rejected, (state, action) => {
        state.loading = false
      })
    // create survey
      .addCase(createSurveyTemplate.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(createSurveyTemplate.fulfilled, (state, action) => {
        state.actionLoading = false
      })
      .addCase(createSurveyTemplate.rejected, (state, action) => {
        state.actionLoading = false
      })

    // update survey
      .addCase(editSurveyTemplate.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(editSurveyTemplate.fulfilled, (state, action) => {
        state.actionLoading = false
      })
      .addCase(editSurveyTemplate.rejected, (state, action) => {
        state.actionLoading = false
      })
  }
})

export const {
  resetState,
  setTitle,
  addSection,
  removeSection,
  setSectionName,
  setSectionTitle,
  setSectionDescription,
  moveSectionUp,
  moveSectionDown,
  addSurveyQestionInSection,
  setSelectedQuestion,
  moveQuestionUp,
  moveQuestionDown,
  deleteQuestionFromSection,
  setEditableQuestion,
  updateQuestionInSection,
  setEditableSurvey
} = surveyTemplateSlice.actions

export default surveyTemplateSlice.reducer
