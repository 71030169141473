import { type ICreatedBy, type DataObjectList, type IOwnedBy, type IModifiedBy, type DataObject } from './DataObject'
import { type ITerm } from './Term'

export enum SlotStatus {
  Draft = 'Draft',
  Published = 'Published',
  Live = 'Live',
  Closed = 'Closed'
}
export interface ISlotNodeDetails {
  courseCount: number
  slotStatus: SlotStatus
  studentCount: number
}
export interface ISurveyTemplate {
  title: string
  templateId: string
  templateRevision: number
}
export interface ISlot extends ICreatedBy, IOwnedBy, IModifiedBy {
  isArchived: boolean
  releaseTimestamp: Date
  slotStartTimestamp: Date
  slotEndTimestamp: Date
  slotCloseTimestamp: string
  slotId: string
  slotLiveTimestamp: string | null
  slotNodeDetails: ISlotNodeDetails
  slotTypeId: string | null
  surveyTemplate: ISurveyTemplate | null
  term: ITerm
  title: string
}

export type IDistributionPlanRow = ISlot

export type IDistributionPlanList = DataObject<ISlot>

export type IDistributionPlanRowDataType = IDistributionPlanList[]

export type IDistributionPlan = DataObjectList<ISlot>
