import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, useTheme } from '@mui/material'
import { type Breakpoint } from '@mui/system'
import { StyledDialogTitle } from './styles'
import { ButtonType } from '../GenericButton/styles'
import { grey } from '../../colors'
import DefaultModalActions from './DefaultModalActions'

export enum ModalActions {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  ERROR = 'error',
  INFORMATION = 'info',
  WARNING = 'warning'
}
interface GenericModalProps {
  isOpen: boolean
  onClose: () => void
  // This can be any type of action, <ReactMouseClickEvent> | <ReactFormSubmitButtonEvent> | <ReactButtonEvent>  | etc
  onConfirm?: any
  title?: string
  children?: React.ReactElement | React.ReactNode
  action?: boolean | React.ReactElement
  showCloseIcon?: boolean
  confirmActionText?: string
  confirmActionType?: ButtonType
  confirmButtonDisabled?: boolean
  hideConfirmButton?: boolean
  modalWidth?: string
  maxWidth?: string
  headerWrapper?: any
  destructiveButtonType?: ButtonType
  destructiveButtonText?: string
  destructiveButtonDisabled?: boolean
  destructiveButtonLoading?: boolean
  confirmButtonLoading?: boolean
}

const GenericModal: React.FC<GenericModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  children,
  showCloseIcon = true,
  action = true,
  confirmActionText = 'Confirm',
  confirmButtonDisabled = false,
  confirmActionType = ButtonType.Primary,
  hideConfirmButton = false,
  modalWidth,
  maxWidth = 'md',
  headerWrapper,
  destructiveButtonType = ButtonType.Ghost,
  destructiveButtonText = 'Cancel',
  destructiveButtonDisabled,
  destructiveButtonLoading = false,
  confirmButtonLoading = false
}) => {
  const Icon = (
    <IconButton onClick={onClose} sx={{ padding: 0, color: grey[150] }} >
      <CloseIcon />
    </IconButton>
  )

  const modalActions = typeof action === 'boolean' && action
    ? <DefaultModalActions
      confirmButtonProps={{
        onClick: onConfirm,
        text: confirmActionText,
        disabled: confirmButtonDisabled,
        buttonType: confirmActionType,
        loading: confirmButtonLoading,
        hideButton: hideConfirmButton
      }}
      destructiveButtonProps={{
        onClick: onClose,
        text: destructiveButtonText,
        disabled: destructiveButtonDisabled,
        buttonType: destructiveButtonType,
        loading: destructiveButtonLoading
      }}
    />
    : action

  const dialogStyle = {
    borderRadius: '12px',
    width: modalWidth
  }

  const theme = useTheme()

  const contentStyle = {
    padding: `0  ${theme.spacing(6)} `
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth as Breakpoint} PaperProps={{ style: dialogStyle }} >
      <StyledDialogTitle sx={headerWrapper}>
        {title}
        {showCloseIcon && Icon}
      </StyledDialogTitle>

      <DialogContent sx={contentStyle}>
        {children}
      </DialogContent>

      {modalActions}
    </Dialog>
  )
}

export default GenericModal
