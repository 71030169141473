import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}

export const StyledSaveMenuWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  width: '100%',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    flexWrap: 'wrap'
  }
}))
