import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledBoxProps {
  theme?: Theme
}
export const StyledSelectQuestionWrapper = styled(Box)<StyledBoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginTop: theme.spacing(4),
  justifyContent: 'center'
}))
