import { type DataObject, type DataObjectList } from './DataObject'
import type { SurveyChoice } from './Survey'

export enum DivisionCategory {
  University = 'University',
  College = 'College',
  School = 'School',
  Department = 'Department',
  Course = 'Course',
  Section = 'Section'
}

export interface DivisionResponse {
  defaultId: string
  divisionHierarchy: Division[]
  default_id?: string
}

export interface Division {
  categoryId?: string
  createdOn: string
  modifiedOn: string
  divisionId: string
  createdBy: string
  createdByUsername: string
  modifiedBy: string
  modifiedByUsername: string
  ownedBy: string
  categoryName: DivisionCategory
  title: string
  descr: string
  children: Division[] | null
  abbreviation: string
  parent: {
    divisionId: string
    title: string
  }
}
export interface InstructorDetails {
  instructorId: string
  instructorName: string
}

export interface SurveyCompletionRate extends Omit<Division, 'children' | 'divisionId'> {
  completionCount: number
  childrenCount: number
  studentCount: number
  children: SurveyCompletionRate[]
  divisionId?: string
  sectionId?: string
  instructorDetails: InstructorDetails[]
  termId?: string
}

export interface SurveyChoiceCounts extends SurveyChoice {
  count: number
}

// maps each id of a division to the id of it's parent division, like a series of nodes 4 -> 3 -> 2 -> 1
export function createPrevDivisionIdMapping (divisions: Division[]): Record<string, string | null> {
  const mapping: Record<string, string | null> = {}

  function searchNodes (divisions: Division[] | null, prev: string | null = null): void {
    if (divisions == null) {
      return
    }
    divisions.forEach(d => {
      mapping[d.divisionId] = prev
      searchNodes(d.children, d.divisionId)
    })
  }

  searchNodes(divisions)
  return mapping
}

export function createDivisionIdMapping (divisions: Division[] | null): Record<string, Division> {
  const mapping: Record<string, Division> = {}

  function searchNodes (divisions: Division[] | null): void {
    if (divisions == null) {
      return
    }
    divisions.forEach(d => {
      mapping[d.divisionId] = d
      searchNodes(d.children)
    })
  }

  searchNodes(divisions)
  return mapping
}

export type IDivisionRow = Division

export type IDivisionList = DataObject<Division>

export type IDivisionRowDataType = IDivisionList[]

export type IDivision = DataObjectList<Division>
