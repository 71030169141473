declare global {
  interface Window {
    env: any
  }
}

// change with your own variables
interface EnvType {
  REACT_APP_EDLINK_CLIENT_ID: string
}
export const env: EnvType = { ...process.env, ...window.env }
