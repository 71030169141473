import createApiThunk from '../../../ApplicationFactory'
import { type ITerm, type ITermListResponse } from '../../../models/Term'

export const getAllTermsApi = createApiThunk<ITermListResponse>('getAllTermsApi', {
  url: '/api/terms/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const getTermByIdApi = createApiThunk<ITerm>('getTermByIdApi', {
  url: '/api/terms/<urlId>',
  method: 'GET'
})

export const createTermsApi = createApiThunk<ITermListResponse>('createTermsApi', {
  url: '/api/terms/',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})

export const updateTermsApi = createApiThunk<ITerm>('updateTermsApi', {
  url: '/api/terms/<urlId>',
  method: 'PATCH'
})

export const deleteTermsApi = createApiThunk<{ message: string }>('deleteTermsApi', {
  url: '/api/terms/<urlId>',
  method: 'DELETE'
})
