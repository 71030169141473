import React, { useState } from 'react'
import GenericModal from '../../../components/GenericModal'
import { DragAndDrop } from '../../../components/DragAndDropUpload'
import Dropdown from '../../../components/Dropdown'
import CreateAndEditFacultyAndStaff from '../CreateAndEditFacultyAndStaff'
import { ButtonType } from '../../../components/GenericButton/styles'
import { uploadInstructureApi, uploadRolesApiViaCSV } from '../../../store/slices/facultyAndStaffSlice/apis'
import { useAppDispatch } from '../../../store'
import { notification } from '../../../components/Notifications'
import { actionLoading } from '../../../store/slices/facultyAndStaffSlice/selectors'

const FacultyAndStaffTopbarActions: React.FC = () => {
  const [isUploadModal, setIsUploadModal] = useState<boolean>(false)
  const [isUploadRolesModal, setIsUploadRolesModal] = useState<boolean>(false)
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const [taskId, setTaskId] = useState<string>('')
  const [alertType, setAlertType] = useState<string | null>(null)
  const [validationError, setValidationError] = useState<string | null>(null)

  const dispatch = useAppDispatch()
  const isUploadloading = actionLoading()

  const closeUploadModal = (): void => {
    setIsUploadModal(false)
    setIsUploadRolesModal(false)
    setAlertType(null)
  }

  const handleFile = (file: File): void => {
    setFile(file)
  }

  const closeUploadRolesModal = (): void => {
    setIsUploadRolesModal(false)
    setAlertType(null)
  }

  const handleUpload = async (): Promise<void> => {
    if (file) {
      try {
        await dispatch(uploadInstructureApi({ data: file })).then(async (res: any) => {
          if (res.payload?.taskId) {
            setTaskId(res.payload?.taskId)
            setAlertType('success')
            const message = 'Operation Completed!'
            notification(message, { variant: 'success' })
          } else {
            setValidationError(res?.payload)
            setAlertType('warning')
          }
        })
      } catch (error) {
        console.error('Error uploading file', error)
      }
    }
  }

  const handleUploadRoles = async (): Promise<void> => {
    if (file) {
      try {
        await dispatch(uploadRolesApiViaCSV({ data: file })).then(async (res: any) => {
          if (res.payload?.taskId) {
            setTaskId(res.payload?.taskId)
            setAlertType('success')
            const message = 'Operation Completed!'
            notification(message, { variant: 'success' })
          } else {
            setValidationError(res?.payload)
            setAlertType('warning')
          }
        })
      } catch (error) {
        console.error('Error uploading file', error)
      }
    }
  }

  const config = {
    headers: [
      { name: 'First Name', type: 'string', required: true },
      { name: 'Last Name', type: 'string', required: true },
      { name: 'Email', type: 'string', required: true },
      { name: 'School ID', type: 'string' },
      { name: 'Password', type: 'string' }
    ]
  }

  const uploadApi = (): void => {
    setIsUploadModal(true)
  }

  const handleUploadRolesModal = (): void => {
    setIsUploadRolesModal(true)
  }

  const addManually = (): void => {
    setIsCreateModal(true)
  }

  const menuItems = [
    { text: 'Import Staff CSV', action: uploadApi },
    { text: 'Import Role CSV', action: handleUploadRolesModal },
    { text: 'Add Manually', action: addManually }
  ]

  return (
    <>
      <Dropdown menuItems={menuItems} label='Add Faculty & staff' extraButtonProps={{ buttonType: ButtonType.Primary }} />
      <GenericModal
        isOpen={isCreateModal}
        title={'Add Faculty'}
        onClose={() => { setIsCreateModal(false) }}
        maxWidth='sm'
        action={false}
      >
        <CreateAndEditFacultyAndStaff handleCloseModal={() => { setIsCreateModal(false) }}/>
      </GenericModal>
      <GenericModal action={false} maxWidth='sm' confirmActionText='Upload' title='Import Users' onConfirm={handleUpload} showCloseIcon={false} isOpen={isUploadModal} onClose={closeUploadModal}>
        <DragAndDrop taskId={taskId} onDrop={handleFile} onClose={closeUploadModal} onConfirm={handleUpload} loading={isUploadloading} alertType={alertType} validationErrorBE={validationError} validationConfig={config} />
      </GenericModal>

      <GenericModal action={false} maxWidth='sm' confirmActionText='Upload' title='Import Roles' onConfirm={handleUpload} showCloseIcon={false} isOpen={isUploadRolesModal} onClose={closeUploadModal}>
        <DragAndDrop onDrop={handleFile} taskId={taskId} onClose={closeUploadRolesModal} onConfirm={handleUploadRoles} loading={isUploadloading} alertType={alertType} validationErrorBE={validationError} />
      </GenericModal>
    </>
  )
}

export default FacultyAndStaffTopbarActions
