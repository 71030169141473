import createApiThunk from '../../../ApplicationFactory'
import { type IUser, type IUserListResponse } from '../../../models/User'

export const getStudentsApi = createApiThunk<IUserListResponse>('getStudentsApi', {
  url: '/api/users/?is_student=True',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const createStudentsApi = createApiThunk<IUser>('createStudentsApi', {
  url: '/api/users/?is_student=True',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})

export const createStudentsApiViaCSV = createApiThunk<IUser>('createStudentsApiViaCSV', {
  url: '/api/users/?is_student=True',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv',
    Accept: 'application/cdg'
  }
})

export const createStudentRoleApi = createApiThunk<IUserListResponse>('createStudentsApi', {
  url: '/api/users/<urlId>/role/',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})

export const updateStudentsApi = createApiThunk<IUser>('updateStudentsApi', {
  url: '/api/users/<urlId>',
  method: 'PATCH',
  headers: {
    Accept: 'application/cdg'
  }
})

export const uploadInstructureApi = createApiThunk<IUserListResponse>('createInstructureApi', {
  url: '/api/users/',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv',
    Accept: 'application/cdg'
  }
})

export const uploadRolesApiViaCSV = createApiThunk<IUserListResponse>('uploadRolesApiViaCSV', {
  url: '/api/users/roles/?is_student=True',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv',
    Accept: 'application/cdg'
  }
})

export const getUserRolesApi = createApiThunk('getUserRolesApi', {
  url: '/api/users/roles/',
  method: 'GET'
})

export const createUserRolesApi = createApiThunk('createUserRolesApi', {
  url: '/api/users/roles/',
  method: 'POST'
})
