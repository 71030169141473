import { notification } from '../components/Notifications'

export const exportCsv = (data: string, fileName: string = 'exported_csv', message: string = 'CSV successfully downloaded'): void => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
  notification(message, { variant: 'success', subTitle: fileName })
}
