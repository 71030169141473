import createApiThunk from '../../../ApplicationFactory'
import type { IDistributionPlan, SlotStatus } from '../../../models/DistributionPlan'
import type { IDistributionSeries } from '../../../models/DistributionSeries'

export interface ISlotStatus {
  status: SlotStatus
}

export const getAllDistributionSeriesList = createApiThunk<IDistributionSeries[]>('getAllDistributionSeries', {
  url: '/api/distribution_series/',
  method: 'GET'
})

export const createDistributionSeriesApi = createApiThunk<IDistributionSeries>('createDistributionSeries', {
  url: '/api/distribution_series/',
  method: 'POST'
})

export const getDistributionSeriesApi = createApiThunk<IDistributionSeries[]>('getDistributionSeries', {
  url: '/api/distribution_series/<urlId>',
  method: 'GET'
})

export const editDistributionSeriesApi = createApiThunk<IDistributionSeries[]>('editDistributionSeries', {
  url: '/api/distribution_series/<urlId>',
  method: 'PATCH'
})

export const deleteDistributionSeriesApi = createApiThunk<{ message: string }>('deleteDistributionSeriesApi', {
  url: '/api/distribution_series/<urlId>',
  method: 'DELETE'
})

export const getSeriesSlotApi = createApiThunk<IDistributionPlan>('getSeriesSlot', {
  url: '/api/distribution_series/<urlId>/slots/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const createSlotApi = createApiThunk<IDistributionPlan>('createSlot', {
  url: '/api/distribution_series/<urlId>/slots/',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})
