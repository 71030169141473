import React from 'react'
import { Typography, Link } from '@mui/material'

import Eula from '../../assets/END USER LICENSE AGREEMENT.pdf'
import Terms from '../../assets/Terms.pdf'

import { StyledContainer } from './styles'

const linkStyles = {
  textDecoration: 'none'
}

const CopyrightFooter: React.FC = (): JSX.Element => {
  return (
    <StyledContainer role="footer">
      <Typography variant="subtitle2">
        Copyright &#169; 2024 ClassRanked Inc. All rights reserved.<br />
        By using this website, you agree to the <Link href={Eula} sx={linkStyles}>
          End User License Agreement
        </Link> and <Link href={Terms} sx={linkStyles}>Terms of Service</Link>.
      </Typography>
    </StyledContainer>
  )
}

export default CopyrightFooter
