import { createSlice } from '@reduxjs/toolkit'
import { getStudentDistributions, getStudentSurveyById, submitStudentSurvey } from './apis'
import { type StudentDistribution } from '../../../models/Distribution'
import { type ISurveyTemplate } from '../../../models/SurveyTemplate'

export interface StudentDistributionSliceType {
  studentDistributions: StudentDistribution[] | null
  isStudentDistributionsLoading: boolean
  error: string | null
  studentSelectedSurvey: ISurveyTemplate | null
  isLoading: boolean
}

const initialState: StudentDistributionSliceType = {
  studentDistributions: null,
  isStudentDistributionsLoading: false,
  error: null,
  studentSelectedSurvey: null,
  isLoading: false
}

const studentDistributionSlice = createSlice({
  name: 'studentDistributions',
  initialState,
  reducers: {
    resetState: (state) => {
      state.studentDistributions = null
      state.isStudentDistributionsLoading = false
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentDistributions.pending, (state) => {
        state.isStudentDistributionsLoading = true
      })
      .addCase(getStudentDistributions.fulfilled, (state, action) => {
        state.isStudentDistributionsLoading = false
        state.studentDistributions = action.payload
      })
      .addCase(getStudentDistributions.rejected, (state, action) => {
        state.isStudentDistributionsLoading = false
        state.error = action.payload?.message ?? 'An error occurred during API request.'
      })

      // Student single Survey by Id
      .addCase(getStudentSurveyById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStudentSurveyById.fulfilled, (state, action) => {
        state.isLoading = false
        state.studentSelectedSurvey = action.payload
      })
      .addCase(getStudentSurveyById.rejected, (state, action) => {
        state.isLoading = false
      })

      // Submit student survey
      .addCase(submitStudentSurvey.pending, (state) => {
        state.isLoading = true
      })
      .addCase(submitStudentSurvey.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(submitStudentSurvey.rejected, (state, action) => {
        state.isLoading = false
      })
  }
})
export const { resetState } = studentDistributionSlice.actions
export default studentDistributionSlice.reducer
