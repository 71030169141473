import React, { useState } from 'react'

import GenericModal from '../../../../components/GenericModal'
import Dropdown from '../../../../components/Dropdown'
import DragAndDrop from '../../../../components/DragAndDropUpload'
import { useAppDispatch } from '../../../../store'
import { getEnrollmentsApi } from '../../../../store/slices/termsSectionsSlice/api'
import { CreateEnrollment } from '../CreateEnrollment'
import { uploadRolesApiViaCSV } from '../../../../store/slices/facultyAndStaffSlice/apis'
import { actionLoading } from '../../../../store/slices/facultyAndStaffSlice/selectors'
import { type ApplyFiltersAndDispatchParams } from '../../../../components/CustomTable/types'
import { useParams } from 'react-router-dom'

interface TopbarActionsProps {
  params?: ApplyFiltersAndDispatchParams
}

const config = {
  headers: [
    { name: 'Academic Unit', type: 'string', required: true },
    { name: 'Email', type: 'string', required: true },
    { name: 'Role', type: 'string', required: true },
    { name: 'Term', type: 'string', required: true }
  ]
}

export const TopbarActions: React.FC<TopbarActionsProps> = ({ params }) => {
  const [isUploadModal, setIsUploadModal] = useState<boolean>(false)
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const [alertType, setAlertType] = useState<string | null>(null)
  const [validationError, setValidationError] = useState<string | null>(null)
  const [taskId, setTaskId] = useState<string>('')
  const dispatch = useAppDispatch()
  const isUploadloading = actionLoading()
  const { id } = useParams()

  const closeUploadModal = (): void => {
    setIsUploadModal(false)
    setAlertType(null)
  }

  const handleFile = (file: File): void => {
    setFile(file)
  }

  const handleUpload = async (): Promise<void> => {
    if (file) {
      try {
        await dispatch(uploadRolesApiViaCSV({ data: file })).then(async (res: any) => {
          if (res.payload?.taskId) {
            setTaskId(res.payload?.taskId)
            setAlertType('success')
            const params = { pageSize: 15 }
            await dispatch(getEnrollmentsApi({ urlId: id, params }))
          } else {
            setValidationError(res?.payload)
            setAlertType('warning')
          }
        })
      } catch (error) {
        console.error('Error uploading file', error)
      }
    }
  }

  const uploadApi = (): void => {
    setIsUploadModal(true)
  }

  const addManually = (): void => {
    setIsCreateModal(true)
  }

  const menuItems = [
    { text: 'Upload CSV', action: uploadApi },
    { text: 'Add Manually', action: addManually }
  ]

  const title = alertType ? 'Upload complete' : 'Upload courses'

  return (
    <>
      <Dropdown menuItems={menuItems} label='Add Enrollments' />
      <GenericModal
        isOpen={isCreateModal}
        title={'Create Student Enrollment'}
        onClose={() => { setIsCreateModal(false) }}
        maxWidth='sm'
        action={false}
      >
        <CreateEnrollment params={params} handleCloseModal={() => { setIsCreateModal(false) }} />
      </GenericModal>
      <GenericModal action={false} maxWidth='sm' title={title} showCloseIcon={false} isOpen={isUploadModal} onClose={closeUploadModal}>
        <DragAndDrop taskId={taskId} onDrop={handleFile} onClose={closeUploadModal} onConfirm={handleUpload} loading={isUploadloading} alertType={alertType} validationConfig={config} validationErrorBE={validationError} />
      </GenericModal>
    </>
  )
}
