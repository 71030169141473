
import React from 'react'
import TermsFeature from '../../features/Terms'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'

const TermsPage: React.FC = (): JSX.Element => {
  return (
    <>
      <CustomHeader title={PAGE_TITLES.TERMS} />
      <TermsFeature />
    </>
  )
}

export default TermsPage
