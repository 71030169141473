import React, { useEffect } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useNavigate } from 'react-router-dom'
import CustomModal from '../../../components/CustomModal'
import { StyledSaveMenuWrapper } from './styles'
import ROUTES from '../../../consts/paths'
import { useAppDispatch } from '../../../store'
import GenericButton from '../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../components/GenericButton/styles'
import {
  getActionLoading,
  getEditableQuestion,
  getEditableSurvey,
  getSurveySections,
  getSurveyTitle
} from '../../../store/slices/SurveyTemplateSlice/selectors'
import { Messages, type Question, SurveyItemQuestionType } from '../types'
import { type ISurveySection, resetState } from '../../../store/slices/SurveyTemplateSlice'
import DeleteSurveyModal from '../../AdminSurveyListFeature/Components/DeleteSurveyModal'
import { createSurveyTemplate, editSurveyTemplate } from '../../../store/slices/SurveyTemplateSlice/apis'
import { notification } from '../../../components/Notifications'
import { findInvalidQuestion, findInvalidSectionIndex, scrollToInvalidSection } from '../helpers'
import _ from 'lodash'
import { type ISurveyItem } from '../../../models/SurveyTemplate'

const SurveyHeader: React.FC = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false)
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const surveyTitle = getSurveyTitle()
  const surveySections = getSurveySections()
  const editableQuestion = getEditableQuestion()
  const editableSurvey = getEditableSurvey()

  const isLoading = getActionLoading()

  const handleBack = (): void => {
    navigate(ROUTES.SURVEYS)
    dispatch(resetState())
  }

  const checkIsTitleDirty = (): boolean => {
    if (surveyTitle !== editableSurvey.title) return true
    return false
  }

  const checkIsSectionDirty = (section: ISurveySection, sectionIndex: number): boolean => {
    const areAllSectionEqual = _.isEqual(editableSurvey?.surveySections?.[sectionIndex], section)
    return !areAllSectionEqual
  }

  const checkQuestionIsDirty = (sectionIndex: number, newQuestion: Question): boolean => {
    let isDirty = false
    editableSurvey?.surveySections?.[sectionIndex]?.surveyItems.forEach((question: ISurveyItem) => {
      if (question.itemId === newQuestion.itemId) {
        const isQuestionEqual = _.isEqual(question, newQuestion)
        isDirty = !isQuestionEqual
      }
    })
    return isDirty
  }

  const submitEditableSurvey = async (): Promise<void> => {
    const isTitleDirty = checkIsTitleDirty()
    const areAllSectionsEqual = _.isEqual(surveySections, editableSurvey?.surveySections)
    const isTemplateDirty = isTitleDirty || !areAllSectionsEqual

    const preparedData = {
      title: surveyTitle,
      isDirty: isTemplateDirty,
      surveySections: surveySections?.map((section: ISurveySection, sectionIndex: number) => ({
        isDirty: section.surveySectionId && checkIsSectionDirty(section, sectionIndex),
        name: section.name,
        title: section.title ?? undefined,
        descr: section.descr,
        surveySectionId: section.surveySectionId ?? undefined,
        surveyItems: section.surveyItems?.map((question: Question) => ({
          isDirty: question.itemId && checkQuestionIsDirty(sectionIndex, question),
          itemId: question.itemId ?? undefined,
          itemType: question.itemType,
          prompt: question.prompt,
          isRequired: question.isRequired,
          ...(question.itemType === SurveyItemQuestionType.MULTIPLE_CHOICE ||
            question.itemType === SurveyItemQuestionType.LINEAR_SCALE
            ? { object: question.object }
            : {})
        }))
      }))
    }

    const surveyTemplateData = {
      urlId: editableSurvey.templateId,
      data: preparedData
    }

    try {
      const res = await dispatch(editSurveyTemplate(surveyTemplateData))
      if (res.meta?.requestStatus === 'fulfilled') {
        handleBack()
      }
    } catch (error) {
      console.error('Error editing survey template:', error)
    }
  }

  const submitCreateSurvey = async (): Promise<any> => {
    const data = { title: surveyTitle, surveySections }
    const res = await dispatch(createSurveyTemplate({ data }))
    if (res.meta?.requestStatus === 'fulfilled') {
      handleBack()
      dispatch(resetState())
    }
  }

  const handleSave = async (): Promise<void> => {
    if (!surveySections) return

    const invalidSectionIndex = findInvalidSectionIndex(surveySections)

    if (invalidSectionIndex !== -1) {
      notification(Messages.NO_QUESTION, { variant: 'error' })
      scrollToInvalidSection(invalidSectionIndex.toString())
      return
    }

    const invalidQuestion = findInvalidQuestion(surveySections)
    if (invalidQuestion) {
      notification(Messages.INVALID_QUESTION, { variant: 'error' })
      scrollToInvalidSection(`${invalidQuestion.sectionIndex}${invalidQuestion.questionIndex}`)
      return
    }

    editableSurvey ? await submitEditableSurvey() : await submitCreateSurvey()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleDeleteClick = (): void => {
    setAnchorEl(null)
    setDeleteModalOpen(true)
  }

  const handleDeleteModal = (): void => {
    handleBack()
  }

  const handleCloseModal = (): void => {
    setDeleteModalOpen(false)
  }

  useEffect(() => {
    findInvalidSectionIndex(surveySections)
  }, [surveySections])

  return (
    <React.Fragment>
      <StyledSaveMenuWrapper>
        <GenericButton
          buttonType={ButtonType.Primary}
          buttonSize={ButtonSize.Medium}
          disabled={editableQuestion !== null || surveyTitle === ''}
          loading={isLoading}
          label={editableSurvey ? 'Save' : 'Create'}
          onClick={() => {
            void handleSave()
          }}
          style={{
            flexShrink: 0,
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          }}
        />
        <GenericButton
          buttonType={ButtonType.Secondary}
          buttonSize={ButtonSize.Medium}
          icon={<MoreVertIcon />}
          onClick={handleClick}
          style={{
            flexShrink: 0,
            [theme.breakpoints.down('md')]: {
              width: '100% !important'
            }
          }}
        />
      </StyledSaveMenuWrapper>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
      <CustomModal
        isModalOpen={isDeleteModalOpen}
        title={'Delete Survey?'}
        onClose={() => {
          setDeleteModalOpen(false)
        }}
        contentText={'Are you sure you want to delete this survey? You cannot undo this action.'}
        paperProps={{ width: theme.spacing(101), height: theme.spacing(70) }}
        actions={<DeleteSurveyModal handleCloseModal={handleCloseModal} handleDeleteModal={handleDeleteModal} />}
      ></CustomModal>
    </React.Fragment>
  )
}
export default SurveyHeader
