import React, { useState } from 'react'
import InstructorSurveyListCard from './components/InstructorSurveyListCard'
import { type InstructorDistribution } from '../../models/Distribution'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useAsyncEffect from 'use-async-effect'
import EmptyListCard from './components/EmptyListCard'
import { grey } from '../../colors'
import { Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PageHeader from '../../components/PageHeader'

export const instructorCardWidth = 720
interface InstructorSurveyListFeatureProps {
  list: InstructorDistribution[]
  instructorFullName: string
  controls?: React.ReactNode
}

const InstructorSurveyListFeature: React.FC<InstructorSurveyListFeatureProps> = ({ list, instructorFullName, controls }): JSX.Element => {
  const theme = useTheme()

  return (
    <Box display='flex' flexDirection='column' maxWidth={instructorCardWidth} width='100%' margin='auto'>
      <PageHeader
        title={instructorFullName}
        pageTitleAriaLabel={`Survey results for${instructorFullName}`}
        controls={controls}
        isCompactOnly
      />
      {list?.map((item, index) => {
        return (
          <InstructorSurveyListCard
            key={index}
            surveyNumber={index + 1}
            totalSurveys={list?.length}
            courseName={item?.sectionDetails?.courseName}
            sectionAbbr={item?.sectionDetails?.sectionAbbr}
            endDate={item?.endTimestamp}
            startDate={item?.startTimestamp}
            releaseDate={item?.releaseTimestamp}
            surveyURL={item?.distributionUrl}
            distributionId={item?.distributionId}
            reportURL={item?.sectionId}
            completionCount={item?.completionDetails?.completionCount}
            studentCount={item?.completionDetails?.studentCount}
          />
        )
      })}
      {
        list.length === 0 && <EmptyListCard />
      }
    </Box>
  )
}

export default InstructorSurveyListFeature
