import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { grey, red, shade } from '../../colors'

interface StyledBoxProps {
  theme?: Theme
}

export const SectionWrapper = styled(Box)<StyledBoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(5),
  border: '1px solid #E3E8EF',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(6)
}))

export const OptionalLabel = styled(Typography)<StyledBoxProps>(({ theme }) => ({
  background: shade[750],
  width: 'fit-content',
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(4),
  padding: `0 ${theme.spacing(2)}`,
  borderRadius: theme.spacing(2),
  color: grey[850],
  fontSize: '14px',
  fontWeight: 500,
  border: `1px solid ${shade[650]}`
}))

export const InvalidQuestionError = styled(Typography)<StyledBoxProps>(({ theme }) => ({
  background: red[50],
  width: 'fit-content',
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(4),
  padding: `${theme.spacing(1)}${theme.spacing(2)}`,
  borderRadius: theme.spacing(2),
  color: red[700],
  fontSize: '14px',
  fontWeight: 500,
  border: `1px solid ${red[100]}`
}))
