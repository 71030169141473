import { createSlice } from '@reduxjs/toolkit'
import { type ITerm } from '../../../models/Term'
import { type IDistributionSeries } from '../../../models/DistributionSeries'
import { getTermsListApi } from '../reportSlice/apis'
import { getAllDistributionSeriesList } from '../distributionSeriesSlice/apis'

export interface TermHeaderSliceType {
  term: ITerm[] | null
  series: IDistributionSeries | null
  selectedTerm: ITerm | null

}

export const initialState: TermHeaderSliceType = {
  term: null,
  selectedTerm: null,
  series: null
}

const TermHeaderSlice = createSlice({
  name: 'term_header',
  initialState,
  reducers: {
    setSelectedTerm (state, action) {
      state.selectedTerm = action.payload
    },
    setSelectedSeries (state, action) {
      state.series = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all terms
      .addCase(getTermsListApi.fulfilled, (state, action) => {
        if (action.payload?.length > 0) {
          state.term = action.payload
          state.selectedTerm = state.selectedTerm ?? action.payload[0]
        }
      })
      .addCase(getTermsListApi.rejected, (state, action) => {
        state.term = null
      })
      .addCase(getAllDistributionSeriesList.fulfilled, (state, action) => {
        if (action.payload?.length > 0) {
          state.series = action.payload[0]
          // state.term = null
        }
      })
      .addCase(getAllDistributionSeriesList.rejected, (state, action) => {
        state.series = null
      })
  }
})

export const { setSelectedTerm, setSelectedSeries } = TermHeaderSlice.actions

export default TermHeaderSlice.reducer
