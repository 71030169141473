export enum Messages {
  NO_QUESTION = 'There are no Questions in Survey Sections',
  INVALID_QUESTION = 'Question is Invalid, Please check',
}

export enum SurveyItemQuestionType {
  DESCRIPTIVE_TEXT = 'Title',
  FREE_RESPONSE = 'FreeResponse',
  LINEAR_SCALE = 'LinearScale',
  MULTIPLE_CHOICE = 'MultipleChoice'
}
export const ItemTypeMappings: Record<string, string> = {
  'Descriptive Text': 'Title',
  'Free Response': 'FreeResponse',
  'Linear Scale': 'LinearScale',
  'Multiple Choice': 'MultipleChoice'
}

export interface DescriptiveQuestion {
  itemType: string
  itemId?: string
  prompt: string
  object?: string[]
  isRequired: boolean
}

export interface MultiChoiceQuestion {
  itemType: string
  itemId?: string
  prompt: string
  object: string[]
  isRequired: boolean
}

export interface ILinearObject {
  option: string
  weight: number
}
export interface LinearQuestion {
  itemType: string
  itemId?: string
  prompt: string
  object: ILinearObject[]
  isRequired: boolean
}

export interface FreeResponseQuestion {
  itemType: string
  itemId?: string
  prompt: string
  object?: string[]
  isRequired?: boolean
}

export type Question = DescriptiveQuestion | MultiChoiceQuestion | FreeResponseQuestion | LinearQuestion
export interface EditableSurveyQuestionItem {
  itemId: string
  itemType: string
  prompt: string
  object?: string[] | ILinearObject[]
}
