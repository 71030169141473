import React, { useState } from 'react'
import GenericModal from '../../../../components/GenericModal'
import { useAppDispatch } from '../../../../store'
import CreateAndEditDistributionSeries from '../CreateAndEditDistributionSeries'
import type { IDistributionSeries } from '../../../../models/DistributionSeries'
import { Menu, MenuItem, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { deleteDistributionSeriesApi } from '../../../../store/slices/distributionSeriesSlice/apis'
import DeleteDistributionSeries from '../DeleteDistributionSeries'

interface DistributionSeriesTopBarActionProps {
  distributionSeries: IDistributionSeries
}

const DistributionSeriesTopBarAction: React.FC<DistributionSeriesTopBarActionProps> = ({ distributionSeries }) => {
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleEditClick = (): void => {
    setIsCreateModal(true)
    handleMenuClose()
  }

  const handleDeleteClick = (): void => {
    setIsDeleteModal(true)
    handleMenuClose()
  }

  const handleDeleteModal = async (): Promise<void> => {
    try {
      const apiCall = dispatch(deleteDistributionSeriesApi({ urlId: distributionSeries?.distributionSeriesId })).unwrap()
      await apiCall
      setIsDeleteModal(false)
    } catch (error) {
      console.error('Failed to delete distribution series:', error)
    }
  }

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEditClick}>Edit Title</MenuItem>
        <MenuItem onClick={handleDeleteClick} style={{ color: 'red' }}>
          Delete
        </MenuItem>
      </Menu>
      <GenericModal
        isOpen={isCreateModal}
        title={'Edit Distribution Series'}
        onClose={() => { setIsCreateModal(false) }}
        maxWidth="sm"
        action={false}
      >
        <CreateAndEditDistributionSeries distributionSeries={distributionSeries} />
      </GenericModal>
      <GenericModal
        isOpen={isDeleteModal}
        title={`Delete "${distributionSeries.title}"?`}
        onClose={() => { setIsDeleteModal(false) }}
        maxWidth="sm"
        action={false}
      >
        <DeleteDistributionSeries
          distributionSeries={distributionSeries}
          handleCloseModal={() => { setIsDeleteModal(false) }}
          handleDeleteModal={() => {
            void handleDeleteModal()
          }}
        />
      </GenericModal>
    </>
  )
}

export default DistributionSeriesTopBarAction
