import createApiThunk from '../../../ApplicationFactory'
import type { AcademicUnitData, ListResponse } from '../../../features/AcademicUnitFeature/types'

export const getAcademicUnitList = createApiThunk<ListResponse>('getAcademicUnit', {
  url: '/api/acad_units/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const createAcademicUnit = createApiThunk<ListResponse>('submitcreateAcademicUnit', {
  url: '/api/acad_units/',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})

export const editAcademicUnit = createApiThunk<AcademicUnitData>('editAcademicUnit', {
  url: '/api/acad_units/<urlId>',
  method: 'PATCH',
  headers: {
    Accept: 'application/cdg'
  }
})

export const deleteAcademicUnitApi = createApiThunk<{ message: string }>('deleteAcademicUnit', {
  url: '/api/acad_units/<urlId>',
  method: 'DELETE'
})
