import createApiThunk from '../../../ApplicationFactory'
import { type InstructorDistribution } from '../../../models/Distribution'
import { type ISection } from '../../../models/TermsSection'

export const getInstructorDistributions = createApiThunk<InstructorDistribution[]>('instructorDistributions', {
  url: '/api/distributions/instructor/',
  method: 'GET'
})

export const getSections = createApiThunk<ISection[]>('instructorSections', {
  url: '/api/sections/',
  method: 'GET'
})

export const getInstructorDistributionsById = createApiThunk<InstructorDistribution[]>('getInstructorDistributionsById', {
  url: '/api/distributions/instructor/<urlId>',
  method: 'GET'
})
