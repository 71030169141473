import React from 'react'
import SurveyListCard from './components/SurveyListCard'
import { SurveyCompletionState } from './components/SurveyListCard/styles'
import { type StudentDistribution } from '../../models/Distribution'
import PageHeader from '../../components/PageHeader'

interface SurveyListFeatureProps {
  list: StudentDistribution[]
  studentName: string
}

const getSurveyState = (isSubmitted: boolean, endTimestamp: string): SurveyCompletionState => {
  const currentUtcDate = new Date().toISOString()
  if (isSubmitted) {
    return SurveyCompletionState.Submitted
  } else if (currentUtcDate >= endTimestamp) {
    return SurveyCompletionState.Closed
  } else {
    return SurveyCompletionState.StartSurvey
  }
}

const SurveyListFeature: React.FC<SurveyListFeatureProps> = ({ list, studentName }): JSX.Element => {
  return (
    <React.Fragment>
      <PageHeader
        title={studentName}
        pageTitleAriaLabel={`Page title ${studentName}`}
        isCompactOnly
      />
      {list.map((item, index) => (
        <SurveyListCard
          index={index}
          total={list?.length}
          key={index}
          title={item.courseDetails.courseName}
          owner={item.courseDetails.instructorName}
          // Change survey State to enum for 'draft survey' feature
          surveyState={getSurveyState(item.isSubmitted, item.endTimestamp)}
          endDate={item.endTimestamp}
          surveyURL={item.distributionUrl}
          surveyId={item.distributionId}
        />
      ))}
    </React.Fragment>
  )
}

export default SurveyListFeature
