import React from 'react'
import AdminSurveyFeature from '../../features/AdminSurveyFeature'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'
import { isSurveyListLoading } from '../../store/slices/SurveySlice/selectors'
import { type CustomBreadcrumbProps } from '../../components/CustomBreadcrumbs'
import ROUTES from '../../consts/paths'

const mockList = [{ title: 'Survey', path: ROUTES.SURVEYS }]

const AdminSurveyPage: React.FC = (): JSX.Element => {
  const [breadcrumbList] = React.useState<CustomBreadcrumbProps>({ breadcrumb: mockList })
  const isLoading = isSurveyListLoading()

  return (
    <>
      <CustomHeader title={isLoading ? PAGE_TITLES.LOADING : PAGE_TITLES.MY_SURVEYS} />
      <AdminSurveyFeature breadcrumb={breadcrumbList.breadcrumb} />
    </>
  )
}

export default AdminSurveyPage
