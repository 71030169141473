import createApiThunk from '../../../ApplicationFactory'
import { type IEnrollmentsList } from '../../../models/Enrollment'
import { type ISection, type ISectionsList } from '../../../models/TermsSection'

export const getTermsSectionsApi = createApiThunk<ISectionsList>('getTermsSectionsApi', {
  url: '/api/sections/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const createTermsSectionsApi = createApiThunk<ISection>('createTermsSectionsApi', {
  url: '/api/sections/',
  method: 'POST'
})

export const updateTermsSectionsApi = createApiThunk<ISection>('updateTermsSectionsApi', {
  url: '/api/sections/<urlId>',
  method: 'PATCH'
})

export const deleteTermsSectionApi = createApiThunk<{ message: string }>('deleteTermsSectionApi', {
  url: '/api/sections/<urlId>',
  method: 'DELETE'
})

export const uploadTermsSectionApiViaCSV = createApiThunk<ISection>('uploadTermsSectionApiViaCSV', {
  url: '/api/sections/',
  method: 'POST',
  headers: {
    'Content-Type': 'text/csv'
  }
})

export const getEnrollmentsApi = createApiThunk<IEnrollmentsList>('getEnrollmentsApi', {
  url: '/api/terms/<term_id>/enrollments/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const deleteEnrollmentApi = createApiThunk<{ message: string }>('deleteEnrollmentApi', {
  url: '/api/users/<user_id>/role/<role_id>',
  method: 'DELETE'
})
