import React from 'react'
import DistributionSeries from '../../features/DistributionSeriesAndPlan'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'

const DistributionSeriesPage: React.FC = () => {
  return (
    <>
      <CustomHeader title={PAGE_TITLES.DISTRIBUTION_SERIES} />
      <DistributionSeries />
    </>
  )
}

export default DistributionSeriesPage
