import React, { Suspense, lazy, useState } from 'react'
import api from '../../utils/api'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../components/CustomTable/types'
import LoadingPage from '../../pages/LoadingPage'
import { useQuery } from 'react-query'
import { Alert, Chip } from '@mui/material'
import Button from '@mui/material/Button'
import { notification } from '../../components/Notifications'
import { type ITask, type ITaskList } from '../../models/Task'
import { GridSortItem } from '@mui/x-data-grid'

const AppTable = lazy(async () => await import('../../components/CustomTable'))

const capitalize = (word: string): string => {
  const capitalized =
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  return capitalized
}

const TaskFeature = (): JSX.Element => {
  const [params, setParams] = useState<Record<string, any>>({
    sort: JSON.stringify({
      field: 'start_timestamp',
      direction: 'desc'
    })
  })

  const download = async (taskId: string): Promise<void> => {
    try {
      notification('Downloading Task information', { variant: 'info' })
      const result = await api.get(`/api/tasks/${taskId}/download/`, { responseType: 'blob' })
      console.log(result.data)
      const href = URL.createObjectURL(result.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `task-${taskId}.csv`)
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch {
      notification('Failed to download Task information', { variant: 'error' })
    }
  }

  const { data: studentList, isLoading, error } = useQuery({
    queryKey: ['tasks', params],
    queryFn: async (): Promise<ITaskList> => {
      const result = await api.get<ITaskList>('/api/tasks/', {
        params,
        headers: {
          Accept: 'application/cdg'
        }
      })
      return result.data
    }
  })

  const columns = [...(studentList?.keys ?? []), {
    field: 'task_id_download', headerName: 'Download', shown: true, allowedSearch: false
  }]
  const rows = studentList?.data ?? []

  const overrides = {
    taskIdDownload: (row: ITask) => {
      return <Button disabled={row.status !== 'COMPLETED'} onClick={() => { void download(row.taskId) }}>Download</Button>
    },
    status: (row: ITask) => {
      const status = row.status
      let color: 'secondary' | 'primary' | 'error' | 'success' | 'warning' = 'primary'

      switch (status) {
        case 'STARTED':
          color = 'primary'
          break
        case 'COMPLETED':
          color = 'success'
          break
        case 'FAILED':
          color = 'error'
          break
        case 'REVOKED':
          color = 'warning'
          break
      }
      return <Chip
        label={capitalize(status)}
        size='small'
        color={color}
        variant={'outlined'} />
    },
    result: (row: ITask) => {
      const result = row.result
      let color: 'secondary' | 'primary' | 'error' | 'success' | 'warning' = 'primary'

      switch (result) {
        case 'ERROR':
          color = 'error'
          break
        case 'SUCCESSFUL':
          color = 'success'
          break
        case 'PENDING':
          color = 'warning'
          break
      }
      return <Chip
        label={capitalize(result)}
        size='small'
        color={color}
        variant={'outlined'} />
    }
  }

  return (
    <Suspense fallback={<LoadingPage />}>
    <>
      {error && <Alert severity="error">an issue occoured</Alert>}
      <AppTable
        topbarTitle={'Activity Monitor'}
        list={studentList}
        rows={rows}
        loading={isLoading}
        columns={columns}
        featureName="Tasks"
        params={params}
        setParams={setParams}
        actionModalEntityDisplayField='task'
        hideEditColumn={true}
        overrides={overrides}
      />
    </>
    </Suspense>
  )
}

export default TaskFeature
