import React from 'react'
import { ButtonType } from '../../../components/GenericButton/styles'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useAppDispatch } from '../../../store'
import { grey } from '../../../colors'
import { type ISurveyRow } from '../types'
import { archiveSurveyTemplateApi } from '../../../store/slices/SurveySlice/apis'
import { archiveLoading } from '../../../store/slices/SurveySlice/selectors'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'

interface ArchiveSurveyTemplateProps {
  selectedRow?: ISurveyRow
  handleCloseModal?: () => void
  showErrorModal?: () => void
  setErrorMessage?: (arg: string) => void
}

const ArchiveSurveyTemplate: React.FC<ArchiveSurveyTemplateProps> = ({ selectedRow, handleCloseModal, showErrorModal, setErrorMessage }) => {
  const dispatch = useAppDispatch()
  const isDeleteLoading = archiveLoading()

  const theme = useTheme()

  const handleArchive = async (): Promise<void> => {
    try {
      await dispatch(archiveSurveyTemplateApi({ urlId: selectedRow?.templateId })).unwrap()
      handleCloseModal?.()
    } catch (error: any) {
      if (error && error.response?.status === 422) {
        const errorMessage = error.response?.data?.error
        setErrorMessage?.(errorMessage)
        showErrorModal?.()
      }
    }
  }

  return (
    <>
      <Typography fontSize='14px' lineHeight={theme.spacing(5)} color={grey[850]}>
        You will not be able to recover this  Survey Template. All references to it will be hidden.
      </Typography>
      <DefaultModalActions
        confirmButtonProps={{
          onClick: handleArchive,
          text: 'Archive',
          loading: isDeleteLoading,
          buttonType: ButtonType.Destructive
        }}
        destructiveButtonProps={{
          onClick: handleCloseModal
        }}
      />
    </>

  )
}

export default ArchiveSurveyTemplate
