import { createSlice } from '@reduxjs/toolkit'
import { getCompletionRate, getReports, getBreadCrumbList, getTermsListApi } from './apis'
import { type SurveyCompletionRate, type DivisionResponse } from '../../../models/Division'
import { type Report } from '../../../models/Report'
import { type BreadcrumbT } from '../../../components/CustomBreadcrumbs'
import { type ITerm } from '../../../models/Term'

export interface ReportSlice {
  divisions: DivisionResponse | null
  report: Report[] | null
  breadCrumb: BreadcrumbT[]
  completionRate: SurveyCompletionRate | null
  isReportLoading: boolean
  isBreadCrumbLoading: boolean
  isCompletionRateLoading: boolean
  error: string | null
  reportError: Record<string, any> | null
  completionRateError: string | null
  termsList: ITerm[] | null
}

const initialState: ReportSlice = {
  divisions: null,
  report: null,
  breadCrumb: [],
  completionRate: null,
  isReportLoading: false,
  isBreadCrumbLoading: false,
  isCompletionRateLoading: false,
  completionRateError: null,
  reportError: null,
  error: null,
  termsList: null
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    resetState: (state) => {
      state.divisions = null
      state.report = null
      state.breadCrumb = []
      state.completionRate = null
      state.isReportLoading = true
      state.isBreadCrumbLoading = true
      state.isCompletionRateLoading = true
      state.reportError = null
      state.termsList = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompletionRate.pending, (state) => {
        state.completionRateError = null
        state.isCompletionRateLoading = true
      })
      .addCase(getCompletionRate.fulfilled, (state, action) => {
        state.isCompletionRateLoading = false
        state.completionRate = action.payload
      })
      .addCase(getCompletionRate.rejected, (state, action) => {
        state.isCompletionRateLoading = false
        // @TODO use action.error with apifactory
        if (action.payload?.response?.status === 404) {
          state.completionRateError = action.payload?.response?.data?.error ?? 'An error occurred during API request.'
        }
        state.completionRate = null
      })
      .addCase(getReports.pending, (state) => {
        state.reportError = null
        state.isReportLoading = true
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.isReportLoading = false
        state.report = action.payload
      })
      .addCase(getReports.rejected, (state, action) => {
        state.isReportLoading = false
        state.report = null
        if (action.payload?.response?.status === 400) {
          state.reportError = action.payload?.response?.data?.error
        }
      })
      .addCase(getBreadCrumbList.pending, (state) => {
        state.error = null
        state.isBreadCrumbLoading = true
      })
      .addCase(getBreadCrumbList.fulfilled, (state, action) => {
        state.isBreadCrumbLoading = false
        state.breadCrumb = action.payload
      })
      .addCase(getBreadCrumbList.rejected, (state, action) => {
        state.isBreadCrumbLoading = false
        state.error = action.payload?.message ?? 'An error occurred during API request.'
      })
      // Get Terms By Id
      .addCase(getTermsListApi.fulfilled, (state, action) => {
        state.termsList = action.payload
      })
  }
})
export const { resetState } = reportSlice.actions
export default reportSlice.reducer
