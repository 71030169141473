import { type RootState } from './../../index'
import { useAppSelector } from '../..'
import type { AcademicUnitRowData, ListResponse, RowDataType } from '../../../features/AcademicUnitFeature/types'

export const isAcademicUnitLoading = (): boolean => useAppSelector((state: RootState) => state.academicUnit.isAcademicUnitLoading === true)
export const academicUnitDetails = (): ListResponse => useAppSelector((state: RootState) => state.academicUnit?.academicUnit)
export const academicUnitDetailsForForm = (): { selectedRow: AcademicUnitRowData } => useAppSelector((state: RootState) => ({ selectedRow: state.academicUnit.academicUnit?.data ?? null }))
export const isCreateAcademicUnitLoading = (): boolean => useAppSelector((state: RootState) => (
  (state.academicUnit?.isCreateAcademicUnitLoading === true) ||
  (state.academicUnit?.isEditAcademicUnitLoading === true)
))
export const academicUnitDetailsForChildren = (): RowDataType => useAppSelector((state: RootState) => (state.academicUnit.academicUnit?.data))
export const deleteLoading = (): boolean => useAppSelector((state: RootState) => state.academicUnit.deleteLoading)
