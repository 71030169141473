import * as React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import type { LinearProgressProps } from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/system'

interface LinearProgressPropsWithNumber extends LinearProgressProps {
  value: number
  isCard?: boolean
}

const LinearProgressWithLabel: React.FC<LinearProgressPropsWithNumber> = ({ value, isCard = false, ...props }): JSX.Element => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pt: 2, pb: 1.5, flexDirection: isCard ? 'column' : 'row' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
            tabIndex={0}
          variant="determinate"
          sx={{
            // @TODO Needs to be own Styles
            height: {
              xs: theme.spacing(3),
              sm: theme.spacing(4),
              md: theme.spacing(5)
            },
            borderRadius: {
              xs: theme.spacing(3 / 2),
              sm: theme.spacing(4 / 2),
              md: theme.spacing(5 / 2)
            }
          }}
          value={value > 100 ? 100 : value}
          {...props} />
      </Box>
      {!isCard && <Box sx={{ pl: 1 }}>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            color: theme.palette.primary.dark
          }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>}
    </Box>
  )
}

export default LinearProgressWithLabel
