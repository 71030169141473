import React, { type ReactNode, useEffect, useMemo } from 'react'
import { useTheme, Autocomplete, TextField } from '@mui/material'
import { type ITerm } from '../../models/Term'
import { useAppDispatch } from '../../store'
import { getSelectedSeries, getSelectedTerm, getTermListFromTermSlice } from '../../store/slices/TermHeaderSlice/selectors'
import { setSelectedSeries, setSelectedTerm } from '../../store/slices/TermHeaderSlice'
import { type IDistributionSeries } from '../../models/DistributionSeries'
import { getAllSeriesData } from '../../store/slices/distributionSeriesSlice/selectors'
import { TermHeaderContainer } from './styles'
import { useSearchParams } from 'react-router-dom'

export interface TermHeaderProps {
  showSeries?: boolean
  children?: ReactNode
}

const autocompleteStyle = {
  autoHighlight: true,
  disableClearable: true,
  size: 'small' as 'small' | 'medium' | undefined
}

const TermHeader: React.FC<TermHeaderProps> = ({
  children = null,
  showSeries = true
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultTerm = getSelectedTerm()

  const termId = searchParams.get('termId') ?? defaultTerm?.termId

  const termsList = getTermListFromTermSlice()
  const selectedTerm = termsList?.find(d => d.termId === termId)

  const seriesList = getAllSeriesData()
  const selectedSeries = getSelectedSeries()

  const theme = useTheme()

  const textfieldStyle = useMemo(() => ({
    '& .MuiInputLabel-root': { paddingY: theme.spacing(1.25) },
    '& .MuiInputLabel-shrink': { paddingY: 0 }
  }), [theme])

  const autocompleteResponsiveSx = useMemo(() => ({
    [theme.breakpoints.up('md')]: {
      maxWidth: '256px'
    }
  }), [theme])

  return (
    <TermHeaderContainer>
      {
        showSeries &&
        <Autocomplete
          id="series-select"
          {...autocompleteStyle}
          value={selectedSeries}
          options={seriesList}
          getOptionLabel={(series) => series.title}
          renderInput={(params) => <TextField
            sx={textfieldStyle}
            {...params} label="Series" />}
          isOptionEqualToValue={(option, value) => option.distributionSeriesId === value.distributionSeriesId}
          onChange={(event: any, newValue: IDistributionSeries | null) => {
            dispatch(setSelectedSeries(newValue))
          }}
          fullWidth
          sx={autocompleteResponsiveSx}
        />
      }
      <Autocomplete
        id="term-select"
        {...autocompleteStyle}
        value={selectedTerm}
        options={termsList ?? []}
        getOptionLabel={(term) => term.termName}
        renderInput={({ InputLabelProps, ...params }) => <TextField
          sx={textfieldStyle}
          {...params} label="Term" />}
        isOptionEqualToValue={(option, value) => option.termId === value.termId}
        onChange={(event: any, newValue: ITerm | null) => {
          setSearchParams({ termId: newValue?.termId ?? '' })
          dispatch(setSelectedTerm(newValue))
        }}
        fullWidth
        sx={autocompleteResponsiveSx}
      />
      {
        React.Children.map(children, (child) => (
          child
        ))
      }
    </TermHeaderContainer>
  )
}

export default TermHeader
