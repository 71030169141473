import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const TermHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    flexWrap: 'wrap'
  }
}))
