import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}
export const StyledOptionsWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.75)
}))

export const StyledStyledTextField = styled(TextField)<StyledProps>(({ theme }) => ({
  '& input:focus': {
    border: '1px solid blue'
  }
}))
export const StyledBoxWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  border: '1px solid lightgray',
  borderRadius: '50%',
  width: theme.spacing(5),
  height: theme.spacing(5)
}))
