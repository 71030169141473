import { createSlice } from '@reduxjs/toolkit'
import { createDivisionCoursesApi, deleteDivisionCoursesApi, getDivisionCoursesApi, getDivisionDepartmentApi, updateDivisionCoursesApi, uploadCourseCatalogApiViaCSV } from './api'
import { type IDivisionList, type IDivision, type Division } from '../../../models/Division'
import { notification } from '../../../components/Notifications'

export interface CourseCatalogSlice {
  isLoading: boolean
  divisionList: IDivision | null
  divisionDepartments: Division[] | null
  actionLoading: boolean
}

const initialState: CourseCatalogSlice = {
  isLoading: false,
  divisionList: null,
  divisionDepartments: null,
  actionLoading: false
}

const courseCatalogSlice = createSlice({
  name: 'courseCatalog',
  initialState,
  reducers: {
    resetState: state => {
      state.isLoading = initialState.isLoading
    }

  },
  extraReducers: (builder) => {
    builder
    // Get Division Course Catalog Api
      .addCase(getDivisionCoursesApi.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDivisionCoursesApi.fulfilled, (state, action) => {
        state.divisionList = action.payload
        state.isLoading = false
      })
      .addCase(getDivisionCoursesApi.rejected, (state, action) => {
        state.isLoading = false
      })
    // Get Division Departments Api
      .addCase(getDivisionDepartmentApi.pending, (state) => {
      })
      .addCase(getDivisionDepartmentApi.fulfilled, (state, action) => {
        state.divisionDepartments = action.payload
      })
      .addCase(getDivisionDepartmentApi.rejected, (state, action) => {
        state.isLoading = false
      })
      // Create Division Course Catalog Api
      .addCase(createDivisionCoursesApi.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(createDivisionCoursesApi.fulfilled, (state, action) => {
        state.actionLoading = false
        notification('Successfully Created Courses', { variant: 'success', subTitle: action?.meta?.arg?.data?.title })
      })
      .addCase(createDivisionCoursesApi.rejected, (state, action) => {
        state.actionLoading = false
      })
      // Update Division Course Catalog Api
      .addCase(updateDivisionCoursesApi.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(updateDivisionCoursesApi.fulfilled, (state, action) => {
        state.actionLoading = false
        notification('Successfully Updated Courses', { variant: 'success', subTitle: action?.meta?.arg?.data?.title })
      })
      .addCase(updateDivisionCoursesApi.rejected, (state, action) => {
        state.actionLoading = false
        notification(action.payload?.response?.data?.error?.error, { variant: 'error' })
      })
    // delete divison course catalog api
      .addCase(deleteDivisionCoursesApi.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(deleteDivisionCoursesApi.fulfilled, (state, action) => {
        state.actionLoading = false
        if (state.divisionList) {
          state.divisionList.data = state.divisionList?.data.filter((divison: IDivisionList) => divison.data.divisionId !== action.meta.arg.urlId)
          state.divisionList.pagination.totalResults = state.divisionList?.pagination.totalResults - 1
        }
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteDivisionCoursesApi.rejected, (state, action) => {
        state.actionLoading = false
      })
      // Upload Division Course Catalog Api
      .addCase(uploadCourseCatalogApiViaCSV.pending, (state) => {
        state.actionLoading = true
      })
      .addCase(uploadCourseCatalogApiViaCSV.fulfilled, (state, action) => {
        state.actionLoading = false
      })
      .addCase(uploadCourseCatalogApiViaCSV.rejected, (state, action) => {
        state.actionLoading = false
      })
  }
})
export const { resetState } = courseCatalogSlice.actions
export default courseCatalogSlice.reducer
