import Button, { type ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/system'
import { blue, grey } from '../../colors'
import { IconButton } from '@mui/material'

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Ghost = 'Ghost',
  Destructive = 'Destructive'
}
export enum ButtonSize {
  DoubleExtraLarge = 'xxl',
  ExtraLarge = 'xl',
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export interface StyledButtonProps extends ButtonProps {
  buttonType?: ButtonType
  buttonSize: ButtonSize
}

const primaryStyles = {
  color: grey[0],
  backgroundColor: blue[500],
  border: `1px solid ${blue[500]}`,
  boxShadow: '0px 1px 2px 0px rgba(32, 41, 57, 0.05)',
  '&:hover': {
    color: grey[0],
    border: `1px solid ${blue[700]}`,
    backgroundColor: blue[700]
  },
  '&:focus': {
    border: `1px solid ${blue[500]}`,
    backgroundColor: blue[500],
    boxShadow: `0px 0px 0px 4px ${blue[100]}, 0px 1px 2px 0px rgba(32, 41, 57, 0.05)`
  },
  '&:disabled': {
    color: grey[0],
    border: `1px solid ${blue[200]}`,
    backgroundColor: '#B9CCF9'
  }
}

const secondaryStyles = {
  color: '#364152',
  backgroundColor: grey[0],
  border: '1px solid #CDD5DF',
  boxShadow: '0px 1px 2px 0px rgba(32, 41, 57, 0.05)',
  '&:hover': {
    color: '#202939',
    border: '1px solid #CDD5DF',
    backgroundColor: '#FCFCFD'
  },
  '&:focus': {
    color: '#364152',
    border: `1px solid ${blue[300]}`,
    backgroundColor: grey[0],
    boxShadow: ' 0 0 1px 4px #DEE7FD'
  },
  '&:disabled': {
    color: '#CDD5DF',
    border: '1px solid #E3E8EF',
    backgroundColor: grey[0]
  }
}

const tertiaryStyles = {
  color: blue[500],
  backgroundColor: '#EEF3FF',
  border: '1px solid #B9CCF9',
  boxShadow: '0px 1px 2px 0px rgba(32, 41, 57, 0.05)',
  '&:hover': {
    color: '#07225F',
    border: '1px solid #B9CCF9',
    backgroundColor: '#DEE7FD'
  },
  '&:focus': {
    color: '#0F49D2',
    border: '1px solid #7EA3F6',
    backgroundColor: '#EEF3FF',
    boxShadow: `0px 0px 0px 4px ${blue[100]}, 0px 1px 2px 0px rgba(32, 41, 57, 0.05)`
  },
  '&:disabled': {
    color: '#7EA3F6',
    backgroundColor: '#F8FAFF',
    border: 'none'
  }
}

const ghostStyles = {
  color: '#4B5565',
  backgroundColor: grey[0],
  '&:hover': {
    backgroundColor: '#F8FAFC'

  },
  '&:focus': {
    backgroundColor: grey[0]
  },
  '&:disabled': {
    color: '#CDD5DF'
  }
}

const destructiveStyles = {
  color: grey[0],
  backgroundColor: '#D92D20',
  border: '1px solid #D92D20',
  boxShadow: '0px 1px 2px 0px rgba(32, 41, 57, 0.05)',
  '&:hover': {
    border: '1px solid #B42318',
    backgroundColor: '#B42318'
  },
  '&:focus': {
    border: '1px solid #D92D20',
    backgroundColor: '#D92D20',
    boxShadow: ' 0 0 1px 5px #FEE4E2'
  },
  '&:disabled': {
    color: grey[0],
    border: `1px solid #FECDCA
    `,
    backgroundColor: '#FECDCA'
  }
}

const defaultStyles = {
  color: blue[500],
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'

  },
  '&:focus': {
    backgroundColor: 'transparent'
  },
  '&:disabled': {
    color: '#CDD5DF'
  }
}

const getButtonStyles = (buttonType: ButtonType | undefined): Record<string, any> => {
  switch (buttonType) {
    case 'Primary':
      return primaryStyles
    case 'Secondary':
      return secondaryStyles
    case 'Tertiary':
      return tertiaryStyles
    case 'Ghost':
      return ghostStyles
    case 'Destructive':
      return destructiveStyles
    default:
      return defaultStyles
  }
}
const DoubleExtraLargeStyles = {
  padding: '14px 28px',
  fontSize: '18px'
}
const ExtraLargeStyles = {
  fontSize: '16px',
  padding: '12px 20px',
  height: '48px'

}
const LargeStyles = {
  fontSize: '16px',
  padding: '10px 18px',
  height: '44px'

}
const MediumStyles = {
  fontSize: '14px',
  padding: '10px 16px',
  height: '40px'

}
const SmallStyles = {
  fontSize: '14px',
  padding: '8px 14px',
  height: '36px'

}

const getButtonSize = (buttonSize: ButtonSize): Record<string, any> => {
  switch (buttonSize) {
    case 'xxl':
      return DoubleExtraLargeStyles
    case 'xl':
      return ExtraLargeStyles
    case 'Large':
      return LargeStyles
    case 'Medium':
      return MediumStyles
    case 'Small':
      return SmallStyles
    default:
      return ExtraLargeStyles
  }
}
export const StyledButton = styled(Button)<StyledButtonProps>(({ theme, buttonType, buttonSize }) => ({
  textTransform: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  padding: `${theme.spacing(4)} ${theme.spacing(7)}`,
  ...getButtonStyles(buttonType),
  ...getButtonSize(buttonSize)
}))

export const StyledIconButton = styled(IconButton)<StyledButtonProps>(({ theme, buttonType, buttonSize }) => ({
  textTransform: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  padding: `${theme.spacing(4)} ${theme.spacing(7)}`,
  ...getButtonStyles(buttonType),
  ...getButtonSize(buttonSize)
}))

export default StyledButton
