import type { ListResponse, ReportHierarchyRowData } from './../../../features/ReportHierarchyFeature/types'
import createApiThunk from '../../../ApplicationFactory'
import { type BreadcrumbT } from '../../../components/CustomBreadcrumbs'
import { type DataObject } from '../../../models/DataObject'
import { type Division } from '../../../models/Division'

export const getDivisionDetails = createApiThunk<ReportHierarchyRowData>('getDivisionDetails', {
  url: '/api/divisions/<urlId>',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const getDivisionList = createApiThunk<ListResponse>('getDivisionList', {
  url: '/api/divisions/children/<urlId>',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const editDivision = createApiThunk<DataObject<Partial<Division>>>('editDivision', {
  url: '/api/divisions/<urlId>',
  method: 'PATCH',
  headers: {
    Accept: 'application/cdg'
  }
})

export const deleteDivisionApi = createApiThunk<{ message: string }>('deleteDivisionApi', {
  url: '/api/divisions/<urlId>',
  method: 'DELETE'
})

export const archiveDivisionApi = createApiThunk<{ message: string }>('archiveDivisionApi', {
  url: '/api/divisions/<urlId>/archive',
  method: 'POST'
})

export const createDivision = createApiThunk<ListResponse>('submitCreateNewDivision', {
  url: '/api/divisions/',
  method: 'POST',
  headers: {
    Accept: 'application/cdg'
  }
})

export const getBreadcrumbList = createApiThunk<BreadcrumbT[]>('breadcrumb', {
  // url: '/api/divisions/path/',
  url: '/api/divisions/path/<urlId>',
  method: 'GET'
})
