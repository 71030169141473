/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form'
import FormInput from '../../../components/FormElements/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '../../../store'
import * as yup from 'yup'
import { useTheme } from '@mui/material/styles'
import { type ReportHierarchyRowData } from '../types'
import { createDivision, editDivision, getDivisionList } from '../../../store/slices/reportHierarchySlice/apis'
import { divisionList, isCreateEditDivisionLoading } from '../../../store/slices/reportHierarchySlice/selectors'
import ValidationAlert from '../../../components/ValidationAlert'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'
import { getDirtyValues } from '../../../utils/onlyUpdatedFields'

interface FormType {
  title: string
  abbreviation: string
  categoryName: string
  parent: string
}

interface CreateEditDivisionProps {
  selectedRow?: ReportHierarchyRowData
  handleCloseModal?: () => void
}

export const CreateEditDivision: React.FC<CreateEditDivisionProps> = ({
  selectedRow,
  handleCloseModal = () => { }
}: CreateEditDivisionProps): JSX.Element => {
  const [errors, setErrors] = useState<unknown>({})

  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isEditing = selectedRow
  const list = divisionList()
  const loading = isCreateEditDivisionLoading()

  const { title, abbreviation, categoryName, parent } = selectedRow ?? {}

  const distributionFormSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    abbreviation: yup.string().required('Abbreviation is required'),
    categoryName: yup.string().required('Unit type is required'),
    parent: yup.string().required('Parent Unit is required')
  })

  const defaultParent = list?.data?.[0].data.parent
  const defaultCategoryName = list?.data?.[0].data?.categoryName
  const defaultCategoryId = list?.data?.[0].data?.categoryId

  const methods = useForm<FormType>({
    resolver: yupResolver(distributionFormSchema),
    defaultValues: {
      title: title ?? '',
      abbreviation: abbreviation ?? '',
      categoryName: categoryName ?? defaultCategoryName,
      parent: parent?.title ?? defaultParent?.title
    }
  })
  const { handleSubmit, control, formState: { isDirty, dirtyFields, isSubmitting } } = methods

  const onSubmit: SubmitHandler<FormType> = async (data: FormType, e: any) => {
    try {
      if (isEditing) {
        const updatedVals = getDirtyValues(dirtyFields, data)
        await dispatch(editDivision({ urlId: selectedRow?.divisionId, data: updatedVals })).unwrap()
      } else {
        const createdData = {
          ...data,
          categoryId: defaultCategoryId,
          parent: parent?.divisionId ?? defaultParent?.divisionId
        }
        await dispatch(createDivision({ data: createdData })).unwrap()
      }

      handleCloseModal?.()
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <ValidationAlert errors={errors} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' flexDirection='column' gap={theme.spacing(6)}>
          <FormInput
            name="title"
            label="Title"
            control={control}
            required
          />
          <FormInput
            name="abbreviation"
            label="Abbreviation"
            control={control}
            required
          />
          <FormInput
            name="categoryName"
            label="Unit type"
            control={control}
            required
            disabled
          />
          <FormInput
            name="parent"
            label="Parent Unit "
            control={control}
            required
            disabled
          />
        </Box>

        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditing ? 'Save' : 'Create',
            loading,
            type: 'submit',
            disabled: isSubmitting || (isEditing && !isDirty)
          }}
        />
      </form>
    </FormProvider>
  )
}

export default CreateEditDivision
