import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import GenericButton from '../../../../components/GenericButton'
import { ButtonType } from '../../../../components/GenericButton/styles'
import { Box } from '@mui/material'
import { ItemTypeMappings } from '../../types'
import { setSelectedQuestion } from '../../../../store/slices/SurveyTemplateSlice'
import { StyledMenu, StyledMenuItem } from './styles'

interface SurveyItemDropdownProps {
  sectionNumber: number
}

const SurveyItemDropdown: React.FC<SurveyItemDropdownProps> = ({ sectionNumber }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const dispatch = useDispatch()

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => { setAnchorEl(null) }

  const handleMenuClick = (item: string): void => {
    const selectedValue = ItemTypeMappings[item]
    dispatch(setSelectedQuestion({ sectionIndex: sectionNumber, questionType: selectedValue }))
    handleClose()
  }

  const itemKeys = Object.keys(ItemTypeMappings)

  return (
    <Box display='flex' justifyContent='center' mt={'24px'}>
      <GenericButton
        buttonType={ButtonType.Primary}
        onClick={handleClick}
        label='Add Question'
        startIcon={<AddIcon />}

      />

      <StyledMenu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {itemKeys.map((item: string, index: number) => {
          return (
            <StyledMenuItem key={index} onClick={() => { handleMenuClick(item) }} disableRipple>
              {item}
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </Box>
  )
}

export default SurveyItemDropdown
