// https://github.com/orgs/react-hook-form/discussions/1991
// this method is to only send changed fields

export function getDirtyValues<DirtyFields extends Record<string, unknown>, Values extends Partial<Record<keyof DirtyFields, unknown>>> (
  dirtyFields: DirtyFields,
  values: Values
): Partial<typeof values> {
  // dirtyFields: dirtyFields object coming from react-hook-form
  // values: data object from react-hook-form onSubmit method
  // returns -> mapping of changed field to its changed value
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key]
    }
  }, {})

  return dirtyValues
}
