import React, { useState } from 'react'
import { blue } from '../../colors'
import { Button } from '@mui/material'
import { SkipNavigationWrapper, StyledTypography } from './styles'

interface SkipNavigationProps {
  targetId?: string
}

const SkipNavigation: React.FC<SkipNavigationProps> = ({ targetId = 'main-content' }) => {
  const handleClick = (): void => {
    const targetElement = document.getElementById(targetId)
    if (targetElement) {
      targetElement.tabIndex = -1
      targetElement.focus()
    }
  }

  const [focused, setFocused] = useState(false)

  const handleFocus = (): void => {
    setFocused(true)
  }

  const handleBlur = (): void => {
    setFocused(false)
  }

  return (
    <SkipNavigationWrapper focused={focused} elevation={3} >
      <StyledTypography>Skip To:</StyledTypography>
      <Button disableRipple onBlur={handleBlur} onFocus={handleFocus} tabIndex={1110} onClick={handleClick} sx={{ color: blue[500], padding: '2px 0px', textDecoration: 'underline', fontSize: '14px', fontWeight: '500' }}>
        Skip Navigation
      </Button>
    </SkipNavigationWrapper>
  )
}

export default SkipNavigation
