import React, { lazy, Suspense } from 'react'
import Box from '@mui/material/Box'
import LoadingPage from '../../pages/LoadingPage'
import type { ListResponse, ColumnDataType } from './types'
import CreateNewAcademicUnit from './CreateNewAcademicUnit'
import { type GridSortItem } from '@mui/x-data-grid'
import DeleteAcademicUnit from './DeleteAcademicUnit'
import PageHeader from '../../components/PageHeader'
import PAGE_TITLES from '../../consts/titles'
import { type CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import { exportCsv } from '../../utils/exportCsv'
import { useAppDispatch } from '../../store'
import api from '../../utils/api'
import { notification } from '../../components/Notifications'
interface AcademicUnitProps {
  list: ListResponse
  loading: boolean
  breadcrumb: CustomBreadcrumbT[]
  onSortModelChange: (sortModel: GridSortItem) => void
}

const AppTable = lazy(async () => await import('../../components/CustomTable'))
const AcademicUnitsFeature: React.FC<AcademicUnitProps> = ({ list, loading, breadcrumb, onSortModelChange }): JSX.Element => {
  // @TODO need to remove from HERE,  needs to send this value from BE
  const dispatch = useAppDispatch()

  const rows: Array<Record<string, any>> = list?.data.map((item) => ({
    ...item,
    data: {
      ...item?.data,
      children: item?.data?.children.map((child) => child.categoryName)
    }
  }))

  const columns: ColumnDataType = list.keys
  const headerName: string = list?.header?.headerName

  const extraColumnProps = {
    width: 260
  }

  const extraModalProps = {
    width: 'sm'
  }

  const handleExport = async (): Promise<void> => {
    try {
      const response = await api.get('/api/divisions/export/')
      const data = response?.data

      if (data) {
        exportCsv(data, 'academic-units_export.csv')
      }
    } catch (error: any) {
      notification(error?.response?.data?.error ?? 'Internal server error', { variant: 'error' })
    }
  }

  return (
    <Box width={'100%'}>
      <PageHeader
        title={PAGE_TITLES.ACADEMIC_UNITS}
        breadcrumb={breadcrumb}
      />

      <Suspense fallback={<LoadingPage />}>
        {columns !== undefined && rows !== undefined &&
          <AppTable
            loading={loading}
            topbarTitle={headerName}
            createActionTitle='Create New Unit'
            createAction={<CreateNewAcademicUnit />}
            exportCsv={() => { void handleExport() }}
            rows={rows}
            onSortModelChange={onSortModelChange}
            columns={columns}
            editAction={<CreateNewAcademicUnit />}
            deleteAction={<DeleteAcademicUnit />}
            featureName="Unit"
            extraColumnProps={extraColumnProps}
            extraModalProps={extraModalProps}
            actionModalEntityDisplayField='categoryName'
          />
        }
      </Suspense>
    </Box >
  )
}

export default AcademicUnitsFeature
