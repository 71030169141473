
import { type ISurveySection } from '../../store/slices/SurveyTemplateSlice'
import { type DescriptiveQuestion, SurveyItemQuestionType, type LinearQuestion, type MultiChoiceQuestion, type Question, type FreeResponseQuestion, type ILinearObject } from './types'

export const removeInvalidBorderColor = (id: string): boolean => {
  const element = document.getElementById(id)
  if (element !== null) {
    element.style.borderColor = '#D5D9DC'
  }
  return true
}

export const scrollToInvalidSection = (sectionIndex: string): boolean => {
  const element = document.getElementById(sectionIndex)
  if (element !== null) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
    element.style.borderColor = 'red'
  }
  return true
}

export const findInvalidSectionIndex = (sections: ISurveySection[]): number => {
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    const surveyItems = section?.surveyItems
    if (!Array.isArray(surveyItems) || surveyItems.length === 0) {
      return i
    }
    removeInvalidBorderColor(i.toString())
  }
  return -1
}

const multiChoiceQuestionValidation = (question: MultiChoiceQuestion): boolean => {
  const validOptions = question?.object?.every((option: string) => option !== '')
  if (!validOptions || question?.prompt === '') {
    return false
  }

  return true
}

const linearQuestionValidation = (question: LinearQuestion): boolean => {
  const validOptions = question?.object?.every((option: ILinearObject) => option?.option !== '')
  if (!validOptions || question?.prompt === '') {
    return false
  }
  return true
}

const descriptiveQuestionValidation = (question: DescriptiveQuestion): boolean => {
  if (question.prompt === '') {
    return false
  }
  return true
}

const freeResponseQuestionValidation = (question: FreeResponseQuestion): boolean => {
  if (question?.prompt === '') {
    return false
  }
  return true
}

const checkQuestionValidation = (question: Question): boolean => {
  switch (question?.itemType) {
    case SurveyItemQuestionType.MULTIPLE_CHOICE:
      return multiChoiceQuestionValidation(question as MultiChoiceQuestion)
    case SurveyItemQuestionType.LINEAR_SCALE:
      return linearQuestionValidation(question as LinearQuestion)
    case SurveyItemQuestionType.FREE_RESPONSE:
      return freeResponseQuestionValidation(question as FreeResponseQuestion)
    case SurveyItemQuestionType.DESCRIPTIVE_TEXT:
      return descriptiveQuestionValidation(question as DescriptiveQuestion)
    default:
      return true
  }
}

export const findInvalidQuestion = (sections: ISurveySection[]): { sectionIndex: number, questionIndex: number } | null => {
  for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
    const section = sections[sectionIndex]
    if (!section.surveyItems) continue

    const questionIndex = section.surveyItems.findIndex(question => !checkQuestionValidation(question))
    if (questionIndex !== -1) {
      return { sectionIndex, questionIndex }
    }
  }
  return null
}
