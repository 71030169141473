import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}
export const StyledDropdownWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  minWidth: 120,
  flex: 1.5
}))

export const StyledMenuItem = styled(MenuItem)<StyledProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.5)
}))
