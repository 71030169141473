import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'

interface WrapperProps {
  theme?: Theme
}

export const Wrapper = styled(Box)<WrapperProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  position: 'absolute',
  right: '-66px',
  top: '0'
}))
