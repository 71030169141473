import React, { useState } from 'react'
import { actionLoading } from '../../../../store/slices/termsSectionsSlice/selectors'
import GenericModal from '../../../../components/GenericModal'
import Dropdown from '../../../../components/Dropdown'
import DragAndDrop from '../../../../components/DragAndDropUpload'
import { CreateAndEditTermsSection } from '../CreateAndEditTermsSection'
import { useAppDispatch } from '../../../../store'
import { getTermsSectionsApi, uploadTermsSectionApiViaCSV } from '../../../../store/slices/termsSectionsSlice/api'
import { type IUser } from '../../../../models/User'

interface TermsTopbarActionsProps {
  params: Record<string, any>
}

const TermsTopbarActions: React.FC<TermsTopbarActionsProps> = ({ params }) => {
  const [isUploadModal, setIsUploadModal] = useState<boolean>(false)
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const [alertType, setAlertType] = useState<string | null>(null)
  const [validationError, setValidationError] = useState<string | null>(null)
  const [taskId, setTaskId] = useState<string>('')

  const dispatch = useAppDispatch()
  const isUploadloading = actionLoading()

  const closeUploadModal = (): void => {
    setIsUploadModal(false)
    setAlertType(null)
  }

  const handleFile = (file: File): void => {
    setFile(file)
  }

  const handleUpload = async (): Promise<void> => {
    if (file) {
      try {
        await dispatch(uploadTermsSectionApiViaCSV({ data: file, params: { termId: params?.termId } })).then(async (res: any) => {
          if (res.payload?.taskId) {
            setTaskId(res.payload?.taskId)
            setAlertType('success')
            await dispatch(getTermsSectionsApi({ params }))
          } else {
            setValidationError(res?.payload)
            setAlertType('warning')
          }
        })
      } catch (error) {
        console.error('Error uploading file', error)
      }
    }
  }

  const uploadApi = (): void => {
    setIsUploadModal(true)
  }

  const addManually = (): void => {
    setIsCreateModal(true)
  }

  const menuItems = [
    { text: 'Upload CSV', action: uploadApi },
    { text: 'Add Manually', action: addManually }
  ]

  const config = {
    headers: [
      { name: 'Title', type: 'string', required: true },
      { name: 'Course ID', type: 'string', required: true },
      { name: 'Section ID', type: 'string', required: true },
      { name: 'Start Date', type: 'string', required: true },
      { name: 'End Date', type: 'string', required: true }
    ]
  }

  const modalTitle = alertType ? 'Upload complete' : 'Upload courses'

  return (
    <>
      <Dropdown menuItems={menuItems} label='Add Course Sections'/>
      <GenericModal
        isOpen={isCreateModal}
        title={'Create Course Section'}
        onClose={() => { setIsCreateModal(false) }}
        maxWidth='sm'
        action={false}
      >
        <CreateAndEditTermsSection params={params} handleCloseModal={() => { setIsCreateModal(false) }} />
      </GenericModal>
      <GenericModal action={false} maxWidth='sm' title={modalTitle} showCloseIcon={false} isOpen={isUploadModal} onClose={closeUploadModal}>
        <DragAndDrop taskId={taskId} onDrop={handleFile} onClose={closeUploadModal} onConfirm={handleUpload} loading={isUploadloading} alertType={alertType} validationConfig={config} validationErrorBE={validationError} />
      </GenericModal>
    </>
  )
}

export default TermsTopbarActions
