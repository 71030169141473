import Box from '@mui/material/Box'
import type { Theme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import AlertTitle from '@mui/material/AlertTitle'
import { grey, shade, yellow } from '../../../colors'
import { SlotStatus } from '../../../models/DistributionPlan'

interface headingProps {
  theme?: Theme
}

export const Heading = styled(AlertTitle)<headingProps>(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  margin: 0
}))

interface WrapperProps {
  theme?: Theme
  status?: string
}

export const Wrapper = styled(Box)<WrapperProps>(({ theme, status }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  borderRadius: '12px',
  border: `1px solid ${status === SlotStatus.Draft ? yellow[400] : grey[350]}`,
  background: status === SlotStatus.Draft ? shade[50] : grey[750],
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`
}))

interface SubHeadingProps {
  theme?: Theme
}

export const SubHeading = styled(AlertTitle)<SubHeadingProps>(({ theme }) => ({
  fontSize: '14px',
  color: grey[850]
}))
