import React from 'react'
import { CardHeader, Card, CardContent, FormControl, Typography } from '@mui/material'
import type { RadioButtonGroupProps, FieldValues } from 'react-hook-form-mui'
import CustomRadioButtonGroup, { getId } from '../../../../components/CustomRadioButtonGroup'
import { InvalidQuestionError, OptionalLabel } from '../../styles'

interface MultipleChoiceCardProps extends Omit<RadioButtonGroupProps<FieldValues>, 'options'> {
  title: string
  options: string[]
  questionNumber: number
  invalidQuestionItemId: string | undefined
  isRequired: boolean
}

const MultipleChoiceCard: React.FC<MultipleChoiceCardProps> = (
  {
    title,
    name,
    options,
    disabled = false,
    questionNumber,
    isRequired,
    invalidQuestionItemId,
    ...rest
  }
): JSX.Element => {
  return (
    <Card tabIndex={0} aria-label={`Survey Question ${questionNumber} ${title}`} id={name}>
      {
        !isRequired && <OptionalLabel>{!isRequired && 'Optional'}</OptionalLabel>
      }
      {
        invalidQuestionItemId && invalidQuestionItemId === name && <InvalidQuestionError>*Response required. Please answer and submit again.</InvalidQuestionError>
      }
      <CardHeader title={
        <Typography variant="h5" component="h3" color={disabled ? 'text.secondary' : 'text.primary'} >
          {questionNumber}. {title}
        </Typography>
      } />
      <CardContent>
        <FormControl aria-label={title} aria-owns={options.map((value) => getId(name, value.toString())).join(' ')}>
          <CustomRadioButtonGroup
            name={name}
            type="string"
            options={options.map((value) => {
              return {
                id: value,
                label: value
              }
            })}
            {...rest} />
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default MultipleChoiceCard
