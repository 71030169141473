export const formatDate = (dateString: string): string => {
  return new Date(Date.parse(dateString))?.toLocaleDateString('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'short'
  })
}

export const getUserFullName = (userAttributes: any): string => {
  return `${userAttributes.given_name as string} ${userAttributes.family_name as string}`
}
