// convert object to string and store in localStorage
export function saveToLocalStorage<T> (state: T, storageKey: string): void {
  try {
    const serialisedState = JSON.stringify(state)
    localStorage.setItem(storageKey, serialisedState)
  } catch (e) {
    console.warn(e)
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
export function loadFromLocalStorage<T> (storageKey: string): T | undefined {
  try {
    const serialisedState = localStorage.getItem(storageKey)
    if (serialisedState === null) return undefined
    return JSON.parse(serialisedState)
  } catch (e) {
    return undefined
  }
}
