import React from 'react'
import { ButtonType } from '../../../../components/GenericButton/styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../../store'
import { grey } from '../../../../colors'
import { deleteSectionApi, getSlotSectionApi } from '../../../../store/slices/distributionPlanSlice/apis'
import { deleteLoading } from '../../../../store/slices/distributionPlanSlice/selectors'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import { useParams } from 'react-router-dom'
import { type ISection } from '../../../../models/TermsSection'
import { type ApplyFiltersAndDispatchParams } from '../../../../components/CustomTable/types'

interface CourseDeleteProps {
  selectedRow?: ISection
  handleCloseModal?: () => void
  showErrorModal?: () => void
  setErrorMessage?: (arg: string) => void
  params?: ApplyFiltersAndDispatchParams
}

const CourseDelete: React.FC<CourseDeleteProps> = ({ selectedRow, handleCloseModal, showErrorModal, setErrorMessage, params }) => {
  const dispatch = useAppDispatch()
  const isDeleteLoading = deleteLoading()
  const theme = useTheme()
  const { id } = useParams()

  const handleDelete = async (): Promise<void> => {
    try {
      if (id && selectedRow?.sectionId) {
        await dispatch(deleteSectionApi({ urlIds: [id, selectedRow?.sectionId] })).unwrap().then(() => { handleCloseModal?.() })
        void dispatch(getSlotSectionApi({ urlId: id, params }))
      }
    } catch (error: any) {
      if (error && error.response?.status === 422) {
        const errorMessage = error.response?.data?.error
        setErrorMessage?.(errorMessage)
        showErrorModal?.()
      }
    }
  }

  return (
    <>
      <Typography fontSize='14px' lineHeight={theme.spacing(5)} color={grey[850]}>
        This does <b>NOT</b> delete the underlying Course Section. You can reassign this Course Section to this Survey Distribution later.
      </Typography>
      <DefaultModalActions
        confirmButtonProps={{
          onClick: handleDelete,
          text: 'Delete',
          loading: isDeleteLoading,
          buttonType: ButtonType.Destructive
        }}
        destructiveButtonProps={{
          onClick: handleCloseModal
        }}
      />
    </>

  )
}

export default CourseDelete
