import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import GenericButton from '../../../../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../../../../components/GenericButton/styles'
import { useAppDispatch } from '../../../../../../store'
import { setEditableQuestion } from '../../../../../../store/slices/SurveyTemplateSlice'

interface CreateQuestionCardDropDownMenuProps {
  handleDelete: () => void
}

const CreateQuestionCardDropDownMenu: React.FC<CreateQuestionCardDropDownMenuProps> = ({ handleDelete }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dispatch = useAppDispatch()

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleDeleteClick = (): void => {
    dispatch(setEditableQuestion(null))
    setAnchorEl(null)
    handleDelete()
  }

  return (
    <>
      <GenericButton
        buttonType={ButtonType.Ghost}
        buttonSize={ButtonSize.Small}
        icon={<MoreVertIcon />}
        onClick={handleClick}
        style={{
          background: 'transparent'
        }} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </>
  )
}

export default CreateQuestionCardDropDownMenu
