import React, { type CSSProperties } from 'react'
import StyledButton, { ButtonSize, ButtonType, StyledIconButton } from './styles'
import { CircularProgress, type CircularProgressProps } from '@mui/material'
import { blue, grey } from '../../colors'

interface GenericButtonProps {
  ref?: React.Ref<any>
  variant?: 'text' | 'outlined' | 'contained' | undefined
  label?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  buttonType?: ButtonType
  disabled?: boolean
  buttonSize?: ButtonSize
  icon?: React.ReactNode
  textTransform?: string
  fontWeight?: string
  fullWidth?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  style?: CSSProperties
  loading?: boolean
  circularProgressStyles?: CSSProperties
  circularProgressProps?: CircularProgressProps
}
// Generic Button is responsible for rendering IconButton or Button
// Button have few variants like Button can have start or end icon along with label
// And Icon can have only icon without any text
// If label is not passed and icon is passed then Generic Button will return IconButton instead of Button
// https://mui.com/material-ui/react-button/

const GenericButton: React.FC<GenericButtonProps> = ({
  ref,
  label,
  startIcon,
  endIcon,
  onClick,
  disabled = false,
  buttonType,
  buttonSize = ButtonSize.Large,
  icon,
  textTransform = 'none',
  fontWeight = 400,
  fullWidth,
  type,
  style,
  loading = false,
  circularProgressStyles,
  circularProgressProps,
  ...props
}) => {
  const getCircularProgressSize = (buttonSize: ButtonSize): number => {
    switch (buttonSize) {
      case 'xxl':
        return 36
      case 'xl':
        return 32
      case 'Large':
        return 28
      case 'Medium':
        return 24
      case 'Small':
        return 20
      default:
        return 20
    }
  }

  const circularProgressColor = buttonType === ButtonType.Primary || buttonType === ButtonType.Destructive ? grey[0] : blue[500]
  const iconButtonIcon = loading ? <CircularProgress thickness={5} size={getCircularProgressSize(buttonSize)} {...circularProgressProps} sx={{ color: circularProgressColor, ...circularProgressStyles }}/> : icon

  return (
    (label != null && !loading)
      ? <StyledButton
      fullWidth={fullWidth}
      type={type}
      sx={{ textTransform, fontWeight, ...style }} // Using property shorthand for textTransform
      buttonSize={buttonSize} // No change needed for buttonSize
      disableRipple
      buttonType={buttonType}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      color={'success'}
      disabled={disabled}
      {...props}
    >
      {label}
    </StyledButton >

      : <StyledIconButton ref={ref} fullWidth={fullWidth} style={style } type={type} buttonSize={buttonSize} disableRipple buttonType={buttonType} onClick={loading ? undefined : onClick}disabled={disabled} {...props}>
        {iconButtonIcon}
      </StyledIconButton>
  )
}

export default GenericButton
