import { isEmpty } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { saveToLocalStorage, loadFromLocalStorage } from '../../../utils/localstorage'
import { type ConfigSliceType } from '.'
import useProfile from '../../../hooks/useProfile'

const CONFIG_STORAGE_KEY = 'config_settings'

export const configResetState = createAsyncThunk(
  'config/resetLocalStorage',
  async (_, { rejectWithValue }) => {
    const { user } = useProfile()
    const id: string = user?.user_id ?? ''

    if (isEmpty(id)) {
      rejectWithValue('failed to find user to use save config with')
    }

    const storageKey = `${CONFIG_STORAGE_KEY}_${id.toString()}`
    localStorage.removeItem(storageKey)
  }
)

export const configSaveState = createAsyncThunk(
  'config/saveToLocalStorage',
  async (config: Partial<ConfigSliceType>, { rejectWithValue }) => {
    const { user } = useProfile()
    const id: string = user?.user_id ?? ''

    if (isEmpty(id)) {
      rejectWithValue('failed to find user to use save config with')
      return null
    }

    console.log(config)
    const storageKey = `${CONFIG_STORAGE_KEY}_${id.toString()}`
    saveToLocalStorage(config, storageKey)
    return config
  }
)

export const configLoadState = createAsyncThunk(
  'config/loadFromLocalStorage',
  async (_, { rejectWithValue }) => {
    const { user } = useProfile()
    const id: string = user?.user_id ?? ''

    if (isEmpty(id)) {
      rejectWithValue('failed to find user to load save config with')
      return null
    }

    const storageKey = `${CONFIG_STORAGE_KEY}_${id.toString()}`
    return await loadFromLocalStorage(storageKey) ?? null
  }
)
