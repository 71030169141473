import React from 'react'
import CoursesAndEnrollment from '../../features/CoursesAndEnrollment'

const Page = (): JSX.Element => {
  return (
    <CoursesAndEnrollment />
  )
}

export default Page
