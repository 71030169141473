import React from 'react'
import { Box, Typography } from '@mui/material'
import { grey } from '../../../colors'
import { useTheme } from '@mui/material/styles'

const NoPermissionCard = (): JSX.Element => {
  const theme = useTheme()
  return (
        <Box display='flex' justifyContent='center' alignItems='center' height={`calc(100vh - ${theme.spacing(66)})`}>
            <Box
             sx={{
               width: `clamp(${theme.spacing(100)}, 30%,${theme.spacing(150)})`
             }}
            >
                <Typography fontSize={'40px'} fontWeight={'600'} color= {grey[250]}>You currently don’t have permissions to use ClassRanked.</Typography>
                <Typography mt={theme.spacing(6)} fontSize={'14px'} color={grey[850]} >If this is a mistake, please reach out to your course evaluation administrator.</Typography>
            </Box>
        </Box>
  )
}

export default NoPermissionCard
