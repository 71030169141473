import { type IEditableQuestion, type ISelectedQuestion, type ISurveySection } from '.'
import { useAppSelector } from '../..'
import { type ISurveyTemplate } from '../../../models/SurveyTemplate'

export const getSurveyTitle = (): string => useAppSelector((state) => state.surveyTemplate?.title)
export const getSurveySections = (): ISurveySection[] => useAppSelector((state) => state.surveyTemplate?.surveySections)
export const getSelectedQuestion = (): ISelectedQuestion => useAppSelector((state) => state.surveyTemplate?.selectedQuestion)
export const getEditableQuestion = (): IEditableQuestion => useAppSelector((state) => state.surveyTemplate?.editableQuestion)
export const getEditableSurvey = (): ISurveyTemplate => useAppSelector((state) => state.surveyTemplate?.editableSurvey)
export const getLoading = (): boolean => useAppSelector((state) => state.surveyTemplate?.loading)
export const getActionLoading = (): boolean => useAppSelector((state) => state.surveyTemplate?.actionLoading)
