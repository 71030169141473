import React from 'react'
import { Wrapper } from './styles'
import GenericButton from '../../../../components/GenericButton'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { ButtonType } from '../../../../components/GenericButton/styles'
import { useAppDispatch } from '../../../../store'
import { removeSection, moveSectionUp, moveSectionDown } from '../../../../store/slices/SurveyTemplateSlice'
import { useTheme } from '@mui/material/styles'

interface SectionActionProps {
  sectionNumber: number
  totalSections: number
}

const SectionAction: React.FC<SectionActionProps> = ({ sectionNumber, totalSections }) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const handleDeleteSection = (): void => {
    dispatch(removeSection({ sectionNumber }))
  }

  const handleUp = (): void => {
    dispatch(moveSectionUp({ sectionNumber }))
  }

  const handleDown = (): void => {
    dispatch(moveSectionDown({ sectionNumber }))
  }
  const iconStyle = {
    width: theme.spacing(12),
    height: theme.spacing(12)
  }

  return (
    <Wrapper>
      <GenericButton
        onClick={handleDeleteSection}
        icon={<DeleteOutlineOutlinedIcon />}
        buttonType={ButtonType.Secondary}
        disabled={totalSections === 1}
        style={iconStyle}
      />

      <GenericButton
        onClick={handleUp}
        icon={<KeyboardArrowUpRoundedIcon />}
        buttonType={ButtonType.Secondary}
        style={iconStyle}
        disabled={sectionNumber === 0}
      />

      <GenericButton
        onClick={handleDown}
        icon={<KeyboardArrowDownRoundedIcon />}
        buttonType={ButtonType.Secondary}
        style={iconStyle}
        disabled={sectionNumber + 1 === totalSections}
      />
    </Wrapper>
  )
}

export default SectionAction
