import React, { useMemo } from 'react'
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material'
import { useFormContext, Controller, type FieldValues } from 'react-hook-form'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { debounce, isEmpty } from 'lodash'

interface GenericAutocompleteProps<T> {
  name: string
  label: string
  options: T[]
  readOnly?: boolean
  placeholder?: string
  defaultValue?: T
  getOptionLabel: (option: T) => string
  getOptionValue?: (option: T) => any
  onChange?: (selectedValue: T | null) => void
  required?: boolean
  loading?: boolean
  fullWidth?: boolean
  size?: 'small' | 'medium'
  selectedValue?: string
  onInputChange?: (value: string) => void
}

const GenericAutocomplete = <T,>({
  name,
  label,
  options = [],
  readOnly,
  placeholder,
  defaultValue,
  getOptionLabel,
  getOptionValue = () => {},
  onChange,
  required,
  loading,
  fullWidth = true,
  size = 'small',
  selectedValue,
  onInputChange = () => {},
  ...rest
}: GenericAutocompleteProps<T>): JSX.Element => {
  // cannot render a value if no options
  if (isEmpty(options)) {
    return <TextField placeholder={placeholder} fullWidth={fullWidth} variant="outlined" />
  }

  const { control } = useFormContext<FieldValues>()

  const getDebouncedInput = useMemo(
    () => debounce(onInputChange, 500),
    [onInputChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onFieldChange, onBlur, value: fieldValue }, fieldState: { error } }) => {
        const value = selectedValue ?? fieldValue
        const errorMessage = error?.message ?? false

        const foundVal = options?.find((option: T) => {
          return getOptionValue(option) === value
        })

        return (
          <Box>
          {
            label && <InputLabel sx={{ marginBottom: 2 }} required={required}>{label}</InputLabel>
          }
            <Autocomplete
              loading={loading}
              size={size}
              defaultValue={defaultValue ?? foundVal}
              value={foundVal}
              options={options}
              disabled={readOnly}
              popupIcon={<KeyboardArrowDownIcon />}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={(option, value) => getOptionValue(option) === value}
              onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                  getDebouncedInput(value)
                }
              }}
              renderInput={(params) => {
                return (
                  <TextField placeholder={placeholder}
                  {...params} fullWidth={fullWidth} variant="outlined" onBlur={onBlur} helperText={errorMessage} error={Boolean(errorMessage)} />
                )
              }}
              onChange={(event, value: T | null) => {
                if (getOptionValue != null && value !== null) {
                  const selectedValue = getOptionValue(value)
                  onFieldChange(selectedValue)

                  if (onChange != null) {
                    onChange(value)
                  }
                }
              }}
              {...rest}
            />
          </Box>
        )
      }}
    />
  )
}

export default GenericAutocomplete
