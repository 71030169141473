import React from 'react'
import type PAGE_TITLES from '../../consts/titles'
import NotFoundFeature from '../../features/NotFoundFeature'

export interface NotFoundPageProps {
  title?: PAGE_TITLES
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  title
}): JSX.Element => {
  return (
    <NotFoundFeature title={title} />
  )
}

export default NotFoundPage
