import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import type { Theme } from '@mui/system'
import { blue, green, grey, red, shade } from './colors'

const defaultPaddings = (theme: Theme): Record<string, any> => ({
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
    '&:last-child': {
      paddingBottom: theme.spacing(4)
    }
  },
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(5)} ${theme.spacing(5)} 0 ${theme.spacing(5)}`,
    '&:last-child': {
      paddingBottom: theme.spacing(5)
    }
  }
})

let CustomTheme = createTheme({
  typography: {
    fontFamily: '\'Inter\', sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    backgroudColor: grey[100],
    borderColor: grey[200],
    iconColor: grey[500],
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[600]
      // contrastText: grey[300]
    },
    secondary: {
      light: '#FFFFFF',
      main: '',
      dark: '#122B78',
      contrastText: 'rgba(0, 0, 0, 0.6)'
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF'
    },
    action: {
      // active: '#1F5EEF',
      // hover: '#1F5EEF',
      // hoverOpacity: 1,
      // selected: '#1F5EEF',
      // selectedOpacity: 1,
      disabled: '#7E8E9A'
      // disabledOpacity: 1,
      // disabledBackground: 'inherit'
      // focus: string;
      // focusOpacity: number;
      // activatedOpacity: number;
    },
    text: {
      primary: grey[800],
      secondary: grey[700],
      disabled: grey[600]
    }
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          strokeLinecap: 'round',
          animationDuration: '3s',
          strokeDasharray: '10, 80'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-display: swap;
          src: local('Inter');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    }
  },
  spacing: (f: number) => `${0.25 * f}rem`
})

declare module '@mui/material/styles' {

  interface Palette {
    borderColor: string
  }
  interface PaletteOptions {
    borderColor: string
  }
  interface Palette {
    iconColor: string
  }
  interface PaletteOptions {
    iconColor: string
  }

  interface Palette {
    backgroudColor: string
  }
  interface PaletteOptions {
    backgroudColor: string
  }
  interface TypographyVariants {
    poster: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties
  }
}

// declare module '@mui/material/styles/createMixins' {
//   interface Mixins {
//     centerPage?: React.CSSProperties
//   }
// }

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true
    p1: true
    p2: true
    p3: true
    p4: true
    label: true
  }
}

CustomTheme = responsiveFontSizes(createTheme(CustomTheme, {
  // mixins: {
  //   centerPage: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     height: `calc(100vh - ${useAppBarHeight(CustomTheme)}px)`
  //   }
  // },
  shape: {
    borderRadius: '6px'
  },
  typography: {
    poster: {
      ...CustomTheme.typography.h1,
      fontWeight: 500,
      letterSpacing: '0.01em',
      color: CustomTheme.palette.text.disabled
    },
    h1: {
      fontSize: '2.25rem',
      fontWeight: 600,
      color: grey[250]
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: 600,
      color: grey[250]
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: grey[700]
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 400,
      color: grey[900]
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
      color: grey[900]
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: grey[800]
    },
    label: {
      fontSize: '0.875rem',
      fontWeight: 600,
      color: grey[600]
    },
    p1: {
      lineHeight: 1.8,
      fontSize: '1.25rem',
      fontWeight: 400,
      color: grey[700],
      overflowWrap: 'break-word'
    },
    p2: {
      fontSize: '1rem',
      fontWeight: 500,
      color: grey[800],
      overflowWrap: 'break-word'
    },
    p3: {
      fontSize: '1rem',
      fontWeight: 400,
      color: grey[800],
      overflowWrap: 'break-word'
    },
    p4: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: grey[800],
      overflowWrap: 'break-word'
    },
    body1: {
      overflowWrap: 'break-word'
    },
    body2: {
      overflowWrap: 'break-word'
    },
    subtitle1: {
      ...CustomTheme.typography.h6,
      color: CustomTheme.palette.text.secondary
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-display: swap;
          src: local('Inter');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: '1px'
        },

        inputRoot: {
          '& .MuiAutocomplete-input': {
            padding: '2px',
            height: '15px'
          }
        },

        option: {
          padding: '1px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: blue[1100]
          },
          '& .MuiInputBase-input': {
            padding: '10px 14px',
            color: blue[1100]
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: blue[300]
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: blue[300],
            boxShadow: `0px 0px 0px 4px ${blue[100]}`
          },

          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: red[0]
          },
          '& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
            boxShadow: ` 0px 0px 0px 4px ${red[100]}`
          },

          '& .MuiInputBase-input::placeholder': {
            color: grey[150]
          },
          // Disabled styles
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: grey[350]
          },
          '& .MuiInputBase-input.Mui-disabled': {
            color: grey[350]
          },
          '& .MuiInputLabel-root.Mui-disabled': {
            color: grey[350]
          },
          '& .MuiFormHelperText-root': {
            margin: '6px 0 0 0',
            color: grey[850],
            fontSize: '14px',
            lineHeight: '20px'
          }
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: {
          // Default styles
          // color: grey[950],
          padding: '0px 6px',
          borderRadius: '16px',
          fontSize: '14px',
          fontWeight: '500',

          // '&.MuiChip-filled': {
          //   backgroundColor: shade[650]
          // },

          // '&.MuiChip-outlined': {
          //   border: `1px solid ${shade[650]}`
          // },

          // Primary state
          '&.MuiChip-colorPrimary': {
            color: blue[500],
            '&.MuiChip-filled': {
              backgroundColor: shade[950],
              border: `1px solid ${shade[850]}`
            },
            '&.MuiChip-outlined': {
              border: `1px solid ${shade[850]}`
            }
          },

          // Success state
          '&.MuiChip-colorSuccess': {
            color: green[100],
            '&.MuiChip-filled': {
              backgroundColor: green[0],
              border: `1px solid ${green[50]}`

            },
            '&.MuiChip-outlined': {
              border: `1px solid ${green[50]}`

            }
          }
        }
      }
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          padding: '16px'
        },
        outlinedError: {
          backgroundColor: grey[25],
          color: red[600],
          border: `1px solid ${red[0]}`
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: grey[0]
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: blue[400]
          }
        },
        track: {
          opacity: 1,
          backgroundColor: shade[150],
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: blue[200]
          }
        }
      }
    },
    MuiTypography: {
      root: {
        overflowWrap: 'break-word'
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '0.875rem',
          borderRadius: '0.4375rem'
        },
        colorPrimary: {
          backgroundColor: shade[300]
        },
        bar: {
          backgroundColor: CustomTheme.palette.primary.dark
        }
      }
    },

    MuiSelect: {

      styleOverrides: {
        root: {
          padding: '2px 14px',
          color: blue[1100],
          border: 0,
          borderRadius: '8px',
          '&:hover': {
            border: 'none',
            outline: 'none'
          },
          '&.Mui-focused': {
            borderColor: blue[300],
            boxShadow: `0px 0px 0px 4px ${blue[100]}`
          }
        },

        option: {
          marginRight: CustomTheme.spacing(2),
          marginLeft: CustomTheme.spacing(2),
          marginBottom: CustomTheme.spacing(1),

          paddingTop: CustomTheme.spacing(1),
          paddingBottom: CustomTheme.spacing(1),
          paddingRight: CustomTheme.spacing(2.5),
          paddingLeft: CustomTheme.spacing(2.5),

          '&:hover, &.Mui-focused': {
            borderRadius: CustomTheme.shape.borderRadius,
            backgroundColor: `${shade[200]} !important`,
            color: blue[700]
          },

          '&:last-child': {
            marginBottom: CustomTheme.spacing(0)
          }
        }
      }
    },
    // MuiAutocomplete: {
    //   styleOverrides: {
    //     inputRoot: {
    //       padding: '3px 14px',
    //       margin: 0
    //     }
    //   }
    // },

    MuiLink: {
      // defaultProps: {
      //   underline: 'hover'
      // }
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'text-bottom'
          // color: CustomTheme.palette.primary.contrastText
        }
      }
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          [CustomTheme.breakpoints.down('md')]: {
            paddingTop: CustomTheme.spacing(0.75),
            paddingBottom: CustomTheme.spacing(0.75)
          },
          [CustomTheme.breakpoints.up('md')]: {
            paddingTop: CustomTheme.spacing(1),
            paddingBottom: CustomTheme.spacing(1)
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          ...defaultPaddings(CustomTheme)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ...defaultPaddings(CustomTheme)
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg',
        sx: {
          width: '100%',
          px: { xs: 3, sm: 4, md: 6 },
          pt: { xs: 4, sm: 5, md: 7 },
          pb: { xs: 4, sm: 5, md: 7 }
          // pb: { xs: 5, sm: 6, md: 8 }
        }
      }
      // styleOverrides: {
      //   root: ({ theme }) => theme.unstable_sx({
      //     width: '100%',
      //     px: { xs: 3, sm: 4, md: 6 },
      //     pt: { xs: 4, sm: 5, md: 7 },
      //     pb: { xs: 5, sm: 6, md: 8 }
      //   })
      // }
      // styleOverrides: {
      //   root: ({ theme }) => ({
      //     ...defaultPaddings(theme)
      //   })
      // }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [CustomTheme.breakpoints.down('md')]: {
            minHeight: CustomTheme.spacing(14),
            paddingRight: CustomTheme.spacing(4),
            paddingLeft: CustomTheme.spacing(4),
            mx: 1
          },
          [CustomTheme.breakpoints.up('md')]: {
            minHeight: CustomTheme.spacing(16),
            mx: 2,
            paddingRight: CustomTheme.spacing(5),
            paddingLeft: CustomTheme.spacing(5)
          }
        }
      }
    },
    MuiStack: {
      defaultProps: {
        spacing: { xs: 6, sm: 6, md: 8 }
      }
    },
    MuiRadio: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          marginLeft: CustomTheme.spacing(1.5)
        }
      }
    },
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            variant: 'body2',
            pt: 0.75
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          // '&.Mui-selected': {
          //   color: '#1F5EEF',
          //   backgroundColor: 'inherit'
          // }
          // '&.Mui-disabled': {
          //   color: 'rgba(0, 0, 0, 0.38)'
          // }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          color: grey[100]
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // 'div.Mui-selected > MuiListItemIcon-root': {
          //   color: '#1F5EEF',
          //   backgroundColor: 'inherit'
          // },
          // 'div.Mui-disabled > MuiListItemIcon-root': {
          //   color: 'rgba(0, 0, 0, 0.38)'
          // }
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'md'
      },
      styleOverrides: {
        paper: {
          paddingTop: CustomTheme.spacing(4),
          paddingBottom: CustomTheme.spacing(4),
          paddingRight: CustomTheme.spacing(6),
          paddingLeft: CustomTheme.spacing(6)
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          pb: 5.5
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginRight: CustomTheme.spacing(-6),
          marginLeft: CustomTheme.spacing(-6),
          paddingRight: CustomTheme.spacing(12),
          paddingLeft: CustomTheme.spacing(12)
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        // root: {
        //   marginRight: 0,
        //   marginLeft: 0,
        //   paddingRight: 0,
        //   paddingLeft: 0,
        //   marginBottom: 0,
        //   paddingBottom: 0,
        //   marginTop: CustomTheme.spacing(4)
        // }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        inputSizeSmall: {
          paddingTop: `${CustomTheme.spacing(2)} !important`,
          paddingBottom: `${CustomTheme.spacing(2)} !important`,
          paddingLeft: `${CustomTheme.spacing(3)} !important`,
          paddingRight: `${CustomTheme.spacing(3)} !important`
        }
      }
    }
  }
}))

export default CustomTheme
