import React from 'react'
import { Breadcrumbs } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'
import { StyledButton } from './styles'
import type { CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import { useNavigateToUrl } from '../../hooks/useNavigateToUrl'

export interface BreadcrumbT {
  id: string
  title: string
  model: string
}

export interface CustomBreadcrumbProps {
  breadcrumb: CustomBreadcrumbT[]
}

const CustomBreadcrumbs: React.FC<CustomBreadcrumbProps> = ({
  breadcrumb
}): JSX.Element => {
  const navigateToURL = useNavigateToUrl()

  const setDivision = (event: React.MouseEvent<HTMLButtonElement>, id: string | null): void => {
    if (id !== null) {
      navigateToURL(event, id)
    }
  }

  return (
    <Breadcrumbs
      tabIndex={0}
      maxItems={5}
      separator={<NavigateNext fontSize="small" sx={{ marginBottom: -0.5 }} />}
      aria-label="breadcrumb">
      {
        breadcrumb?.map(({ path, title }: CustomBreadcrumbT, i) => {
          return <StyledButton tabIndex={0}
            disabled={i === breadcrumb.length - 1}
            key={path}
            onClick={(e) => { setDivision(e, path) }}
            isLastButton={i === breadcrumb.length - 1}
            aria-label={title}
          >
            {title}
          </StyledButton>
        }
        )
      }
    </Breadcrumbs >
  )
}

export default CustomBreadcrumbs
