import React from 'react'
import { StyledBox, StyledFormLabel, StyledTextField } from './styles'
import { TextField } from '@mui/material'
import PreparedQuestionCardWrapper from '../PreparedQuestionCardWrapper'
import { type FreeResponseQuestion } from '../../../types'
import { PreparedEmptyField } from '../../../styles'

interface FreeResponseCardProps {
  question: FreeResponseQuestion
  questionNumber: number
  index: number
  sectionIndex: number
  totalQuestionInSection: number
}

const FreeResponseCard: React.FC<FreeResponseCardProps> = ({ question, questionNumber, index, sectionIndex, totalQuestionInSection }): JSX.Element => {
  return (
    <PreparedQuestionCardWrapper questionNumber={questionNumber} question={question} index={index} sectionIndex={sectionIndex} totalQuestionInSection={totalQuestionInSection}>
      <>
        <StyledBox >
          {question?.prompt !== undefined && question?.prompt !== ''
            ? <StyledFormLabel >{question?.prompt}</StyledFormLabel>
            : <PreparedEmptyField>Type your question...</PreparedEmptyField>
          }
        </StyledBox>
        <PreparedEmptyField>Type your response</PreparedEmptyField>
      </>
    </PreparedQuestionCardWrapper>
  )
}

export default FreeResponseCard
