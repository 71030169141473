import { Auth } from 'aws-amplify'
import axios from 'axios'
import type { ApplyCaseMiddlewareOptions } from 'axios-case-converter'
import applyCaseMiddleware from 'axios-case-converter'
import { type AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

const options: ApplyCaseMiddlewareOptions = {
  caseOptions: {
    stripRegexp: /[^A-Z0-9]+/gi
  }
}

const api = applyCaseMiddleware(axios.create({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json'
  }
}), options)

api.interceptors.request.use(
  async config => {
    config.headers['Auth-Type'] = localStorage.getItem('authType')
    if (localStorage.getItem('authType') === 'cognito') {
      const authData = await Auth.currentSession()

      if (authData.isValid()) {
        config.headers.Authorization = `Bearer ${authData.getIdToken().getJwtToken()}`
        config.headers['Aws-Tenant-Key'] = localStorage.getItem('tenantKey')
      }
    }
    return config
  },
  async error => {
    return error
  }
)

// https://medium.com/@sina.alizadeh120/repeating-failed-requests-after-token-refresh-in-axios-interceptors-for-react-js-apps-50feb54ddcbc

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void
  reject: (error?: any) => void
  config: AxiosRequestConfig
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = []

// Flag to prevent multiple token refresh requests
let isRefreshing = false

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config

    if (localStorage.getItem('authType') === 'edlink' &&
        Cookies.get('edlink_refresh_token_exists') != null &&
          error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        try {
          // Refresh the access token
          await axios.post('/api/auth/refresh')

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            api
              .request(config)
              .then((response) => { resolve(response) })
              .catch((err) => { reject(err) })
          })

          // Clear the queue
          refreshAndRetryQueue.length = 0

          // Retry the original request
          return await api(originalRequest)
        } catch (refreshError) {
          // Handle token refresh error
          // You can clear all storage and redirect the user to the login page
          window.location.href = `${window.location.origin}/logout`
        } finally {
          isRefreshing = false
        }
      }

      // Add the original request to the queue
      return await new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject })
      })
    }

    // Return a Promise rejection if the status code is not 401
    return await Promise.reject(error)
  }
)

export default api
