import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import { Box, MenuItem, ListItemButton, ListItemText } from '@mui/material'

import type { Theme } from '@mui/material/styles'

import { blue, shade, grey, red, yellow } from '../../../colors'

interface StyledMenuItemProps {
  theme?: Theme
  active?: boolean | null
}

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ theme, active }) => ({
  fontSize: '16px',
  fontWeight: 500,
  padding: `${theme.spacing(2.2)} ${theme.spacing(2.5)}`,
  margin: `${theme.spacing(0)} ${theme.spacing(3)}`,
  borderRadius: `${theme.spacing(1.5)}`,
  color: (active ?? false) ? blue[500] : grey[250],
  background: (active ?? false) ? shade[1] : 'unset',

  '&:hover': {
    background: shade[950]
  }
}))

interface StyledGroupNameProps {
  theme?: Theme
}

export const StyledGroupName = styled(Typography)<StyledGroupNameProps>(({ theme }) => ({
  color: grey[850],
  padding: `${theme.spacing(1.5)} ${theme.spacing(5.5)}`,
  fontWeight: '700',
  fontSize: '12px',
  textTransform: 'uppercase'

}))
interface StyledChipProps {
  theme?: Theme
}
export const StyledChip = styled(Box)<StyledChipProps>(({ theme }) => ({
  width: theme.spacing(6.5),
  height: theme.spacing(5),
  fontWeight: 500,
  color: red[800],
  backgroundColor: shade[1000],
  borderRadius: theme.spacing(1.5),
  border: ` 1px solid ${yellow[100]}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))
interface StyledListItemTextProps {
  theme?: Theme
  active?: boolean | null

}

export const StyledListItemText = styled(ListItemText)<StyledListItemTextProps>(({ theme, active }) => ({
  '& .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: '600',
    color: (active ?? false) ? blue[500] : grey[50],
    background: active ? shade[950] : 'transparent',
    rderRadius: `${theme.spacing(3)}`
  }
}))

interface StyledListItemButtonProps {
  theme?: Theme
  active?: boolean | null

}

export const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(({ theme, active }) => ({
  color: active ? blue[500] : grey[250],
  background: active ? blue[100] : 'unset',
  borderRadius: theme.spacing(3),
  '& .MuiListItemIcon-root': {
    color: active ? blue[500] : grey[250]
  },
  '& .MuiListItemText-primary': {
    color: active ? blue[500] : grey[250]
  }
}))
