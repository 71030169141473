import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { type PaperProps } from '@mui/material/Paper/Paper'
import { type Breakpoint } from '@mui/material/styles'
import { StyledDialogTitle } from './styles'

interface CustomModalProps {
  isModalOpen: boolean
  onClose: (arg: boolean) => void
  title?: string
  contentText?: string
  children?: never[] | React.ReactElement
  actions?: React.ReactElement
  maxWidth?: Breakpoint | undefined
  paperProps?: React.CSSProperties & Partial<PaperProps>
}

const CustomModal: React.FC<CustomModalProps> = ({ isModalOpen, onClose, title = '', contentText = '', children = <></>, actions, maxWidth = 'sm', paperProps = {} }) => {
  if (!isModalOpen) return null

  return (
    <Dialog open={isModalOpen} onClose={() => { onClose(false) }} maxWidth={maxWidth} sx={{
      '& .MuiPaper-root': {
        ...paperProps
      }
    }}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <DialogContent dividers sx={{ border: 0 }}>
        <DialogContentText>
          {contentText}
        </DialogContentText>
        {children}
      </DialogContent>
      {actions !== null && actions !== undefined && (
        <>
        <DialogActions>
          <br />
          {actions}
        </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default React.memo(CustomModal)
