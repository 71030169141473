import React from 'react'
import { useAppDispatch } from '../../../store'
import { openReportModal } from '../../../store/slices/reportHierarchySlice'
import EditTableHeader from './EditTableHeader'
import { type Division } from '../../../models/Division'

export interface Instructor {
  username: string
  email: string
}
export interface ReportHierarchyFeatureProps {
  division: Division
  childrenCount: number
}

const DivisionDetails: React.FC<ReportHierarchyFeatureProps> = ({
  division,
  childrenCount
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const { title, abbreviation, categoryName, parent } = division

  const headerItems = [
    { name: 'Title', value: title },
    { name: 'Abbreviation', value: abbreviation },
    { name: 'Academic Unit Type', value: categoryName },
    { name: 'Child Units', value: childrenCount },
    { name: 'Parent Unit', value: parent?.title }
  ]
  return (

    <>
      <EditTableHeader
        onEdit={() => { dispatch(openReportModal()) }}
        items={headerItems}
        isDivisionEditable={true}
      />
    </>
  )
}

export default DivisionDetails
