import { Grid, Stack, DialogActions, FormGroup } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SwitchElement } from 'react-hook-form-mui'
import useAsyncEffect from 'use-async-effect'
import CustomModal from '../../../../components/CustomModal/'
import { useAppDispatch } from '../../../../store'
import { initialState, type ConfigSliceType } from '../../../../store/slices/configSlice'
import { configLoadState, configSaveState } from '../../../../store/slices/configSlice/api'
import { getConfig } from '../../../../store/slices/configSlice/selectors'
import GenericButton from '../../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../../components/GenericButton/styles'

export interface DisplayCustomizationModalProps {
  isModalOpen: boolean
  onClose: () => void
}

const DisplayCustomizationModal: React.FC<DisplayCustomizationModalProps> = ({
  onClose,
  isModalOpen
}): JSX.Element => {
  const config = getConfig()
  const dispatch = useAppDispatch()

  const methods = useForm<Partial<ConfigSliceType>>({
    defaultValues: config
  })

  const { reset, formState } = methods

  useAsyncEffect(async () => {
    reset(config)
  }, [config, isModalOpen])

  useAsyncEffect(async () => {
    void dispatch(configLoadState())
  }, [])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault()
    const data = methods.getValues()
    void dispatch(configSaveState(data))
    onClose()
  }

  return (
    <CustomModal title="Display Settings" isModalOpen={isModalOpen} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={(event) => { void onSubmit(event) }}>
          <FormGroup>
            <SwitchElement label="Display Percentages" name="displayPercentages" />
            <SwitchElement label="Display Average" name="displayAverage"/>
          </FormGroup>
          <DialogActions sx={{
            p: 0,
            m: 0,
            marginTop: 4
          }}>
            <Grid container>
              <Grid item xs>
                <GenericButton
                  buttonType={ButtonType.Primary}
                  buttonSize={ButtonSize.Small}
                  onClick={() => {
                    reset(initialState, { keepDefaultValues: true })
                  }}
                  label=' Reset to Default'
                />
              </Grid>
              <Grid item xs="auto">
                <Stack direction="row" spacing={2}>
                  <GenericButton
                    buttonType={ButtonType.Ghost}
                    buttonSize={ButtonSize.Small}
                    onClick={() => { onClose() }}
                    label='Cancel'
                  />
                  <GenericButton
                    buttonType={ButtonType.Primary}
                    buttonSize={ButtonSize.Small}
                    disabled={!formState.isDirty}
                    type="submit"
                    label='Save'
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </FormProvider>
    </CustomModal>
  )
}

export default DisplayCustomizationModal
