import React, { useEffect, useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import { useDispatch } from 'react-redux'
import { StyledTextField, StyledBoxWrapper } from './styles'
import CreateQuestionCardDropDownMenu from '../CreateQuestionCardWrapper/CreateQuestionCardDropDownMenu'
import { type DescriptiveQuestion, SurveyItemQuestionType } from '../../../types'
import { addSurveyQestionInSection, deleteQuestionFromSection, setEditableQuestion, setSelectedQuestion, updateQuestionInSection } from '../../../../../store/slices/SurveyTemplateSlice'
import { getEditableQuestion } from '../../../../../store/slices/SurveyTemplateSlice/selectors'

interface CreateDescriptiveQuestionCardProps {
  sectionIndex?: number
  questionIndex?: number
}

const CreateDescriptiveQuestionCard: React.FC<CreateDescriptiveQuestionCardProps> = ({ sectionIndex = 0, questionIndex = 0 }): JSX.Element => {
  const dispatch = useDispatch()
  const editableQuestion = getEditableQuestion()

  const [descriptiveCardData, setDescriptiveCardData] = useState<DescriptiveQuestion>({
    itemType: SurveyItemQuestionType.DESCRIPTIVE_TEXT,
    prompt: '',
    isRequired: false
  })

  useEffect(() => {
    if (editableQuestion != null) {
      setDescriptiveCardData(editableQuestion.question as DescriptiveQuestion)
    }
  }, [editableQuestion])

  const handleQuestionTitle = (questionTitle: string): void => {
    setDescriptiveCardData(prevState => {
      return { ...prevState, prompt: questionTitle }
    })
  }

  const handleDelete = (): void => {
    if (editableQuestion != null) {
      dispatch(deleteQuestionFromSection({ sectionIndex, questionIndex }))
      return
    }
    dispatch(setSelectedQuestion(null))
  }

  const handleClickAway = (): void => {
    if (editableQuestion) {
      dispatch(updateQuestionInSection({ sectionIndex, questionIndex, updatedQuestion: descriptiveCardData }))
      dispatch(setEditableQuestion(null))
      return
    }

    dispatch(addSurveyQestionInSection(descriptiveCardData))
    dispatch(setSelectedQuestion(null))
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}>
      <StyledBoxWrapper >
        <StyledTextField type='text' value={descriptiveCardData?.prompt} placeholder='Add text here...' autoFocus onChange={(e) => { handleQuestionTitle(e.target.value) }} fullWidth />
        <CreateQuestionCardDropDownMenu handleDelete={handleDelete} />
      </StyledBoxWrapper>
    </ClickAwayListener >

  )
}

export default CreateDescriptiveQuestionCard
