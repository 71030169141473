/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { useForm, FormProvider, type SubmitHandler } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useAppDispatch } from '../../../store'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormInput from '../../../components/FormElements/FormInput'
import ValidationAlert from '../../../components/ValidationAlert'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'
import { type Division, type IDivisionRow } from '../../../models/Division'
import { createDivisionCoursesApi, getDivisionCoursesApi, updateDivisionCoursesApi } from '../../../store/slices/courseCatalogSlice/api'
import { actionLoading, divisionDepartmentList } from '../../../store/slices/courseCatalogSlice/selectors'
import FormAutoComplete from '../../../components/FormElements/FormAutoComplete'
import { type ApplyFiltersAndDispatchParams } from '../../../components/CustomTable/types'

interface CreateAndEditCourseCatalogProps {
  selectedRow?: IDivisionRow
  handleCloseModal?: () => void
  params?: ApplyFiltersAndDispatchParams
}
interface FormType { title?: string | undefined, abbreviation: string, categoryId?: string, parent?: string }

const courseDivisionSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  abbreviation: yup.string().required('Course ID is required'),
  categoryId: yup.string(),
  parent: yup.string()
})

const CreateAndEditCourseCatalog: React.FC<CreateAndEditCourseCatalogProps> = ({ handleCloseModal = () => { }, selectedRow, params }): JSX.Element => {
  const [errors, setErrors] = useState<unknown>({})

  const isEditAction = selectedRow !== undefined
  const list = divisionDepartmentList()

  const methods = useForm({
    resolver: yupResolver(courseDivisionSchema),
    defaultValues: {
      title: selectedRow?.title,
      abbreviation: selectedRow?.abbreviation,
      categoryId: 'course',
      parent: selectedRow?.parent?.divisionId
    }
  })

  const dispatch = useAppDispatch()
  const loading = actionLoading()
  const { handleSubmit, formState: { isDirty, isSubmitting } } = methods
  const isEditing = selectedRow

  const onSubmit: SubmitHandler<FormType> = async ({ title, abbreviation, categoryId, parent }): Promise<void> => {
    const isEditing = isEditAction
    const hasParentUpdated = selectedRow?.parent?.divisionId !== parent
    try {
      const payload = {
        title,
        categoryId: 'course',
        parent,
        abbreviation
      }
      const UpdatePayload = hasParentUpdated ? { title, parent } : { title }

      const apiCall = isEditing
        ? dispatch(updateDivisionCoursesApi({ urlId: selectedRow?.divisionId, data: UpdatePayload })).unwrap()
        : dispatch(createDivisionCoursesApi({ data: payload })).unwrap()

      await apiCall
      await dispatch(getDivisionCoursesApi({ params }))

      handleCloseModal()
    } catch (error: any) {
      if (error?.response) {
        setErrors(error.response?.data?.error)
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <ValidationAlert errors={errors} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <FormInput name='title' type='text' label='Title' size='medium' required />
          </Grid>
          <Grid item xs={12}>
            <FormInput name='abbreviation' type='text' label='Course ID' size='medium' required disabled={isEditAction} />
          </Grid>
          <Grid item xs={12}>
            <FormAutoComplete
              label="Parent Unit (Optional)"
              placeholder='Select Parent Unit'
              name="parent"
              readOnly={!!(isEditAction && selectedRow?.parent?.divisionId)}
              defaultValue={selectedRow?.parent?.divisionId ? list?.find((division: Division) => division.divisionId === selectedRow?.parent?.divisionId) : undefined}
              options={list ?? []}
              getOptionLabel={(option: Division) => option?.title}
              getOptionValue={(option: Division) => option?.divisionId}
            />
            <DefaultModalActions
              destructiveButtonProps={{
                onClick: handleCloseModal
              }}
              confirmButtonProps={{
                text: isEditing ? 'Save' : 'Create',
                loading,
                type: 'submit',
                disabled: isSubmitting || (isEditing && !isDirty)
              }}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider >
  )
}

export default CreateAndEditCourseCatalog
