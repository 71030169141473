import React from 'react'
import { Card, CardContent, Divider, Typography, useTheme } from '@mui/material'

export interface CommentCardProps {
  comments: string[]
  title: string
  key: number
}

const CommentCard: React.FC<CommentCardProps> = (
  { comments, title, key }
): JSX.Element => {
  const theme = useTheme()
  return (
    <Card key={key} sx={{ px: theme.spacing(3) }}>
      <CardContent sx={{ my: theme.spacing(2) }}>
        <Typography variant="h6">
          {title}
        </Typography>
      </CardContent>
      {
        comments?.map((comment, i) => {
          if (comment !== null && comment.trim() !== '') {
            return (<>
            {
              (i > 0) && (
              <CardContent sx={{ my: 0, py: 0 }}>
                <Divider light />
              </CardContent>)
            }
            <CardContent>
              {/* <Typography variant="h5" gutterBottom>
                Comment {i + 1}
              </Typography> */}
              <Typography variant="p1" color="text.secondary">
                {comment}
              </Typography>
            </CardContent>
          </>)
          } else {
            return null
          }
        })
      }
    </Card>
  )
}

export default CommentCard
