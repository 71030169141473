import React from 'react'
import { StyledTextField } from '../styles'
import { useDispatch } from 'react-redux'
import { setTitle } from '../../../store/slices/SurveyTemplateSlice'
import { getSurveyTitle } from '../../../store/slices/SurveyTemplateSlice/selectors'

export interface SurveyTitleProps {
  onTitleUpdate: (newTitle: string) => void
}

const SurveyTitle: React.FC<SurveyTitleProps> = ({ onTitleUpdate }): JSX.Element => {
  const dispatch = useDispatch()

  const surveyTitle = getSurveyTitle()

  const handleSurveyTitle = (title: string): void => {
    dispatch(setTitle(title))
    onTitleUpdate(title)
  }

  return (
    <StyledTextField placeholder='Type Survey Title...' onChange={(e) => { handleSurveyTitle(e.target.value) }} value={surveyTitle} />
  )
}

export default SurveyTitle
