import createApiThunk from '../../../ApplicationFactory'
import { type DivisionAndSection } from '../../../models/Role'

export const getUserPagePermissionsApi = createApiThunk<string[]>('getUserPagePermissionsApi', {
  url: '/api/permissions/',
  method: 'GET'
})

export const getUserRolesApi = createApiThunk<DivisionAndSection[]>('getUserRoleBaseDivisionAndSectionsApi', {
  url: '/api/users/roles/',
  method: 'GET'
})

export const getAllRolesApi = createApiThunk<DivisionAndSection[]>('getAllRolesApi', {
  url: '/api/roles/',
  method: 'GET'
})

export const createUserRolesApi = createApiThunk('userRolesApi', {
  url: '/api/users/roles/',
  method: 'POST'
})
