import * as React from 'react'
import type { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import { useTheme } from '@mui/system'
import { Box } from '@mui/material'
import { shade } from '../../../../colors'

export interface CompletionChartProps {
  value: number
}

const CompletionChart: React.FC<CompletionChartProps> = ({ value }): JSX.Element => {
  const theme = useTheme()

  const isLow = value < 6

  const percent = (value > 100) ? 100 : value

  const series = [{
    name: 'Completed',
    data: [percent]
  }, {
    name: 'Not Completed',
    data: [100 - percent]
  }]

  const options: ApexOptions = {
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '0.875rem',
        colors: isLow ? ['rgba(0,0,0,0)', theme.palette.primary.dark] : [theme.palette.primary.contrastText, 'rgba(0,0,0,0)']
      },
      formatter: (_, __) => `${value}%`,
      offsetY: 7.5,
      offsetX: isLow ? (value === 0 ? 17 : 15) : 0
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      parentHeightOffset: 0,
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: isLow ? 'bottom' : 'center'
        },
        borderRadius: 0,
        horizontal: true,
        columnWidth: '100%'
      }
    },
    fill: {
      colors: [theme.palette.primary.dark, shade[300]],
      opacity: 1
    },
    grid: {
      show: false,
      padding: {
        top: -30,
        left: -15,
        right: -15,
        bottom: -15
      }
    },
    legend: {
      show: false
    },
    yaxis: {
      show: false
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    }
  }

  const completedLow = value < 11

  let completedPercent: number = value

  if (value <= 11) {
    completedPercent = 11
  } else if (value > 100) {
    completedPercent = 100
  }

  const completedSeries = [{
    name: 'Completed',
    data: [completedPercent]
  }, {
    name: 'Not Completed',
    data: [100 - completedPercent]
  }]

  const optionsComplete: ApexOptions = {
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '0.875rem',
        fontWeight: 500,
        fontFamily: '\'Inter\', sans-serif',
        colors: [theme.palette.primary.dark, 'rgba(0,0,0,0)']
      },
      formatter: (_, __) => 'COMPLETED',
      offsetY: 7.5,
      offsetX: completedLow ? 42 : 0
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      parentHeightOffset: 0,
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: completedLow ? 'bottom' : 'center'
        },
        borderRadius: 0,
        horizontal: true,
        columnWidth: '100%'
      }
    },
    fill: {
      colors: ['rgba(0,0,0,0)', 'rgba(0,0,0,0)'],
      opacity: 1
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        left: -15,
        right: -15
      }
    },
    legend: {
      show: false
    },
    yaxis: {
      show: false
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    }
  }

  return (
    <Box sx={{ py: 1 }}>
      <div style={{ clipPath: 'inset(10px 0 10px 0 round 8px)' }}>
        <ReactApexChart options={options} series={series} type="bar" height={42}/>
      </div>
      <ReactApexChart options={optionsComplete} series={completedSeries} type="bar" height={20} />
    </Box>
  )
}

export default CompletionChart
