import React from 'react'
import CourseCatalog from '../../features/CourseCatalogFeature'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'

const Page = (): JSX.Element => {
  return (
    <React.Fragment>
      <CustomHeader title={PAGE_TITLES.COURSE_CATALOG} />
      <CourseCatalog />
    </React.Fragment >
  )
}

export default Page
