import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { blue, grey, shade } from '../../../colors'
import { Button } from '@mui/material'
interface DivProps {
  isMobile: boolean
  theme?: Theme
}

export const Div = styled(Box)<DivProps>(({ theme, isMobile }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  paddingRight: isMobile ? theme.spacing(0.5) : theme.spacing(3.75)
}))

interface StyledLinkProps {
  isTablet: boolean
  isMobile: boolean
  theme?: Theme
  active?: boolean | null
}

export const StyledLink = styled(Button)<StyledLinkProps>(({ theme, active, isMobile }) => ({
  color: (active ?? false) ? blue[500] : grey[50],
  textTransform: 'capitalize',
  background: active ? shade[1] : 'transparent',
  fontWeight: isMobile ? 'unset' : 'bold',
  cursor: 'pointer',
  letterSpacing: '1%',
  position: 'relative',
  fontSize: isMobile ? theme.spacing(3) : theme.spacing(4),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  transition: '.3s',
  borderRadius: `${theme.spacing(2)}`,

  '&:hover': {
    background: blue[100],
    color: theme.palette.primary.main

  }

}))
