import React from 'react'
import Box from '@mui/material/Box'
import GenericButton from '../../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../../components/GenericButton/styles'

interface DeleteSurveyModalProps {
  handleCloseModal?: () => void
  handleDeleteModal?: () => void
}

const DeleteSurveyModal: React.FC<DeleteSurveyModalProps> = ({ handleCloseModal, handleDeleteModal }): JSX.Element => {
  return (
    <Box display={'flex'} gap={2}>
      <GenericButton
        buttonType={ButtonType.Ghost}
        buttonSize={ButtonSize.Small}
        onClick={handleCloseModal} label='Cancel'
      />
      <GenericButton
        buttonType={ButtonType.Destructive}
        buttonSize={ButtonSize.Small}
        onClick={handleDeleteModal}
        label='Delete'
      />
    </Box>
  )
}

export default DeleteSurveyModal
