// get survey questions

import { isEmpty } from 'lodash'
import isOfType from '.'
import { red } from '../colors'
import { type ILinearObject } from '../features/AdminSurveyListFeature/types'
import type { ISurveyItem, ISurveyTemplate } from './SurveyTemplate'

export interface SurveyChoice {
  name: string
  value: number
}

export enum SurveyQuestionType {
  Title = 'Title',
  FreeResponse = 'FreeResponse',
  LinearScale = 'LinearScale',
  MultipleChoice = 'MultipleChoice',
  DescriptiveText = 'DescriptiveText'
}

export interface SurveyItem {
  itemType: SurveyQuestionType
  itemId: string
  revision: number
  prompt: string
  releaseDate?: string
}

export interface SurveyQuestion extends SurveyItem {
  itemTypeId: string
  objectId: string
  object: string[] | ILinearObject[]
}

export function isSurveyQuestion (x: any): x is SurveyQuestion {
  return isOfType<SurveyQuestion>(x, 'prompt')
}

export interface Survey {
  divisionId: string
  revision: number
  title: string
  // instanceTitle: string
  description: string
  surveyItems: SurveyQuestion[]
}

// survey submit response

export interface SurveySubmit {
  surveyResponseItems: SurveyResponse[]
}

export type SurveyResponse = Pick<ISurveyItem, 'itemId' | 'revision'> & {
  answer: number | string | undefined
}

export function createSurveyResponse ({ itemId, revision }: ISurveyItem, answer?: string | number): SurveyResponse {
  return {
    answer,
    itemId,
    revision
  }
}

function createSurveyResponses (
  survey: ISurveyTemplate,
  surveyResponseMap: Record<string, string | undefined>
): SurveyResponse[] {
  let responses: SurveyResponse[] = []

  survey?.surveySections.forEach(surveysection => {
    surveysection?.surveyItems.forEach(item => {
      if (item.itemType === SurveyQuestionType.Title) {
        return
      }

      const answer: string | undefined = surveyResponseMap[item.itemId]
      responses = responses.concat([createSurveyResponse(item, answer)])
    })
  })
  return responses
}

export function createSurveySubmit (
  survey: ISurveyTemplate,
  surveyResponseMap: Record<string, string | undefined>): SurveySubmit {
  return {
    surveyResponseItems: createSurveyResponses(survey, surveyResponseMap)
  }
}

export const scrollIntoInvalidQuestion = (survey: ISurveyTemplate, surveyResponseMap: Record<string, string | undefined>): string[] => {
  const invalidItemIds: string[] = []

  survey?.surveySections.forEach(surveysection => {
    surveysection?.surveyItems.forEach(item => {
      const element = document.getElementById(item.itemId)
      if (item.isRequired && isEmpty(surveyResponseMap[item.itemId]) && element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        element.style.borderColor = red[700]
        invalidItemIds.push(item.itemId)
      } else if (element) {
        element.style.borderColor = ''
      }
    })
  })

  return invalidItemIds
}
