import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/system'
import CopyrightFooter from '../../components/CopyrightFooter'
import CustomAppBar from '../../components/CustomAppbar'
import { StyledOutletBox } from './styles'
import { useMediaQuery, useTheme } from '@mui/material'

interface HeaderFooterLayoutProps {
  isTabMenu?: boolean
}
const HeaderFooterLayout: React.FC<HeaderFooterLayoutProps> = ({ isTabMenu }): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))

  const calculateAppbarOffset = (): string => {
    switch (true) {
      case isMobile:
        return `${theme.spacing(12)}`
      case isTablet:
        return `${theme.spacing(14)}`
      default:
        return `${theme.spacing(16)}`
    }
  }

  return (
    <Box display='flex' flexDirection='column' minHeight='100vh' marginTop={calculateAppbarOffset()}>
      <CustomAppBar isTabMenu={isTabMenu} />
      <StyledOutletBox isMobile={isMobile} flexGrow='1'>
        <Outlet />
      </StyledOutletBox>
      <CopyrightFooter />
    </Box>
  )
}

const MemoizedHeaderFooterLayout = memo(HeaderFooterLayout)

export default MemoizedHeaderFooterLayout
