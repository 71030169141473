import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'
import { shade } from '../../../../../../colors'

interface StyledProps {
  theme?: Theme
}
export const StyledOptionsWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `${theme.spacing(20)} auto`,
  alignItems: 'center'
}))

export const StyledStyledTextField = styled(TextField)<StyledProps>(({ theme }) => ({
  height: theme.spacing(10),
  '& :disabled': {
    background: shade[750]
  }
}))
