import { useLocation } from 'react-router-dom'
import ROUTES from '../consts/paths'
import { getPagePermissions } from '../store/slices/roleSlice/selectors'

type PreparedRoutes = Record<string, string[]>

export enum PreparedRouteKeys {
  ActivityMonitorPage = 'Admin Settings - Activity Monitor',
  AdminSettingsAcadUnits = 'Admin Settings - Acad Units',
  AdminSettingsUsers = 'Admin Settings - Users',
  AdminSettingsDistributionSeries = 'Admin Settings - Distribution Plans',
  AdminSettingsHierarchy = 'Admin Settings - Hierarchy',
  AdminSettingsTemplates = 'Admin Settings - Templates',
  ReportPage = 'Report - Page',
  StudentSurveyListPage = 'Student Survey List - Page',
  InstructorCourseListPage = 'Instructor Course List - Page',
  InstructorReportPage = 'Instructor Report - Page',
  TermsPage = 'Admin Settings - Terms',
  CourseCatalog = 'Admin Settings- Course Catalog',
}

export type PreparedRouteKey = keyof typeof PreparedRouteKeys

export const preparedRoutes: PreparedRoutes = {
  [PreparedRouteKeys.AdminSettingsAcadUnits]: [ROUTES.ACADEMIC_UNITS],
  [PreparedRouteKeys.AdminSettingsUsers]: [ROUTES.STUDENTS, ROUTES.FACULTY_AND_STAFF],
  [PreparedRouteKeys.AdminSettingsDistributionSeries]: [ROUTES.DISTRIBUTION_SERIES, ROUTES.COURSE_AND_ENROLLMENT],
  [PreparedRouteKeys.AdminSettingsHierarchy]: [ROUTES.REPORTING_HIERARCHY],
  [PreparedRouteKeys.AdminSettingsTemplates]: [ROUTES.SURVEYS, ROUTES.SURVEY_LIST],
  [PreparedRouteKeys.ReportPage]: [ROUTES.REPORTS, ROUTES.ADMIN_REPORTS],
  [PreparedRouteKeys.StudentSurveyListPage]: [ROUTES.STUDENT_SURVEYS, ROUTES.SURVEY_PAGE, ROUTES.SURVEY_COMPLETED],
  [PreparedRouteKeys.InstructorCourseListPage]: [ROUTES.INSTRUCTOR_SURVEYS],
  [PreparedRouteKeys.InstructorReportPage]: [ROUTES.REPORTS, ROUTES.ADMIN_REPORTS, ROUTES.INSTRUCTOR_SURVEYS],
  [PreparedRouteKeys.TermsPage]: [ROUTES.TERMS],
  [PreparedRouteKeys.CourseCatalog]: [ROUTES.COURSE_CATALOG],
  [PreparedRouteKeys.ActivityMonitorPage]: [ROUTES.TASKS]
}

const isAllowedForPath = (path: string, pagePermissions: string[] | undefined): boolean => {
  if (!pagePermissions) return false
  return pagePermissions.some(permission => preparedRoutes?.[permission]?.some(routePath => path.startsWith(routePath)))
}

export const useAllowedPath = (pathname?: string): boolean => {
  const location = useLocation()
  const finalPathname = pathname ?? location.pathname
  const pagePermissions = getPagePermissions()
  return isAllowedForPath(finalPathname, pagePermissions)
}
