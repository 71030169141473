import React from 'react'
import { ButtonType } from '../../../components/GenericButton/styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { grey } from '../../../colors'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'
import { type ITerm } from '../../../models/Term'
import { deleteTermsApi } from '../../../store/slices/termsSlice/api'
import { getDeleteLoading } from '../../../store/slices/termsSlice/selectors'

interface DeleteTermsProps {
  selectedRow?: ITerm
  handleCloseModal?: () => void
  showErrorModal?: () => void
  setErrorMessage?: (arg: string) => void
}

const DeleteTerms: React.FC<DeleteTermsProps> = ({ selectedRow, handleCloseModal, showErrorModal, setErrorMessage }) => {
  const dispatch = useAppDispatch()
  const isDeleteLoading = getDeleteLoading()
  const theme = useTheme()

  const handleDelete = async (): Promise<void> => {
    try {
      await dispatch(deleteTermsApi({ urlId: selectedRow?.termId })).unwrap()
      handleCloseModal?.()
    } catch (error: any) {
      if (error && error.response?.status === 422) {
        const errorMessage = error.response?.data?.error?.error ?? ''
        setErrorMessage?.(errorMessage)
        showErrorModal?.()
      }
    }
  }

  return (
    <>
      <Typography fontSize='14px' lineHeight={theme.spacing(5)} color={grey[850]}>
        You will not be able to recover this Term. All references to it will be deleted.
      </Typography>
      <DefaultModalActions
        confirmButtonProps={{
          onClick: handleDelete,
          text: 'Delete',
          loading: isDeleteLoading,
          buttonType: ButtonType.Destructive
        }}
        destructiveButtonProps={{
          onClick: handleCloseModal
        }}
      />
    </>

  )
}

export default DeleteTerms
