import React from 'react'
import { SnackbarContent, type CustomContentProps, useSnackbar, type OptionsObject, type SnackbarMessage, type VariantType, enqueueSnackbar } from 'notistack'
import { Alert, type AlertColor, AlertTitle, Typography, IconButton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as CheckCircleOutlineIcon } from '../../assets/check-with-circle.svg'
import CloseIcon from '@mui/icons-material/Close'

interface NotificationsProps extends CustomContentProps {
  subTitle?: string
}

export const Notifications = React.forwardRef<HTMLDivElement, NotificationsProps>((props, ref) => {
  const {
    id,
    message,
    subTitle,
    variant,
    ...other
  } = props

  const theme = useTheme()

  const { closeSnackbar } = useSnackbar()

  const iconMapping = {
    success: <CheckCircleOutlineIcon />,
    error: <InfoOutlinedIcon sx={{ rotate: '180deg' }}/>
  }

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Alert
        iconMapping={iconMapping}
        severity={variant as AlertColor}
        variant="filled"
         onClose={() => { closeSnackbar(id) }}
         action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            sx={{ position: 'absolute', right: theme.spacing(2), top: theme.spacing(3) }}
            onClick={() => { closeSnackbar(id) }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{ width: theme.spacing(100), padding: theme.spacing(3.5), borderRadius: theme.spacing(3) }} >
        <AlertTitle sx={{ fontSize: '14px', fontWeight: '600' }}>{message}</AlertTitle>
        <Typography sx={{ fontSize: '14px' }}>{subTitle}</Typography>
      </Alert>

    </SnackbarContent>
  )
})

Notifications.displayName = 'Notifications'

interface ShowNotificationOptions<V extends VariantType> extends OptionsObject<V> {
  subTitle?: string
}

export const notification = (message: SnackbarMessage, options: ShowNotificationOptions<VariantType>): void => {
  enqueueSnackbar(message, options)
}
