import React, { useEffect } from 'react'
import PageHeader from '../../components/PageHeader'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../store'
import { getTermByIdApi } from '../../store/slices/termsSlice/api'
import { getTermLoading, getTerm } from '../../store/slices/termsSlice/selectors'
import TermsDetails from './TermsDetails'
import TermsSection from './TermsSectionTable'
import Enrollments from './Enrollments'
import LoadingPageV2 from '../../pages/LoadingPageV2'
import PAGE_TITLES from '../../consts/titles'

const TermsDetailsFeature: React.FC = (): JSX.Element => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const termsDetails = getTerm()
  const loading = getTermLoading()

  useEffect(() => {
    void dispatch(getTermByIdApi({ urlId: id }))
  }, [id])

  if (loading) {
    return <LoadingPageV2 title={PAGE_TITLES.TERMS} />
  }

  return (
    <>
      <PageHeader title={termsDetails?.termName ?? ''} showBackButton />
      <TermsDetails />
      <TermsSection/>
      <Enrollments/>
    </>
  )
}

export default TermsDetailsFeature
