import React from 'react'
import { Fab, Typography } from '@mui/material'
import { ArrowBack, DoneOutlineOutlined } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { blue } from '../../colors'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'
import CenteredContainer from '../../components/CenteredContainer'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../consts/paths'
import GenericButton from '../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../components/GenericButton/styles'

const SurveyCompletedPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  const handleBack = (): void => {
    navigate(ROUTES.STUDENT_SURVEYS)
  }

  return (
    <CenteredContainer>
      <CustomHeader title={PAGE_TITLES.SURVEY_COMPLETED} />
      <Stack sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}>
        <Fab disableRipple sx={{
          backgroundColor: blue[100],
          color: 'primary.main',
          boxShadow: 0,
          pointerEvents: 'none'
        }}>
          <DoneOutlineOutlined />
        </Fab>
        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
          Thank you for participating!
        </Typography>
        <GenericButton
          buttonType={ButtonType.Primary}
          buttonSize={ButtonSize.Large}
          label='Return'
          onClick={handleBack}
          startIcon={<ArrowBack />}
        />
      </Stack>
    </CenteredContainer>
  )
}

export default SurveyCompletedPage
