import React from 'react'
import CustomHeader from '../../components/CustomHeader'
import PAGE_TITLES from '../../consts/titles'
import NotFoundCard from '../../components/NotFoundCard'
import CenteredContainer from '../../components/CenteredContainer'

export interface NotFoundPageProps {
  title?: PAGE_TITLES
}

const NotFoundFeature: React.FC<NotFoundPageProps> = ({
  title
}): JSX.Element => {
  return (
<CenteredContainer>
      {(title != null)
        ? <CustomHeader title={title} info={PAGE_TITLES.NOT_FOUND} />
        : <CustomHeader title={PAGE_TITLES.NOT_FOUND} />}
      <NotFoundCard />
    </CenteredContainer>
  )
}

export default NotFoundFeature
