import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import type { Theme } from '@mui/system'

interface StyledProps {
  theme?: Theme
}
export const StyledBoxWrapper = styled(Box)<StyledProps>(({ theme }) => ({
  padding: theme.spacing(5.2)
}))
