import React, { useState } from 'react'
import GenericModal from '../../../../components/GenericModal'
import { DragAndDrop } from '../../../../components/DragAndDropUpload'
import { useAppDispatch } from '../../../../store'
import { type ISlot } from '../../../../models/DistributionPlan'
import { getSlotSectionApi, uploadSectionCsvApi } from '../../../../store/slices/distributionPlanSlice/apis'
import Dropdown from '../../../../components/Dropdown'
import { CreateAndEditCourses } from '../CreateAndEditCourses'
import { uploadLoading } from '../../../../store/slices/distributionPlanSlice/selectors'
import { notification } from '../../../../components/Notifications'
import { type ApplyFiltersAndDispatchParams } from '../../../../components/CustomTable/types'
import { type IUser } from '../../../../models/User'

interface CoursesTopbarActionsProps {
  slot: ISlot
  params?: ApplyFiltersAndDispatchParams
}

const CoursesTopbarActions: React.FC<CoursesTopbarActionsProps> = ({ slot, params }) => {
  const [isUploadModal, setIsUploadModal] = useState<boolean>(false)
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const [alertType, setAlertType] = useState<string | null>(null)
  const [validationError, setValidationError] = useState<string | null>(null)
  const [taskId, setTaskId] = useState<string>('')

  const dispatch = useAppDispatch()
  const isUploadloading = uploadLoading()

  const closeUploadModal = (): void => {
    setIsUploadModal(false)
    setAlertType(null)
  }

  const handleFile = (file: File): void => {
    setFile(file)
  }

  const handleUpload = async (): Promise<void> => {
    if (file) {
      try {
        await dispatch(uploadSectionCsvApi({ data: file, urlId: slot?.slotId })).then(async (res: any) => {
          if (res.payload?.taskId) {
            setTaskId(res.payload?.taskId)
            setAlertType('success')
            const message = 'Courses successfully uploaded'
            notification(message, { variant: 'success' })
            await dispatch(getSlotSectionApi({ urlId: slot?.slotId, params }))
          } else {
            setValidationError(res?.payload)
            setAlertType('warning')
          }
        })
      } catch (error) {
        console.error('Error uploading file', error)
      }
    }
  }

  const uploadApi = (): void => {
    setIsUploadModal(true)
  }

  const addManually = (): void => {
    setIsCreateModal(true)
  }

  const menuItems = [
    { text: 'Upload CSV', action: uploadApi },
    { text: 'Add Manually', action: addManually }
  ]

  const config = {
    headers: [
      { name: 'Section ID', type: 'string', required: true },
      { name: 'Survey Start Date', type: 'string', required: true },
      { name: 'Survey End Date', type: 'string', required: true },
      { name: 'Admin Release Date', type: 'string' },
      { name: 'Instructor Release Date', type: 'string' }
    ]
  }

  const modalTitle = alertType ? 'Upload complete' : 'Upload courses'

  return (
    <>
      <Dropdown menuItems={menuItems} label='Add Courses'/>
      <GenericModal
        isOpen={isCreateModal}
        title={'Course Section Assignment'}
        onClose={() => { setIsCreateModal(false) }}
        maxWidth='sm'
        action={false}
      >
        <CreateAndEditCourses handleCloseModal={() => { setIsCreateModal(false) }} slot={slot} />
      </GenericModal>
      <GenericModal action={false} maxWidth='sm' title={modalTitle} showCloseIcon={false} isOpen={isUploadModal} onClose={closeUploadModal}>
        <DragAndDrop taskId={taskId} onDrop={handleFile} onClose={closeUploadModal} onConfirm={handleUpload} loading={isUploadloading} alertType={alertType} validationConfig={config} validationErrorBE={validationError} />
      </GenericModal>
    </>
  )
}

export default CoursesTopbarActions
