import { createSlice } from '@reduxjs/toolkit'
import { type IDistributionPlan } from '../../../models/DistributionPlan'
import { createSlotApi, getAllDistributionSeriesList, createDistributionSeriesApi, deleteDistributionSeriesApi, editDistributionSeriesApi, getSeriesSlotApi } from './apis'
import { notification } from '../../../components/Notifications'
import type { IDistributionSeries } from '../../../models/DistributionSeries'
import DistributionSeries from '../../../features/DistributionSeriesAndPlan'

export interface IDistributionSeriesSlice {
  distributionSeries: IDistributionSeries[]
  distributionPlans: Record<string, IDistributionPlan>
  distributionSeriesLoading: boolean
  distributionPlansLoading: Record<string, boolean>
}

const initialState: IDistributionSeriesSlice = {
  distributionSeries: [],
  distributionPlans: {},
  distributionSeriesLoading: false,
  distributionPlansLoading: {}
}

const distributionSeriesSlice = createSlice({
  name: 'distributionSeries',
  initialState,
  reducers: {
    resetState: (state) => {
      state.distributionSeries = []
      state.distributionPlans = {}
      state.distributionSeriesLoading = false
      state.distributionPlansLoading = {}
    }
  },
  extraReducers: (builder) => {
    builder
      ///  ALL Distribution Series
      .addCase(getAllDistributionSeriesList.pending, (state) => {
        state.distributionSeriesLoading = true
      })
      .addCase(getAllDistributionSeriesList.fulfilled, (state, action) => {
        state.distributionSeries = action.payload
        state.distributionSeriesLoading = false
      })
      .addCase(getAllDistributionSeriesList.rejected, (state) => {
        state.distributionSeriesLoading = false
      })
      // Create Distribution Series
      .addCase(createDistributionSeriesApi.pending, (state) => {
        state.distributionSeriesLoading = true
      })
      .addCase(createDistributionSeriesApi.fulfilled, (state, action) => {
        state.distributionSeriesLoading = false
        state.distributionSeries.push(action.payload)
        notification('Distribution Series added successfully', { variant: 'success', subTitle: action.meta.arg.data?.title })
      })
      .addCase(createDistributionSeriesApi.rejected, (state) => {
        state.distributionSeriesLoading = false
      })
      //  Delete Distribution Series
      .addCase(deleteDistributionSeriesApi.pending, (state) => {
        state.distributionSeriesLoading = true
      })
      .addCase(deleteDistributionSeriesApi.fulfilled, (state, action) => {
        state.distributionSeriesLoading = false
        state.distributionSeries = state.distributionSeries.filter(series => series.distributionSeriesId !== action.meta.arg.urlId)
        notification('Successfully deleted', { variant: 'success', subTitle: action.payload.message })
      })
      .addCase(deleteDistributionSeriesApi.rejected, (state, action) => {
        state.distributionSeriesLoading = false
        const errorMessage = action.payload?.response?.data?.error ?? 'An error occurred during deletion'
        notification('Delete Failed', { variant: 'error', subTitle: errorMessage })
      })
      // Update Distribution Series
      .addCase(editDistributionSeriesApi.pending, (state) => {
        state.distributionSeriesLoading = true
      })
      .addCase(editDistributionSeriesApi.fulfilled, (state, action) => {
        state.distributionSeriesLoading = false
        const index = state.distributionSeries.findIndex(series => series.distributionSeriesId === action.meta.arg.urlId)
        if (index !== -1) {
          state.distributionSeries[index] = { ...state.distributionSeries[index], ...action.payload }
        }
        notification('Distribution Series saved successfully', { variant: 'success', subTitle: action.meta.arg?.data?.title })
      })
      .addCase(editDistributionSeriesApi.rejected, (state) => {
        state.distributionSeriesLoading = false
      })
      // Get Slots Associated with Distribution Series
      .addCase(getSeriesSlotApi.pending, (state, action) => {
        if (action.meta.arg.urlId) {
          state.distributionPlansLoading[action.meta.arg.urlId] = true
        }
      })
      .addCase(getSeriesSlotApi.fulfilled, (state, action) => {
        if (action.meta.arg.urlId) {
          state.distributionPlans[action.meta.arg.urlId] = action.payload
          state.distributionPlansLoading[action.meta.arg.urlId] = false
        }
      })
      .addCase(getSeriesSlotApi.rejected, (state, action) => {
        if (action.meta.arg.urlId) {
          state.distributionPlansLoading[action.meta.arg.urlId] = false
        }
      })
      // Create Slots Associated with Distribution Series
      .addCase(createSlotApi.pending, (state, action) => {
        if (action.meta.arg.urlId) {
          state.distributionPlansLoading[action.meta.arg.urlId] = true
        }
      })
      .addCase(createSlotApi.fulfilled, (state, action) => {
        if (action.meta.arg.urlId) {
          state.distributionPlans[action.meta.arg.urlId] = action.payload
          state.distributionPlansLoading[action.meta.arg.urlId] = false
        }
      })
      .addCase(createSlotApi.rejected, (state, action) => {
        if (action.meta.arg.urlId) {
          state.distributionPlansLoading[action.meta.arg.urlId] = false
        }
        const errorMessage = action.payload?.response?.data?.error[0] ?? 'An error occurred during creation'
        notification('Create Failed', { variant: 'error', subTitle: errorMessage })
      })
  }
})
export const { resetState } = distributionSeriesSlice.actions
export default distributionSeriesSlice.reducer
