import React, { useState } from 'react'
import { Alert, Box, AlertTitle } from '@mui/material'
import { ReactComponent as CheckCircleOutlineIcon } from '../../assets/check-with-circle.svg'
import { ReactComponent as InfoOutlinedIcon } from '../../assets/alert-circle.svg'
import GenericButton from '../GenericButton'
import { useTheme } from '@mui/material/styles'
import { ButtonSize } from '../GenericButton/styles'
import { red } from '@mui/material/colors'

interface ValidationAlertProps {
  type?: 'success' | 'error'
  errors?: Record<string, any> | null | unknown
}

const ValidationAlert: React.FC<ValidationAlertProps> = ({ type = 'error', errors }) => {
  const [showAllErrors, setShowAllErrors] = useState(false)
  const theme = useTheme()
  const iconMapping = {
    success: <CheckCircleOutlineIcon width={theme.spacing(5)} height={theme.spacing(5)} />,
    error: <InfoOutlinedIcon width={theme.spacing(5)} height={theme.spacing(5)} />
  }

  const formatErrorResponse = (data: Record<string, any>): string[] => {
    return Object.values(data)?.flatMap((current) =>
      Array.isArray(current) && current.length > 0 ? [current[0]] : []
    )
  }

  const formattedErrors = formatErrorResponse(errors ?? {})

  if (!formattedErrors?.length) {
    return null
  }

  const totalErrors = formattedErrors?.length
  const errorTitle = totalErrors === 1 ? 'Error found' : 'Errors found'

  const handleClick = (): void => {
    setShowAllErrors((prevShowAllErrors) => !prevShowAllErrors)
  }

  return (
    <Box mb={6} mt={5}>
      <Alert iconMapping={iconMapping} variant='outlined' severity={type} sx={{ pb: 0 }}>
        <AlertTitle color={red[700]} sx={{ fontWeight: 600, fontSize: '14px' }}>
          {totalErrors} {errorTitle}:
        </AlertTitle>
        <Box ml={theme.spacing(2)}>
          {showAllErrors
            ? formattedErrors.map((error, index) => <li key={index}>{error}</li>)
            : <li>{formattedErrors[0]}</li>}
        </Box>
        {totalErrors > 1 && (
          <GenericButton
            style={{ padding: 0, marginTop: theme.spacing(3), color: red[600], textDecoration: 'underline', textUnderlineOffset: theme.spacing(0.5) }}
            fontWeight='600'
            buttonSize={ButtonSize.Small}
            label={showAllErrors ? 'Show less' : 'Show more'}
            onClick={handleClick}
          />
        )}
      </Alert>
    </Box>
  )
}

export default ValidationAlert
