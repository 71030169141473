import React from 'react'
import { Box, Typography } from '@mui/material'
import GenericButton from '../../../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../../../components/GenericButton/styles'
import type { IDistributionSeries } from '../../../../models/DistributionSeries'

interface DeleteDistributionSeriesProps {
  distributionSeries: IDistributionSeries
  handleCloseModal?: () => void
  handleDeleteModal?: () => void
}

const DeleteDistributionSeries: React.FC<DeleteDistributionSeriesProps> = ({ distributionSeries, handleCloseModal, handleDeleteModal }): JSX.Element => {
  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        You will not be able to recover this {distributionSeries.title}. All references to it will be deleted.
      </Typography>
      <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
        <GenericButton
          buttonType={ButtonType.Ghost}
          buttonSize={ButtonSize.Small}
          onClick={handleCloseModal} label='Cancel'
        />
        <GenericButton
          buttonType={ButtonType.Destructive}
          buttonSize={ButtonSize.Small}
          onClick={handleDeleteModal}
          label='Delete'
        />
      </Box>
    </Box>
  )
}

export default DeleteDistributionSeries
