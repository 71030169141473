import React, { useState } from 'react'
import { StyledBox } from './styles'
import SurveyTitle from './SurveyTitle'
import { useAppDispatch } from '../../store'
import { addSection, setEditableSurvey, resetState } from '../../store/slices/SurveyTemplateSlice'
import { ButtonType } from '../../components/GenericButton/styles'
import AddIcon from '@mui/icons-material/Add'
import GenericButton from '../../components/GenericButton'
import useAsyncEffect from 'use-async-effect'
import { useParams } from 'react-router-dom'
import { getSurveyByIdApi } from '../../store/slices/SurveyTemplateSlice/apis'
import { getLoading, getSurveyTitle } from '../../store/slices/SurveyTemplateSlice/selectors'
import SurveySections from './SurveySections'
import SurveyHeader from './SurveyHeader'
import PageHeader from '../../components/PageHeader'
import LoadingPageV2 from '../../pages/LoadingPageV2'
import PAGE_TITLES from '../../consts/titles'

const SurveyTemplateFeature: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const loading = getLoading()
  const { id } = useParams()
  const [title, setTitle] = useState(getSurveyTitle())

  const handleSection = (): void => {
    dispatch(addSection())
  }

  useAsyncEffect(async () => {
    if (id) {
      const survey = await dispatch(getSurveyByIdApi({ urlId: id })).unwrap()
      dispatch(setEditableSurvey(survey?.data))
    }
  }, [id])

  if (loading) {
    return <LoadingPageV2 title={PAGE_TITLES.SURVEY_TEMPLATE} />
  }

  return (
    <>
      <PageHeader title={title || PAGE_TITLES.UNTITLED_SURVEY_TEMPLATE} controls={<SurveyHeader />} resetState={resetState} showBackButton isCompactOnly />
      <StyledBox>
        <SurveyTitle onTitleUpdate={setTitle} />
        <SurveySections />
        <GenericButton
          buttonType={ButtonType.Secondary}
          startIcon={<AddIcon />}
          label='Add Section'
          fontWeight='bold'
          onClick={handleSection}
        />
      </StyledBox>
    </>
  )
}

export default SurveyTemplateFeature
