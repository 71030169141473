import React from 'react'
import { ButtonType } from '../../../../components/GenericButton/styles'
import { Typography } from '@mui/material'
import { useAppDispatch } from '../../../../store'
import { grey } from '../../../../colors'
import { useTheme } from '@mui/material/styles'
import DefaultModalActions from '../../../../components/GenericModal/DefaultModalActions'
import { deleteTermsSectionApi } from '../../../../store/slices/termsSectionsSlice/api'
import { actionLoading } from '../../../../store/slices/termsSectionsSlice/selectors'
import { type ISection } from '../../../../models/TermsSection'

interface DeleteSectionProps {
  selectedRow?: ISection | Record<string, any>
  handleCloseModal?: () => void
  showErrorModal?: () => void
  setErrorMessage?: (arg: string) => void
}

const DeleteSection: React.FC<DeleteSectionProps> = ({ selectedRow, handleCloseModal, showErrorModal, setErrorMessage }) => {
  const dispatch = useAppDispatch()
  const loading = actionLoading()
  const theme = useTheme()

  const handleDelete = async (): Promise<void> => {
    try {
      await dispatch(deleteTermsSectionApi({ urlId: selectedRow?.sectionId })).unwrap()
      handleCloseModal?.()
    } catch (error: any) {
      if (error && error.response?.status === 422) {
        const errorMessage = error.response?.data?.error
        setErrorMessage?.(errorMessage)
        showErrorModal?.()
      }
    }
  }

  return (
    <>
      <Typography fontSize='14px' lineHeight={theme.spacing(5)} color={grey[850]}>
      You will not be able to recover this Section. All references to it will be removed.
      </Typography>
      <DefaultModalActions
        confirmButtonProps={{
          onClick: handleDelete,
          text: 'Delete',
          loading,
          buttonType: ButtonType.Destructive
        }}
        destructiveButtonProps={{
          onClick: handleCloseModal
        }}
      />
    </>

  )
}

export default DeleteSection
