import createApiThunk from '../../../ApplicationFactory'
import type { ISurveyList, ISurveyListResponse } from '../../../features/AdminSurveyFeature/types'

export const getSurveyList = createApiThunk<ISurveyListResponse>('getSurveyList', {
  url: '/api/templates/',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const getSurveyByIdApi = createApiThunk<ISurveyList>('getSurveyTemplateById', {
  url: '/api/templates/<urlId>',
  method: 'GET',
  headers: {
    Accept: 'application/cdg'
  }
})

export const createSurveyTemplate = createApiThunk<Record<string, any>>('createSurveyTemplate', {
  url: '/api/templates/',
  method: 'POST'
})

export const deleteSurveyTemplateApi = createApiThunk<Record<string, any>>('deleteSurveyTemplateApi', {
  url: '/api/templates/<urlId>',
  method: 'DELETE'
})

export const archiveSurveyTemplateApi = createApiThunk<Record<string, any>>('archiveSurveyTemplateApi', {
  url: '/api/templates/<urlId>/archive',
  method: 'POST'
})

export const editSurveyTemplate = createApiThunk<Record<string, any>>('editSurveyTemplate', {
  url: '/api/templates/<urlId>',
  method: 'POST'
})
