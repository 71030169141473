import React, { type CSSProperties } from 'react'
import DialogActions from '@mui/material/DialogActions'
import GenericButton from '../../GenericButton'
import { ButtonType } from '../../GenericButton/styles'
import { useTheme } from '@mui/material/styles'

interface IButtonProps {
  onClick?: () => void | Promise<void>
  text?: string
  disabled?: boolean
  buttonType?: ButtonType
  loading?: boolean
  hideButton?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
}

interface DefaultModalActionsProps {
  confirmButtonProps?: IButtonProps
  destructiveButtonProps?: IButtonProps
  wrapperStyle?: CSSProperties
}

const DefaultModalActions: React.FC<DefaultModalActionsProps> = ({
  confirmButtonProps = {},
  destructiveButtonProps = {},
  wrapperStyle
}) => {
  const {
    buttonType: destructiveButtonType = ButtonType.Secondary,
    text: destructiveButtonText = 'Cancel',
    disabled: destructiveButtonDisabled = false,
    loading: destructiveButtonLoading = false,
    onClick: destructiveOnClick,
    hideButton: hideDestructiveButton = false,
    type: typedestructiveButton
  } = destructiveButtonProps

  const {
    buttonType: confirmButtonType = ButtonType.Primary,
    text: confirmButtonText = 'Confirm',
    disabled: confirmButtonDisabled = false,
    loading: confirmButtonLoading = false,
    hideButton: hideConfirmButton = false,
    onClick: onConfirmClick,
    type: confirmConfirmButton

  } = confirmButtonProps

  const theme = useTheme()

  return (
    <DialogActions
      sx={{
        marginTop: 6,
        display: 'flex',
        flexDirection: {
          xs: 'column-reverse',
          sm: 'unset'
        },
        gap: {
          xs: theme.spacing(4),
          sm: 'unset'
        },
        justifyContent: {
          xs: 'unset',
          sm: 'flex-end'
        },
        padding: 0,
        ...wrapperStyle
      }}
    >
      {
        !hideDestructiveButton &&
        <GenericButton
          key="destructiveButton"
          buttonType={destructiveButtonType}
          label={destructiveButtonText}
          onClick={destructiveOnClick}
          disabled={destructiveButtonDisabled}
          loading={destructiveButtonLoading}
          fontWeight="600"
          type={typedestructiveButton}
        />
      }

      {!hideConfirmButton && (
        <GenericButton
          key="confirmButton"
          buttonType={confirmButtonType}
          label={confirmButtonText}
          onClick={onConfirmClick}
          disabled={confirmButtonDisabled}
          loading={confirmButtonLoading}
          fontWeight="600"
          type={confirmConfirmButton}
        />
      )}
    </DialogActions>
  )
}

export default DefaultModalActions
