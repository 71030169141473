/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { Box, Divider, FormControlLabel, Switch, Typography, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { type SubmitHandler, useForm, FormProvider, useFieldArray } from 'react-hook-form'
import GenericButton from '../../../components/GenericButton'
import { type DivisionAndSection, type Role } from '../../../models/Role'
import AddIcon from '@mui/icons-material/Add'
import { ButtonType } from '../../../components/GenericButton/styles'
import { shade } from '../../../colors'
import FormInput from '../../../components/FormElements/FormInput'
import DefaultModalActions from '../../../components/GenericModal/DefaultModalActions'
import { UpdateFacultyAndStaffApi, createFacultyAndStaffApi, createFacultyRoleApi, getFacultyAndStaffApi } from '../../../store/slices/facultyAndStaffSlice/apis'
import { useAppDispatch } from '../../../store'
import { actionLoading } from '../../../store/slices/facultyAndStaffSlice/selectors'
import { notification } from '../../../components/Notifications'
import { isAxiosError } from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import api from '../../../utils/api'
import { type Division } from '../../../models/Division'
import { getDirtyValues } from '../../../utils/onlyUpdatedFields'
import RoleCard from '../RoleCard'
import { isEmpty } from 'lodash'

interface RoleFormData {
  roleId: string
  academicUnitId: string
}

interface FormData {
  firstName: string
  lastName: string
  studentId?: string
  email: string
  roles?: RoleFormData[]
  password?: string
}

const AddRoleButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  padding: '24px',
  my: '24px',
  borderRadius: '8px',
  background: shade[750]
}

const RoleSchema = yup.object().shape({
  roleId: yup.string().required('Role is required'),
  academicUnitId: yup.string().required('Section ID is required')
})

const facultyFormSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  roles: yup.array().of(RoleSchema),
  password: yup.string().test('password', 'Password must be at least 6 characters',
    (password) => {
      if (!password) {
        return true
      }
      if (password.length < 6) {
        return false
      }
      return true
    }
  )
})

interface CreateAndEditFacultyAndStaffProps {
  selectedRow?: any
  handleCloseModal?: () => void
}

const CreateAndEditFacultyAndStaff: React.FC<CreateAndEditFacultyAndStaffProps> = ({ selectedRow, handleCloseModal }) => {
  const [systemAdmin, setSystemAdmin] = useState<boolean>(false)
  const [parsedRolesData, setParsedRolesData] = useState<RoleFormData[]>([])
  const [roleError, setRoleError] = useState<number | undefined>()
  const [deleteLoadingList, setDeleteLoadingList] = useState<number[]>([])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const loading = actionLoading()

  const params = {
    isFaculty: !systemAdmin ?? undefined,
    isAdmin: systemAdmin ?? undefined,
    sort: JSON.stringify({
      field: 'role.role_name',
      direction: 'asc'
    })
  }

  const { data: selectRolesData, isLoading: selectRoleLoading } = useQuery({
    queryKey: ['faculty_roles', systemAdmin],
    staleTime: 50000,
    queryFn: async (): Promise<Role[]> => {
      const result = await api.get('/api/roles/', {
        params
      })
      return result.data
    }
  })

  const selectRoles = selectRolesData ?? []
  const isEditAction = selectedRow !== undefined
  const { firstName, lastName, studentId, email, userId } = selectedRow ?? {}

  const { isLoading: isRolesDataLoading } = useQuery<DivisionAndSection[]>({
    enabled: isEditAction,
    queryKey: ['user_roles', userId],
    queryFn: async (): Promise<DivisionAndSection[]> => {
      const result = await api.get(`/api/users/${userId}/roles/`, {
        params
      })

      const data = result.data.filter((role: DivisionAndSection) => role?.role?.roleType === 'GENERIC')
      return data
    },
    onSuccess: (rolesData) => {
      const newparsedRolesData = rolesData.map(({ roleId, divisionId, sectionId }: DivisionAndSection): RoleFormData => {
        return {
          roleId,
          academicUnitId: divisionId ?? sectionId ?? ''
        }
      })
      setParsedRolesData(newparsedRolesData)
    }
  })

  const methods = useForm<FormData>({
    resolver: yupResolver(facultyFormSchema),
    defaultValues: {
      firstName,
      lastName,
      studentId,
      email,
      roles: []
    }
  })

  const { mutateAsync, isLoading: isRoleCreateLoading } = useMutation({
    mutationFn: async (data: { userId: string }): Promise<any> => {
      const result = await api.post(`/api/users/${data?.userId}/role/`, data)
      return result.data
    }
  })

  const { handleSubmit, control, formState: { isDirty, dirtyFields } } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'roles'
  })

  const onSubmit: SubmitHandler<FormData> = async (values: FormData) => {
    let iter = 0
    setRoleError(undefined)
    try {
      const { roles, password, ...userPayload } = getDirtyValues(dirtyFields, values)

      const apiCall = isEditAction
        ? dispatch(UpdateFacultyAndStaffApi({ urlId: selectedRow.userId, data: userPayload }))
        : dispatch(createFacultyAndStaffApi({ data: { ...userPayload, password: password === '' ? undefined : password } }))

      const res = await apiCall.unwrap()
      const userId = res?.userId
      const rolesList = values?.roles
      if (userId && rolesList) {
        for (const role of rolesList) {
          const payload = {
            divisionId: role.academicUnitId,
            roleId: role.roleId,
            userId
          }
          await mutateAsync(payload).then(() => {}).catch((e) => {
            setRoleError(iter)
            throw e
          })
          remove(0) // remove top item of form when processed
          setParsedRolesData(parsedRolesData.concat([role])) // add top item of form to parsedRolesData
          iter = iter++
        }
      }
      void dispatch(getFacultyAndStaffApi({ }))
      handleCloseModal?.()
      notification(`Faculty ${isEditAction ? 'Updated' : 'Added'} Successfully!`, { variant: 'success' })
    } catch (error) {
      console.error('API call failed:', error)
      if (isAxiosError(error)) {
        if (error.response?.data) {
          const errorMessage = (error.response.data as { error: string }).error || 'Unknown error'
          notification(`Operation Failed: ${JSON.stringify(errorMessage)}`, { variant: 'error' })
        } else {
          notification('Operation Failed: An unknown error occurred', { variant: 'error' })
        }
      } else {
        notification('Operation Failed: An unknown error occurred', { variant: 'error' })
      }
    }
  }

  const addRole = (): any => {
    append({ roleId: '', academicUnitId: '' })
  }

  const deleteRole = async (role: RoleFormData, index: number): Promise<void> => {
    try {
      setDeleteLoadingList(deleteLoadingList.concat([index]))

      await api.delete(`/api/users/${userId}/role/${role.roleId}`)
      setParsedRolesData(parsedRolesData.filter((_, i) => i !== index))
      notification('Role deleted Successfully!', { variant: 'success' })
      setDeleteLoadingList(deleteLoadingList.filter((_, i) => i !== index))
    } catch (error: any) {
      notification(`Operation Failed: Failed to delete role; ${error?.response?.data}`, { variant: 'error' })
    }
  }

  const roleLoading = selectRoleLoading || isRolesDataLoading

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" mt={2} gap={theme.spacing(6)}>
          <FormInput name="firstName" label="First name" placeholder="Enter first name" control={control} required />
          <FormInput name="lastName" label="Last name" placeholder="Enter last name" control={control} required />
          <FormInput name="studentId" label="School ID" placeholder="Enter a School ID" control={control} required />
          <FormInput name="email" label="Email" placeholder="Enter an email" control={control} required />
          {
            !isEditAction &&
            <FormInput name="password" type="password" label="Password" placeholder="Enter a password" control={control} />
          }
        </Box>

        <Divider sx={{ my: '24px' }} />
          <Typography fontWeight={600} color="#000">Academic Unit Roles</Typography>
            {roleLoading &&
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '24px' }}>
                <CircularProgress />
              </Box>
            }
            {parsedRolesData.map((role, index) => (
              <RoleCard
                key={`exist-${index}`}
                selectedRole={role.roleId}
                selectedDivision={role.academicUnitId}
                loading={roleLoading}
                divisionFieldName={`${index}existingdiv`}
                roleFieldName={`${index}existingrole`}
                selectRoles={selectRoles}
                deleteRole={() => {
                  void deleteRole(role, index)
                }}
                readOnly={true}
                deleteLoading={deleteLoadingList.includes(index)}
              />
            ))}
            {fields?.map((field, index) => (
              <RoleCard
                error={index === roleError}
                key={field.id}
                loading={roleLoading}
                divisionFieldName={`roles[${index}].academicUnitId`}
                roleFieldName={`roles[${index}].roleId`}
                selectRoles={selectRoles}
                deleteRole={() => {
                  if (index === roleError) {
                    // remove roleerror if you delete the item
                    setRoleError(undefined)
                  }
                  remove(index)
                }} />
            ))}
            {!roleLoading &&
              <Box sx={fields.length === 0 ? AddRoleButtonStyle : {}}>
                <GenericButton onClick={addRole} startIcon={<AddIcon />} label='Add role' buttonType={ButtonType.Secondary} fontWeight='600' />
              </Box>}
          <Divider sx={{ my: '24px' }} />

        {
        // TODO disable system admin switch for now
        // <Box>
        //   <FormControlLabel control={<Switch checked={ systemAdmin} onChange={() => { setSystemAdmin(!systemAdmin) }} />} label="System admin" />
        // </Box>
        // <Divider sx={{ my: '24px' }} />
        }

        <DefaultModalActions
          destructiveButtonProps={{
            onClick: handleCloseModal
          }}
          confirmButtonProps={{
            text: isEditAction ? 'Save' : 'Create',
            type: 'submit',
            loading: loading || isRoleCreateLoading,
            disabled: isEditAction && !isDirty
          }}
        />
      </form>
    </FormProvider>
  )
}

export default CreateAndEditFacultyAndStaff
