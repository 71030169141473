import React from 'react'
import { StyledBox, StyledBoxWrapper, StyledTextField } from './styles'
import PreparedQuestionCardDropDownMenu from '../PreparedQuestionCardWrapper/PreparedQuestionCardDropDownMenu'
import { useDispatch } from 'react-redux'
import { type DescriptiveQuestion } from '../../../types'
import { getEditableQuestion, getSelectedQuestion } from '../../../../../store/slices/SurveyTemplateSlice/selectors'
import { setEditableQuestion } from '../../../../../store/slices/SurveyTemplateSlice'
import { StyledFormLabel } from '../FreeResponseCard/styles'

interface DescriptiveTextCardProps {
  question: DescriptiveQuestion
  index: number
  sectionIndex: number
  totalQuestionInSection: number
}
const DescriptiveTextCard: React.FC<DescriptiveTextCardProps> = ({ question, index, sectionIndex, totalQuestionInSection }): JSX.Element => {
  const dispatch = useDispatch()
  const editableQuestion = getEditableQuestion()
  const selectedQuestion = getSelectedQuestion()

  const handleEditableQuestion = (): void => {
    if (editableQuestion == null && !selectedQuestion) {
      dispatch(setEditableQuestion({ question, questionIndex: index, sectionIndex }))
    }
  }

  return (
    <StyledBoxWrapper>
      <StyledBox onClick={handleEditableQuestion} id={`${sectionIndex}${index}`}>
      {question?.prompt !== ''
        ? <StyledFormLabel>{question.prompt}</StyledFormLabel>
        : <StyledTextField name='DescriptiveCard' type='text' placeholder='Add text here...' value={question?.prompt} fullWidth />
        }
      </StyledBox>
      <PreparedQuestionCardDropDownMenu questionIndex={index} sectionIndex={sectionIndex} totalQuestionInSection={totalQuestionInSection}/>
    </StyledBoxWrapper>
  )
}

export default DescriptiveTextCard
