import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Typography, Box } from '@mui/material'
import { StyledDropzone } from './styles'
import { ReactComponent as AttachIcon } from '../../assets/paperclip.svg'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as FileIcon } from '../../assets/file.svg'
import { grey, red } from '../../colors'
import { ReactComponent as InfoOutlinedIcon } from '../../assets/alert-circle.svg'
import DefaultModalActions from '../GenericModal/DefaultModalActions'
import CSVFileValidator from 'csv-file-validator'
import Papa from 'papaparse'
import UploadModalAlert from './UploadModalAlert'
import { bytesToKb } from '../../helpers/bytesToKb'
import { notification } from '../Notifications'
import GenericButton from '../GenericButton'
import CloseIcon from '@mui/icons-material/Close'

interface DragAndDropProps {
  onDrop: (files: File) => void
  onConfirm?: () => Promise<void>
  onClose?: () => void
  loading?: boolean
  validationConfig?: any
  alertType?: null | string
  validationErrorBE?: any
  taskId?: string
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({ onDrop, onConfirm, onClose, loading, validationConfig, alertType, validationErrorBE, taskId }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [validationError, setValidationError] = useState<any>(null)

  const theme = useTheme()

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFiles(acceptedFiles)
    onDrop(acceptedFiles[0])

    if (validationConfig) {
      CSVFileValidator(acceptedFiles[0], validationConfig)
        .then((csvData) => {
          const invalidData = csvData.inValidData
          if (invalidData?.length > 0) {
            setValidationError(invalidData)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [selectedFiles, onDrop])

  const {
    getRootProps,
    fileRejections,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: handleDrop,
    noClick: selectedFiles.length > 0 && true,
    maxFiles: 1,
    multiple: false,
    accept: { 'text/csv': ['.csv'] }
  })

  const downloadFile = (data: string, fileName: string): void => {
    console.log('data', data)
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadErrorsAsCSV = (): void => {
    const validationErrors = validationErrorBE || validationError

    if (!validationErrors || !Array.isArray(validationErrors)) {
      notification('No validation errors found or incorrect format', { variant: 'error' })
      return
    }

    const csvData = Papa.unparse(validationErrors)
    const fileName = 'validation-error.csv'
    downloadFile(csvData, fileName)
    notification('Successfully downloaded', { variant: 'success', subTitle: fileName ?? '' })
  }

  const isFileFormateError =
    fileRejections && fileRejections[0]?.errors[0].code === 'file-invalid-type'
  const isFileDrop = selectedFiles.length > 0

  const confirmButtonProps = (): Record<string, any> => {
    switch (true) {
      case isFileFormateError:
        return {
          text: 'Close',
          onClick: onClose
        }
      case alertType === 'success':
        return {
          text: 'Done',
          onClick: onClose
        }
      case Boolean(validationError):
      case alertType === 'warning':
        return {
          text: 'Download Error Summary',
          onClick: downloadErrorsAsCSV
        }
      default:
        return {
          text: 'Upload',
          onClick: onConfirm,
          disabled: !isFileDrop,
          loading
        }
    }
  }

  const styledDropZoneProps = {
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    isFileDrop,
    isError: isFileFormateError
  }

  const getTitle = (): string => {
    switch (true) {
      case isFileFormateError:
        return 'Processing your file, please wait...'
      case alertType === 'warning':
      case alertType === 'success':
        return 'File upload complete, please review'
      case Boolean(validationError):
        return 'File processing complete, please review'
      case isFileDrop:
        return 'File processing complete'
      default:
        return 'Choose a file to upload'
    }
  }

  const validationAlertProps = {
    type: 'error',
    title: 'Error: There are errors in your file.',
    message: 'Please download the Error Summary to correct the row with errors and try again.',
    onDownloadErrors: downloadErrorsAsCSV
  }

  const BeValidationAlertProps = {
    type: 'warning',
    title: 'Partial file upload',
    message: 'We encountered issues while uploading your file. Some rows in your file were not saved. Please download the Error Summary to review those rows and re-submit.',
    onDownloadErrors: downloadErrorsAsCSV
  }

  const successAlertProps = {
    type: 'success',
    title: 'File Upload Received',
    message: 'Your CSV file is being processed. You can now close this modal.',
    taskId
  }

  const showAlert = (): JSX.Element | undefined => {
    switch (true) {
      case alertType === 'success':
        return <UploadModalAlert {...successAlertProps} />
      case alertType === 'warning':
        return <UploadModalAlert {...BeValidationAlertProps} />
      case validationError !== null:
        return <UploadModalAlert {...validationAlertProps} />
      default:
        break
    }
  }

  useEffect(() => {
    if (loading) {
      const handleBeforeUnload = (event: BeforeUnloadEvent): string => {
        event.preventDefault()
        const confirmationMessage = 'Changes you made may not be saved.'
        return confirmationMessage
      }
      window.addEventListener('beforeunload', handleBeforeUnload)
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [loading])

  const handleRemoveFile = (): void => {
    setSelectedFiles([])
    setValidationError(null)
  }

  return (
    <Box>
      <Typography fontSize={'14px'} mb={5}>
        {getTitle()}
      </Typography>
      {showAlert()}
      <StyledDropzone {...getRootProps()} {...styledDropZoneProps}>
        <input {...getInputProps()} />

        {isFileDrop && (
          <Box display='flex' justifyContent='space-between' width='100%' alignItems='center' >
            <Box display="flex" gap={theme.spacing(3)} width="80%" alignItems="center">
              <FileIcon />
              <Box display="flex" flexDirection="column">
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: grey[950] }}>
                  {selectedFiles[0]?.name}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 400, color: grey[850] }}>
                  {bytesToKb(selectedFiles?.[0]?.size)}
                </Typography>
              </Box>
            </Box>
          {!alertType && <GenericButton disabled={loading} icon={<CloseIcon sx={{ color: grey[400] }} /> } onClick={handleRemoveFile} />}
          </Box>
        )}

        {isFileFormateError && (
          <Box display="flex" gap={theme.spacing(3)} width="80%" alignItems="center">
            <InfoOutlinedIcon width={theme.spacing(6)} height={theme.spacing(6)} />
            <Box display="flex" flexDirection="column">
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: red[700] }}>
                Error: File must be in CSV format.
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 400, color: red[600] }}>
                Please try again.
              </Typography>
            </Box>
          </Box>
        )}

        {!isFileDrop && !isFileFormateError && (
          <Box display="flex" justifyContent="center" alignItems="center" gap={theme.spacing(2)}>
            <AttachIcon />
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: grey[950] }}>
              Upload a CSV file
            </Typography>
          </Box>
        )}
      </StyledDropzone>

      <DefaultModalActions
        confirmButtonProps={confirmButtonProps()}
        destructiveButtonProps={{
          onClick: onClose,
          hideButton: isFileFormateError || alertType === 'success',
          disabled: loading,
          text: (validationError || alertType === 'warning') ? 'Close' : 'Cancel'
        }}
      />
    </Box>
  )
}

export default DragAndDrop
