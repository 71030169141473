import React, { useState } from 'react'
import { Collapse, List, Menu, useMediaQuery, useTheme } from '@mui/material'
import { StyledMenuItem, AccountDropdownButton, StyledChip } from './styles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { getPagePermissions } from '../../../store/slices/roleSlice/selectors'
import { getStudentDistributionsList } from '../../../store/slices/studentSurveyListSlice/selectors'
import { StyledListItemButton, StyledListItemText } from '../MobileTabMenu/style'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../consts/paths'

const AccountDropdown: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isOpenForMobile, setIsOpenForMobile] = useState<boolean>(false)

  const theme = useTheme()
  const pagePermissions = getPagePermissions()
  const studentDistributions = getStudentDistributionsList()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentUtcDate = new Date().toISOString()
  const surveyDues = studentDistributions?.filter((survey) => !survey.isSubmitted && currentUtcDate <= survey.endTimestamp)

  const isStudent = pagePermissions?.includes('Student Survey List - Page')

  const navigate = useNavigate()

  const handleSignOut = (): void => {
    navigate(ROUTES.LOGOUT)
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      {!isMobile &&
        <>
          <AccountDropdownButton onClick={(e) => { handleOpenMenu(e) }} >
            Account <ExpandMoreRoundedIcon />
          </AccountDropdownButton>

          <Menu role='list' anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
            disableScrollLock
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{ mt: theme.spacing(2) }}
            slotProps={{
              paper: {
                style: {
                  padding: `${theme.spacing(2)} 0`
                }
              }
            }}
            MenuListProps={{
              style: {
                padding: 0,
                minWidth: theme.spacing(60),
                borderRadius: theme.spacing(2)
              }
            }}
            anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} elevation={3}>
            {
              isStudent &&
              <StyledMenuItem onClick={() => {
                navigate(ROUTES.STUDENT_SURVEYS)
              }}>
                Surveys due
                {
                  surveyDues && surveyDues?.length > 0 &&
                  <StyledChip>{surveyDues?.length}</StyledChip>
                }
              </StyledMenuItem>
            }
            <StyledMenuItem onClick={() => { handleSignOut() }}>
              Logout
            </StyledMenuItem>
          </Menu>
        </>
      }

      {isMobile &&
        <>
          <StyledListItemButton onClick={() => { setIsOpenForMobile(!isOpenForMobile) }} active={isOpenForMobile} >
            <StyledListItemText primary="Account" />
            {isOpenForMobile ? <ExpandLess /> : <ExpandMore />}
          </StyledListItemButton>
          <Collapse in={isOpenForMobile} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                isStudent &&
                <StyledMenuItem >
                  Surveys due
                  <StyledChip>{surveyDues?.length}</StyledChip>
                </StyledMenuItem>
              }
              <StyledMenuItem onClick={() => { handleSignOut() }} >
                Logout
              </StyledMenuItem>
            </List>
          </Collapse>
        </>
      }
    </>
  )
}

export default AccountDropdown
