import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useAppDispatch } from '../../store'
import { getAllDistributionSeriesList, getSeriesSlotApi } from '../../store/slices/distributionSeriesSlice/apis'
import { useTheme } from '@mui/material'
import { CreateAndEditDistributionPlan } from './DistributionPlan/CreateAndEditDistributionPlan'
import { type ApplyFiltersAndDispatchParams, type Row } from '../../components/CustomTable/types'
import ROUTES from '../../consts/paths'
import { useNavigate } from 'react-router-dom'
import { type GridSortItem } from '@mui/x-data-grid'
import DeleteDistributionPlan from './DistributionPlan/DeleteDistributionPlan'
import type { CustomBreadcrumbT } from '../../utils/BreadcrumbDataPreparation'
import GenericButton from '../../components/GenericButton'
import { ButtonSize, ButtonType } from '../../components/GenericButton/styles'
import GenericModal from '../../components/GenericModal'
import CreateEditDistributionSeries from './DistributionSeries/CreateAndEditDistributionSeries'
import DistributionSeriesTopBarAction from './DistributionSeries/DistributionSeriesTopBarActions'
import { getAllSeriesData, getDistributionPlanLoading, getSeriesSlotsList } from '../../store/slices/distributionSeriesSlice/selectors'
import PAGE_TITLES from '../../consts/titles'
import LoadingPageV2 from '../../pages/LoadingPageV2'
import PageHeader from '../../components/PageHeader'

const AppTable = lazy(async () => await import('../../components/CustomTable'))

const DistributionSeries: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const theme = useTheme()

  const distributionSeriesData = getAllSeriesData()
  const distributionPlans = getSeriesSlotsList()
  const distributionPlansLoading = getDistributionPlanLoading()

  const breadcrumbPrefixList: CustomBreadcrumbT[] = [{ title: 'Distribution Series', path: ROUTES.DISTRIBUTION_SERIES }]

  const [perPageSizes, setPerPageSizes] = useState<Record<string, number>>({})
  const [searches, setSearches] = useState<Record<string, string>>({})
  const [sortModels, setSortModels] = useState<Record<string, GridSortItem | undefined>>({})

  const [openModal, setOpenModal] = useState(false)

  const handleCreateSeries = (): void => {
    setOpenModal(true)
  }

  useEffect(() => {
    void dispatch(getAllDistributionSeriesList({}))
  }, [distributionSeriesData.length])

  useEffect(() => {
    const fetchSeriesSlots = async (): Promise<void> => {
      if (distributionSeriesData?.length > 0) {
        for (const series of distributionSeriesData) {
          const seriesId = series.distributionSeriesId
          try {
            await dispatch(getSeriesSlotApi({ urlId: seriesId, params: { pageSize: perPageSizes[seriesId] || 5 } }))
          } catch (error) {
            console.error(`Error fetching series slots for series ID ${seriesId}:`, error)
          }
        }
      }
    }
    void fetchSeriesSlots()
  }, [distributionSeriesData.length])

  const applyFiltersAndDispatch = async ({ pageSize, pageNumber, search, sort: sortModel }: ApplyFiltersAndDispatchParams, seriesId: string): Promise<void> => {
    const sort = sortModel && { field: sortModel.field, direction: sortModel.sort }
    const params = {
      search,
      sort: sortModel ? JSON.stringify(sort) : undefined,
      pageSize,
      pageNumber
    }

    void dispatch(getSeriesSlotApi({ urlId: seriesId, params }))
  }

  const handleRowsPerPageChange = async (pageSize: number, seriesId: string): Promise<void> => {
    setPerPageSizes(prev => ({ ...prev, [seriesId]: pageSize }))
    await applyFiltersAndDispatch({ pageSize, search: searches[seriesId], sort: sortModels[seriesId] }, seriesId)
  }

  const handleSearch = async (value: string, seriesId: string): Promise<void> => {
    setSearches(prev => ({ ...prev, [seriesId]: value }))
    await applyFiltersAndDispatch({ pageSize: perPageSizes[seriesId], search: value, sort: sortModels[seriesId] }, seriesId)
  }

  const handlePageChange = async (page: number, seriesId: string): Promise<void> => {
    await applyFiltersAndDispatch({ pageSize: perPageSizes[seriesId], pageNumber: page, search: searches[seriesId], sort: sortModels[seriesId] }, seriesId)
  }

  const onSortModelChange = async (sortModel: GridSortItem, seriesId: string): Promise<void> => {
    setSortModels(prev => ({ ...prev, [seriesId]: sortModel }))
    await applyFiltersAndDispatch({ pageSize: perPageSizes[seriesId], search: searches[seriesId], sort: sortModel }, seriesId)
  }

  return (
    <Suspense fallback={<LoadingPageV2 title={PAGE_TITLES.DISTRIBUTION_SERIES} />}>
      <PageHeader title={PAGE_TITLES.DISTRIBUTION_SERIES} breadcrumb={breadcrumbPrefixList} controls={<GenericButton
        buttonType={ButtonType.Primary}
        label='Create Series'
        onClick={handleCreateSeries}
        style={{
          flexShrink: 0,
          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }}
      />} />
      <GenericModal
        isOpen={openModal}
        maxWidth='sm'
        title={'Distribution Series'}
        onClose={() => {
          setOpenModal(false)
        }}
        action={false}
      >
        <CreateEditDistributionSeries handleCloseModal={() => { setOpenModal(false) }} />
      </GenericModal>

      {distributionSeriesData?.map((series) => {
        const seriesId = series.distributionSeriesId
        const rows: Row[] = distributionPlans[seriesId]?.data ?? []
        const columns = distributionPlans[seriesId]?.keys ?? []
        const loading = distributionPlansLoading[seriesId]
        const pageSize = perPageSizes[seriesId] || 5
        const searchValue = searches[seriesId] || ''
        const sortModel = sortModels[seriesId]

        // wrap the app table in react.memo

        return (
          <AppTable
            key={seriesId}
            topbarTitle={series.title}
            customTopbarAction={<DistributionSeriesTopBarAction distributionSeries={series} />}
            createAction={<CreateAndEditDistributionPlan distributionSeries={series} />}
            list={distributionPlans[seriesId]}
            rows={rows}
            loading={loading}
            editAction={<CreateAndEditDistributionPlan distributionSeries={series} />}
            deleteAction={<DeleteDistributionPlan />}
            columns={columns}
            createActionTitle='Add Term'
            createButtonProps={
              {
                buttonType: ButtonType.Secondary,
                buttonSize: ButtonSize.Medium
              }
            }
            featureName="Distribution Plan"
            onEditClick={(selectedData: Row) => {
              navigate(`${ROUTES.COURSE_AND_ENROLLMENT}/${selectedData?.data?.slotId as string}`)
            }}
            onSearch={(value: string) => { void handleSearch(value, seriesId) }}
            handlePageChange={(page: number) => { void handlePageChange(page, seriesId) }}
            handleRowsPerPageChange={(pageSize: number) => { void handleRowsPerPageChange(pageSize, seriesId) }}
            onSortModelChange={(sortModel: GridSortItem) => { void onSortModelChange(sortModel, seriesId) }}
            noDataComponentProps={{
              title: 'Start by creating a distribution plan',
              subTitle: 'Plan, coordinate, and execute Surveys by creating a Distribution Plan.'
            }}
          />
        )
      })}
    </Suspense>
  )
}

export default DistributionSeries
