import React from 'react'
import LinearProgress, { type LinearProgressProps } from '@mui/material/LinearProgress'
import { blue, shade } from '../../../../colors'
import { styled } from '@mui/material/styles'
import CardContent from '@mui/material/CardContent'
import theme from '../../../../theme'

export enum SurveyStatusState {
  Released,
  NotReleased,
  NotEnded
}

export interface StyledLinearProgressProps extends LinearProgressProps {
  surveyState: SurveyStatusState
}

export const StyledLinearProgressBar = ({ surveyState, value, ...props }: StyledLinearProgressProps): JSX.Element => {
  const backgroundColor = shade[650]
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: theme.spacing(3),
        backgroundColor,
        '& .MuiLinearProgress-bar1Determinate': {
          backgroundColor: surveyState === SurveyStatusState.NotEnded ? '' : blue[700]
        }
      }}
    />
  )
}

export const CenteredCardContent = styled(CardContent)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px'
})
