import { createSlice } from '@reduxjs/toolkit'
import { getInstructors } from './apis'

interface Role {
  roleId: string
  roleName: string
}

export interface InstructorT {
  createdBy: string
  createdByUsername: string
  createdOn: string
  email: string
  externalId: null
  firstName: string
  isArchived: null
  lastName: string
  middleName: null
  modifiedBy: string
  modifiedByUsername: string
  modifiedOn: string
  role: Role[]
  suffix: null
  userId: string
  username: string
}

export interface InstructorSlice {
  instructors: InstructorT | null
}

const initialState: InstructorSlice = {
  instructors: null
}

const instructorSlice = createSlice({
  name: 'instructor',
  initialState,
  reducers: {
    resetState: state => {
      state.instructors = initialState.instructors
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstructors.fulfilled, (state, action) => {
        state.instructors = action.payload
      })
  }
})
export const { resetState } = instructorSlice.actions
export default instructorSlice.reducer
