import createApiThunk from '../../../ApplicationFactory'
import { type BreadcrumbT } from '../../../components/CustomBreadcrumbs'
import { type SurveyCompletionRate, type Division } from '../../../models/Division'
import { type Report } from '../../../models/Report'
import { type ITerm } from '../../../models/Term'

export const getDivisions = createApiThunk<Division[]>('divisions', {
  url: '/api/divisions/',
  method: 'GET'
})

export const getDivisionCSVExport = createApiThunk<any>('divisionExport', {
  url: '/api/divisions/<urlId>/reports/export/',
  method: 'GET',
  responseType: 'blob'
})

export const getDivisionPDFExport = createApiThunk<any>('divisionPDFExport', {
  url: '/api/divisions/<urlId>/reports/export/pdf/',
  method: 'GET',
  responseType: 'blob'
})

export const getReports = createApiThunk<Report[]>('reports', {
  url: '/api/divisions/<urlId>/reports/',
  method: 'GET'
})

export const getCompletionRate = createApiThunk<SurveyCompletionRate>('completionRate', {
  url: '/api/divisions/<urlId>/completion_rates/',
  method: 'GET'
})

export const getTermsListApi = createApiThunk<ITerm[]>('getTermsListApi', {
  url: '/api/terms/',
  method: 'GET'
})

export const getBreadCrumbList = createApiThunk<BreadcrumbT[]>('breadcrumb', {
  url: '/api/divisions/path/<urlId>',
  method: 'GET'
})
